import React, { useState, useEffect } from "react";

const CountryCode = ({
  countryCode,
  setCountryCode,
  allCountryCode,
  setAllCountryCode,
}) => {
  const [triggerCode, setTriggerCode] = useState(false);

  useEffect(() => {
    if (countryCode) {
      const matchingCountries = allCountryCode.filter(
        (country) =>
          country.dial_code
            .toLowerCase()
            .startsWith(countryCode.toLowerCase()) ||
          country.name.toLowerCase().startsWith(countryCode.toLowerCase()) ||
          country.code.toLowerCase().startsWith(countryCode.toLowerCase())
      );

      const nonMatchingCountries = allCountryCode.filter(
        (country) =>
          !(
            country.dial_code
              .toLowerCase()
              .startsWith(countryCode.toLowerCase()) ||
            country.name.toLowerCase().startsWith(countryCode.toLowerCase()) ||
            country.code.toLowerCase().startsWith(countryCode.toLowerCase())
          )
      );

      setAllCountryCode([...matchingCountries, ...nonMatchingCountries]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  return (
    <div className="relative mb-[28px]">
      <div className="flex items-center">
        <input
          type="text"
          id="code"
          required
          value={countryCode}
          onFocus={() => setTriggerCode(true)}
          onBlur={() => setTriggerCode(false)}
          onChange={(event) => setCountryCode(event.target.value)}
          className="peer bg-gray-50 border border-gray-300 text-gray-900 text-[12px] rounded-lg h-[40px] placeholder-transparent focus:ring-primary focus:border-primary block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white font-medium dark:focus:ring-blue-500 dark:focus:border-blue-500"
          autoComplete="off"
        />
        <img
          src="/svgs/arrow.svg"
          alt=""
          onClick={() => setTriggerCode(!triggerCode)}
          className="transform rotate-180 h-[16px] cursor-pointer mr-2 absolute right-0"
        />
        <label
          htmlFor={"code"}
          className="absolute left-2 -top-5 mb-1 text-sm peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-900 peer-placeholder-shown:text-opacity-40 peer-placeholder-shown:top-[10px] peer-placeholder-shown:left-[13px] transition-all peer-focus:-top-5 peer-focus:text-gray-900 peer-focus:text-opacity-40 peer-focus:text-[12.5px] font-medium text-gray-900 text-opacity-40 dark:text-gray-300"
        >
          Country Code <span className="text-red-600">*</span>
        </label>
      </div>

      {triggerCode && (
        <div className="border max-h-[110px] absolute w-full z-20 bg-white overflow-y-scroll rounded-lg border-primary">
          {allCountryCode?.map((codes) => (
            <span
              // key={result}
              className="w-[100%] flex h-[40px] items-center pl-2 text-[12px] font-[500] cursor-pointer hover:bg-gray-200 "
              onMouseDown={(event) => {
                setCountryCode(codes.dial_code);
                setTriggerCode(false);
                event.stopPropagation();
              }}
            >
              {codes.name} {codes.dial_code}
            </span>
          ))}
        </div>
      )}
    </div>
    // <div className="w-[20%] relative">
    //   <input
    //     type="text"
    //     id="code"
    //     required
    //     value={countryCode}
    //     onFocus={() => setTriggerCode(true)}
    //     onBlur={() => setTriggerCode(false)}
    //     onChange={(event) => setCountryCode(event.target.value)}
    //     className="form-input w-full"
    //     autoComplete="off"
    //   />

    //   {triggerCode && (
    //     <div className="border max-h-[145px] z-20  absolute bg-white overflow-y-scroll rounded-lg w-full">
    //       {allCountryCode?.map((codes) => (
    //         <span
    //           className="w-[100%] flex h-[40px] items-center pl-2 text-[12px] font-[500] cursor-pointer hover:bg-gray-200 "
    //           onMouseDown={(event) => {
    //             setCountryCode(codes.dial_code);
    //             event.stopPropagation();
    //           }}
    //         >
    //           {codes.code} {codes.dial_code}
    //         </span>
    //       ))}
    //     </div>
    //   )}
    // </div>
  );
};

export default CountryCode;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideToast } from "../../redux/actions/toastActions";

const Toast = () => {
  const dispatch = useDispatch();
  const toast = useSelector((state) => state.toastNotifications);

  useEffect(() => {
    let timer;
    if (toast?.show) {
      timer = setTimeout(() => {
        dispatch(hideToast());
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [toast?.show]);

  return (
    <>
      {toast?.category === "success" ? (
        <div
          className={`${
            toast?.show ? "flex" : "hidden"
          } mymd:w-full h-[150px] w-full z-50 fixed bottom-[40px]  md:left-0 md:right-0 mx-auto md:top-[100px] md:-translate-y-1/2 justify-center items-center `}
        >
          <div className=" flex bg-white rounded-lg p-3 text-black border border-[#2ECC71] justify-center items-center text-[16px] font-[500] overflow-hidden md:max-w-[500px] mx-[20px] md:min-w-[250px]">
            <div className="w-[23px] mr-2">
              <svg
                viewBox="0 0 48 48"
                width="23"
                height="23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_118_2684)">
                  <path
                    d="M24 4C12.96 4 4 12.96 4 24C4 35.04 12.96 44 24 44C35.04 44 44 35.04 44 24C44 12.96 35.04 4 24 4ZM20 34L10 24L12.82 21.18L20 28.34L35.18 13.16L38 16L20 34Z"
                    fill="#2ECC71"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_118_2684">
                    <rect width="48" height="48" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <p>{toast?.message}</p>
          </div>
        </div>
      ) : toast?.category === "danger" ? (
        <div
          className={`${
            toast?.show ? "flex" : "hidden"
          } mymd:w-full h-[150px] w-full z-50 fixed bottom-[70px]  md:left-0 md:right-0 mx-auto md:top-[100px] md:-translate-y-1/2 justify-center items-center `}
        >
          <div className=" flex bg-white rounded-lg p-3 text-black border border-[#E74C3C] justify-center items-center text-[16px] font-[500] overflow-hidden md:max-w-[500px] mx-[20px] md:min-w-[250px]">
            <div className="w-[23px] mr-2">
              <svg
                width="23"
                height="23"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 0C7.8225 0 0 7.8225 0 17.5C0 27.1775 7.8225 35 17.5 35C27.1775 35 35 27.1775 35 17.5C35 7.8225 27.1775 0 17.5 0ZM26.25 23.7825L23.7825 26.25L17.5 19.9675L11.2175 26.25L8.75 23.7825L15.0325 17.5L8.75 11.2175L11.2175 8.75L17.5 15.0325L23.7825 8.75L26.25 11.2175L19.9675 17.5L26.25 23.7825Z"
                  fill="#E74C3C"
                />
              </svg>
            </div>
            <p>{toast?.message}</p>
          </div>
        </div>
      ) : toast?.category === "warning" ? (
        <div
          className={`${
            toast?.show ? "flex" : "hidden"
          } mymd:w-full h-[150px] w-full z-50 fixed bottom-[40px]  md:left-0 md:right-0 mx-auto md:top-[100px] md:-translate-y-1/2 justify-center items-center`}
        >
          <div className=" flex bg-white rounded-lg p-3 text-black border border-primary justify-center items-center text-[16px] font-[500] overflow-hidden md:max-w-[500px] mx-[20px] md:min-w-[250px]">
            <img src="/svgs/info.svg" alt="info" className="mr-2 w-[25px]" />
            <p>{toast?.message}</p>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Toast;

import { HIDE_TOAST, SHOW_TOAST } from "../constants/toastConstants";

const initialState = {
  message: "",
  category: "",
  show: false,
};

const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TOAST:
      console.log(action.payload.message);
      return {
        message: action.payload.message,
        category: action.payload.category,
        show: true,
      };
    case HIDE_TOAST:
      return null;
    default:
      return state;
  }
};

export default toastReducer;

import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import TextInput from "../../../../common/inputElements/TextInput";
import { useForm } from "react-hook-form";
import throttle from "../../../../utils/throttle";
import { useMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../../../redux/actions/toastActions";
import { patchRequest } from "../../../../utils/API/api.ts";

export default function AddMailchimp({
  open,
  setOpen,
  event,
  isEdit,
  setIsEdit,
  eventDetails,
  setEventDetails,
}) {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const eventsId = useMatch("/events/:eventId/*");
  const [accessToken, setAccessToken] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({ mode: "onChange" });
  useEffect(() => {
    if (isEdit) {
      reset({
        apikey:
          eventDetails.mailchimpData?.apiKey.substring(
            0,
            eventDetails?.mailchimpData?.apiKey.length - 7
          ) + "*".repeat(7),
        listId: eventDetails.mailchimpData?.listId,
        server: eventDetails.mailchimpData?.server,
      });
    } else {
      reset({
        apikey: "",
        listId: "",
        server: "",
      });
    }
  }, [event?._id, open]);

  const onSubmit = async (data) => {
    const obj = {
      apiKey: data.apikey,
      listId: data.listId,
      server: data.server,
    };
    setIsSubmitting(true);
    try {
      const updatedEvent = await patchRequest(
        `/event/${eventsId.params.eventId}`,
        {
          mailchimpData: obj,
        }
      );
      if (updatedEvent.status === 200) {
        setEventDetails(updatedEvent.data.savedEventConfig);
        dispatch(
          showToast({
            message: "Details Added Successfully",
            category: "success",
          })
        );
      }
      setOpen(false);
      setIsSubmitting(false);
      setIsEdit(false);
    } catch (err) {
      dispatch(
        showToast({
          message: "Some Error occured",
          category: "danger",
        })
      );
      setOpen(false);
      setIsSubmitting(false);
      isSubmitting(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    setAccessToken(token);
  }, []);
  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="z-40 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="z-40 fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-[45px] md:left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md outline-none hidden md:block text-gray-300"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>X
                          {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
                        </button>
                        <button
                          className="md:hidden flex items-center"
                          onClick={() => setOpen(false)}
                        >
                          <i className="fa fa-angle-left text-[24px]"></i>
                          <span className="text-[17px] pt-0.5">Back</span>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6 fixed z-20 bg-white w-[100%] top-0">
                        <Dialog.Title className="text-[22px] pb-[25px] font-[600] text-gray-900 pt-5">
                          {isEdit ? "Edit Details" : "Connect Mailchimp"}
                        </Dialog.Title>
                      </div>
                      <div className="relative mt-[75px] flex-1 px-4 sm:px-6 mb-[50px]">
                        <form
                          onSubmit={handleSubmit((data) =>
                            throttle(() => onSubmit(data), 5000)
                          )}
                        >
                          <TextInput
                            label="Api Key"
                            register={register}
                            type="text"
                            id="apikey"
                            required
                          />

                          <div className="my-[40px]">
                            <TextInput
                              label="List ID / Audience ID"
                              register={register}
                              type="text"
                              id="listId"
                              required
                            />
                          </div>

                          <TextInput
                            label="Mailchimp Server"
                            register={register}
                            type="text"
                            id="server"
                            required
                          />

                          <div className="fixed bottom-0 w-[87%] bg-white  h-[80px] flex justify-center items-center ml-[-2px]">
                            {isEdit ? (
                              <input
                                disabled={isSubmitting}
                                value={
                                  isSubmitting ? "Loading..." : "Save Changes"
                                }
                                type="submit"
                                className="primary_submit"
                              />
                            ) : (
                              <input
                                disabled={isSubmitting}
                                value={isSubmitting ? "Loading..." : "Connect"}
                                type="submit"
                                className="primary_submit"
                              />
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddEmailSchedule from "./AddEmailSchedule";
import AddEmailAndDomain from "./AddEmailAndDomain";
import { patchAuthenticatedRequest } from "../../../../utils/API/api.ts";
import EditDefaultEmail from "./EditDefaultEmail";
import { getSingleEvent } from "../../../../redux/actions/eventActions";
import { useMatch } from "react-router-dom";
import ScheduledEmailStats from "./ScheduledEmailStats";
const EmailMarketing = () => {
  const dispatch = useDispatch();
  const [emailDetails, setEmailDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDefaultEmail, setOpenDefaultEmail] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deletEmail, setDeleteEmail] = useState("");
  const [singleEmail, setsingleEmail] = useState([]);
  const [defaultEmail, setDefaultEmail] = useState("");
  const [testEmail, setTestEmail] = useState(false);
  const event = useSelector((state) => state.eventData);
  const [open3, setOpen3] = useState(false);
  const [emailBody, setEmailBody] = useState("");
  const eventsId = useMatch("events/:eventId/*");
  const targetRef = useRef([]);
  const now = new Date().getTime();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (event?._id) {
      setEmailDetails(event);
    }
  }, [event]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, []);

  useEffect(() => {
    dispatch(getSingleEvent({ eventId: eventsId.params.eventId }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = async (email) => {
    const filteredEmail = emailDetails.scheduledEmails.filter(
      (scheduledEmails) => {
        return scheduledEmails._id !== email._id;
      }
    );
    const updatedEmails = { ...emailDetails, scheduledEmails: filteredEmail };
    setEmailDetails(updatedEmails);

    await patchAuthenticatedRequest(`/event/${event._id}`, {
      scheduledEmails: filteredEmail,
    });
    setDeleteEmail("");
  };

  const showInfo = (index) => {
    targetRef.current[index].style.display = "block";
  };

  const hideInfo = (index) => {
    targetRef.current[index].style.display = "none";
  };

  return (
    <div className="w-full md:w-[375px] md:ml-[0px] md:mt-[27px] pb-[200px]">
      <div className="font-[600] w-[375px] md:w-[800px] text-[24px] text-[#585858] flex justify-between items-center mt-0">
        Scheduled Emails
        <div className="w-[160px] ">
          <div
            className="primary_submit"
            onClick={() => {
              setIsEdit(false);
              setTestEmail(false);
              setOpen(true);
            }}
          >
            Schedule Email
          </div>
        </div>
      </div>
      <div className="border w-[800px] h-[285px] mt-4 rounded-lg overflow-auto pb-1">
        {loading ? (
          <div className="text-center h-full w-[100%]">
            <div className="h-full w-full grid place-items-center">
              <svg
                className="inline mr-2 w-11 h-11 text-gray-200 animate-spin dark:text-gray-600 fill-primary"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          </div>
        ) : emailDetails?.scheduledEmails?.length > 0 ? (
          emailDetails?.scheduledEmails
            ?.sort((b, a) => {
              const timeA = new Date(
                new Date(a.campaignTime).toLocaleString("en-US", {
                  timeZone: "Asia/Kolkata",
                })
              ).getTime();
              const timeB = new Date(
                new Date(b.campaignTime).toLocaleString("en-US", {
                  timeZone: "Asia/Kolkata",
                })
              ).getTime();
              return timeB - timeA;
            })
            .map((email, index) => {
              return (
                <div
                  className={`px-4 border-b hover:bg-[#f3f6fa] cursor-pointer relative`}
                  onClick={() => {
                    setOpen(true);
                    setIsEdit(true);
                    setTestEmail(false);
                    setsingleEmail(email);
                  }}
                >
                  <div className="flex h-[70px] items-center " key={email._id}>
                    <div>
                      <img src="/svgs/clock.svg" alt="clock" />
                    </div>
                    <div className=" w-full ml-4">
                      <div className="flex items-center mr-7">
                        <img
                          src="/svgs/calender.svg"
                          className="mr-2"
                          alt="calendar"
                        />
                        <span className="text-[15px] line-clamp-1">
                          {email.emailSubject}
                        </span>
                      </div>
                      <p className="text-[13px] mt-1.5 text-gray-500">
                        <div className="flex">
                          To:
                          <span className="text-primary font-[500]">
                            &nbsp;
                            {email.emailTo[0]?.label === "Send to all"
                              ? "All"
                              : email.emailTo[0]?.label}
                            -&nbsp;
                          </span>
                          <span className="text-primary font-[500]">
                            {email.filters.length > 0 ? (
                              <>{email.filters[0]?.label}&nbsp;-&nbsp;</>
                            ) : (
                              <></>
                            )}
                          </span>
                          {new Date(
                            new Date(email.campaignTime).toLocaleString(
                              "en-US",
                              {
                                timeZone: "Asia/Kolkata",
                              }
                            )
                          ).getTime() <= now ? (
                            <div className="text-green-600 font-medium flex">
                              Sent:&nbsp;
                              {new Date(email.campaignTime).toLocaleString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                }
                              )}
                              ,{" "}
                              {new Date(email.campaignTime).toLocaleString(
                                "en-US",
                                {
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                }
                              )}
                            </div>
                          ) : (
                            <div className="text-orange-500 font-medium	">
                              Scheduled:&nbsp;
                              {new Date(email.campaignTime).toLocaleString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                }
                              )}
                              ,{" "}
                              {new Date(email.campaignTime).toLocaleString(
                                "en-US",
                                {
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                }
                              )}
                            </div>
                          )}
                        </div>
                      </p>
                    </div>
                    {new Date(
                      new Date(email.campaignTime).toLocaleString("en-US", {
                        timeZone: "Asia/Kolkata",
                      })
                    ).getTime() -
                      30 * 60 * 1000 <=
                    now ? (
                      <>
                        {new Date(
                          new Date(email.campaignTime).toLocaleString("en-US", {
                            timeZone: "Asia/Kolkata",
                          })
                        ).getTime() <= now ? (
                          <div className="flex w-[80px] justify-end ">
                            {deletEmail === email._id ? (
                              <div className="flex w-[70px] justify-between">
                                <img
                                  src="/svgs/done.svg"
                                  alt="done"
                                  className="w-6 h-6 cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDelete(email);
                                  }}
                                />
                                <img
                                  src="/svgs/Cross.svg"
                                  alt="delete"
                                  className="w-6 h-6 cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setDeleteEmail("");
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="flex w-[70px] justify-between ">
                                <img
                                  src="/svgs/Delete.svg"
                                  alt="delete"
                                  title="Delete"
                                  className="w-6 h-6 cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setDeleteEmail(email._id);
                                  }}
                                />
                                <img
                                  src="/svgs/stats.svg"
                                  alt="stats"
                                  title="Stats"
                                  className="w-6 h-6 cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setOpen3(true);
                                    setsingleEmail(email);
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div
                            className="cursor-pointer relative "
                            onMouseEnter={() => showInfo(index)}
                            onMouseLeave={() => hideInfo(index)}
                          >
                            <img src="/svgs/info.svg" alt="info" />

                            <div
                              className="absolute z-20 bg-gray-700 h-[90px] text-[12px] w-[150px] p-2 ml-5 rounded-md	text-white text-center hidden"
                              ref={(element) => {
                                targetRef.current[index] = element;
                              }}
                            >
                              Emails cannot be deleted or edited 30 minutes
                              before the scheduled time.
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="flex w-[80px] justify-between">
                        {deletEmail === email._id ? (
                          <>
                            <img
                              src="/svgs/done.svg"
                              alt="done"
                              className="w-6 h-6 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(email);
                              }}
                            />
                            <img
                              src="/svgs/Cross.svg"
                              alt="delete"
                              className="w-6 h-6 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                setDeleteEmail("");
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src="/svgs/Edit.svg"
                              alt="edit"
                              title="Edit"
                              className="w-6 h-6 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpen(true);
                                setIsEdit(true);
                                setTestEmail(false);
                                setsingleEmail(email);
                              }}
                            />
                            <img
                              src="/svgs/Delete.svg"
                              alt="delete"
                              className="w-6 h-6 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                setDeleteEmail(email._id);
                              }}
                            />
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            })
        ) : (
          <div className="flex flex-col justify-center items-center w-full h-full gap-4 overflow-hidden">
            <img src="/svgs/nullState.svg" alt="" className="w-[250px]" />
            <span className="font-[500] ">No Emails Scheduled</span>
          </div>
        )}
      </div>

      <div className="mt-5 text-[20px] text-[#585858] font-[600]">
        Registration Confirmation
      </div>
      <div
        className="hover:bg-[#f3f6fa] cursor-pointer border w-[800px]  mt-5 rounded-lg"
        onClick={() => {
          setEmailBody("");
          setOpenDefaultEmail(true);
          setDefaultEmail("registration");
          setTestEmail(false);
        }}
      >
        <div className="flex h-[70px] items-center px-4">
          <div>
            <img src="/svgs/clock.svg" alt="clock" />
          </div>
          <div className=" w-full ml-4">
            <div className="flex items-center mr-5">
              <img src="/svgs/calender.svg" className="mr-2 " alt="calandar" />
              <span className="text-[15px] line-clamp-1">
                {emailDetails?.defaultEmails?.resgistrationConfirmationEmail
                  .emailBody !== "none"
                  ? emailDetails?.defaultEmails?.resgistrationConfirmationEmail
                      .emailSubject
                  : `Registration confirmed for ${event?.title}`}
              </span>
            </div>
            <p className="text-[13px] mt-1.5 text-gray-500">
              <div className="flex">
                To:
                <span className="text-primary font-[500]">
                  &nbsp;Registered -&nbsp;
                </span>
                <div className="text-green-600 font-medium	">
                  Sent:&nbsp; As soon as registered
                </div>
              </div>
            </p>
          </div>
          <div className="flex w-[80px] justify-between">
            <img
              src="/svgs/Edit.svg"
              alt="edit"
              className="w-6 h-6 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setEmailBody("");
                setOpenDefaultEmail(true);
                setDefaultEmail("registration");
                setTestEmail(false);
              }}
            />
            <img
              src="/svgs/stats.svg"
              alt="stats"
              title="Stats"
              className="w-6 h-6 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setOpen3(true);
                setsingleEmail({
                  _id: eventsId.params.eventId,
                  emailSubject: "Registration confirmed",
                  campaignTime: event?.startDate,
                  isRegistrationEmail: true,
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="mt-5 text-[20px] text-[#585858] font-[600]">
        Speaker Invite
      </div>
      <div
        className="border w-[800px]  mt-5 rounded-lg scrollbar-hide hover:bg-[#f3f6fa] cursor-pointer"
        onClick={(e) => {
          setEmailBody("");
          setDefaultEmail("speaker");
          setOpenDefaultEmail(true);
        }}
      >
        <div className="flex  h-[70px] items-center px-4">
          <div>
            <img src="/svgs/clock.svg" alt="clock" />
          </div>
          <div className=" w-full ml-4">
            <div className="flex items-center mr-5">
              <img src="/svgs/calender.svg" className="mr-2" alt="calendar" />
              <span className="text-[15px] line-clamp-1">
                {emailDetails?.defaultEmails?.speakerConfirmationEmail
                  .emailBody !== "none"
                  ? emailDetails?.defaultEmails?.speakerConfirmationEmail
                      .emailSubject
                  : `You are invited as a speaker at ${event?.title}`}
              </span>
            </div>
            <p className="text-[13px] mt-1.5 text-gray-500">
              <div className="flex">
                To:
                <span className="text-primary font-[500]">
                  &nbsp;Registered -&nbsp;
                </span>
                <div className="text-green-600 font-medium	">
                  Sent:&nbsp; As soon as added
                </div>
              </div>
            </p>
          </div>
          <img
            src="/svgs/Edit.svg"
            alt="edit"
            className="w-6 h-6 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setEmailBody("");
              setDefaultEmail("speaker");
              setOpenDefaultEmail(true);
            }}
          />
        </div>
      </div>
      <AddEmailSchedule
        open={open}
        setOpen={setOpen}
        event={event}
        setEmailDetails={setEmailDetails}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        testEmail={testEmail}
        setTestEmail={setTestEmail}
        emailDetails={emailDetails}
        singleEmail={singleEmail}
      />

      <EditDefaultEmail
        open={openDefaultEmail}
        setOpen={setOpenDefaultEmail}
        event={emailDetails}
        emailDetails={emailDetails}
        defaultEmail={defaultEmail}
        setEmailDetails={setEmailDetails}
        testEmail={testEmail}
        setTestEmail={setTestEmail}
        emailBody={emailBody}
        setEmailBody={setEmailBody}
      />

      <AddEmailAndDomain
        open={open2}
        setOpen={setOpen2}
        event={event}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        singleEvent={event}
      />

      <ScheduledEmailStats
        open={open3}
        setOpen={setOpen3}
        singleEmail={singleEmail}
        event={event}
      />
    </div>
  );
};

export default EmailMarketing;

import React, { useEffect, useRef, useState } from "react";
import { useMatch, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import QrScanner from "qr-scanner";
import { patchRequest } from "../../../../utils/API/api.ts";
import { showToast } from "../../../../redux/actions/toastActions.js";
import { useReactToPrint } from "react-to-print";
import socketIOClient from "socket.io-client";
const url = new URL(process.env.REACT_APP_SERVER_URL);
const baseUrl = `${url.protocol}//${url.host}`;
const PrintAndCheckinBadge = ({
  setTriggerScan,
  triggerScan,
  camera,
  setCamera,
}) => {
  const eventsId = useMatch("events/:eventId/*");
  const dispatch = useDispatch();
  const [badgeUrl, setBadgeUrl] = useState("");
  const [searchParams] = useSearchParams();
  const location = searchParams.get("location");
  const [socketData, setSocketData] = useState({});
  let videoElemRef = useRef();
  const qrScannerRef = useRef(null);
  const [latestCheckedinUsers, setLatestCheckedinUsers] = useState([]);
  const errors = [
    "Event or Attendee not found",
    "Invalid checkin location.",
    "Invalid badge",
    "Attendee has not checked in at the main gate yet",
    "Already Checked In",
    "Attendee Not Checked In Yet",
  ];
  useEffect(() => {
    if (socketData) {
      const newSocket = socketIOClient("https://server.warpbay.com");
      newSocket.emit("checkin", {
        ...socketData,
      });
      return () => {
        newSocket.disconnect();
      };
    }
  }, [socketData]);

  const checkInAttendee = async (val, uidParam) => {
    try {
      const res = await patchRequest(
        `attendee/${uidParam}/${eventsId.params.eventId}?val=${val}&location=${location}&userId=${uidParam}`
      );

      if (res.status === 200 && triggerScan === "checkin") {
        setSocketData({
          eventId: eventsId.params.eventId,
          user: {
            firstName: res.data.userData?.firstName,
            lastName: res.data.userData?.lastName,
            profilePicture: res.data.userData?.profilePicture,
          },
        });
        let userData = res?.data.userData;
        setLatestCheckedinUsers((prev) => {
          const updatedUsers = [...prev, userData]?.slice(-5);
          return updatedUsers;
        });
        res.data.data.eventSpecificData.map((data) => {
          if (data.eventId === eventsId.params.eventId) {
            setBadgeUrl(data.badgeUrl);
          }
        });
      } else {
        dispatch(
          showToast({
            message: "Failed to check in attendee",
            category: "danger",
          })
        );
      }
    } catch (err) {
      if (errors?.includes(err?.response?.data?.message)) {
        dispatch(
          showToast({
            message: err?.response?.data?.message,
            category: "danger",
          })
        );
      } else {
        dispatch(
          showToast({
            message: "Something Went Wrong",
            category: "danger",
          })
        );
      }
    }
  };

  useEffect(() => {
    let timerId;
    qrScannerRef.current = new QrScanner(
      videoElemRef.current,
      (result) => {
        const urlObj = new URL(result.data);
        const uidParam = urlObj.searchParams.get("uid");

        if (uidParam) {
          if (triggerScan === "checkin") {
            checkInAttendee("checkin", uidParam);
            qrScannerRef.current.stop();
            timerId = setTimeout(() => {
              qrScannerRef.current.start();
            }, 2000);
          }
        } else {
          console.log("nothing");
        }
      },
      {
        onDecodeError: (error) => {
          console.log(error);
        },
        highlightScanRegion: true,
        highlightCodeOutline: true,
        preferredCamera: camera,
        // calculateScanRegion: true,
        maxScansPerSecond: 10,
      }
    );

    qrScannerRef.current.start();
    return () => {
      clearTimeout(timerId);
      qrScannerRef.current.stop();
    };
  }, []);

  const handleClose = () => {
    qrScannerRef.current?.destroy();
    setTriggerScan("");
  };

  const changeCamera = async (id) => {
    setTriggerScan("");
    setCamera(camera === "environment" ? "user" : "environment");
    setTimeout(() => {
      setTriggerScan(triggerScan);
    }, 500);
  };

  useEffect(() => {
    function preventBackgroundScroll(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    document.body.style.overflow = "hidden";
    document.addEventListener("scroll", preventBackgroundScroll, {
      passive: false,
    });

    return () => {
      document.body.style.overflow = "visible";
      document.removeEventListener("scroll", preventBackgroundScroll);
    };
  }, []);

  useEffect(() => {
    if (badgeUrl) {
      setTimeout(() => {
        PrintImage();
      }, 2700);
    }
  }, [badgeUrl]);

  const PrintImage = useReactToPrint({
    content: () => {
      const imgElement = document.createElement("img");
      imgElement.src = badgeUrl;
      imgElement.style.width = "97%";
      imgElement.style.height = "97%";
      imgElement.style.display = "block";
      const containerElement = document.createElement("div");
      containerElement.style.display = "flex";
      containerElement.style.justifyContent = "center";
      containerElement.style.alignItems = "center";
      containerElement.style.width = "100%";
      containerElement.style.height = "100%";
      containerElement.appendChild(imgElement);
      return containerElement;
    },
    onAfterPrint: () => {
      setBadgeUrl("");
    },
  });

  return (
    <>
      <div className="pt-0 md:pt-0 md:mb-0 w-full md:w-full h-[100%] relative mymd:flex">
        <video
          id="videoElem"
          ref={videoElemRef}
          className={`relative mymd:w-[calc(100vw_-_400px)] w-full h-[calc(100vh_-_200px)] mymd:h-full object-fill mymd:rounded-[15px]`}
        ></video>
        <div className="fixed top-[0px] left-0 right-0 flex justify-center items-center w-full text-white mymd:w-[calc(100vw_-_400px)] font-medium text-[20px] pt-[30px]">
          <p className="bg-gradient-to-b from-transparent via-[rgba(0,0,0,0.1)] to-transparent">
            Scan Badge
          </p>
          <div className="flex justify-center items-center w-[26px] h-[26px] rounded-full bg-gray-500 cursor-pointer fixed right-[25px] mymd:top-[10px] mymd:right-[15px]">
            <img
              src="/svgs/Cross.svg"
              alt="cross"
              className="w-[18px]"
              onClick={handleClose}
            />
          </div>
          <div className=" fixed flex justify-center items-center w-[26px] h-[26px] rounded-full bg-gray-500 cursor-pointer right-[60px] mymd:left-[20px]">
            <img
              src="/svgs/switch-camera.svg"
              alt="camera"
              className="w-[16px]"
              onClick={changeCamera}
              title="Switch Camera"
            />
          </div>
        </div>
        <div className="h-[200px] mymd:h-full mymd:w-[400px] p-2 mymd:p-4 w-full overflow-auto pb-[80px] bg-gray-100 mymd:rounded-[15px]">
          <div className="w-[full] mymd:mt-8">
            {/* <p className="font-medium mymd:text-[18px]">
            Total {triggerScan === "checkin" ? "checkedin" : "checkedout"}:{" "}
            <span className="text-green-600 font-medium">10</span>
          </p> */}
          </div>

          <p className="font-medium mymd:mb-3 mymd:mt-4 my-1.5 text-[14px] mymd:text-[18px]">
            Latest {triggerScan === "checkin" ? "checkedin" : "checkedout"}{" "}
            users: (Last 5)
          </p>
          <div>
            {latestCheckedinUsers?.length > 0 ? (
              latestCheckedinUsers
                ?.map((user, index) => {
                  return (
                    <div
                      className="border p-1 mymd:p-2 rounded-md mb-1 flex items-center bg-gray-50 font-medium mymd:text-[18px]"
                      key={index}
                    >
                      <img
                        src="/svgs/Checkcircle.svg"
                        alt="check"
                        className="mr-1 rounded-full w-[24px] mymd:w-[26px]"
                      />
                      {user?.firstName?.trim() + " " + user?.lastName.trim()}
                    </div>
                  );
                })
                ?.reverse()
            ) : (
              <div className="border p-1 mymd:p-2 rounded-md mb-0.5 flex items-center bg-gray-50 font-medium mymd:text-[18px]">
                N/A
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintAndCheckinBadge;

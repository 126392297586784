import React, { useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddCustomDomain from "./AddCustomDomain";
import VerifyEmailAndDomain from "./VerifyEmailAndDomain";
import AddSenderEmail from "./AddSenderEmail";

const EmailDomainWhitelable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const event = useSelector((state) => state.eventData);
  const [isEdit, setIsEdit] = useState(false);
  //   const eventsId = useMatch("/events/:eventId/*");

  return (
    <div className="w-full md:w-[845px] md:ml-[0px] md:mt-[27px] pb-[200px] ">
      <div className="font-[600] w-[275px] mx-auto md:w-[845px] text-[21px] text-black flex items-center mt-2">
        <div className="cursor-pointer mr-3" onClick={() => navigate(-1)}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 12H5"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12 19L5 12L12 5"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        White Label Email Domain
      </div>

      <div className="mt-[20px] w-[250px]">
        <h2 class="mb-2 text-[18px] font-[500] text-gray-600 dark:text-white">
          Please follow the steps
        </h2>
        <ol class=" dark:text-gray-400">
          <li
            className="cursor-pointer hover:text-primary flex items-center"
            onClick={() => {
              setOpen(true);
              setIsEdit(false);
            }}
          >
            <img src="/svgs/AddCircle.svg" alt="" className="mr-2" />
            <span class="text-[17px] font-[500] text-gray-600 dark:text-white hover:text-primary underline">
              Connect Custom Domain
            </span>
          </li>
          <li
            className="cursor-pointer hover:text-primary flex items-center my-3"
            onClick={() => {
              setOpen2(true);
              setIsEdit(false);
            }}
          >
            <img src="/svgs/AddCircle.svg" alt="" className="mr-2" />
            <span class="text-[17px] font-[500] text-gray-600 dark:text-white hover:text-primary underline">
              Verify Custom Domain
            </span>
          </li>
          <li
            className="cursor-pointer hover:text-primary flex items-center"
            onClick={() => {
              setOpen3(true);
              setIsEdit(false);
            }}
          >
            <img src="/svgs/AddCircle.svg" alt="" className="mr-2" />
            <span class="text-[17px] font-[500] text-gray-600 dark:text-white hover:text-primary underline">
              Add Sender Email
            </span>
          </li>
        </ol>
      </div>

      <AddCustomDomain
        open={open}
        setOpen={setOpen}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        singleEvent={event}
      />

      <VerifyEmailAndDomain
        open={open2}
        setOpen={setOpen2}
        singleEvent={event}
      />

      <AddSenderEmail open={open3} setOpen={setOpen3} singleEvent={event} />
    </div>
  );
};

export default EmailDomainWhitelable;

import React, { useEffect, useState } from "react";
import { patchRequest } from "../../../../../utils/API/api.ts";
import { useMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../../../../redux/actions/toastActions.js";
import { getSingleEvent } from "../../../../../redux/actions/eventActions.js";
import OpenEmailNotifications from "./OpenEmailNotifications.jsx";

const EmailNotifications = () => {
  const eventsId = useMatch("/events/:eventId/*");
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const event = useSelector((state) => state.eventData);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(getSingleEvent({ eventId: eventsId.params.eventId }));
  }, []);

  useEffect(() => {
    if (event) {
      if (event?.isEmailNotification) {
        setToggle(true);
      }
    }
  }, [event]);

  const handleToggle = async (checked) => {
    try {
      const updatedEvent = await patchRequest(
        `/event/${eventsId.params.eventId}`,
        {
          isEmailNotification: checked,
        }
      );
      if (updatedEvent.status === 200) {
        setToggle(updatedEvent.data?.savedEventConfig?.isEmailNotification);
        setOpen(false);
        dispatch(
          showToast({
            message: "Successfully Updated",
            category: "success",
          })
        );
      }
    } catch (err) {
      setOpen(false);
      dispatch(
        showToast({
          message: "Some Error occured",
          category: "danger",
        })
      );
    }
  };
  return (
    <div className="w-full md:w-[800px] md:ml-[0px] md:mt-[27px] pb-[200px] ">
      <div className="font-[600] mx-auto md:w-[800px] text-[24px] text-[#585858] flex justify-between items-center mt-2">
        <span className="">
          Email Notifications
          <span className="text-[15px] text-primary"></span>
        </span>

        <p className="text-[13px] font-normal	text-[#A55EEA] flex justify-center items-center">
          <label
            htmlFor="whatsapp"
            className="inline-flex relative items-center cursor-pointer"
          >
            <input
              type="checkbox"
              id={"whatsapp"}
              onChange={(e) => handleToggle(e.target.checked)}
              checked={toggle}
              className="sr-only peer"
            />
            <span className="w-[57px] h-[30px] bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[25px] after:w-[25px] after:transition-all dark:border-gray-600 peer-checked:bg-primary"></span>
          </label>
        </p>
      </div>
      <div className="border w-[800px] min-h-[300px] mt-5 rounded-lg">
        <div
          className="flex border-b h-[70px] items-center px-4 hover:bg-[#f9fbff] cursor-pointer"
          onClick={() => {
            setOpen(true);
          }}
        >
          <div>
            <img src="/svgs/clock.svg" alt="clock" />
          </div>
          <div className=" w-full ml-4">
            <div className="flex items-center mr-5">
              <img src="/svgs/calender.svg" className="mr-2 " alt="calandar" />
              <span className="text-[15px] line-clamp-1">
                Warpbay EOD Report - {event.title}
              </span>
            </div>
            <p className="text-[13px] mt-1.5 text-gray-500">
              <div className="flex">
                To:
                <span className="text-primary font-[500]">
                  &nbsp;{event?.primaryOrganizer?.email} -&nbsp;
                </span>
                <div className="text-green-600 font-medium	">
                  Sent:&nbsp; Everyday at 07:30 pm
                </div>
              </div>
            </p>
          </div>
          {/* <div className="flex">
            <img
              src="/svgs/info.svg"
              alt="info"
              className="cursor-pointer"
              onClick={() => {
                setOpen(true);
              }}
            />
      
          </div> */}
        </div>
      </div>
      <OpenEmailNotifications
        open={open}
        setOpen={setOpen}
        event={event}
        handleToggle={handleToggle}
        toggle={toggle}
      />
    </div>
  );
};

export default EmailNotifications;

import React from "react";

const Home = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color ? color : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1395_3443"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill={color ? color : "#D9D9D9"} />
      </mask>
      <g mask="url(#mask0_1395_3443)">
        <path
          d="M4 21V11.625L2.2 13L1 11.4L12 3L23 11.4L21.8 12.975L20 11.625V21H4ZM8 15C7.71667 15 7.479 14.904 7.287 14.712C7.09567 14.5207 7 14.2833 7 14C7 13.7167 7.09567 13.479 7.287 13.287C7.479 13.0957 7.71667 13 8 13C8.28333 13 8.521 13.0957 8.713 13.287C8.90433 13.479 9 13.7167 9 14C9 14.2833 8.90433 14.5207 8.713 14.712C8.521 14.904 8.28333 15 8 15ZM12 15C11.7167 15 11.4793 14.904 11.288 14.712C11.096 14.5207 11 14.2833 11 14C11 13.7167 11.096 13.479 11.288 13.287C11.4793 13.0957 11.7167 13 12 13C12.2833 13 12.521 13.0957 12.713 13.287C12.9043 13.479 13 13.7167 13 14C13 14.2833 12.9043 14.5207 12.713 14.712C12.521 14.904 12.2833 15 12 15ZM16 15C15.7167 15 15.4793 14.904 15.288 14.712C15.096 14.5207 15 14.2833 15 14C15 13.7167 15.096 13.479 15.288 13.287C15.4793 13.0957 15.7167 13 16 13C16.2833 13 16.5207 13.0957 16.712 13.287C16.904 13.479 17 13.7167 17 14C17 14.2833 16.904 14.5207 16.712 14.712C16.5207 14.904 16.2833 15 16 15Z"
          fill={color ? color : "#C5C5C7"}
        />
      </g>
    </svg>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { getRequest } from "../../../../utils/API/api.ts";
import { useDispatch, useSelector } from "react-redux";
import { getSingleEvent } from "../../../../redux/actions/eventActions";
import axios from "axios";
import ExtendedTable2 from "./ExtendedTable2";
import ExtendedTable from "./ExtendedTable.jsx";
import RegistrationSearch from "../../../../common/navbar/RegistrationSearch.jsx";

const ExtendedTableView = () => {
  const navigate = useNavigate();
  const [tabOptions, setTabOptions] = useState([]);
  const eventsId = useMatch("/events/:eventId/*");
  const [userData, setUserData] = useState([]);
  const [userData2, setUserData2] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [next, setNext] = useState();
  const [previous, setPrevious] = useState();
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const [tab, setTab] = useState("");
  const [triggerLabel, setTriggerLabel] = useState(false);
  const [regForm, setRegForm] = useState([]);
  const event = useSelector((state) => state.eventData);
  const searchValue = useSelector((state) => state.searchRegistration);
  const [selectedLabel, setSelectedLabel] = useState({
    registerdLabel: "",
    filterdLabel: "",
  });
  useEffect(() => {
    if (event?.isRegistrationApproval) {
      setTabOptions([
        "All Guests",
        "Attended",
        "Not attended",
        "Approved",
        "Pending",
        "Rejected",
      ]);
      setTab("All Guests");
    } else {
      setTabOptions(["Registered", "Attended", "Not attended"]);
      setTab("Registered");
    }
  }, [event]);

  useEffect(() => {
    dispatch(getSingleEvent({ eventId: eventsId.params.eventId }));
    return () => {};
  }, []);

  useEffect(() => {
    async function fetchData() {
      setNext(null);
      const data = await getRequest(
        `/attendee/${eventsId.params.eventId}/search/attendee?name=${searchValue.value}&search=${tab}`
      );
      if (tab === "Registered") {
        setUserData([...data.data.registrations.attendees]);
        setNext(null);
        setPrevious(null);
        setTotal(data.data.registrations.attendees?.length);
        setTotalPages(0);
        setCurrentPage(0);
      }
    }

    if (searchValue.value) {
      fetchData();
    } else if (searchValue.value === "" || searchValue.value === " ") {
      getAllEventAttendees(`/attendee/${eventsId.params.eventId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue.value, tab]);

  const getAllEventAttendees = async (route) => {
    const response = await getRequest(route);
    setUserData(response.data?.attendees);
    setNext(response?.data?.next);
    setPrevious(response?.data?.previous);
    setTotal(response?.data?.totalAttendees[0]?.total);
    setTotalPages(response.data?.totalPages);
    setCurrentPage(response.data?.currentPage);
  };

  const getAttendedAttendees = async (route) => {
    const response = await getRequest(route);
    setNext(response?.data?.next);
    setPrevious(response?.data?.previous);
    setTotal(response?.data?.totalAttendees);
    setTotalPages(response.data?.totalPages);
    setCurrentPage(response.data?.currentPage);
    setUserData(response?.data?.registrations);
  };

  const getAttendeesApprovalStatus = async (url) => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}${url}`)
      .then(function (response) {
        setCurrentPage(response.data.currentPage);
        setTotal(response.data.totalCount);
        setTotalPages(response.data.totalPages);
        setNext(response.data.next);
        setPrevious(response.data.previous);
        setUserData2(response.data.attendees);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchUserData = (url, pageNo) => {
    let page = pageNo || 1;

    switch (tab) {
      case "All Guests":
        url
          ? getAttendeesApprovalStatus(url)
          : getAttendeesApprovalStatus(
              `/attendee/${eventsId.params.eventId}/getAttendeesApprovalStatus?page=${pageNo}`
            );
        break;
      case "Attended":
        url
          ? getAttendedAttendees(url)
          : getAttendedAttendees(
              `attendee/${eventsId.params.eventId}/attended/?hasAttended=true&location=eventCheckin&page=${page}`
            );
        break;
      case "Not attended":
        url
          ? getAttendedAttendees(url)
          : getAttendedAttendees(
              `attendee/${eventsId.params.eventId}/attended/?hasAttended=false&location=eventCheckin&page=${page}`
            );
        break;
      case "Approved":
        url
          ? getAllEventAttendees(url)
          : getAllEventAttendees(
              `/attendee/${eventsId.params.eventId}?page=${page}`
            );
        break;
      case "Registered":
        url
          ? getAllEventAttendees(url)
          : getAllEventAttendees(
              `/attendee/${eventsId.params.eventId}?page=${page}`
            );
        break;
      case "Pending":
        url
          ? getAttendeesApprovalStatus(url)
          : getAttendeesApprovalStatus(
              `/attendee/${eventsId.params.eventId}/getAttendeesApprovalStatus?status=pending&page=${page}`
            );
        break;
      case "Rejected":
        url
          ? getAttendeesApprovalStatus(url)
          : getAttendeesApprovalStatus(
              `/attendee/${eventsId.params.eventId}/getAttendeesApprovalStatus?status=rejected&page=${page}`
            );
        break;

      default:
        getAllEventAttendees(
          `/attendee/${eventsId.params.eventId}?page=${page}`
        );
    }
  };

  useEffect(() => {
    if (eventsId.params.eventId) {
      fetchUserData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, eventsId]);

  useEffect(() => {
    const getAdditionalForm = async () => {
      const response = await getRequest(
        `/event/${eventsId.params.eventId}/additionalForm`
      );
      if (response.data[0]?.form) {
        let updatedRegForm = response.data[0]?.form?.filter((form) => {
          return (
            form.inputType !== "city" &&
            form.inputType !== "country" &&
            form.inputType !== "state"
          );
        });
        setRegForm(updatedRegForm);
      }
    };
    if (eventsId.params.eventId) {
      getAdditionalForm();
      dispatch(getSingleEvent({ eventId: eventsId.params.eventId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsId]);
  return (
    <>
      <div className="sticky top-0.5 z-50 bg-white">
        <div className="md:w-full  flex items-center mt-0 bg-white pt-[7px] h-[42px]">
          <div
            className="cursor-pointer ml-3 mr-2"
            onClick={() =>
              navigate(`/events/${eventsId.params.eventId}?show=registrations`)
            }
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 12H5"
                stroke="#585858"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 19L5 12L12 5"
                stroke="#585858"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <span className="font-[600] text-[17px] text-[#585858]">
            {event?.title}
          </span>

          {tab === "Registered" && (
            <RegistrationSearch style={{ margin: "0 0 0 20px " }} />
          )}
        </div>
        <div className="pl-10 bg-white w-full mb-[0px]">
          <div className="relative flex items-center justify-between">
            <div
              className="flex items-center justify-center w-[125px] rounded-lg bg-gray-100 font-medium text-[14px] h-[30px]"
              onMouseEnter={() => setTriggerLabel(true)}
              onMouseLeave={() => setTriggerLabel(false)}
            >
              {tab}
              <img
                src="/svgs/arrow.svg"
                alt="arrow"
                className="w-[15px] font-[600] ml-[5px] cursor-pointer rotate-180"
              />
            </div>

            {triggerLabel && (
              <div
                className="absolute w-[125px] top-[25px] pt-4 bg-transperent z-40"
                onMouseEnter={() => setTriggerLabel(true)}
                onMouseLeave={() => {
                  setTriggerLabel(false);
                }}
              >
                <div
                  className="bg-white border max-h-[130px] rounded-lg overflow-y-scroll "
                  style={{
                    boxShadow: "0px 0px 14px rgba(165, 94, 234, 0.2)",
                  }}
                >
                  {tabOptions?.map((label, index) => {
                    return (
                      <div
                        className="border-b hover:bg-gray-100 cursor-pointer grid place-items-center text-[14px] font-[500] p-1.5"
                        key={index}
                        onClick={() => {
                          setTab(label);
                          setTriggerLabel(false);
                        }}
                      >
                        {label}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            <div className="flex items-center mr-4 pb-[3px]">
              <p className="text-[14px] font-[500] mr-2">
                Sorted By:{" "}
                <span className="text-primary">
                  {tab === "Registered"
                    ? selectedLabel.registerdLabel
                      ? selectedLabel.registerdLabel
                      : "No labels"
                    : selectedLabel.filterdLabel
                    ? selectedLabel.filterdLabel
                    : "No labels"}
                </span>
              </p>
              <div className="text-[13px] mr-2">
                <span className="font-semibold text-[14px]">
                  {tab === "All Guests" ||
                  tab === "Pending" ||
                  tab === "Rejected"
                    ? userData2?.length
                    : userData?.length}
                </span>{" "}
                of <span className="font-semibold text-[14px]">{total}</span>{" "}
                results
              </div>

              <button
                className={`text-center cursor-pointer text-${
                  previous === null ? "gray-300" : "primary"
                } text-[23px]`}
                disabled={previous === null}
                onClick={async () => {
                  fetchUserData(previous);
                }}
              >
                {"<"}
              </button>

              <div className="mx-3 cursor-pointer">
                <select
                  id="title"
                  name="title"
                  required
                  value={currentPage}
                  onChange={(e) => {
                    fetchUserData(null, e.target.value);
                  }}
                  className={`w-[75px]
                  peer bg-white border-2 border-primary rounded-[5px] text-gray-900 text-[12px] placeholder-transparent focus:ring-transparent py-0 bg-[right] 1.5rem cursor-pointer
               `}
                >
                  {Array.from({ length: totalPages }).map((_, index) => (
                    <option
                      key={index + 1}
                      value={index + 1}
                      className="hover:bg-gray-300 border-primary rounded-[5px] overflow-scroll max-h-[50px]"
                    >
                      {index + 1}
                    </option>
                  ))}
                </select>
              </div>

              <button
                disabled={next === null}
                className={`text-center cursor-pointer text-${
                  next === null ? "gray-300" : "primary"
                } text-[23px]`}
                onClick={async () => {
                  fetchUserData(next);
                }}
              >
                {">"}
              </button>
            </div>
          </div>
        </div>
      </div>
      {tab === "All Guests" || tab === "Pending" || tab === "Rejected" ? (
        <ExtendedTable2
          userData={userData2}
          event={event}
          regForm={regForm}
          getAllEventAttendees={getAllEventAttendees}
          getAttendedAttendees={getAttendedAttendees}
        />
      ) : (
        <ExtendedTable
          userData={userData}
          event={event}
          total={total}
          tab={tab}
          next={next}
          previous={previous}
          totalPages={totalPages}
          currentPage={currentPage}
          regForm={regForm}
          getAllEventAttendees={getAllEventAttendees}
          getAttendedAttendees={getAttendedAttendees}
          selectedLabel={selectedLabel}
          setSelectedLabel={setSelectedLabel}
        />
      )}
    </>
  );
};

export default ExtendedTableView;

import React from "react";
import DefaultProfilePicture from "../../common/defaultProfilePicture/DefaultProfilePicture";
import styles from "./Landing.module.css";

const LandingSpeakers = ({ singleEvent }) => {
  return (
    <div className={styles.speakers_grid}>
      {singleEvent.speakers && singleEvent.speakers.length > 0 ? (
        singleEvent.speakers.map((speakerData, key) => (
          <>
            <div className={styles.single_speaker_tab} key={key}>
              <div className="flex items-center">
                {speakerData.profilePicture ? (
                  <img
                    src={speakerData.profilePicture}
                    className="rounded-full sm:w-[40px] sm:h-[40px] w-[40px] h-[40px] object-cover mr-3"
                    alt="profile"
                  />
                ) : (
                  <div className="mr-2">
                    <DefaultProfilePicture
                      firstName={speakerData.firstName}
                      lastName={speakerData.lastName}
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "300px",
                        fontSize: "14px",
                      }}
                    />
                  </div>
                )}

                <div className="">
                  <div className="sm:text-[14px] text-[13px] font-semibold pt-[5px] ">
                    {speakerData.firstName} {speakerData.lastName}
                  </div>
                  <div className="text-[12px] font-medium py-[0px] text-gray-500 line-clamp-1">
                    {speakerData.jobTitle}, {speakerData.organization}
                  </div>
                </div>
              </div>
              <div className="text-[rgba(0,0,0,0.5)] text-sm font-semibold mt-2 ml-[45px] ">
                {speakerData.speaker.eventSpecificData[0].bio}
              </div>
              <div className="mt-3 ">
                <span className="flex items-center cursor-pointer text-[#0E76A8] text-[15px] font-bold ml-[45px] border w-[160px] px-1.5 justify-center border-[#0E76A8] rounded-[15px] py-1">
                  <a
                    href={speakerData.linkedinUrl}
                    className="flex items-center"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="/svgs/linkedinblue.svg"
                      alt="share"
                      className="mr-2 w-[20px]"
                    />
                    Visit Linkedin
                  </a>
                </span>
              </div>
            </div>
          </>
        ))
      ) : (
        <div className="grid w-full place-items-center h-[250px]">
          <div>
            <img
              src="/svgs/nullState.svg"
              alt=""
              className="w-[200px] h-[200px]"
            />
            <p className="text-[15px] font-[500] text-[#717171]  text-center">
              Nothing here...
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingSpeakers;

// @ts-nocheck
import React, { useEffect, useState } from "react";
import Primarybtn from "../../../common/buttons/Primarybtn";
import CustomModal from "../../../common/modals/Modal";
import { getRequest } from "../../../utils/API/api.ts";
import AddSpeaker from "./AddSpeaker";
import SpeakerCSVUpload from "./BulkUploadSpeaker";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import DefaultProfilePicture from "../../../common/defaultProfilePicture/DefaultProfilePicture";
import { UPDATE_EVENT } from "../../../redux/constants/eventConstants";
import { showToast } from "../../../redux/actions/toastActions";
import PrimaryButton from "../../../common/buttons/PrimaryButton";
import { Button, Modal } from "flowbite-react";
import axios from "axios";

function Speakers() {
  const [open, setOpen] = useState(false);
  const [speakers, setSpeakers] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [singleSpeaker, setSingleSpeaker] = useState({});
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const eventsId = useMatch("/events/:eventId");
  const event = useSelector((state) => state.eventData);
  const searchValue = useSelector((state) => state.searchSpeaker);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const data = await getRequest(
        `/attendee/${eventsId.params.eventId}/search/speaker?name=${searchValue.value}`
      );
      setSpeakers([...data.data.registrations.speakers]);
    }
    // setLoading(false);
    if (searchValue.value) {
      fetchData();
    } else if (searchValue.value === "" || searchValue.value === " ") {
      setSpeakers(event.speakers);
    }
  }, [searchValue.value]);

  useEffect(() => {
    if (event.speakers) {
      setSpeakers(event.speakers);
    }
  }, [event.speakers]);

  const deleteSpeaker = (speaker) => {
    axios
      .delete(
        `${process.env.REACT_APP_SERVER_URL}/user/${speaker?._id}/${eventsId.params.eventId}?role=speaker`
      )
      .then(function (response) {
        setSpeakers(response?.data?.updatedData);
        setOpenModal(false);
        setSingleSpeaker({});
        dispatch({
          type: UPDATE_EVENT,
          payload: {
            speakers: response?.data?.updatedData,
          },
        });
        dispatch(
          showToast({
            message: `User deleted successfully`,
            category: "success",
          })
        );
      })
      .catch(function (error) {
        console.log(error);
        dispatch(
          showToast({
            message: "Something went wrong!!",
            category: "danger",
          })
        );
      });
  };

  return (
    <>
      <Modal
        show={openModal}
        size="md"
        onClose={() => setOpenModal(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <h3 className="mb-5 text-[14px] font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to delete this speaker from the event?
            </h3>
            <div className="flex justify-center gap-4">
              <Button
                color="failure"
                onClick={() => {
                  deleteSpeaker(singleSpeaker);
                }}
              >
                {"Yes, I'm sure"}
              </Button>
              <Button color="gray" onClick={() => setOpenModal(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="w-full md:w-[422px] md:ml-[0px] md:mt-[0] pb-5">
        <div className="py-0">
          <div className="font-[600] w-[335px] mx-auto md:w-[422px] text-[24px] pt-3 text-black md:flex items-center justify-between fixed bg-white z-10 min-h-[82px]">
            <div className="text-[21px] mt-0.5">Speakers</div>

            {speakers && speakers.length > 0 ? (
              <div className="w-[335px] md:w-[150px]">
                <PrimaryButton
                  btnText={"Add Speakers"}
                  onClick={() => {
                    setOpen(true);
                    setIsEdit(false);
                  }}
                  style={{ height: "35px" }}
                />
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="mt-2 mb-[50px] w-[335px] mx-auto md:w-[430px] overflow-y-auto scrollbar pt-[75px] absolute pb-[50px]">
            {speakers && speakers.length > 0 ? (
              speakers.map((speaker) => (
                <div key={speaker._id}>
                  <div className="my-4 flex justify-between">
                    <div className="flex items-center">
                      {speaker.profilePicture ? (
                        <img
                          src={speaker.profilePicture}
                          className="rounded-full w-[50px] h-[50px] object-cover"
                          alt="speaker"
                        />
                      ) : (
                        <div className=" ">
                          <DefaultProfilePicture
                            firstName={speaker.firstName}
                            lastName={speaker.lastName}
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "300px",
                              fontSize: "17px",
                            }}
                          />
                        </div>
                      )}

                      <div className="pl-2.5 w-[197px]">
                        <div className="text-[14px] font-semibold py-1">
                          {speaker.firstName} {speaker.lastName}
                        </div>
                        <div className="text-sm font-medium py-1 text-gray-500">
                          {speaker.jobTitle}, {speaker.organization}
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-x-2.5 mr-6 items-center">
                      <img
                        src="/svgs/Edit.svg"
                        alt="edit"
                        className="w-6 h-6 cursor-pointer"
                        onClick={() => {
                          let bio = "";
                          speaker.speaker.eventSpecificData.find((ele) => {
                            if (ele.eventId === eventsId.params.eventId) {
                              bio = ele.bio;
                            }
                          });
                          setOpen(true);
                          setIsEdit(true);
                          setSingleSpeaker({
                            firstName: speaker.firstName,
                            lastName: speaker.lastName,
                            jobTitle: speaker.jobTitle,
                            organization: speaker.organization,
                            email: speaker.email,
                            bio: bio,
                            linkedinUrl: speaker.linkedinUrl,
                            profilePicture: speaker.profilePicture,
                          });
                        }}
                      />
                      <img
                        src="/svgs/Delete.svg"
                        alt="delete"
                        className="w-6 h-6 cursor-pointer"
                        onClick={() => {
                          setSingleSpeaker(speaker);
                          setOpenModal(true);
                        }}
                      />
                    </div>
                    {/* <div
                  className="edit cursor-pointer"
                >
                  <i class="fa-regular fa-pen-to-square"></i>
                </div> */}
                  </div>
                  <div className="w-[325px] md:w-[422px]">
                    <hr />
                  </div>
                </div>
              ))
            ) : (
              <div className="grid w-full h-[300px]">
                <div>
                  <img
                    src="/svgs/nullState.svg"
                    alt=""
                    className="w-[200px] h-[200px]"
                  />
                  <p className="text-[15px] font-[500] text-[#717171]">
                    No Speakers available...
                  </p>
                </div>

                <div className="w-[335px] md:w-[250px]">
                  <Primarybtn
                    onClick={() => {
                      setOpen(true);
                      setIsEdit(false);
                    }}
                  >
                    Add Speakers
                  </Primarybtn>
                </div>
              </div>
            )}
          </div>

          {/* <div className="w-[335px] mx-auto md:w-[422px]">
          <SecondaryButton
            btnText={"Upload CSV"}
            onClick={() => {
              console.log("clicked");
              setIsBulkUpload(true);
            }}
          />
        </div>
        <div className="w-[335px] mx-auto md:w-[422px]">
          <span className="text-primary font-normal text-sm cursor-pointer pt-5">
            Download the CSV format
          </span>
        </div> */}

          <AddSpeaker
            open={open}
            setOpen={setOpen}
            event={event}
            speakers={speakers}
            setSpeakers={setSpeakers}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            singleSpeaker={singleSpeaker}
            setSingleSpeaker={setSingleSpeaker}
          />
          <div className="">
            <CustomModal isOpen={isBulkUpload} setIsOpen={setIsBulkUpload}>
              <SpeakerCSVUpload setIsBulkUpload={setIsBulkUpload} />
            </CustomModal>
          </div>
        </div>
      </div>
    </>
  );
}

export default Speakers;

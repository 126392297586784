import React, { useEffect, useRef, useState } from "react";
import DefaultProfilePicture from "../../../../common/defaultProfilePicture/DefaultProfilePicture";

const ExtendedTable2 = ({ userData, event, regForm }) => {
  const [scrollDirection, setScrollDirection] = useState(null);
  const containerRef = useRef(null);
  function formatTime(regDate) {
    if (regDate) {
      const utcDate = new Date(regDate);
      const options = {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZone: "Asia/Kolkata",
      };

      const formatter = new Intl.DateTimeFormat("en-IN", options);
      const dateString = formatter.format(utcDate);

      return dateString;
    }
    return null;
  }

  const getAdditionalInfo = (attendee, form) => {
    let eventSpecificData;
    if (Array.isArray(attendee)) {
      eventSpecificData = attendee[0]?.eventSpecificData?.find(
        (data) => data.eventId === event?._id
      );
    } else {
      eventSpecificData = attendee?.eventSpecificData?.find(
        (data) => data.eventId === event?._id
      );
    }

    if (eventSpecificData && eventSpecificData?.additionalData) {
      let field = eventSpecificData.additionalData[form?.fieldName] || null;
      if (typeof field === "boolean") {
        field = field === true ? "Yes" : "No";
      }

      return Array.isArray(field) ? field : [field];
    }

    return [];
  };

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      if (container) {
        if (container.scrollLeft > 0) {
          setScrollDirection("horizontal");
        } else if (container.scrollTop > 0) {
          setScrollDirection("vertical");
        } else {
          setScrollDirection(null);
        }
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <div className="overflow-auto " style={{ height: "calc(100vh - 80px)" }}>
      <table className="table-auto	">
        {/* Table header */}
        <thead className="text-sm font-semibold uppercase text-slate-500  border-b-1 border-slate-200 sticky top-0 z-30 ">
          <tr>
            <th
              className={`px-2 first:pl-3 last:pr-5 py-[8px] whitespace-nowrap border bg-gray-100 ${
                scrollDirection === "vertical" ? "" : "sticky left-0"
              }`}
            >
              <div className="font-semibold text-left">Users</div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-[8px] whitespace-nowrap border bg-gray-100 ">
              <div className="font-semibold text-center">Status</div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-[8px] whitespace-nowrap border bg-gray-100 ">
              <div className="font-semibold text-left">Contact Info</div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-[8px] whitespace-nowrap border bg-gray-100 ">
              <div className="font-semibold text-left">Registration date</div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-[8px] whitespace-nowrap border bg-gray-100 ">
              <div className="font-semibold text-left">Country</div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-[8px] whitespace-nowrap border bg-gray-100 ">
              <div className="font-semibold text-left">City</div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-[8px] whitespace-nowrap border bg-gray-100 ">
              <div className="font-semibold text-left">State</div>
            </th>
            {regForm?.map((form) => {
              return (
                <th className="px-2 first:pl-5 last:pr-5 py-[8px] whitespace-nowrap border bg-gray-100 ">
                  <div className="font-semibold text-left">{form?.label}</div>
                </th>
              );
            })}
          </tr>
        </thead>
        {/* Table body */}
        <tbody className="md:w-[100%] ">
          {userData &&
            userData.length > 0 &&
            userData.map((attendee) => {
              return (
                <tr key={attendee?._id}>
                  <td
                    className={` px-2 first:pl-3 last:pr-5 py-1 w-full  whitespace-nowrap border ${
                      scrollDirection === "vertical"
                        ? "bg-white"
                        : "sticky left-0 bg-[#f9fbff]"
                    }`}
                  >
                    <div className="grid grid-cols-[45px_1fr] gap-[5px]">
                      {attendee.user?.profilePicture ? (
                        <img
                          src={attendee?.user?.profilePicture}
                          className="w-[33px] h-[33px] rounded-[50%] object-cover"
                          alt="profile"
                        />
                      ) : (
                        <div className=" ">
                          <DefaultProfilePicture
                            firstName={attendee?.user?.firstName}
                            lastName={attendee?.user?.lastName}
                            style={{
                              width: "33px",
                              height: "33px",
                              borderRadius: "300px",
                              fontSize: "13px",
                            }}
                          />
                        </div>
                      )}

                      <span className="text-[12px] font-[500] w-full">
                        <p>
                          {attendee?.user?.firstName?.charAt(0).toUpperCase() +
                            attendee?.user?.firstName?.slice(
                              1,
                              attendee?.user?.firstName?.length
                            )}{" "}
                          {attendee?.user?.lastName}
                        </p>
                        <p className="text-[12px] text-slate-500 w-[250px] font-[450] whitespace-normal break-words ">
                          {attendee?.user?.jobTitle && attendee?.user?.jobTitle}
                          ,{" "}
                          {attendee?.user?.organization &&
                            attendee?.user?.organization}
                        </p>
                      </span>
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap text-[12px] font-[500] border text-center">
                    {attendee?.status === "approved" ? (
                      <p className="p-[2px] font-[500] text-green-500 grid place-items-center w-[80px] bg-green-100 rounded-[12px] text-[12px] h-[27px]">
                        Approved
                      </p>
                    ) : attendee?.status === "rejected" ? (
                      <p className="p-[2px] font-[500] text-red-500 grid place-items-center w-[80px] bg-red-100 rounded-[12px] text-[12px] h-[27px]">
                        Rejected
                      </p>
                    ) : attendee?.status === "pending" ? (
                      <p className="p-[2px] font-[500] text-yellow-500 grid place-items-center w-[80px] bg-yellow-100 rounded-[12px] text-[12px] h-[27px]">
                        Pending
                      </p>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-1 text-[14px] font-[500] border whitespace-nowrap ">
                    <div className="text-[12px] font-[500] grid grid-rows-2 w-full">
                      <p>{attendee?.user?.email && attendee?.user?.email}</p>
                      <span className="flex">
                        <p className="text-[12px] text-slate-500 font-[500]">
                          {attendee?.user?.mobile &&
                          attendee?.user?.countryCode ? (
                            attendee?.user?.countryCode
                          ) : (
                            <></>
                          )}
                          &nbsp;
                        </p>
                        <p className="text-[12px] text-slate-500 font-[500]">
                          {(attendee?.user?.mobile && attendee?.user?.mobile) ||
                            "N/A"}
                        </p>
                      </span>
                    </div>
                  </td>

                  <td className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap border text-[12px] font-[500]">
                    {formatTime(attendee?.timeStamp) || "N/A"}
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap text-[12px] font-[500] border">
                    {attendee?.user?.location?.country
                      ? attendee?.user?.location?.country.name
                      : "N/A"}
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap text-[12px] font-[500] border">
                    {attendee?.user?.location?.city
                      ? attendee?.user?.location?.city.name
                      : "N/A"}
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap text-[12px] font-[500] border">
                    {attendee?.user?.location?.state
                      ? attendee?.user?.location?.state.name
                      : "N/A"}
                  </td>
                  {regForm?.map((form) => {
                    return (
                      <td className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap text-[12px] font-[500] border">
                        {form?.inputType === "multi-select" ? (
                          getAdditionalInfo(attendee?.attendee, form)?.map(
                            (option) => {
                              return (
                                <div className="bg-gray-100 p-[5px] mb-1 rounded-[10px] text-[12px]">
                                  {option?.label}
                                </div>
                              );
                            }
                          )
                        ) : form?.inputType === "url" ? (
                          <>
                            {getAdditionalInfo(attendee?.attendee, form) ? (
                              <div
                                className="cursor-pointer text-primary underline"
                                onClick={() => {
                                  window.open(
                                    getAdditionalInfo(attendee?.attendee, form)
                                  );
                                }}
                              >
                                Open File
                              </div>
                            ) : (
                              "N/A"
                            )}
                          </>
                        ) : (
                          getAdditionalInfo(attendee?.attendee, form) || "N/A"
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ExtendedTable2;

import React, { useEffect, useRef, useState } from "react";
import DefaultProfilePicture from "../../../../common/defaultProfilePicture/DefaultProfilePicture";

const StatusBadge = ({ status }) => {
  let statusText, statusColor;
  switch (status) {
    case "approved":
      statusText = "Approved";
      statusColor = "green";
      break;
    case "rejected":
      statusText = "Rejected";
      statusColor = "red";
      break;
    case "pending":
      statusText = "Pending";
      statusColor = "yellow";
      break;
    case "N/A":
      statusText = "N/A";

      break;
    default:
      return "N/A";
  }

  return (
    <p
      className={`p-[2px] font-[500] text-${statusColor}-500 grid place-items-center w-[80px] bg-${statusColor}-100 rounded-[12px] text-[12px] mr-0 h-[27px]`}
    >
      {statusText}
    </p>
  );
};

export { StatusBadge };

const ExtendedTable = ({
  userData,
  event,
  regForm,
  getAllEventAttendees,
  getAttendedAttendees,
  tab,
  setSelectedLabel,
  selectedLabel,
}) => {
  const [scrollDirection, setScrollDirection] = useState(null);
  const containerRef = useRef(null);
  const [triggerLabel, setTriggerLabel] = useState(false);

  const labelOptions = [
    "No labels",
    "Sponsor",
    "Speaker",
    "Government",
    "Delegate",
    "Media",
    "Organizer",
    "Volunteer",
  ];

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      if (container) {
        if (container.scrollLeft > 0) {
          setScrollDirection("horizontal");
        } else if (container.scrollTop > 0) {
          setScrollDirection("vertical");
        } else {
          setScrollDirection(null);
        }
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const getApprovalStatus = (userId) => {
    const userStatus = event?.attendeeApprovalStatus?.filter((attendee) => {
      return attendee.user === userId;
    });
    return userStatus[0]?.status || "N/A";
  };

  function getEventSpecificData(attendee) {
    return Array.isArray(attendee?.attendee)
      ? attendee?.attendee[0]?.eventSpecificData
      : attendee?.attendee?.eventSpecificData;
  }

  function getEventData(eventSpecificData) {
    return eventSpecificData?.find((data) => data.eventId === event?._id);
  }

  const fetchRegistrationDate = (attendee) => {
    if (attendee) {
      const eventSpecificData = getEventSpecificData(attendee);
      const eventData = getEventData(eventSpecificData);
      const formattedDate = formatTime(eventData?.timeStamp);

      return formattedDate;
    }
  };

  const getUserLabel = (attendee) => {
    if (attendee) {
      const eventSpecificData = getEventSpecificData(attendee);
      const eventData = getEventData(eventSpecificData);
      return eventData?.label ? eventData?.label : "None";
    }
  };

  const getAdditionalInfo = (attendee, form) => {
    let eventSpecificData;
    if (Array.isArray(attendee)) {
      eventSpecificData = attendee[0]?.eventSpecificData?.find(
        (data) => data.eventId === event?._id
      );
    } else {
      eventSpecificData = attendee?.eventSpecificData?.find(
        (data) => data.eventId === event?._id
      );
    }

    if (eventSpecificData && eventSpecificData?.additionalData) {
      let field = eventSpecificData.additionalData[form?.fieldName] || null;
      if (typeof field === "boolean") {
        field = field === true ? "Yes" : "No";
      }

      if (form?.inputType === "multi-select") {
        return Array.isArray(field) ? field : [field];
      } else {
        return Array.isArray(field) ? field : field;
      }
    }

    return [];
  };

  function formatTime(regDate) {
    if (regDate) {
      const utcDate = new Date(regDate);
      const options = {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZone: "Asia/Kolkata",
      };

      const formatter = new Intl.DateTimeFormat("en-IN", options);
      const dateString = formatter.format(utcDate);

      return dateString;
    }
    return null;
  }

  return (
    <div className="overflow-auto " style={{ height: "calc(100vh - 80px)" }}>
      <table className="table-auto">
        {/* Table header */}
        <thead className="text-sm font-semibold uppercase text-slate-500  border-b-1 border-slate-200 sticky top-0 z-30 bg-w">
          <tr>
            <th
              className={`px-2 first:pl-3 last:pr-5 py-[8px] whitespace-nowrap border bg-gray-100  ${
                scrollDirection === "vertical" ? "" : "sticky left-0 z-10"
              }`}
            >
              <div className="font-semibold text-left">Users</div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-[8px] whitespace-nowrap border bg-gray-100">
              <div className="font-semibold text-center">Status</div>
            </th>
            <th className="px-3 first:pl-5 last:pr-5 py-[8px] whitespace-nowrap  bg-gray-100 flex justify-center items-center relative ">
              <div className="font-semibold ">Label</div>
              <img
                src="/svgs/arrow.svg"
                alt="arrow"
                className="w-[15px] font-[600] ml-[5px] cursor-pointer rotate-180"
                onMouseEnter={() => setTriggerLabel(true)}
                onMouseLeave={() => setTriggerLabel(false)}
              />
              {triggerLabel && (
                <div
                  className="absolute w-[130px] top-[27px] pt-4 bg-transperent z-20"
                  onMouseEnter={() => setTriggerLabel(true)}
                  onMouseLeave={() => {
                    setTriggerLabel(false);
                  }}
                >
                  <div
                    className="bg-white border h-[128px]  rounded-lg overflow-y-scroll font-[500] "
                    style={{
                      boxShadow: "0px 0px 14px rgba(165, 94, 234, 0.2)",
                    }}
                  >
                    {labelOptions?.map((label, index) => {
                      return (
                        <div
                          className="border-b hover:bg-gray-200 h-[30px] cursor-pointer flex justify-center items-center flex-col"
                          key={index}
                          onClick={() => {
                            setTriggerLabel(false);

                            if (tab === "Registered") {
                              setSelectedLabel({
                                ...selectedLabel,
                                registerdLabel:
                                  label === "No labels" ? "" : label,
                              });
                              getAllEventAttendees(
                                `/attendee/${event?._id}?label=${
                                  label === "No labels" ? null : label
                                }`
                              );
                            } else {
                              setSelectedLabel({
                                ...selectedLabel,
                                filterdLabel:
                                  label === "No labels" ? "" : label,
                              });
                              getAttendedAttendees(
                                `/attendee/${
                                  event?._id
                                }/attended/?hasAttended=${
                                  tab === "Attended" ? "true" : "false"
                                }&location=eventCheckin&limit=${
                                  label === "No labels" ? "10" : "0"
                                }&label=${label === "No labels" ? null : label}`
                              );
                            }
                          }}
                        >
                          {label}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-[8px] whitespace-nowrap border bg-gray-100">
              <div className="font-semibold text-left">Contact Info</div>
            </th>

            <th className="px-2 first:pl-5 last:pr-5 py-[8px] whitespace-nowrap border bg-gray-100">
              <div className="font-semibold text-left">Registration date</div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-[8px] whitespace-nowrap border bg-gray-100">
              <div className="font-semibold text-left">Country</div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-[8px] whitespace-nowrap border bg-gray-100">
              <div className="font-semibold text-left">City</div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-[8px] whitespace-nowrap border bg-gray-100">
              <div className="font-semibold text-left">State</div>
            </th>
            {regForm?.map((form) => {
              return (
                <th className="px-2 first:pl-5 last:pr-5 py-[8px] whitespace-nowrap border bg-gray-100">
                  <div className="font-semibold text-left">{form?.label}</div>
                </th>
              );
            })}
          </tr>
        </thead>
        {/* Table body */}
        {/* <tbody className="md:w-[100%]"> */}
        <tbody className="md:w-[100%]">
          {userData &&
            userData.length > 0 &&
            userData.map((attendee) => {
              return (
                <tr key={attendee?._id}>
                  <td
                    className={` px-2 first:pl-3 last:pr-5 py-1 w-full  whitespace-nowrap border  ${
                      scrollDirection === "vertical"
                        ? "bg-white"
                        : "sticky left-0 bg-[#f9fbff]"
                    }`}
                  >
                    <div className="grid grid-cols-[45px_1fr]">
                      {attendee?.profilePicture ? (
                        <img
                          src={attendee?.profilePicture}
                          className="w-[33px] h-[33px] rounded-[50%] object-cover"
                          alt="profile"
                        />
                      ) : (
                        <div className=" ">
                          <DefaultProfilePicture
                            firstName={attendee?.firstName}
                            lastName={attendee?.lastName}
                            style={{
                              width: "33px",
                              height: "33px",
                              borderRadius: "300px",
                              fontSize: "13px",
                            }}
                          />
                        </div>
                      )}

                      <span className="text-[12px] font-[500] w-full">
                        <p>
                          {attendee?.firstName?.charAt(0).toUpperCase() +
                            attendee?.firstName?.slice(
                              1,
                              attendee?.firstName?.length
                            )}{" "}
                          {attendee?.lastName}
                        </p>
                        <p className="text-[12px] text-slate-500 w-[250px] font-[450] whitespace-normal break-words ">
                          {attendee.jobTitle && attendee.jobTitle},{" "}
                          {attendee.organization && attendee.organization}
                        </p>
                      </span>
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap text-[12px] font-[500] border text-center ">
                    {event?.isRegistrationApproval ? (
                      <StatusBadge status={getApprovalStatus(attendee?._id)} />
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap text-[12px] font-[500] border text-center ">
                    <p className="px-1.5 py-1 bg-gray-100 rounded-[10px]">
                      {getUserLabel(attendee)}
                    </p>
                    {/* <p className="p-[4px] font-[500] text-black grid place-items-center w-[85%] bg-gray-200 rounded-[12px]"> */}

                    {/* </p> */}
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-1 text-[14px] font-[500] border whitespace-nowrap ">
                    <div className="text-[12px] font-[500] grid grid-rows-2 w-full">
                      <p>{attendee.email && attendee.email}</p>
                      <span className="flex">
                        <p className="text-[12px] text-slate-500 font-[500]">
                          {attendee.mobile && attendee.countryCode ? (
                            attendee.countryCode
                          ) : (
                            <></>
                          )}
                          &nbsp;
                        </p>
                        <p className="text-[12px] text-slate-500 font-[500]">
                          {(attendee.mobile && attendee.mobile) || "N/A"}
                        </p>
                      </span>
                    </div>
                  </td>

                  <td className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap border text-[12px] font-[500]">
                    {fetchRegistrationDate(attendee) || "N/A"}
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap text-[12px] font-[500] border">
                    {attendee?.location?.country
                      ? attendee?.location?.country.name
                      : "N/A"}
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap text-[12px] font-[500] border">
                    {attendee?.location?.city
                      ? attendee?.location?.city.name
                      : "N/A"}
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap text-[12px] font-[500] border">
                    {attendee?.location?.state
                      ? attendee?.location?.state.name
                      : "N/A"}
                  </td>
                  {regForm?.map((form) => {
                    return (
                      <td className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap text-[12px] font-[500] border">
                        {form?.inputType === "multi-select" ? (
                          getAdditionalInfo(attendee?.attendee, form)?.map(
                            (option) => {
                              return (
                                <div className="bg-gray-100 p-[5px] mb-1 rounded-[10px] text-[12px]">
                                  {option?.label || "N/A"}
                                </div>
                              );
                            }
                          )
                        ) : form?.inputType === "url" ? (
                          <>
                            {getAdditionalInfo(attendee?.attendee, form) ? (
                              <div
                                className="cursor-pointer text-primary underline"
                                onClick={() => {
                                  window.open(
                                    getAdditionalInfo(attendee?.attendee, form)
                                  );
                                }}
                              >
                                Open File
                              </div>
                            ) : (
                              "N/A"
                            )}
                          </>
                        ) : (
                          getAdditionalInfo(attendee?.attendee, form) || "N/A"
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ExtendedTable;

import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useDispatch } from "react-redux";
import { UPDATE_EVENT } from "../../../redux/constants/eventConstants";
import FileInput from "../../../common/inputElements/FileInput";
import TextArea from "../../../common/inputElements/TextArea";
import throttle from "../../../utils/throttle";
import TextInput from "../../../common/inputElements/TextInput";
import { useMatch, useNavigate } from "react-router-dom";
import { showToast } from "../../../redux/actions/toastActions";

export default function AddExhibitorOrganization({
  open,
  setOpen,
  singleCompany,
  setSingleCompany,
  isEdit,
  setIsEdit,
  singleEvent,
}) {
  const [companyLogo, setCompanyLogo] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const eventsId = useMatch("/events/:eventId");
  const navigate = useNavigate();
  const [characterCount, setCharacterCount] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      ...singleCompany,
    },
    mode: "onChange",
  });

  // edit purpose
  useEffect(() => {
    if (!open) {
      reset({
        logo: "",
        companyName: "",
        email: "",
        website: "",
        linkedinUrl: "",
        bio: "",
        industry: "",
        inviteTeam: [],
      });
    }
    if (singleCompany?.companyName) {
      setCompanyLogo(singleCompany.logo);
      reset({
        ...singleCompany,
        inviteTeam: singleCompany.inviteTeam,
      });
      setCharacterCount(singleCompany?.bio.slice(0, 500));
    }
    if (!open) {
      setCompanyLogo("");
      setSingleCompany({});
      setIsEdit(false);
      setCharacterCount("");
    }
  }, [singleCompany?.companyName, open, isEdit]);

  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken) {
    alert("please login!! access token not found");
    navigate("/login");
  }

  // onSubmit form create exhibitor organization
  async function onSubmit(data) {
    setIsSubmitting(true);

    if (Object.values(data).length <= 0) {
      dispatch(
        showToast({
          message: "Please fill all the details",
          category: "danger",
        })
      );
    } else {
      try {
        if (!companyLogo) {
          dispatch(
            showToast({
              message: "Please upload company logo",
              category: "danger",
            })
          );
          setIsSubmitting(false);
          return;
        }

        if (!isEdit) {
          for (let i = 0; i < singleEvent?.exhibitorOrganizations.length; i++) {
            if (data.email) {
              if (data.email === singleEvent?.exhibitorOrganizations[i].email) {
                dispatch(
                  showToast({
                    message: "Company email already exists",
                    category: "danger",
                  })
                );
                return;
              }
            }
          }
        }

        if (isEdit) {
          let exhibitorOrganizationsExcluding =
            singleEvent.exhibitorOrganizations.filter(
              (ele, index) => singleCompany._id !== ele._id
            );

          let exhibitorOrganizationsCopy = [
            ...exhibitorOrganizationsExcluding,
            {
              ...data,
              logo: companyLogo,
              inviteTeam: singleCompany.inviteTeam,
              exhibitorAndSponsors: singleCompany.exhibitorAndSponsors,
            },
          ];

          console.log(
            exhibitorOrganizationsCopy,
            "exhibitorOrganizationsCopy 114"
          );

          // editing data to the event model
          const updatedEvent = await axios.patch(
            `${process.env.REACT_APP_SERVER_URL}/event/${eventsId.params.eventId}/addOrganization?edit=true`,
            {
              exhibitorOrganizations: [...exhibitorOrganizationsCopy],
            },
            {
              headers: {
                Authorization: `bearer ${accessToken}`,
              },
            }
          );
          dispatch({
            type: UPDATE_EVENT,
            payload: {
              exhibitorOrganizations:
                updatedEvent.data.savedEventConfig.exhibitorOrganizations,
            },
          });

          dispatch(
            showToast({
              message: "Changes Saved Successfully",
              category: "success",
            })
          );

          setOpen(false);
          setIsEdit(false);
          setIsSubmitting(false);
          reset();
        } else {
          // adding data to the event model
          const updatedEvent = await axios.patch(
            `${process.env.REACT_APP_SERVER_URL}/event/${eventsId.params.eventId}/addOrganization?edit=false`,
            {
              exhibitorOrganizations: [
                ...singleEvent.exhibitorOrganizations,
                {
                  ...data,
                  logo: companyLogo,
                  inviteTeam:
                    data.inviteTeam.length > 1
                      ? data.inviteTeam.split(",")
                      : data.inviteTeam.length === 1
                      ? [...data.inviteTeam.split(",")[0]]
                      : [],
                },
              ],
            },
            {
              headers: {
                Authorization: `bearer ${accessToken}`,
              },
            }
          );

          dispatch({
            type: UPDATE_EVENT,
            payload: {
              exhibitorOrganizations:
                updatedEvent.data.savedEventConfig.exhibitorOrganizations,
            },
          });

          if (updatedEvent.status === 200) {
            dispatch(
              showToast({
                message: "Exhibitor Added Successfully",
                category: "success",
              })
            );
          }

          setOpen(false);
          setIsEdit(false);
          setIsSubmitting(false);
          reset();
        }
      } catch (error) {
        console.log(error);
        dispatch(
          showToast({
            message: "Some Error Occured. Please try again!",
            category: "danger",
          })
        );
        setIsSubmitting(false);
      }
    }
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="z-40 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="z-40 fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-[45px] md:left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md outline-none hidden md:block text-gray-300"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>X
                          {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
                        </button>
                        <button
                          className="md:hidden flex items-center"
                          onClick={() => setOpen(false)}
                        >
                          <i className="fa fa-angle-left text-[24px]"></i>
                          <span className="text-[17px] pt-0.5">Back</span>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6 fixed z-20 bg-white w-[100%] top-0">
                        <Dialog.Title className="text-[22px] pt-5 pb-[10px] font-[600] text-gray-900">
                          Add Exhibitor Organization
                        </Dialog.Title>
                      </div>
                      <div className="relative mt-[50px] flex-1 px-4 sm:px-6 mb-[50px]">
                        <form
                          onSubmit={handleSubmit((data) =>
                            throttle(() => onSubmit(data), 5000)
                          )}
                        >
                          <p className="text-[14px] font-[500] text-[#A55EEA] mb-[35px] flex justify-between items-center">
                            <span className="inline-block w-[183px]">
                              Is a sponsor ?
                            </span>
                            <label
                              htmlFor="default-toggle"
                              className="inline-flex top-[0px] left-[0px] relative items-center cursor-pointer"
                            >
                              <input
                                type="checkbox"
                                value=""
                                id="default-toggle"
                                className="sr-only peer"
                                // {...register("isSponsor")}
                              />
                              <span className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></span>
                            </label>
                          </p>
                          <TextInput
                            register={register}
                            type="text"
                            id={"companyName"}
                            label="Company Name"
                            required
                            placeholder="Company Name"
                          />

                          <TextInput
                            register={register}
                            disabled={isEdit ? true : false}
                            type="email"
                            id={"email"}
                            placeholder="Email Address"
                            label="Company Email (optional)"
                            errors={errors}
                            pattern={
                              /^([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$/i
                            }
                          />

                          <TextInput
                            register={register}
                            type="url"
                            id={"linkedinUrl"}
                            placeholder="LinkedIn URL"
                            label="Linkedin URL"
                          />

                          <FileInput
                            profilePicture={companyLogo}
                            setProfilePicture={setCompanyLogo}
                            label="Company Logo (Recommended size - 500 X 500 pixels)"
                            mb="12"
                            required
                          />

                          <TextInput
                            register={register}
                            type="url"
                            id={"website"}
                            required
                            label="website link"
                            placeholder="website link"
                          />

                          <TextArea
                            register={register}
                            type="text"
                            id={"bio"}
                            label={
                              characterCount
                                ? `Bio (${
                                    characterCount.split("").length
                                  }/500 characters)`
                                : "Bio"
                            }
                            placeholder="Bio"
                            maxLength={500}
                            onChange={(e) => setCharacterCount(e.target.value)}
                          />
                          <div className="mb-[42px]"></div>

                          <TextInput
                            register={register}
                            type="text"
                            id={"industry"}
                            label="Industry"
                            required
                            placeholder="Industry"
                          />

                          <TextArea
                            register={register}
                            type="text"
                            id={"inviteTeam"}
                            label="Add Team (separate email with comma no spaces)"
                            placeholder="Event description"
                            mt="40px"
                          />

                          {isEdit ? (
                            <div className="-mt-[20px]">
                              <p className="text-[12px] font-[600]">Team : </p>
                              {singleEvent?.exhibitorOrganizations.length > 0
                                ? singleEvent?.exhibitorOrganizations?.map(
                                    (ele, index) => {
                                      if (ele._id === singleCompany._id) {
                                        if (
                                          ele.exhibitorAndSponsors.length > 0
                                        ) {
                                          return ele.exhibitorAndSponsors.map(
                                            (ele1, index1) => {
                                              return (
                                                <p
                                                  className="text-[11px] font-[500]"
                                                  key={index1}
                                                >
                                                  {ele1.email}
                                                </p>
                                              );
                                            }
                                          );
                                        }
                                      }
                                    }
                                  )
                                : null}
                            </div>
                          ) : null}

                          {isEdit ? (
                            <p
                              className="mt-[20px] text-[13px] font-[500] text-primary cursor-pointer block -mt-[10px]"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `${window.location.origin}/events/${singleEvent._id}/register-exhibitor?companyName=${singleCompany.companyName}&companyId=${singleCompany._id}`
                                );
                                dispatch(
                                  showToast({
                                    message: "Link Copied to Clipboard",
                                    category: "success",
                                  })
                                );
                              }}
                            >
                              <i className="fa-regular fa-copy"></i> Copy link
                              to invite team members
                            </p>
                          ) : null}

                          <div className="mb-[42px]"></div>
                          <div className="fixed bottom-0 w-[87%] bg-white  h-[80px] flex justify-center items-center ml-[-2px]">
                            <input
                              value={
                                isSubmitting
                                  ? "Loading..."
                                  : isEdit
                                  ? "Save Changes"
                                  : "Add Exhibitor"
                              }
                              type="submit"
                              className="primary_submit"
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

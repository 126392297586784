import React from "react";
import { useMatch, useSearchParams } from "react-router-dom";
import PrimaryButton from "../../common/buttons/PrimaryButton";

const PaymentStatusMessage = () => {
  const [searchParams] = useSearchParams();
  const match = useMatch("/payments/:platform/:status");
  const status = match?.params?.status;
  let receiptUrl = searchParams.get("url");
  if (!receiptUrl) {
    receiptUrl = localStorage.getItem("receiptUrl");
  }
  return (
    <div>
      <div className="grid place-items-center my-[35px]">
        <div
          className="h-[350px] w-[600px] mt-[50px] flex flex-col items-center border justify-center px-6 rounded-sm"
          style={{ boxShadow: "0px -4px 14px 4px rgba(0, 0, 0, 0.1)" }}
        >
          {status === "success" ? (
            <>
              <img
                src="/svgs/Checkcircle.svg"
                alt="success"
                className="w-[90px]"
              />
              <span className="text-[26px] mt-[10px] font-[500] text-center">
                Payment successful
              </span>

              <p className="mt-[10px] font-[500] text-center">
                Please use the QR code to check-in to the event. An email
                confirmation is also sent to you with the QR code.
              </p>
              <div className="w-[335px] md:w-[160px] mt-1">
                <PrimaryButton
                  btnText={"Download Receipt"}
                  onClick={() => {
                    window.open(receiptUrl, "_blank");
//                     const url = localStorage.getItem("pdfUrl");
//                     window.open(url, "_blank");
                  }}
                  style={{ height: "38px" }}
                />
              </div>
            </>
          ) : status === "failure" ? (
            <>
              <img
                src="/svgs/Cancelled.svg"
                alt="failed"
                className="w-[90px]"
              />
              <span className="text-[26px] mt-[10px] font-[500] text-center">
                Failed to Register
              </span>

              <p className="mt-[10px] font-[500] text-center">
                Payment failed. Please try again later.
              </p>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentStatusMessage;

import React, { useState } from "react";
import AllEvents from "../../components/events/AllEvents";
import ScanIcon from "../../common/scanIcon/ScanIcon";

function Events() {
  return (
    <>
      <div className="flex justify-center flex-col items-center w-full mx-auto bg-white">
        <AllEvents />
      </div>
      <ScanIcon style={"mb-[30px]"} />
    </>
  );
}

export default Events;

import React, { useEffect, useRef, useState } from "react";
import DefaultProfilePicture from "../../../common/defaultProfilePicture/DefaultProfilePicture";

const RegistrationTabs = ({
  registrations,
  getAllEventAttendees,
  total,
  eventsId,
  tab,
  next,
  getAttendedAttendees,
  previous,
  totalPages,
  currentPage,
  selectedLabel,
  setSelectedLabel,
  setSingleAttendee,
  setOpen,
  loading,
  searchValue,
}) => {
  const targetRef = useRef([]);
  const [triggerLabel, setTriggerLabel] = useState(false);

  const showBadge = (index) => {
    targetRef.current[index].style.display = "block";
  };

  const hideBadge = (index) => {
    targetRef.current[index].style.display = "none";
  };
  const labelOptions = [
    "No labels",
    "Sponsor",
    "Speaker",
    "Government",
    "Delegate",
    "Media",
    "Organizer",
    "Volunteer",
  ];

  const getLatestCheckinTime = (attendee) => {
    const eventSpecificData = getEventSpecificData(attendee);
    const eventData = getEventData(eventSpecificData);
    if (eventData) {
      const lastCheckIn = eventData.checkInData
        .filter((checkIn) => checkIn.location === "eventCheckin")
        .sort((a, b) => new Date(b.entry) - new Date(a.entry))[0];
      const formattedDate = formatTime(lastCheckIn?.entry);
      return formattedDate;
    }
  };

  function formatTime(dateToFormat) {
    if (dateToFormat) {
      const utcDate = new Date(dateToFormat);
      const options = {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZone: "Asia/Kolkata",
      };

      const formatter = new Intl.DateTimeFormat("en-IN", options);
      const dateString = formatter?.format(utcDate);

      return dateString;
    }
    return null;
  }

  useEffect(() => {
    if (tab !== "Registered") {
      setSelectedLabel({ ...selectedLabel, filterdLabel: "" });
    }
  }, [tab]);

  const fetchRegistrationDate = (attendee) => {
    if (attendee) {
      const eventSpecificData = getEventSpecificData(attendee);
      const eventData = getEventData(eventSpecificData);
      const formattedDate = formatTime(eventData?.timeStamp);

      return formattedDate;
    }
  };

  function getEventSpecificData(attendee) {
    return Array.isArray(attendee?.attendee)
      ? attendee?.attendee[0]?.eventSpecificData
      : attendee?.attendee?.eventSpecificData;
  }

  function getEventData(eventSpecificData) {
    return eventSpecificData?.find(
      (data) => data.eventId === eventsId.params.eventId
    );
  }

  const getHighestRoleData = (attendee) => {
    const eventSpecificData = getEventSpecificData(attendee);
    const eventData = getEventData(eventSpecificData);

    const highestRole = eventData?.highestRole;

    let roleData = {
      className: "bg-primary",
      roleName: "N/A",
    };

    if (highestRole === "attendee") {
      roleData = {
        className: "bg-[#2D9CDB]",
        roleName: "Attendee",
      };
    } else if (highestRole === "speaker") {
      roleData = {
        className: "bg-[#27AE60]",
        roleName: "Speaker",
      };
    } else if (highestRole === "organiser") {
      roleData = {
        className: "bg-[#F2994A]",
        roleName: "Organiser",
      };
    } else if (highestRole === "exhibitorAndSponsor") {
      roleData = {
        className: "bg-primary",
        roleName: "Exhibitor",
      };
    } else if (highestRole === "delegate") {
      roleData = {
        className: "bg-orange-500",
        roleName: "Delegate",
      };
    } else {
      roleData = {
        className: "bg-primary",
        roleName: highestRole,
      };
    }

    return roleData;
  };

  return (
    <>
      {loading ? (
        <div className=" overflow-hidden h-[calc(100vh-214px)]">
          <table className="table-auto mb-[30px] w-[100%] overflow-hidden">
            <thead className="">
              <tr className="">
                {["50px", "100px", "80px", "90px", "110px", "40px"].map(
                  (header, i) => {
                    return (
                      <React.Fragment key={i}>
                        <th
                          className={`text-[12px] font-[600] text-left leading-[45px] w-[200px]`}
                        >
                          <div
                            className={`w-[${header}] bg-gray-200 custom-pulse h-[20px] mt-3`}
                          ></div>
                        </th>
                      </React.Fragment>
                    );
                  }
                )}
              </tr>
            </thead>

            <tbody className="md:w-[100%] overflow-hidden">
              {Array.from({ length: 25 }, (_, rowIndex) => (
                <tr key={rowIndex + 1} className="h-[55px] border-b">
                  {[20, 32, 36, 24, 12, 11].map((header, index) => {
                    return (
                      <td className="" key={index}>
                        <div className="flex gap-2">
                          {index === 0 && (
                            <div className="w-[32px] h-[32px] bg-gray-200 rounded-full custom-pulse"></div>
                          )}
                          <div
                            className={`w-${header} bg-gray-200 custom-pulse h-[10px] mt-3 rounded-sm `}
                          ></div>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : registrations?.length > 0 ? (
        <div className="flex-grow md:w-[100%]	">
          <table className="table-auto mb-[30px]">
            <thead className="">
              <tr className="">
                <th className="text-[12px] font-[600] text-left leading-[45px] w-[200px] flex">
                  User
                </th>
                <th className="text-[12px] font-[600] text-left leading-[45px] w-[230px]">
                  Contact Info
                </th>
                <th className="text-[12px] font-[600] text-left leading-[45px] w-[160px]">
                  {tab === "Attended"
                    ? "Last Check-in time"
                    : "Registration date"}
                </th>
                <th className="text-[12px] font-[600] text-left leading-[45px] w-[90px]  relative">
                  Role
                </th>
                <th className="text-[12px] font-[600] text-center  relative leading-[45px] w-[110px] flex justify-center items-center">
                  Label
                  <img
                    src="/svgs/arrow.svg"
                    alt="arrow"
                    className="w-[15px] font-[600] ml-[5px] cursor-pointer rotate-180"
                    onMouseEnter={() => setTriggerLabel(true)}
                    onMouseLeave={() => setTriggerLabel(false)}
                  />
                  {triggerLabel && (
                    <div
                      className="absolute w-[130px] top-[27px] pt-4 bg-transperent z-20"
                      onMouseEnter={() => setTriggerLabel(true)}
                      onMouseLeave={() => {
                        setTriggerLabel(false);
                      }}
                    >
                      <div
                        className="bg-white border h-[128px]   rounded-lg overflow-y-scroll"
                        style={{
                          boxShadow: "0px 0px 14px rgba(165, 94, 234, 0.2)",
                        }}
                      >
                        {labelOptions?.map((label, index) => {
                          return (
                            <div
                              className="border-b hover:bg-gray-200 h-[40px] cursor-pointer"
                              key={index}
                              onClick={() => {
                                setTriggerLabel(false);

                                if (tab === "Registered") {
                                  setSelectedLabel({
                                    ...selectedLabel,
                                    registerdLabel:
                                      label === "No labels" ? "" : label,
                                  });
                                  getAllEventAttendees(
                                    `/attendee/${
                                      eventsId.params.eventId
                                    }?label=${
                                      label === "No labels" ? null : label
                                    }`
                                  );
                                } else {
                                  setSelectedLabel({
                                    ...selectedLabel,
                                    filterdLabel:
                                      label === "No labels" ? "" : label,
                                  });
                                  getAttendedAttendees(
                                    `/attendee/${
                                      eventsId.params.eventId
                                    }/attended/?hasAttended=${
                                      tab === "Attended" ? "true" : "false"
                                    }&location=eventCheckin&limit=${
                                      label === "No labels" ? "10" : "0"
                                    }&label=${
                                      label === "No labels" ? null : label
                                    }`
                                  );
                                }
                              }}
                            >
                              {label}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </th>
                <th className="text-[12px] font-[600] leading-[45px] text-center w-[90px]">
                  Badge
                </th>
              </tr>
            </thead>
            <tbody className="md:w-[100%] ">
              <>
                {registrations.map((attendee, index) => {
                  return (
                    <React.Fragment key={attendee?._id}>
                      <tr
                        className="h-[55px] border-b cursor-pointer hover:bg-[#f9fbff] hover:shadow-sm"
                        onClick={() => {
                          setSingleAttendee({
                            user: attendee,
                            status: "approved",
                          });
                          setOpen(true);
                        }}
                      >
                        <td className="text-[12px] font-[500]]">
                          <div className="grid grid-cols-[45px_1fr] gap-[1px]">
                            {attendee.profilePicture ? (
                              <img
                                src={attendee.profilePicture}
                                className="w-[32px] h-[32px] rounded-[50%] object-cover"
                                alt="profile"
                              />
                            ) : (
                              <div className=" ">
                                <DefaultProfilePicture
                                  firstName={attendee.firstName}
                                  lastName={attendee.lastName}
                                  style={{
                                    width: "32px",
                                    height: "32px",
                                    borderRadius: "300px",
                                    fontSize: "13px",
                                  }}
                                />
                              </div>
                            )}

                            <span className="text-[12px] font-[400] ">
                              <p className="line-clamp-1 w-[140px]">
                                <span className="capitalize">
                                  {attendee?.firstName?.trim()}
                                </span>
                                &nbsp;
                                {attendee.lastName?.trim()}
                              </p>
                              <p className="text-[12px] text-slate-500 line-clamp-1 w-[140px] font-[450]">
                                {attendee?.jobTitle?.trim() &&
                                attendee?.organization?.trim()
                                  ? `${attendee?.jobTitle}, ${attendee?.organization}`
                                  : attendee?.jobTitle?.trim()
                                  ? attendee?.jobTitle
                                  : attendee?.organization?.trim()
                                  ? attendee?.organization
                                  : "N/A"}
                              </p>
                            </span>
                          </div>
                        </td>
                        <td className="text-[12px] font-[500]">
                          <div className="text-[12px] font-[400] grid grid-rows-2">
                            <p className="w-[220px] truncate">
                              {attendee?.email && attendee.email}
                            </p>
                            <span className="flex">
                              <p className="text-[12px] text-slate-500 font-[450]">
                                {attendee?.countryCode && attendee.countryCode}
                                &nbsp;
                              </p>
                              <p className="text-[12px] text-slate-500 font-[450]">
                                {attendee?.mobile && attendee.mobile}
                              </p>
                            </span>
                          </div>
                        </td>
                        <td className="text-[12px] font-[400]">
                          {tab !== "Attended"
                            ? fetchRegistrationDate(attendee) || "N/A"
                            : getLatestCheckinTime(attendee) || "N/A" || "N/A"}
                        </td>

                        <td className="text-[12px] font-[400] relative left-[0px]">
                          <p
                            className={` rounded-[12px] p-[4px] text-white grid place-items-center w-[85%] capitalize ${
                              getHighestRoleData(attendee)?.className
                            }`}
                          >
                            {getHighestRoleData(attendee)?.roleName}
                          </p>
                        </td>

                        <td className="text-[12px] font-[500] relative left-[0px]">
                          <p className="p-[4px] font-[500] text-black grid place-items-center w-[85%] bg-gray-200 rounded-[12px] capitalize">
                            {getEventData(getEventSpecificData(attendee))
                              ?.label || "N/A"}
                          </p>
                        </td>

                        <td className="text-center relative">
                          <div
                            ref={(element) => {
                              targetRef.current[index] = element;
                            }}
                            className="absolute z-20 px-3 py-1 ml-10 mt-0 bg-transparent hidden w-[200px]"
                            onMouseEnter={() => showBadge(index)}
                            onMouseLeave={() => hideBadge(index)}
                          >
                            <img
                              className="border rounded-t-xl register_img "
                              src={
                                getEventData(getEventSpecificData(attendee))
                                  ?.badgeUrl
                              }
                              alt="badge"
                            />

                            <div className=" flex justify-evenly text-white">
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  var win = window.open("");
                                  let ele = getEventData(
                                    getEventSpecificData(attendee)
                                  );

                                  if (ele?.badgeUrl) {
                                    win.document.write(
                                      '<html><head><style>img { display: block; margin: 0 auto; }</style></head><body><img src="' +
                                        ele.badgeUrl +
                                        '" onload="window.print();window.close()" /></body></html>'
                                    );
                                  }

                                  win.focus();
                                }}
                                className="w-[100%] bg-primary h-[30px] mr-2 rounded-bl-lg"
                              >
                                Print
                              </button>
                              <button
                                className="w-[100%] bg-primary h-[30px] rounded-br-lg"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let ele = getEventData(
                                    getEventSpecificData(attendee)
                                  );

                                  if (ele?.badgeUrl) {
                                    window.open(`${ele.badgeUrl} `, "_blank");
                                  }
                                }}
                              >
                                Preview
                              </button>
                            </div>
                          </div>
                          <img
                            src="/svgs/IDCard.svg"
                            alt="IDCard"
                            className="ml-[35px] cursor-pointer"
                            onMouseEnter={() => showBadge(index)}
                            onMouseLeave={() => hideBadge(index)}
                          />
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </>
            </tbody>
          </table>

          {searchValue?.value?.trim() === "" && (
            <div className="flex items-center ml-0 w-full mx-auto py-[3px] fixed bottom-0 bg-white border-t-2 h-[60px]">
              <div className="text-[13px] mr-2">
                <span className="font-semibold text-[14px]">
                  {registrations?.length}
                </span>{" "}
                of <span className="font-semibold text-[14px]">{total}</span>{" "}
                results
              </div>

              <button
                className={`text-center cursor-pointer text-${
                  previous === null ? "gray-300" : "primary"
                } text-[23px]`}
                disabled={
                  tab === "Registered"
                    ? selectedLabel.registerdLabel
                      ? true
                      : false
                    : selectedLabel.filterdLabel
                    ? true
                    : false || previous === null
                }
                onClick={async () => {
                  if (tab === "Registered") {
                    await getAllEventAttendees(previous);
                  } else {
                    await getAttendedAttendees(previous);
                  }
                }}
              >
                {"<"}
              </button>

              <div className="mx-3 cursor-pointer">
                <select
                  id="title"
                  disabled={
                    tab === "Registered"
                      ? selectedLabel.registerdLabel
                        ? true
                        : false
                      : selectedLabel.filterdLabel
                      ? true
                      : false
                  }
                  name="title"
                  required
                  value={currentPage}
                  onChange={(e) => {
                    if (tab === "Registered") {
                      getAllEventAttendees(
                        `/attendee/${eventsId.params.eventId}/?page=${e.target.value}&limit=10`
                      );
                    } else {
                      const url = next || previous;
                      const regex = /\?(.*?)&location/;
                      const match = url.match(regex);
                      const queryString = match ? match[1] : "";

                      getAttendedAttendees(
                        `/attendee/${eventsId.params.eventId}/attended/?${queryString}&location=eventCheckin&page=${e.target.value}&limit=10`
                      );
                    }
                  }}
                  className={`
            w-[75px]
            peer bg-white border-2 border-primary rounded-[5px] text-gray-900 text-[12px] placeholder-transparent focus:ring-transparent py-0 bg-[right] 1.5rem cursor-pointer
             `}
                >
                  {Array.from({ length: totalPages }).map((_, index) => (
                    <option
                      key={index + 1}
                      value={index + 1}
                      className="hover:bg-gray-300 border-primary rounded-[5px] overflow-scroll max-h-[50px]"
                    >
                      {index + 1}
                    </option>
                  ))}
                </select>
              </div>

              <button
                disabled={
                  tab === "Registered"
                    ? selectedLabel.registerdLabel
                      ? true
                      : false
                    : selectedLabel.filterdLabel
                    ? true
                    : false || next === null
                }
                className={`text-center cursor-pointer text-${
                  next === null ? "gray-300" : "primary"
                } text-[23px]`}
                onClick={async () => {
                  if (tab === "Registered") {
                    await getAllEventAttendees(next);
                  } else {
                    await getAttendedAttendees(next);
                  }
                }}
              >
                {">"}
              </button>
              <p className="text-[14px] font-[500] ml-2">
                Sorted By:{" "}
                <span className="text-primary">
                  {tab === "Registered"
                    ? selectedLabel.registerdLabel
                      ? selectedLabel.registerdLabel
                      : "No labels"
                    : selectedLabel.filterdLabel
                    ? selectedLabel.filterdLabel
                    : "No labels"}
                </span>
              </p>
            </div>
          )}
        </div>
      ) : (
        <div className="flex mt-[50px] ml-2">
          <div>
            <img src="/svgs/nullState.svg" alt="" className="h-[200px]" />
            <p className="text-[15px] font-[500] text-[#717171] text-center mt-[15px]">
              Nothing here...
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default RegistrationTabs;

import Plugins from "./Plugins";
import Marketing from "./marketing/Marketing";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import Notifications from "./notifications/Notifications";

function Communications() {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const eventsId = useMatch("events/:eventId/*");
  const navigate = useNavigate();
  return (
    <div className="w-full md:w-[375px] md:ml-[0px] md:mt-[27px]">
      <div className="font-[600] w-[375px] mx-auto md:w-[375px] text-[21px] pt-2.5 text-black">
        Communications
      </div>
      <div className="w-[375px] mt-5 mx-auto border-b border-gray-200 dark:border-gray-700">
        <ul
          className="flex flex-wrap w-[375px] mx-auto -mb-px text-sm font-medium text-center"
          id="myTab"
          data-tabs-toggle="#myTabContent"
          role="tablist"
        >
          <li className="mr-2" role="presentation">
            <button
              className={`inline-block px-4 py-1 rounded-t-lg border-b-2  ${
                tab === "marketing-tab" || tab === null
                  ? "text-primary hover:text-primary dark:text-blue-500 dark:hover:text-blue-500 border-primary dark:border-blue-500"
                  : "border-transparent hover:text-gray-600 : hover:border-gray-300 dark:hover:text-gray-300 dark:border-transparent text-gray-500 dark:text-gray-400 border-gray-100 dark:border-gray-700"
              }`}
              id="marketing-tab"
              data-tabs-target="#marketing"
              type="button"
              role="tab"
              aria-controls="marketing"
              aria-selected="false"
              onClick={() => {
                navigate(
                  `/events/${eventsId.params.eventId}?show=communications&tab=marketing-tab`
                );
              }}
            >
              Marketing
            </button>
          </li>
          <li className="mr-2" role="presentation">
            <button
              className={`inline-block px-4 py-1 rounded-t-lg border-b-2  ${
                tab === "notifications-tab"
                  ? "text-primary hover:text-primary dark:text-blue-500 dark:hover:text-blue-500 border-primary dark:border-blue-500"
                  : "border-transparent hover:text-gray-600 : hover:border-gray-300 dark:hover:text-gray-300 dark:border-transparent text-gray-500 dark:text-gray-400 border-gray-100 dark:border-gray-700"
              }`}
              id="notifications-tab"
              data-tabs-target="#notifications"
              type="button"
              role="tab"
              aria-controls="notifications"
              aria-selected="true"
              onClick={() => {
                navigate(
                  `/events/${eventsId.params.eventId}?show=communications&tab=notifications-tab`
                );
              }}
            >
              Notifications
            </button>
          </li>
          <li className="mr-2" role="presentation">
            <button
              className={`inline-block px-4 py-1 rounded-t-lg border-b-2  ${
                tab === "announcements-tab"
                  ? "text-primary hover:text-primary dark:text-blue-500 dark:hover:text-blue-500 border-primary dark:border-blue-500"
                  : "border-transparent hover:text-gray-600 : hover:border-gray-300 dark:hover:text-gray-300 dark:border-transparent text-gray-500 dark:text-gray-400 border-gray-100 dark:border-gray-700"
              }`}
              id="announcements-tab"
              data-tabs-target="#announcements"
              type="button"
              role="tab"
              aria-controls="announcements"
              aria-selected="false"
              onClick={() => {
                navigate(
                  `/events/${eventsId.params.eventId}?show=communications&tab=announcements-tab`
                );
              }}
            >
              Integrations
            </button>
          </li>
        </ul>
      </div>
      <div className="w-[375px] mt-5 mx-auto">
        <div
          className={`${
            tab === "marketing-tab" || tab === null ? "" : "hidden"
          } p-4 `}
          id="marketing"
          role="tabpanel"
          aria-labelledby="marketing-tab"
        >
          <Marketing />
        </div>
        <div
          className={`${tab === "notifications-tab" ? "" : "hidden"}`}
          id="notifications"
          role="tabpanel"
          aria-labelledby="notifications-tab"
        >
          <Notifications />
        </div>

        <div
          className={`${tab === "announcements-tab" ? "" : "hidden"} p-4 `}
          id="announcements"
          role="tabpanel"
          aria-labelledby="announcements-tab"
        >
          <Plugins />
        </div>
      </div>
    </div>
  );
}

export default Communications;

import React from "react";
import RegForm from "../../components/eventLayout/settings/RegForm/RegForm";
import BottomBar from "../../components/bottomBar/BottomBar";

function FormBuilder() {
  return (
    <div>
      {/* <TopMenu /> */}
      {/* <RegForm /> */}
      <BottomBar />
    </div>
  );
}

export default FormBuilder;

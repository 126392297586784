import React, { useState } from "react";
import DefaultProfilePicture from "../../../../common/defaultProfilePicture/DefaultProfilePicture";

function AnalyticsMeetingCard({ whoMetStats }) {
  const [triggerPopup, setTriggerPopup] = useState(false);
  const [userList, setUserList] = useState({
    firstName: "",
    lastName: "",
    list: [],
  });
  return (
    <>
      {triggerPopup && (
        <>
          <div
            className="h-full top-0 bg-[rgba(0,0,0,0.4)] z-30 fixed w-[100%] md:left-0"
            onClick={() => {
              setTriggerPopup(false);
            }}
          ></div>

          <div
            className={`h-[94%] w-full z-40 fixed bottom-0 bg-white rounded-t-[10px]  transform transition duration-1000 ease-in-out pb-[50px] md:h-[75%] md:w-[500px] md:left-0 md:right-0 mx-auto md:top-1/2 md:-translate-y-1/2 md:pb-0 md:rounded-[10px]`}
          >
            <div className="w-full h-[55px] fixed top-0 bg-white flex items-center px-[16px] border-b-2 border-[#EDEDED] md:fixed z-10 md:rounded-t-[10px] pt-[8px]">
              <img
                src="/svgs/Arrowleft.svg"
                className="w-[24px] h-[24px] object-cover cursor-pointer"
                onClick={() => setTriggerPopup(false)}
                alt="arrow"
              />
              <span className="ml-2 text-[20px] font-[500]">
                <p>
                  {userList.firstName.charAt(0).toUpperCase() +
                    userList.firstName.slice(1, userList.firstName.length)}{" "}
                  {userList.lastName} ({userList?.list?.length})
                </p>
              </span>
            </div>
            <div className="mt-[55px]">
              {userList?.list?.map((user) => {
                return (
                  <div className="border-b h-[60px] flex items-center p-4">
                    {user?.profilePicture ? (
                      <img
                        src={user?.profilePicture}
                        className="w-[40px] h-[40px] rounded-[50%] object-cover"
                        alt="user"
                      />
                    ) : (
                      <div className=" ">
                        <DefaultProfilePicture
                          firstName={user?.firstName}
                          lastName={user?.lastName}
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "300px",
                            fontSize: "14px",
                          }}
                        />
                      </div>
                    )}
                    <div>
                      <p className="text-[13px] font-[500] grid grid-rows-2 ml-2">
                        <p>
                          {user.firstName.charAt(0).toUpperCase() +
                            user.firstName.slice(1, user.firstName.length)}{" "}
                          {user.lastName}
                        </p>
                        <p className="text-[12px] text-slate-500 line-clamp-1 w-[250px] font-[450]">
                          {user.jobTitle && user.jobTitle},{" "}
                          {user.organization && user.organization}
                        </p>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}

      <div className="col-span-full bg-[#f9fbff] dark:bg-slate-800 shadow-lg rounded-sm">
        <header className="px-5 py-4 border-b border-slate-100 dark:border-slate-700">
          <h2 className="font-semibold text-slate-800 dark:text-slate-100">
            Who met whom
          </h2>
        </header>
        <div className="p-3">
          {/* Table */}
          <div className="overflow-auto max-h-[330px]">
            <table className="table-auto w-full dark:text-slate-300">
              {/* Table header */}
              <thead className="text-xs uppercase  bg-slate-50 dark:bg-slate-700 dark:bg-opacity-50 rounded-sm">
                <tr>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Name</div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Designation</div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Organization</div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Email</div>
                  </th>

                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Meetings</div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Meet Up With</div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody className="text-sm divide-y divide-slate-100 dark:divide-slate-700">
                {/* Row */}
                {whoMetStats
                  ?.sort(
                    (a, b) => b.meetingWith?.length - a.meetingWith?.length
                  )
                  .map((user) => {
                    return (
                      <tr>
                        <td className="p-2 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex items-center">
                              {user?.profilePicture ? (
                                <img
                                  src={user?.profilePicture}
                                  className="w-[32px] h-[32px] rounded-[50%] object-cover"
                                  alt="user"
                                />
                              ) : (
                                <div className=" ">
                                  <DefaultProfilePicture
                                    firstName={user?.firstName}
                                    lastName={user?.lastName}
                                    style={{
                                      width: "32px",
                                      height: "32px",
                                      borderRadius: "300px",
                                      fontSize: "13px",
                                    }}
                                  />
                                </div>
                              )}

                              <p className="text-[12px] font-[500] ml-2">
                                <p>
                                  {user?.firstName.charAt(0).toUpperCase() +
                                    user?.firstName.slice(
                                      1,
                                      user?.firstName.length
                                    )}{" "}
                                  {user?.lastName}
                                </p>
                              </p>
                            </div>
                          </div>
                        </td>

                        <td className="p-2 whitespace-nowrap">
                          <div className="flex font-[500]">
                            <div>{user?.jobTitle}</div>
                          </div>
                        </td>
                        <td className="p-2 whitespace-nowrap ">
                          <div className="font-[500]">{user?.organization}</div>
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          <div className="font-[500]">{user?.email}</div>
                        </td>

                        <td className="p-2 whitespace-nowrap">
                          <div className="text-center text-[14px] font-[500] text-green-500">
                            {user?.meetingWith?.length}
                          </div>
                        </td>
                        <td
                          className="p-2 whitespace-nowrap"
                          onClick={() => {
                            setUserList({
                              list: user?.meetingWith,
                              firstName: user?.firstName,
                              lastName: user?.lastName,
                            });
                            setTriggerPopup(true);
                          }}
                        >
                          <div className="flex shrink-0 -space-x-3 -ml-px">
                            {user?.meetingWith?.slice(0, 3).map((user) => {
                              return (
                                <div className="cursor-pointer">
                                  {user?.profilePicture ? (
                                    <img
                                      src={user?.profilePicture}
                                      className="rounded-full  box-content w-[28px] h-[28px]"
                                      alt="user"
                                    />
                                  ) : (
                                    <div className=" ">
                                      <DefaultProfilePicture
                                        firstName={user?.firstName}
                                        lastName={user?.lastName}
                                        style={{
                                          width: "28px",
                                          height: "28px",
                                          borderRadius: "300px",
                                          fontSize: "10px",
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default AnalyticsMeetingCard;

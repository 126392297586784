import React from "react";

const Waitlist = () => {
  return (
    <div className="w-screen h-full flex items-center justify-center">
      <div className="w-[400] md:w-[600px] text-center flex items-center justify-center flex-col py-8 gap-8">
        <img src="/svgs/waiting.svg" alt="waiting" className="h-32 w-32 my-8" />
        <div className="text-2xl md:text-3xl font-semibold">
          Your account has been created successfully!
        </div>
        <div className="text-gray-600 font-medium">
          Thank you for joining! Due to massive demand,{" "}
          <span className="font-semibold text-gray-800">
            {" "}
            we have placed you on our waitlist.
          </span>
          We love you and you're not just another member to us. Our team from
          Warpbay will contact you within 24 hours on number you provided. Stay
          Tuned!
        </div>

        {/* <div className="flex gap-4 items-center justify-center">
          <img src="/svgs/check.svg" className="h-8 w-8" />
          <div className="text-[20px] font-semibold">Notifications Enabled</div>
        </div> */}
      </div>
    </div>
  );
};

export default Waitlist;

// @ts-nocheck
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import { UPDATE_EVENT } from "../../../../redux/constants/eventConstants";
import TextArea from "../../../../common/inputElements/TextArea";
import TextInput from "../../../../common/inputElements/TextInput";
import { showToast } from "../../../../redux/actions/toastActions";
import Toggle from "../../../../common/inputElements/Toggle";

export default function AddTickets({
  open,
  setOpen,
  isEdit,
  setIsEdit,
  singleTicket,
  setSingleTicket,
  singleEvent,
  getAllTickets,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [commissionIncluded, setCommissionIncluded] = useState(
    singleTicket?.commissionIncluded || false
  );
  const dispatch = useDispatch();
  const eventsId = useMatch("/events/:eventId/settings/ticketing").params
    .eventId;

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    mode: "onChange",
  });

  useEffect(()=>{
    if(isEdit) reset(singleTicket)
    else reset({
      name: "",
      description: "",
      price: "",
      discountValue: "",
      totalTicketCount: "",
    });
  },[isEdit])

  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken) {
    alert("please login!! access token not found");
    navigate("/login");
  }

  async function onSubmit(data) {
    try {
      setIsSubmitting(true);

      data.commissionIncluded = commissionIncluded;
      if (isEdit) {
        const ticket1 = await axios.patch(
          `${process.env.REACT_APP_SERVER_URL}/ticketCategories/${singleTicket._id}`,
          {
            ...data,
            eventId: eventsId
          },
          {
            headers: {
              "x-access-token": `${accessToken}`,
            },
          }
        );
        // console.log(ticket1)
        if (ticket1.status === 200) {
          dispatch(
            showToast({
              message: "Ticket Updated successfully",
              category: "success",
            })
          );
        }
      } else {
        const ticket = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/event/${eventsId}/ticketCategories`,
          {
            ...data,
          },
          {
            headers: {
              "x-access-token": `${accessToken}`,
            },
          }
        );
        if (ticket.status === 200) {
          dispatch(
            showToast({
              message: "Ticket added successfully",
              category: "success",
            })
          );
        }
      }
      setIsEdit(false);
      getAllTickets();
      setOpen(false);
      setIsSubmitting(false);
      reset({
        name: "",
        description: "",
        price: "",
        discountValue: "",
        totalTicketCount: "",
      });
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-[45px] md:left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md hidden md:block text-gray-300"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>X
                          {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
                        </button>
                        <button
                          className="md:hidden flex items-center"
                          onClick={() => setOpen(false)}
                        >
                          <i className="fa fa-angle-left text-[24px]"></i>
                          <span className="text-[17px] pt-0.5">Back</span>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <Dialog.Title className="text-[20px] pt-0 font-[600] text-gray-900">
                          {`${isEdit ? "Update" : "Add"} Ticket Category`}
                        </Dialog.Title>
                      </div>
                      <div className="relative mt-1 flex-1 px-4 sm:px-6">
                        <form
                          onSubmit={handleSubmit(onSubmit)}
                          className="flex flex-col gap-y-[20px]"
                        >
                          <div className="mt-[20px]"></div>
                          <TextInput
                            register={register}
                            type="text"
                            id={"name"}
                            label="Ticket Name"
                            placeholder="Ticket Name"
                            mb="0"
                            required
                          />
                          <div className="-mt-[37px]"></div>
                          <TextArea
                            register={register}
                            type="text"
                            id={"description"}
                            label="Ticket Description"
                            required
                          />
                          <div className="-mt-[25px]"></div>
                          <TextInput
                            register={register}
                            type="text"
                            id={"price"}
                            label="Amount"
                            mb="3"
                            required
                          />
                          <TextInput
                            register={register}
                            type="text"
                            id={"discountValue"}
                            label="Discounted amount"
                            mb="3"
                            required
                          />
                          <TextInput
                            register={register}
                            type="text"
                            id={"totalTicketCount"}
                            label="Total ticket count"
                            mb="3"
                            required
                          />

                          <div className="w-[300px] flex items-center justify-between text-gray-700">
                            <p className="text-[13px]">
                              Pass on processing fee to customer:{" "}
                            </p>
                            <Toggle
                              isChecked={commissionIncluded}
                              setIsChecked={setCommissionIncluded}
                              id="processing"
                            />
                          </div>

                          {watch().discountValue && (
                            <div className="-mt-[15px]">
                              <p className="text-[12px] font-[500] text-gray-400 pb-1 mt-2">
                                Processing fee - ₹
                                {watch().discountValue * (0.038).toFixed(2)
                                  }
                              </p>
                              <span className="text-[14px] font-semibold">
                                Total Price: ₹
                                {commissionIncluded
                                  ? +watch().discountValue +
                                    +parseFloat(watch().discountValue *(0.038)).toFixed(2)
                                  : +watch().discountValue}
                              </span>
                            </div>
                          )}

                          <div className="-mt-[20px]"></div>
                          <input
                            value={
                              isSubmitting
                                ? "Loading..."
                                : isEdit
                                ? "Update Ticket"
                                : "Create Ticket"
                            }
                            type="submit"
                            className="primary_submit"
                          />
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BookMeeting from "../../people/BookMeeting";
import AttendeeContactDetails from "./AttendeeContactDetails";
import MeetingsNotes from "./MeetingsNotes";
import { getAuthenticatedRequest } from "../../../utils/API/api.ts";
import EditMeeting from "../../people/EditMeeting";
import DefaultProfilePicture from "../../../common/defaultProfilePicture/DefaultProfilePicture";
import { cancelMeeting } from "../../../redux/actions/meetingActions";
import AttendeesReceived from "../../people/AttendeesReceived";
import AttendeesSent from "../../people/AttendeesSent";
import { useDispatch, useSelector } from "react-redux";

const AttendeeMeetings = ({ singleEvent }) => {
  const navigate = useNavigate();
  const [viewContact, setViewContact] = useState(false);
  const [notes, setNotes] = useState(false);
  const [meetingDetails, setMeetingDetails] = useState([]);
  const [singleMeeting, setSingleMeeting] = useState([]);
  const [reschedule, setReschedule] = useState(false);
  const [edit, setEdit] = useState(false);
  const [sentMeetings, setSentMeetings] = useState([]);
  const [receivedMeetings, setReceivedMeetings] = useState([]);
  const [activeTab, setActiveTab] = useState("meeting");
  const dispatch = useDispatch();
  const [singleAttendee, setsingleAttendee] = useState([]);
  const [trigger, settrigger] = useState(false);
  const [isCancelled, setisCancelled] = useState(false);
  const [cancelledDetails, setCancelledDetails] = useState([]);
  const cancelledMeeting = useSelector((state) => state.cancelledMeeting);

  useEffect(() => {
    if (cancelledMeeting.error) {
      alert("Please Cancel again!! Some error occurred");
    }
  }, [cancelledMeeting]);

  useEffect(() => {
    async function fetch() {
      const response = await getAuthenticatedRequest(
        "/user/userMeeting-details"
      );
      const { meetingRequestSent, meetingRequestReceived, scheduledMeetings } =
        response?.data.user;

      const filteredscheduledMeetings = scheduledMeetings.filter(
        (meeting) => meeting.eventId === singleEvent?._id
      );
      const filteredSentMeetings = meetingRequestSent.filter(
        (meeting) => meeting.eventId === singleEvent?._id
      );
      const filteredReceivedMeetings = meetingRequestReceived.filter(
        (meeting) => meeting.eventId === singleEvent?._id
      );

      setMeetingDetails(filteredscheduledMeetings);
      setSentMeetings(filteredSentMeetings);
      setReceivedMeetings(filteredReceivedMeetings);
    }
    fetch();
  }, [singleEvent, activeTab]);

  if (notes) {
    return (
      <MeetingsNotes
        setNotes={setNotes}
        singleMeeting={singleMeeting}
        setSingleMeeting={setSingleMeeting}
      />
    );
  }
  const cancelScheduledMeeting = async (user) => {
    if (
      user.meetingStatus === "Accepted" ||
      user.meetingStatus === "Rescheduled Requested" ||
      user.meetingStatus === "Rescheduled Received"
    ) {
      const updatedmeetingDetails = meetingDetails.map((meeting) => {
        if (meeting.meetingID === user.meetingID) {
          return { ...meeting, meetingStatus: "Cancelled" };
        }
        return meeting;
      });
      setMeetingDetails(updatedmeetingDetails);
    } else {
      const updatedmeetingDetails = meetingDetails.filter((meeting) => {
        return meeting.meetingID !== user.meetingID;
      });
      setMeetingDetails(updatedmeetingDetails);
    }

    try {
      const accessToken = localStorage.getItem("accessToken");

      if (!accessToken) {
        alert("Unauthorised User");
      }

      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/user/cancel-ScheduledMeetings`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            meetingID: user.meetingID,
            meetingStatus: user.meetingStatus,
          }),
        }
      );

      const data = await response.json();
      if (response.status !== 200) {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteMeeting = async (meetingData, singleAttendee) => {
    const cancelMeetings = sentMeetings.filter((meeting) => {
      return meeting.meetingID !== meetingData.meetingID;
    });
    setSentMeetings(cancelMeetings);
    dispatch(cancelMeeting({ meetingID: meetingData.meetingID }));
    setsingleAttendee(singleAttendee);
    setCancelledDetails(meetingData);
    setisCancelled(true);
    settrigger(true);
  };

  const acceptMeeting = async (user) => {
    const updatedmeetingDetails = meetingDetails.map((meeting) => {
      if (meeting.meetingID === user.meetingID) {
        return { ...meeting, meetingStatus: "Accepted" };
      }
      return meeting;
    });
    setMeetingDetails(updatedmeetingDetails);
    try {
      const accessToken = localStorage.getItem("accessToken");

      if (!accessToken) {
        alert("Unauthorised User");
      }
      const meetingObj = {
        eventId: singleEvent._id,
        meetingID: user.meetingID,
        message: user.meetingMessage,
        date: user.meetingDate,
        time: user.meetingTime,
        meetingWith: user.meetingWith[0]._id,
        meetingStatus: user.meetingStatus,
      };
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/user/accept-meeting`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...meetingObj,
          }),
        }
      );

      const data = await response.json();
      console.log(data);
      if (response.status !== 200) {
        throw new Error();
      }
    } catch (error) {
      alert("Error");
    }
  };

  return (
    <div className="w-full min-h-[90vh] bg-[#FAFAFA] md:ml-[17%] md:w-[83%] md:bg-white md:min-h-full pb-[70px]">
      {/* <div className="w-full h-[60px] fixed top-0 bg-white flex items-center px-[16px] border-b border-[#EDEDED] md:mt-[60px] md:relative z-10 md:hidden">
        <img
          src="/svgs/Arrowleft.svg"
          className="w-[24px] h-[24px] object-cover cursor-pointer"
          onClick={() => navigate("/events")}
        />
        <span className="ml-2 text-[22px] font-[500]">Meetings</span>
      </div> */}
      {viewContact && (
        <AttendeeContactDetails
          setViewContact={setViewContact}
          viewContact={viewContact}
          meetingDetails={singleMeeting.meetingWith[0]}
        />
      )}

      {reschedule && (
        <EditMeeting
          singleAttendee={singleMeeting.meetingWith[0]}
          setReschedule={setReschedule}
          reschedule={reschedule}
          event={singleEvent}
          setEdit={setEdit}
          edit={edit}
          meetingDetailSingle={singleMeeting}
          meetingDetails={meetingDetails}
          setMeetingDetails={setMeetingDetails}
        />
      )}

      {trigger && (
        <BookMeeting
          trigger={trigger}
          settrigger={settrigger}
          isCancelled={isCancelled}
          setisCancelled={setisCancelled}
          event={singleEvent}
          singleAttendee={singleAttendee}
          setMeetingDetails={setCancelledDetails}
          meetingDetails={cancelledDetails}
          deleteMeeting={deleteMeeting}
          receivedMeetings={receivedMeetings}
          sentMeetings={sentMeetings}
        />
      )}

      <div className=" mx-[16px] pt-[16px] md:pt-0 md:mt-[75px] md:w-[80%] md:flex flex-wrap">
        <div className="">
          <div className="flex w-[100%] h-[60px] overflow-scroll place-items-center rounded-[8px] text-[16px] ml-0 justify-between items-center md:w-[100%] mt-[10px] md:mt-0">
            <div
              onClick={() => setActiveTab("meeting")}
              className={`grid place-items-center w-[100%] cursor-pointer mr-[30px] text-[14px] font-[600] whitespace-nowrap bg-${
                activeTab === "meeting" ? "primary" : "[#F4F6F9]"
              } p-2 rounded-[17px] text-${
                activeTab === "meeting" ? "white" : "[#F4F6F9]"
              }`}
            >
              Your Meetings
            </div>
            <div
              onClick={() => setActiveTab("received")}
              className={`grid place-items-center whitespace-nowrap w-[100%] cursor-pointer mr-[30px] text-[14px] font-[600] bg-${
                activeTab === "received" ? "primary" : "[#F4F6F9]"
              } p-2 rounded-[17px] text-${
                activeTab === "received" ? "white" : "[#F4F6F9]"
              }`}
            >
              Received ({receivedMeetings?.length})
            </div>

            <div
              onClick={() => setActiveTab("sent")}
              className={`grid place-items-center w-[100%] cursor-pointer mr-[30px] text-[14px] font-[600] whitespace-nowrap bg-${
                activeTab === "sent" ? "primary" : "[#F4F6F9]"
              } p-2 rounded-[17px] text-${
                activeTab === "sent" ? "white" : "[#F4F6F9]"
              }`}
            >
              Sent ({sentMeetings?.length})
            </div>
          </div>
        </div>
      </div>

      {activeTab === "meeting" ? (
        meetingDetails.length > 0 ? (
          <div className="md:w-[80%] md:flex md:flex-wrap">
            {meetingDetails?.map((meeting) => {
              return (
                <div className="">
                  <div
                    key={meeting.meetingID}
                    className="bg-[#FFFFFF] mb-4 rounded-[10px] p-[16px] md:w-[350px] mx-[16px] border border-[#EDEDED]"
                  >
                    <div className="flex items-center relative">
                      {meeting.meetingWith[0]?.profilePicture ? (
                        <img
                          src={meeting.meetingWith[0]?.profilePicture}
                          className="rounded-full w-[45px] h-[45px] object-cover mr-3 "
                        />
                      ) : (
                        <div className="mr-2">
                          <DefaultProfilePicture
                            firstName={meeting.meetingWith[0]?.firstName}
                            lastName={meeting.meetingWith[0]?.lastName}
                            style={{
                              width: "45px",
                              height: "45px",
                              borderRadius: "300px",
                              fontSize: "16px",
                            }}
                          />
                        </div>
                      )}

                      <div className="">
                        <div className="sm:text-[14px] text-[13px] font-[500] pt-[5px] md:font-semibold">
                          {meeting.meetingWith[0].firstName}{" "}
                          {meeting.meetingWith[0].lastName}
                        </div>
                        <div className="text-[12px] font-medium py-[0px] text-gray-500">
                          {meeting.meetingWith[0].jobTitle},{" "}
                          {meeting.meetingWith[0].organization}
                        </div>
                      </div>
                      {meeting.meetingStatus === "Cancelled" ? (
                        <img
                          src="/svgs/Delete.svg"
                          alt=""
                          className="right-[10px] absolute cursor-pointer"
                          onClick={() => cancelScheduledMeeting(meeting)}
                        />
                      ) : (
                        <img
                          src="/svgs/Phone.svg"
                          alt=""
                          className="right-[10px] absolute cursor-pointer"
                          onClick={() => {
                            setSingleMeeting(meeting);
                            setViewContact(true);
                          }}
                        />
                      )}
                    </div>
                    <div className="text-[rgba(0,0,0,0.5)] text-sm font-medium mt-2 italic pb-2 md:h-[47px] overflow-hidden">
                      {meeting?.meetingMessage.split("").length > 100 ? (
                        <>
                          {meeting?.meetingMessage.slice(0, 90)}
                          ...
                        </>
                      ) : (
                        <> {meeting?.meetingMessage.slice(0, 90)}</>
                      )}
                    </div>
                    <span className="text-[#727374] text-[12px] font-[500] flex ">
                      {meeting.meetingDate} | {meeting.meetingTime}
                      {meeting.meetingStatus === "Rescheduled Received" ? (
                        <div className="text-primary font-[500]">
                          &nbsp;|&nbsp;Rescheduled
                        </div>
                      ) : (
                        <></>
                      )}
                    </span>
                    {meeting.meetingStatus === "Cancelled" ||
                    meeting.meetingStatus === "Rescheduled Requested" ? (
                      <></>
                    ) : (
                      <span className="text-[#727374] text-[12px] font-[500] flex my-3">
                        <img
                          src="/svgs/Location.svg"
                          alt="location"
                          className="h-[18px] w-[18px] mx-[5px] ml-[-3px] "
                        />
                        Cubic #5, Networking Dorm
                      </span>
                    )}

                    {meeting.meetingStatus === "Rescheduled Requested" ? (
                      <div className="text-primary text-[12px] font-[600] my-2 md:my-3 flex items-center">
                        <img
                          src="/svgs/send.svg"
                          alt="send"
                          className="cursor-pointer mr-1 h-[12px]"
                        />
                        You requested a reschedule
                      </div>
                    ) : (
                      <div className="text-[12px] md:my-1 md:invisible hidden">
                        No content
                      </div>
                    )}

                    {meeting.meetingStatus === "Cancelled" ? (
                      <>
                        <div className="text-[#E74C3C] text-[12px] font-[500] italic my-3">
                          The meeting was cancelled.
                        </div>
                        <span
                          className="flex items-center cursor-pointer text-white text-[12px] font-[500] text-[12px] h-[32px] w-[100%] justify-center rounded-[4px] bg-primary md:w-[100%]"
                          onClick={() => {
                            setSingleMeeting(meeting);
                            setNotes(true);
                          }}
                        >
                          Notes
                        </span>
                      </>
                    ) : meeting.meetingStatus === "Rescheduled Requested" ? (
                      <span
                        className="flex items-center cursor-pointer text-[#E74C3C] text-[12px] font-[500] text-[12px] border h-[32px] w-[30%] justify-center rounded-[4px] md:w-[100%] md:mt-3"
                        onClick={() => cancelScheduledMeeting(meeting)}
                      >
                        Cancel
                      </span>
                    ) : (
                      <div className="mymd:mt-3 mt-1 flex justify-between">
                        <span
                          className="flex items-center cursor-pointer text-[#E74C3C] text-[12px] font-[500] text-[12px] border h-[32px] w-[30%] justify-center rounded-[4px] md:w-[30%]"
                          onClick={() => cancelScheduledMeeting(meeting)}
                        >
                          Cancel
                        </span>

                        <span
                          className="flex items-center cursor-pointer text-[#1C1C1E] text-[12px] font-[500] text-[12px] border h-[32px] w-[30%] justify-center rounded-[4px] md:w-[30%]"
                          onClick={() => {
                            setSingleMeeting(meeting);
                            setReschedule(true);
                          }}
                        >
                          Reschedule
                        </span>
                        {meeting.meetingStatus === "Rescheduled Received" ? (
                          <span
                            className="flex items-center cursor-pointer text-white text-[12px] font-[500] text-[12px] h-[32px] w-[30%] justify-center rounded-[4px] bg-primary md:w-[30%]"
                            onClick={() => acceptMeeting(meeting)}
                          >
                            Accept
                          </span>
                        ) : (
                          <span
                            className="flex items-center cursor-pointer text-white text-[12px] font-[500] text-[12px] h-[32px] w-[30%] justify-center rounded-[4px] bg-primary md:w-[30%]"
                            onClick={() => {
                              setNotes(true);
                              setSingleMeeting(meeting);
                            }}
                          >
                            Notes
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="grid w-full place-items-center h-[250px] md:w-[65%] px-[16px] md:px-0">
            <div>
              <img
                src="/svgs/Meeting_Empty_State.svg"
                alt=""
                className="w-full h-[340px]"
              />
              <p className="text-[15px] font-[500] text-[#717171]  text-center">
                You haven't scheduled any meeting yet.You can be the one to
                break the ice though!
              </p>
            </div>
          </div>
        )
      ) : activeTab === "received" ? (
        <AttendeesReceived
          receivedMeetings={receivedMeetings}
          sentMeetings={sentMeetings}
          deleteMeeting={deleteMeeting}
          setReceivedMeetings={setReceivedMeetings}
          event={singleEvent}
          setMeetingDetails={setMeetingDetails}
          meetingDetails={meetingDetails}
        />
      ) : activeTab === "sent" ? (
        <AttendeesSent
          sentMeetings={sentMeetings}
          receivedMeetings={receivedMeetings}
          deleteMeeting={deleteMeeting}
          setSentMeetings={setSentMeetings}
          event={singleEvent}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AttendeeMeetings;

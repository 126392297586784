import React, { useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
import socketIOClient from "socket.io-client";
import Confetti from "react-confetti";
import profileImageColor from "../../../utils/profileImageColor.json";
import { useSelector } from "react-redux";
import { getSingleEvent } from "../../../redux/actions/eventActions.js";
import { useDispatch } from "react-redux";

const EventBillboard = () => {
  const dispatch = useDispatch();
  const eventsId = useMatch("/events/:eventId/*");
  const [user, setUser] = useState([]);
  const [showAnimation, setShowAnimation] = useState(false);
  const singleEvent = useSelector((state) => state.eventData);

  useEffect(() => {
    if (eventsId.params.eventId) {
      dispatch(getSingleEvent({ eventId: eventsId.params.eventId }));
    }
  }, [eventsId]);

  // useEffect(() => {
  //   const getAllEventAttendees = async () => {
  //     const response = await getRequest(`/attendee/${eventsId.params.eventId}`);
  //     // fetchLatestUser(response.data.attendees);
  //     setRegistrations(response.data.attendees);
  //   };
  //   getAllEventAttendees();
  // }, []);

  useEffect(() => {
    let animationTimeout;

    if (showAnimation) {
      animationTimeout = setTimeout(() => {
        setShowAnimation(false);
        setUser([]);
      }, 5000);
    }

    return () => {
      clearTimeout(animationTimeout);
    };
  }, [showAnimation]);

  useEffect(() => {
    let eventId = eventsId?.params?.eventId;
    const socket = socketIOClient("https://server.warpbay.com");
    socket.emit("joinEvent", eventId);

    socket.on("updateCheckinList", (user) => {
      setShowAnimation(Date.now());
      setUser(user);
    });

    return () => {
      socket.disconnect();
    };
  }, [eventsId.params.eventId, setShowAnimation]);

  // function getEventSpecificData(attendee) {
  //   return Array.isArray(attendee.attendee)
  //     ? attendee.attendee[0]?.eventSpecificData
  //     : attendee.attendee?.eventSpecificData;
  // }

  // function getEventData(eventSpecificData) {
  //   return eventSpecificData?.find(
  //     (data) => data.eventId === eventsId.params.eventId
  //   );
  // }

  // const getLatestCheckinTime = (attendee) => {
  //   const eventSpecificData = getEventSpecificData(attendee);
  //   const eventData = getEventData(eventSpecificData);

  //   if (eventData) {
  //     const lastCheckIn = eventData.checkInData
  //       .filter((checkIn) => checkIn.location === "eventCheckin")
  //       .sort((a, b) => new Date(b.entry) - new Date(a.entry))[0];
  //     return lastCheckIn ? lastCheckIn : null;
  //   }
  // };

  // const fetchLatestUser = (registrations) => {
  //   const latestCheckinUser = registrations?.reduce((latestUser, user) => {
  //     const userCheckinTime = getLatestCheckinTime(user);
  //     if (!latestUser) return user;
  //     if (!userCheckinTime) return latestUser;

  //     const latestCheckin = getLatestCheckinTime(latestUser);
  //     if (!latestCheckin) return user;

  //     return new Date(userCheckinTime.entry) > new Date(latestCheckin.entry)
  //       ? user
  //       : latestUser;
  //   }, null);
  //   setShowAnimation(true);
  //   setUser(latestCheckinUser);
  // };
  return (
    <div className="bg-[#8559CA] flex justify-center items-center h-screen relative w-screen flex-col">
      <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center text-[50px]">
        {showAnimation && (
          <>
            <Confetti
              width={window.innerWidth}
              height={window.innerHeight}
              friction={1.0}
              numberOfPieces={600}
              gravity={0.2}
            />
          </>
        )}
      </div>

      <div className="justify-center items-center text-[70px] flex flex-col relative">
        <p className="font-customFont text-white text-[100px]">Welcome!</p>
        <p className="text-white font-semibold mt-[-40px]">
          {singleEvent?.title}
        </p>
        {/* mymd:shadow-[0_0px_10px_2px_rgba(0,0,0,0.1)] */}
        {user?.firstName ? (
          <>
            {user?.profilePicture ? (
              <div
                className={`rounded-full w-[350px] h-[350px] border-[#F6CB68] border-[27px] flex justify-center items-center text-white capitalize text-[120px]`}
              >
                <img
                  src={user?.profilePicture}
                  className="w-full h-full rounded-full object-cover"
                  alt="profile"
                />
              </div>
            ) : (
              <div
                className={`rounded-[50%] w-[350px] h-[350px] border-[#F6CB68] border-[27px] flex justify-center items-center text-white capitalize text-[120px]`}
                style={{
                  backgroundColor: `${
                    user?.firstName[0]
                      ? profileImageColor[user?.firstName[0]?.toUpperCase()]
                      : "red"
                  }`,
                }}
              >
                {user?.firstName?.slice(0, 1)}
                <span className="capitalize">
                  {user?.lastName?.slice(0, 1)}
                </span>
              </div>
            )}

            <div className="absolute bg-gray-600 bottom-[18px] text-[35px] text-white p-3 capitalize rounded-[10px] font-medium font-customFont">
              {user?.firstName} {user?.lastName}
            </div>
            {/* 
            <div className="mymd:text-[45px] text-[30px] mt-8 text-center font-[500]">
              <span className="font-[600] text-primary">
                {user?.firstName + " " + user?.lastName + " "}
              </span>
              checked in!
            </div> */}
          </>
        ) : (
          <></>
          // <div className="mymd:text-[45px] text-[30px] mt-8 text-center font-[500]">
          //   No attendees Yet
          // </div>
        )}
      </div>
    </div>
  );
};

export default EventBillboard;

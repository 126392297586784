import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import AnalyticsMeetingCard from "./AnalyticsMeetingCard";

const MeetingAnalytics = React.memo(({ eventsId }) => {
  const [totalMeetings, setTotalMeetings] = useState([]);
  const [whoMetStats, setWhoMetStats] = useState([]);

  const getStats = useCallback(() => {
    const apiUrl = `${process.env.REACT_APP_SERVER_URL}/attendee/${eventsId}/getMeetingStats`;
    axios
      .get(apiUrl)
      .then(function (response) {
        setWhoMetStats(response?.data?.users);
        const transformedData = [
          {
            title: "Total Meetings Accepted",
            value: response?.data?.totalMeetingsCount / 2,
          },
          {
            title: "Total Request Sent",
            value: response?.data?.totalMeetingRequestSent,
          },
          {
            title: "Total Request Received",
            value: response?.data?.totalMeetingRequestReceived,
          },
        ];
        const totalRequestsSent = response?.data?.totalMeetingRequestSent || 0;
        const totalMeetingsAccepted = response?.data?.totalMeetingsCount || 0;

        const acceptancePercentage = Math.round(
          (totalMeetingsAccepted / totalRequestsSent) * 100
        );
        transformedData.push({
          title: "Acceptance Rate",
          value: acceptancePercentage ? acceptancePercentage + "%" : "0%",
        });
        setTotalMeetings(transformedData);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, [eventsId]);

  useEffect(() => {
    getStats();
  }, [getStats]);

  return (
    <div className="w-full md:w-[85%] md:ml-[0px] md:mt-[25px]">
      <div className="mt-[20px]">
        <div className="flex w-full justify-start flex-wrap	">
          {totalMeetings?.map((stats, index) => {
            return (
              <div
                className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 w-[170px] mb-[20px] mr-5"
                key={index}
              >
                <div className="p-4">
                  <h2 className="text-[17px] font-semibold text-slate-800 dark:text-slate-100 mb-2 text-wrap">
                    {stats?.title}
                  </h2>

                  <div className="flex items-start">
                    <div className="text-[21px] font-bold text-slate-800 dark:text-slate-100 mr-2">
                      {stats?.value}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="mt-[20px]">
          <AnalyticsMeetingCard whoMetStats={whoMetStats} />
        </div>
      </div>
    </div>
  );
});

export default MeetingAnalytics;

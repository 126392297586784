import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ScanPopup from "./ScanPopup";

const ConnectionsScan = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const camera = searchParams.get("camera");
  return (
    <div className="">
      <div
        className="h-full top-0 bg-[rgba(0,0,0,0.4)] z-30 fixed mymd:w-[100%] left-0 w-[100vw]"
        onClick={() => navigate(-1)}
      ></div>
      <div
        className={`mymd:w-[55%] mymd:h-[90%] w-full z-40 fixed bottom-0 bg-white rounded-lg p-5 left-0 right-0 mx-auto md:top-1/2 md:-translate-y-1/2 h-[90%] overflow-scroll`}
      >
        <ScanPopup camera={camera} />
      </div>
    </div>
  );
};

export default ConnectionsScan;

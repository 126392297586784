import React from "react";

// Import utilities
import { tailwindConfig, hexToRGB } from "../../../../utils/Utils";
import LineChart03 from "./LineChart03";

function AnalyticsCard01({ labels, data, averageTimeSpent }) {
  const chartData = {
    labels: [...labels],
    datasets: [
      // Indigo line
      {
        label: "Current",
        data: [...data],
        fill: true,
        backgroundColor: `rgba(${hexToRGB(
          tailwindConfig().theme.colors.blue[500]
        )}, 0.08)`,
        borderColor: tailwindConfig().theme.colors.indigo[500],
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
        pointHoverBackgroundColor: tailwindConfig().theme.colors.indigo[500],
        pointBorderWidth: 0,
        pointHoverBorderWidth: 0,
        clip: 20,
      },
    ],
  };

  return (
    <div className="flex flex-col col-span-full xl:col-span-8 bg-[#f9fbff]  shadow-lg rounded-sm mt-[20px] ">
      <header className="px-5 py-4  border-slate-100 dark:border-slate-700 flex items-center">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          Average time spent (Total)
        </h2>
      </header>
      <div className="px-0 py-0">
        <div className="flex flex-wrap border-b-2 border-slate-100 dark:border-slate-700 ">
          {/* Visit Duration*/}
          <div className="flex items-center px-5 pb-4">
            <div>
              <div className="flex items-center">
                <div className="text-3xl font-bold text-slate-800 dark:text-slate-100 mr-2">
                  {averageTimeSpent?.hours}hr {averageTimeSpent?.minutes}m{" "}
                  {averageTimeSpent?.seconds}s
                </div>
              </div>
            </div>
          </div>
        </div>
        <header className="px-5 py-4  flex items-center">
          <h2 className="font-semibold text-slate-800 dark:text-slate-100">
            Average time spent (Daywise)
          </h2>
        </header>
      </div>
      {/* Chart built with Chart.js 3 */}
      <div className="grow">
        <LineChart03 data={chartData} width={800} height={300} />
      </div>
    </div>
  );
}

export default AnalyticsCard01;

import React from "react";

const QrCheckoutScanner = ({ tabHover }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={tabHover === "checkout" ? "#A55EEA" : "#C5C5C7"}
      height="100%"
      viewBox="0 -960 960 960"
      width="100%"
    >
      <path d="M109.825-707Q97-707 88.5-715.625T80-737v-113q0-12.75 8.625-21.375T110-880h113q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T223-820h-83v83q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM110-80q-12.75 0-21.375-8.625T80-110v-113q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-223v83h83q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q253-97 244.375-88.5T223-80H110Zm627 0q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T737-140h83v-83q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-223v113q0 12.75-8.625 21.375T850-80H737Zm112.825-627Q837-707 828.5-715.625T820-737v-83h-83q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T737-880h113q12.75 0 21.375 8.625T880-850v113q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM708-251h63v63h-63v-63Zm0-126h63v63h-63v-63Zm-63 63h63v63h-63v-63Zm-63 63h63v63h-63v-63Zm-63-63h63v63h-63v-63Zm126-126h63v63h-63v-63Zm-63 63h63v63h-63v-63Zm-63-63h63v63h-63v-63Zm252-332v252H519v-252h252ZM440-440v252H188v-252h252Zm0-332v252H188v-252h252Zm-50 534v-152H238v152h152Zm0-332v-152H238v152h152Zm331 0v-152H569v152h152Z" />
    </svg>
  );
};

export default QrCheckoutScanner;

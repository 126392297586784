// @ts-nocheck
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import { UPDATE_EVENT } from "../../../../redux/constants/eventConstants";
import TextArea from "../../../../common/inputElements/TextArea";
import TextInput from "../../../../common/inputElements/TextInput";
import DatePicker from "react-date-picker/dist/entry.nostyle";
import { showToast } from "../../../../redux/actions/toastActions";
import PrimaryButton from "../../../../common/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/buttons/SecondaryButton";
import Select from "../../../../common/inputElements/Select";

const options1=[{
  "value": "unique",
  "label": "unique"
},{
  "value": "generic",
  "label": "generic"
}]

const options2=[{
  "value": "flat",
  "label": "flat"
},{
  "value": "percentage",
  "label": "percentage"
}]

export default function AddCoupons({
  open,
  setOpen,
  isEdit,
  setIsEdit,
  singleEvent,
  ticketCategoryId,
  getAllTickets,
  singleCoupon
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dateValue, setDateValue] = useState(false);
  const dispatch = useDispatch();
  const eventsId = useMatch("/events/:eventId/settings/ticketing");
  const navigate = useNavigate();
  const [couponType, setCouponType] = useState("")
  const [inputType, setInputType] = useState({});
  const [discountType , setDiscountType] = useState();
  const [inputType1 , setInputType1] = useState({})

  useEffect(()=>{
    if(singleCoupon && isEdit){
      setCouponType(singleCoupon.couponType);
      setInputType({
        "value":singleCoupon.couponType,
        "label": singleCoupon.couponType
      })
      setDiscountType(singleCoupon.discountType);
      setInputType1({
        "value":singleCoupon.discountType,
        "label": singleCoupon.discountType
      })
    }
  },[singleCoupon])

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    // defaultValues:{...singleCoupon},
    mode: "onChange",
  });

  console.log(singleCoupon, "single")

  useEffect(() => {
    if(isEdit) {reset(singleCoupon); setDateValue(singleCoupon.validUntil)}
    else{
      setDateValue(false);
      reset({
        couponType: "",
        validUntil: "",
        couponCode: "",
        redeemCount: "",
        discountType: "",
        discountValue: "",
      });
    setDiscountType("");
  setInputType1({});
setCouponType("");
setInputType({})}
  },[isEdit]);
  async function handleDelete(){
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/ticketCategories/${ticketCategoryId}/${singleCoupon._id}`,
        {
          headers: {
            "x-access-token": `${accessToken}`,
          },
        }
      );
      getAllTickets();
      if (response.status === 200) {
        dispatch(
          showToast({
            message: "Coupon deleted successfully",
            category: "success",
          })
        );
      }
      setOpen(false);
      setIsEdit(false);
    } catch (error) {
      console.log(error);
    }
  }

  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken) {
    alert("please login!! access token not found");
    navigate("/login");
  }

  async function onSubmit(data) {
    try {
        data.couponType = couponType;

        data.discountType = discountType;

      setIsSubmitting(true);
      if(isEdit){
        const response = await axios.patch(
          `${process.env.REACT_APP_SERVER_URL}/ticketCategories/${ticketCategoryId}/${singleCoupon._id}`,
          {
            ...data,
            validUntil: new Date(dateValue),
          },
          {
            headers: {
              "x-access-token": `${accessToken}`,
            },
        }
        );
        console.log(response);
        if (response.status === 200) {
          dispatch(
            showToast({
              message: "Coupon updated successfully",
              category: "success",
            })
            );
            
          }
        }else{     
          const response = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/ticketCategories/${ticketCategoryId}`,
            {
              ...data,
              validUntil: new Date(dateValue),
            },
            {
              headers: {
                "x-access-token": `${accessToken}`,
              },
            }
            );
            console.log(response)
            
            if (response.status === 200) {
              dispatch(
                showToast({
                  message: "Coupon added successfully",
                  category: "success",
                })
                );
              }
            }
            getAllTickets();
            setOpen(false);
            setIsSubmitting(false);
            reset({
              couponType: "",
              validUntil: "",
              couponCode: "",
              redeemCount: "",
              discountType: "",
              discountValue: "",
            });
            setIsEdit(false);
            
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-[45px] md:left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md hidden md:block text-gray-300"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>X
                          {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
                        </button>
                        <button
                          className="md:hidden flex items-center"
                          onClick={() => setOpen(false)}
                        >
                          <i className="fa fa-angle-left text-[24px]"></i>
                          <span className="text-[17px] pt-0.5">Back</span>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <Dialog.Title className="text-[20px] pt-0 font-[600] text-gray-900">
                          {`${isEdit ? "Update": "Add"} Coupon`}
                        </Dialog.Title>
                      </div>
                      <div className="relative mt-1 flex-1 px-4 sm:px-6">
                        <form
                          onSubmit={handleSubmit(onSubmit)}
                          className="flex flex-col gap-y-[20px]"
                        >
                          <div className="mt-[20px]"></div>
                          <label
                              htmlFor="type"
                              className="text-[12px] -mb-[10px] text-[#9c9c9c]"
                            >
                              Coupon Type&nbsp;
                              <span className="text-red-600">*</span>
                            </label>
                          <Select
                              register={register}
                              id={"type"}
                              options={options1}
                              value={inputType}
                              onChange={(o) => {
                                console.log(o);
                                // const value = getValues("type");
                                setCouponType(o.value);
                                setInputType(o);
                              }}
                              cross= {false}
                            />
                          {/* <p className="text-gray-400 text-[12px] relative font-[500] -top-[16px] -right-[5px]">
                            Allowed types - generic or unique
                          </p> */}
                          <div className="-mt-[15px]"></div>
                          <TextInput
                            register={register}
                            type="text"
                            id={"couponCode"}
                            label="Coupon Code"
                            mb="0"
                            required
                          />
                          <div className="-mt-[5px]"></div>
                          <div className="relative">
                            <label
                              htmlFor="validUntil"
                              className="text-[12px] text-[#9c9c9c] absolute -top-[19px] left-[5px]"
                            >
                              Valid until&nbsp;
                              <span className="text-red-600">*</span>
                            </label>
                            <DatePicker
                              onChange={(value) => setDateValue(value)}
                              value={dateValue}
                              format="dMMMy"
                              id="validUntil"
                            />
                          </div>

                          <div className="-mt-[5px]"></div>
                          <TextInput
                            register={register}
                            type="text"
                            id={"maxRedeemCount"}
                            label="Max Redeem Count"
                            mb="3"
                            required
                          />
                          <label
                              htmlFor="type"
                              className="text-[12px] -mb-[10px] text-[#9c9c9c]"
                            >
                              Coupon Type&nbsp;
                              <span className="text-red-600">*</span>
                            </label>
                          <Select
                              register={register}
                              id={"type"}
                              options={options2}
                              value={inputType1}
                              onChange={(o) => {
                                console.log(o);
                                // const value = getValues("type");
                                setDiscountType(o.value);
                                setInputType1(o);
                              }}
                              cross= {false}
                            />
                            <div className="-mt-[5px]"></div>
                          <TextInput
                            register={register}
                            type="text"
                            id={"discountValue"}
                            label="Discount Value"
                            mb="3"
                            required
                          />

                          <div className="-mt-[20px]"></div>
                          <input
                            value={isSubmitting ? "Loading..." : isEdit ? "Update Coupon":"Add Coupon"}
                            type="submit"
                            className="primary_submit"
                          />
                          {isEdit && (
                            <SecondaryButton
                            btnText={"Delete Coupon"}
                            onClick={()=>{handleDelete()}}
                            />)}
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

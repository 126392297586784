//@ts-nocheck
import React, { useState, useEffect } from "react";
import PrimaryButton from "../../../common/buttons/PrimaryButton";
import { useSelector, useDispatch } from "react-redux";
import AddExhibitorOrganization from "./AddExhibitorOrganization";
import ListOrganizations from "./ListOrganizations";
import { showToast } from "../../../redux/actions/toastActions";

function Sponsors() {
  const [openCompany, setOpenCompany] = useState(false);
  const [isEditCompany, setIsEditCompany] = useState(false);
  const [singleCompany, setSingleCompany] = useState({});
  const [sponsorAndExhibitors, setSponsorAndExhibitors] = useState([]);
  const [downloadData, setDownloadData] = useState([]);

  const singleEvent = useSelector((state) => state.eventData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (singleEvent.exhibitorOrganizations) {
      // console.log(singleEvent.exhibitorOrganizations);
      let allExhibitors = [];
      let downloadDataArray = [];
      //  = singleEvent.exhibitorOrganizations.map(
      //   (organization, index) => {
      //     console.log(organization, "organization 30");
      //     return organization.exhibitorAndSponsors.map((exhibitor, index) => {
      //       console.log(exhibitor, "exhibitor 30");
      //       return exhibitor;
      //     });
      //   }
      // );
      for (let i = 0; i < singleEvent?.exhibitorOrganizations.length; i++) {
        for (
          let j = 0;
          j < singleEvent.exhibitorOrganizations[i].exhibitorAndSponsors.length;
          j++
        ) {
          let arr =
            singleEvent.exhibitorOrganizations[i].exhibitorAndSponsors[j];
          arr.company = singleEvent.exhibitorOrganizations[i].companyName;
          allExhibitors.push(
            singleEvent.exhibitorOrganizations[i].exhibitorAndSponsors[j]
          );
          downloadDataArray.push(arr);
        }
      }
      setDownloadData(downloadDataArray);
      setSponsorAndExhibitors(allExhibitors);
    }
  }, [singleEvent.exhibitorOrganizations]);

  const handleDownload = async () => {
    let dataToDownload = downloadData;
    console.log(dataToDownload);

    // const response = await getRequest(
    //   `/event/${eventsId.params.eventId}/getAllExhibitorLogins`
    // );
    // if (response.status === 200) {
    //   dataToDownload = response.data;
    // }

    const fields = [
      "_id",
      "company",
      "firstName",
      "lastName",
      "email",
      "organization",
      "jobTitle",
    ];
    const headerRow = fields.join(",");
    const dataRows = dataToDownload.map((row) => {
      let rowData = "";
      fields.forEach((field) => {
        if (rowData !== "") rowData += ",";
        if (!row[field]) rowData += " ";
        else {
          // console.log(row[field]);
          const stringWithoutComma = row[field]?.replace(/,/g, "");
          console.log(stringWithoutComma);
          rowData += stringWithoutComma;
        }
      });
      return rowData;
    });

    const csvContent =
      "data:text/csv;charset=utf-8," + [headerRow, ...dataRows].join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `exhibitor.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="w-full md:w-[422px] md:ml-[0px] md:mt-[0px] pb-5">
      <div className="py-0">
        <div className="font-[600] w-[335px] mx-auto md:w-[540px] text-[20px] pt-2.5 text-black md:flex items-center justify-between bg-white z-10 min-h-[82px]">
          <p className="text-[19px] mt-0.5">Exhibitors / Sponsors</p>
          <div className="flex items-center">
            <div className="w-[335px] md:w-[150px]">
              <PrimaryButton
                btnText={"Add sponsors"}
                style={{ height: "35px" }}
                onClick={() => {
                  setOpenCompany(true);
                }}
              />
            </div>
            <div
              className="bg-primary text-white h-[35px] py-1 px-2 text-[14px] my-0 font-semibold rounded-[8px] w-[150px]  flex items-center text-center justify-center cursor-pointer hover:opacity-95 ml-2.5"
              onClick={handleDownload}
            >
              Export Data
              <img
                src="/svgs/downloadIcon.svg"
                className="w-[18px] ml-2 font-[500]"
                alt="download"
              />
            </div>
          </div>
        </div>

        <div className="w-[335px] mx-auto md:w-[422px] mt-3">
          <p
            className="text-[13px] font-[500] text-primary cursor-pointer block -mt-[10px]"
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.origin}/events/${singleEvent._id}/register-exhibitor-organization`
              );
              dispatch(
                showToast({
                  message: "Link Copied to Clipboard",
                  category: "success",
                })
              );
            }}
          >
            <i className="fa-regular fa-copy"></i> Copy exhibitor registrations
            form link
          </p>
        </div>
        <div className="mt-4 mb-[30px] w-[355px] mx-auto md:w-[430px] overflow-y-auto absolute pb-[50px] min-h-[400px]">
          <ListOrganizations
            open={openCompany}
            setOpen={setOpenCompany}
            isEdit={isEditCompany}
            setIsEdit={setIsEditCompany}
            singleCompany={singleCompany}
            setSingleCompany={setSingleCompany}
          />
        </div>
        <AddExhibitorOrganization
          open={openCompany}
          setOpen={setOpenCompany}
          singleCompany={singleCompany}
          setSingleCompany={setSingleCompany}
          isEdit={isEditCompany}
          setIsEdit={setIsEditCompany}
          singleEvent={singleEvent}
        />
      </div>
    </div>
  );
}

export default Sponsors;

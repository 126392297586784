import React, { useEffect, useState } from "react";
import LineChart from "../charts/LineChart09";
import { tailwindConfig, hexToRGB } from "../../../../utils/Utils";

function FintechCard10({ resultsArray, dateArray }) {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        fill: true,
        backgroundColor: `rgba(${hexToRGB(
          tailwindConfig().theme.colors.orange[500]
        )}, 0.08)`,
        borderColor: tailwindConfig().theme.colors.orange[500],
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: tailwindConfig().theme.colors.orange[500],
        clip: 20,
      },
    ],
  });
  const [todayReg, setTodayReg] = useState(0);

  useEffect(() => {
    const generateYAxis = () => {
      const registrations = [];
      for (let i = 0; i < dateArray.length; i++) {
        const date = dateArray[i];
        const result = resultsArray.find((r) => r.date === date);
        registrations.push(result ? result.registrations : 0);
      }
      return registrations;
    };

    const xAxis = Array.from({ length: dateArray.length }, (_, i) => i);

    const utcDate = new Date();
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      timeZone: "Asia/Kolkata",
    };
    const dateString = utcDate.toLocaleDateString("en-IN", options);

    let todayRegistrations = 0;
    for (let j = 0; j < resultsArray.length; j++) {
      if (dateString === resultsArray[j].date) {
        todayRegistrations = resultsArray[j].registrations;
        break;
      }
    }

    setChartData({
      labels: xAxis,
      datasets: [
        {
          ...chartData.datasets[0],
          data: generateYAxis(),
        },
      ],
    });

    setTodayReg(todayRegistrations);
  }, [dateArray, resultsArray]);

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-3 bg-[#f9fbff] shadow-lg rounded-sm">
      <div className="px-5 pt-5">
        <header>
          <h3 className="text-[15px] font-[600] mb-1">
            <span className="text-[#000]">Attendees</span>
          </h3>
          <div className="text-[22px] font-[600] text-[#000] mb-1">
            {chartData.datasets[0].data.reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0
            )}
          </div>
          <div className="text-sm">
            <span className="font-medium text-emerald-500">+{todayReg}</span> -
            Today
          </div>
        </header>
      </div>
      <div className="grow w-full">
        <LineChart
          data={chartData}
          width={286}
          height={98}
          toolTip="Attendees"
        />
      </div>
    </div>
  );
}

export default FintechCard10;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getUserDetails } from "../../redux/actions/userActions";
import BookMeeting from "./BookMeeting";
import { cancelMeeting } from "../../redux/actions/meetingActions";
import { getAuthenticatedRequest } from "../../utils/API/api.ts";
import AllAttendees from "./AllAttendees";
import SuggestedAttendees from "./SuggestedAttendees";
import ScannedLeads from "./ScannedLeads";

const Attendees = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view");
  const [trigger, settrigger] = useState(false);
  const [isCancelled, setisCancelled] = useState(false);
  const [singleAttendee, setsingleAttendee] = useState([]);
  const dispatch = useDispatch();
  const event = useSelector((state) => state.eventData);
  const userDetails = useSelector((state) => state.userDetails);
  const [sentMeetings, setSentMeetings] = useState([]);
  const [receivedMeetings, setReceivedMeetings] = useState([]);
  const [meetingDetails, setMeetingDetails] = useState([]);
  const [scheduledMeeting, setScheduledMeeting] = useState([]);
  const cancelledMeeting = useSelector((state) => state.cancelledMeeting);
  const { savedUserConfig } = userDetails;

  useEffect(() => {
    if (cancelledMeeting.error) {
      alert("Please Cancel again!! Some error occurred");
    }
  }, [cancelledMeeting]);

  useEffect(() => {
    let accessToken = localStorage.getItem("accessToken");
    dispatch(getUserDetails({ accessToken: accessToken }));
  }, [savedUserConfig?._id]);

  useEffect(() => {
    async function fetch() {
      const meetingDetails = await getAuthenticatedRequest(
        "/user/userMeeting-details"
      );
      const { meetingRequestSent, meetingRequestReceived, scheduledMeetings } =
        meetingDetails?.data?.user;
      console.log(meetingDetails);
      const filteredSentMeetings = meetingRequestSent.filter(
        (meeting) => meeting.eventId === event?._id
      );
      const filteredReceivedMeetings = meetingRequestReceived.filter(
        (meeting) => meeting.eventId === event?._id
      );

      const filteredscheduledMeetings = scheduledMeetings.filter(
        (meeting) => meeting.eventId === event?._id
      );

      setScheduledMeeting(filteredscheduledMeetings);
      setSentMeetings(filteredSentMeetings);
      setReceivedMeetings(filteredReceivedMeetings);
    }
    fetch();
  }, [meetingDetails, event]);

  const deleteMeeting = async (meetingData, singleAttendee) => {
    const cancelMeetings = sentMeetings.filter((meeting) => {
      return meeting.meetingID !== meetingData.meetingID;
    });

    setSentMeetings(cancelMeetings);
    dispatch(cancelMeeting({ meetingID: meetingData.meetingID }));
    setsingleAttendee(singleAttendee);
    setMeetingDetails(meetingData);
    setisCancelled(true);
    settrigger(true);
  };

  return (
    <div className="w-full min-h-[90vh] bg-[#FAFAFA] md:ml-[17%] md:w-[83%] md:bg-white md:min-h-full">
      {/* <div className="w-full h-[60px] fixed top-0 bg-white flex items-center px-[16px] border-b border-[#EDEDED] md:mt-[59px] z-10 md:hidden">
        <img
          src="/svgs/Arrowleft.svg"
          className="w-[24px] h-[24px] object-cover cursor-pointer"
          onClick={() => navigate("/events")}
        />
        <span className="ml-2 text-[22px] font-[500]">Attendees</span>
      </div> */}
      {trigger && (
        <BookMeeting
          trigger={trigger}
          settrigger={settrigger}
          isCancelled={isCancelled}
          setisCancelled={setisCancelled}
          event={event}
          singleAttendee={singleAttendee}
          setMeetingDetails={setMeetingDetails}
          meetingDetails={meetingDetails}
          deleteMeeting={deleteMeeting}
          receivedMeetings={receivedMeetings}
          sentMeetings={sentMeetings}
          savedUserConfig={savedUserConfig}
        />
      )}
      <div className=" pb-[70px] md:mt-[70px]">
        <div className="mx-[16px] ">
          <div className="flex w-[100%] h-[60px] place-items-center rounded-[8px] text-[16px] ml-0 items-center md:w-[30%] mt-[10px] md:mt-0">
            <div
              // onClick={() => setActiveTab("suggested")}
              onClick={() => {
                navigate(`/attendee/${event?._id}?tab=people&view=suggested`);
              }}
              className={`grid place-items-center w-[97px] h-[36px] cursor-pointer text-[14px] font-[600] whitespace-nowrap bg-${
                view === "suggested" || view === null ? "primary" : "[#F4F6F9]"
              } p-2 rounded-[17px] text-${
                view === "suggested" || view === null ? "white" : "[#F4F6F9]"
              }`}
            >
              Suggested
            </div>
            <div
              // onClick={() => setActiveTab("allAttendees")}
              onClick={() => {
                navigate(
                  `/attendee/${event?._id}?tab=people&view=allAttendees`
                );
              }}
              className={`grid place-items-center w-[112px] h-[36px] cursor-pointer mx-[28px] text-[14px] font-[600] whitespace-nowrap bg-${
                view === "allAttendees" ? "primary" : "[#F4F6F9]"
              } p-2 rounded-[17px] text-${
                view === "allAttendees" ? "white" : "[#F4F6F9]"
              }`}
            >
              All Attendees
            </div>

            <div
              // onClick={() => setActiveTab("leads")}
              onClick={() => {
                navigate(`/attendee/${event?._id}?tab=people&view=leads`);
              }}
              className={`grid place-items-center w-[100px] h-[36px] cursor-pointer text-[14px] font-[600] whitespace-nowrap bg-${
                view === "leads" ? "primary" : "[#F4F6F9]"
              } p-2 rounded-[17px] text-${
                view === "leads" ? "white" : "[#F4F6F9]"
              }`}
            >
              Leads
            </div>
          </div>
        </div>
        {view === "allAttendees" || view === null ? (
          <AllAttendees
            event={event}
            settrigger={settrigger}
            setsingleAttendee={setsingleAttendee}
            savedUserConfig={savedUserConfig}
            scheduledMeeting={scheduledMeeting}
            receivedMeetings={receivedMeetings}
            sentMeetings={sentMeetings}
          />
        ) : view === "suggested" ? (
          <SuggestedAttendees
            event={event}
            settrigger={settrigger}
            setsingleAttendee={setsingleAttendee}
            savedUserConfig={savedUserConfig}
            scheduledMeeting={scheduledMeeting}
            receivedMeetings={receivedMeetings}
            sentMeetings={sentMeetings}
          />
        ) : view === "leads" ? (
          <ScannedLeads
            event={event}
            settrigger={settrigger}
            setsingleAttendee={setsingleAttendee}
            savedUserConfig={savedUserConfig}
            scheduledMeeting={scheduledMeeting}
            receivedMeetings={receivedMeetings}
            sentMeetings={sentMeetings}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Attendees;

export const ADD_EVENT = "ADD_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const CARRY_EVENT = "CARRY_EVENT";

export const EVENT_CREATE_SUCCESS = "EVENT_CREATE_SUCCESS";
export const EVENT_CREATE_REQUEST = "EVENT_CREATE_REQUEST";
export const EVENT_CREATE_FAIL = "EVENT_CREATE_FAIL";
export const EVENT_CREATE_DESTROY = "EVENT_CREATE_DESTROY";

export const EVENT_EDIT_SUCCESS = "EVENT_EDIT_SUCCESS";
export const EVENT_EDIT_REQUEST = "EVENT_EDIT_REQUEST";
export const EVENT_EDIT_FAIL = "EVENT_EDIT_FAIL";

export const EVENT_GET_SUCCESS = "EVENT_GET_SUCCESS";
export const EVENT_GET_REQUEST = "EVENT_GET_REQUEST";
export const EVENT_GET_FAIL = "EVENT_GET_FAIL";

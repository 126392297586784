import React from "react";

const Meetings = ({ color }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 16"
      fill={color ? color : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 16V12C0 11.4333 0.196 10.9583 0.588 10.575C0.979333 10.1917 1.45 10 2 10H5.275C5.60833 10 5.925 10.0833 6.225 10.25C6.525 10.4167 6.76667 10.6417 6.95 10.925C7.43333 11.575 8.02933 12.0833 8.738 12.45C9.446 12.8167 10.2 13 11 13C11.8167 13 12.5793 12.8167 13.288 12.45C13.996 12.0833 14.5833 11.575 15.05 10.925C15.2667 10.6417 15.5207 10.4167 15.812 10.25C16.104 10.0833 16.4083 10 16.725 10H20C20.5667 10 21.0417 10.1917 21.425 10.575C21.8083 10.9583 22 11.4333 22 12V16H15V13.725C14.4167 14.1417 13.7877 14.4583 13.113 14.675C12.4377 14.8917 11.7333 15 11 15C10.2833 15 9.58333 14.8877 8.9 14.663C8.21667 14.4377 7.58333 14.1167 7 13.7V16H0ZM11 12C10.3667 12 9.76667 11.854 9.2 11.562C8.63333 11.2707 8.15833 10.8667 7.775 10.35C7.49167 9.93333 7.13767 9.60433 6.713 9.363C6.28767 9.121 5.825 9 5.325 9C5.69167 8.38333 6.46667 7.89567 7.65 7.537C8.83333 7.179 9.95 7 11 7C12.05 7 13.1667 7.179 14.35 7.537C15.5333 7.89567 16.3083 8.38333 16.675 9C16.1917 9 15.7333 9.121 15.3 9.363C14.8667 9.60433 14.5083 9.93333 14.225 10.35C13.8583 10.8833 13.3917 11.2917 12.825 11.575C12.2583 11.8583 11.65 12 11 12ZM3 9C2.16667 9 1.45833 8.70833 0.875 8.125C0.291667 7.54167 0 6.83333 0 6C0 5.15 0.291667 4.43733 0.875 3.862C1.45833 3.28733 2.16667 3 3 3C3.85 3 4.56267 3.28733 5.138 3.862C5.71267 4.43733 6 5.15 6 6C6 6.83333 5.71267 7.54167 5.138 8.125C4.56267 8.70833 3.85 9 3 9ZM19 9C18.1667 9 17.4583 8.70833 16.875 8.125C16.2917 7.54167 16 6.83333 16 6C16 5.15 16.2917 4.43733 16.875 3.862C17.4583 3.28733 18.1667 3 19 3C19.85 3 20.5627 3.28733 21.138 3.862C21.7127 4.43733 22 5.15 22 6C22 6.83333 21.7127 7.54167 21.138 8.125C20.5627 8.70833 19.85 9 19 9ZM11 6C10.1667 6 9.45833 5.70833 8.875 5.125C8.29167 4.54167 8 3.83333 8 3C8 2.15 8.29167 1.43733 8.875 0.862C9.45833 0.287333 10.1667 0 11 0C11.85 0 12.5623 0.287333 13.137 0.862C13.7123 1.43733 14 2.15 14 3C14 3.83333 13.7123 4.54167 13.137 5.125C12.5623 5.70833 11.85 6 11 6Z"
        fill={color ? color : "#C5C5C7"}
      />
    </svg>
  );
};

export default Meetings;

import React from "react";
import Toggle from "../../../../common/inputElements/Toggle";

const PaymentModes = ({
  phonepe,
  setPhonepe,
  razorpay,
  setRazorpay,
  stripe,
  setStripe,
}) => {
  return (
    <div className="flex items-left flex-col justify-between">
      <div className="flex gap-12 mb-2">
        <p className="text-[13px] text-[#585858] relative top-[6px] mr-[10px]">
          Phonepe
        </p>
        <Toggle isChecked={phonepe} setIsChecked={setPhonepe} id={`phonepe`} />
      </div>
      <div className="flex gap-1 mb-2">
        <p className="text-[13px] text-[#585858] relative top-[6px] mr-[20px]">
          Razorpay
        </p>
        <Toggle
          isChecked={razorpay}
          setIsChecked={setRazorpay}
          id={`razorpay`}
        />
      </div>
      <div className="flex gap-12 mb-2">
        <p className="text-[13px] text-[#585858] relative top-[6px] mr-[10px]">
          Stripe
        </p>
        <Toggle isChecked={stripe} setIsChecked={setStripe} id={`stripe`} />
      </div>
    </div>
  );
};

export default PaymentModes;

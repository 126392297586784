import { HIDE_TOAST, SHOW_TOAST } from "../constants/toastConstants";

export const showToast = (payload) => ({
  type: SHOW_TOAST,
  payload,
});

export const hideToast = () => ({
  type: HIDE_TOAST,
});

import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import DefaultProfilePicture from "../../../../common/defaultProfilePicture/DefaultProfilePicture";

const UserDataTable = ({ event }) => {
  const [userData, setUserData] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [next, setNext] = useState();
  const [previous, setPrevious] = useState();
  const [totalUsers, setTotalUsers] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const fetchData = async (url) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/${url}`
      );
      setPrevious(response.data.previous);
      setNext(response.data.next);
      setUserData(response.data.data);
      setTotalUsers(response.data.totalUsers);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (event?._id) {
      fetchData(
        `exhibitor-directory/${
          event?._id
        }/get-all-users?page=${1}&itemsPerPage=${10}`
      );
    }
  }, [event]);

  function formatCheckInTime(checkInData) {
    if (checkInData) {
      const utcDate = new Date(checkInData);
      const options = {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZone: "Asia/Kolkata",
      };

      const formatter = new Intl.DateTimeFormat("en-IN", options);
      const dateString = formatter.format(utcDate);
      return dateString;
    }
    return null;
  }

  return (
    <div>
      <table className="table-auto w-full">
        {/* Table header */}
        <thead className="text-xs font-semibold uppercase text-slate-500  border-b-1 border-slate-200">
          <tr>
            <th className="px-2 first:pl-5 last:pr-5 py-[5px] whitespace-nowrap">
              <div className="font-semibold text-left">
                Users ({totalUsers})
              </div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-[5px] whitespace-nowrap">
              <div className="font-semibold text-left">email</div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-[5px] whitespace-nowrap ">
              <div className="font-semibold text-left ">Mobile</div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div className="font-semibold text-left">Recent Access</div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div className="font-semibold text-left">First Access</div>
            </th>
          </tr>
        </thead>
        {/* Table body */}
        <tbody className="text-sm divide-y divide-slate-200 border-b border-slate-200">
          {userData &&
            userData.length > 0 &&
            userData.map((attendee) => {
              return (
                <tr key={attendee?._id}>
                  <td className="px-2 first:pl-5 last:pr-5 py-1 ">
                    <div className="flex items-center">
                      <div className="w-9 h-9 shrink-0 mr-2 sm:mr-3">
                        {attendee?.name?.split(" ")[0] &&
                          attendee?.name?.split(" ")[1] && (
                            <div className="">
                              <DefaultProfilePicture
                                firstName={attendee?.name?.split(" ")[0]}
                                lastName={attendee?.name?.split(" ")[1]}
                                style={{
                                  width: "34px",
                                  height: "34px",
                                  borderRadius: "300px",
                                  fontSize: "12px",
                                }}
                              />
                            </div>
                          )}
                      </div>
                      <p className="font-medium text-slate-800 max-w-[140px] ">
                        {attendee.name}
                      </p>
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-1">
                    <p className="text-left ">{attendee?.email}</p>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap">
                    <div className="text-left">{attendee?.mobile}</div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap">
                    <div className="text-left">
                      {formatCheckInTime(attendee?.accessedAt?.recentAccess) ||
                        "N/A"}
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap">
                    <div className="text-left">
                      {formatCheckInTime(attendee?.accessedAt?.firstAccess) ||
                        "N/A"}
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className="flex items-center ml-0 w-full mx-auto py-[3px] bg-white border-t-2 h-[60px]">
        <div className="text-[13px] mr-2">
          <span className="font-semibold text-[14px]">{userData?.length}</span>{" "}
          of <span className="font-semibold text-[14px]">{totalUsers}</span>{" "}
          results
        </div>

        <button
          className={`text-center cursor-pointer text-${
            previous === null ? "gray-300" : "primary"
          } text-[23px]`}
          disabled={previous === null}
          onClick={async () => {
            await fetchData(previous);
          }}
        >
          {"<"}
        </button>

        <div className="mx-3 cursor-pointer">
          <select
            id="title"
            name="title"
            required
            value={currentPage}
            onChange={(e) => {
              fetchData(
                `exhibitor-directory/${event?._id}/get-all-users?page=${
                  e.target.value
                }&itemsPerPage=${10}`
              );
            }}
            className={`
              w-[75px]
              peer bg-white border-2 border-primary rounded-[5px] text-gray-900 text-[12px] placeholder-transparent focus:ring-transparent py-0 bg-[right] 1.5rem cursor-pointer
               `}
          >
            {Array.from({ length: totalPages }).map((_, index) => (
              <option
                key={index + 1}
                value={index + 1}
                className="hover:bg-gray-300 border-primary rounded-[5px] overflow-scroll max-h-[50px]"
              >
                {index + 1}
              </option>
            ))}
          </select>
        </div>

        <button
          disabled={next === null}
          className={`text-center cursor-pointer text-${
            next === null ? "gray-300" : "primary"
          } text-[23px]`}
          onClick={async () => {
            await fetchData(next);
          }}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};

export default UserDataTable;

import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { getSingleEvent } from "../../../../../redux/actions/eventActions";
import axios from "axios";
import { useMatch, useNavigate } from "react-router-dom";
import { showToast } from "../../../../../redux/actions/toastActions";
import TextInput from "../../../../../common/inputElements/TextInput";

export default function AddEmailAndDomain({
  open,
  setOpen,
  isEdit,
  setIsEdit,
  singleEvent,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [senderOrganizerDomain, setSenderOrganizerDomain] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    // setValue,
    reset,
  } = useForm({
    defaultValues: {
      // name: singleEvent.senderOrganizerName,
      // email: singleEvent.senderOrganizerEmail,
    },
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if (singleEvent.title) {
      reset({
        domain: singleEvent?.senderOrganizerDomain[0]?.domain,
      });
    }
    if (!open) {
      reset({
        domain: "",
      });
    }
  }, [open]);

  const navigate = useNavigate();
  const eventsId = useMatch(
    "/events/:eventId/communications/email-domain-whitelable"
  );

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      dispatch(
        showToast({
          message: "please login!! access not found",
          category: "danger",
        })
      );
      navigate("/login");
    }

    if (singleEvent?.senderOrganizerDomain[0]?.domain === data.domain) {
      dispatch(
        showToast({
          message: "Domain has already been added",
          category: "danger",
        })
      );
      setIsSubmitting(false);
      return;
    }

    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/event/${eventsId.params.eventId}/custom-domain`,
        {
          senderOrganizerDomain: data.domain,
        },
        {
          headers: {
            Authorization: `bearer ${accessToken}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          dispatch(
            showToast({
              message: "sender Email connected",
              category: "success",
            })
          );
        }
        dispatch(getSingleEvent({ eventId: eventsId.params.eventId }));
        // setOpen(false);
        // reset({
        //   domain: "",
        //   email: "",
        //   name: "",
        // });
        setSenderOrganizerDomain(response.data.body.dns);
        setIsSubmitting(false);
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          dispatch(
            showToast({
              message: "Unauthorized!!",
              category: "danger",
            })
          );
        }
        setIsSubmitting(false);
      });
  };

  function copyRecords(parentNode) {
    if (parentNode) {
      navigator.clipboard.writeText(parentNode.innerText);
      dispatch(
        showToast({
          message: "Copied",
          category: "success",
        })
      );
    }
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="z-40 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="z-40 fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-[45px] md:left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md outline-none hidden md:block text-gray-300"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>X
                          {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
                        </button>
                        <button
                          className="md:hidden flex items-center"
                          onClick={() => setOpen(false)}
                        >
                          <i className="fa fa-angle-left text-[24px]"></i>
                          <span className="text-[17px] pt-0.5">Back</span>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6 fixed z-20 bg-white w-[100%] top-0">
                        <Dialog.Title className="text-[22px] pb-[25px] font-[600] text-gray-900 pt-5">
                          Connect Domain
                        </Dialog.Title>
                      </div>
                      <div className="relative mt-[55px] flex-1 px-4 sm:px-6 mb-[50px] overflow-x-hidden">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="mt-[30px]"></div>
                          <TextInput
                            register={register}
                            type="text"
                            id={"domain"}
                            label="Domain Name"
                            placeholder="Domain Name"
                            mb="3"
                            required
                          />
                          <div className="mt-[40px]"></div>
                          <p className="mb-2 text-gray-500 text-[13px] font-[600] flex items-center">
                            <svg
                              class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                            DNS records
                          </p>

                          {singleEvent.senderOrganizerDomain ? (
                            <div class="relative overflow-x-auto">
                              <table class="text-sm text-left text-gray-500 dark:text-gray-400 w-[700px]">
                                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                  <tr>
                                    <th scope="col" class="px-6 py-3">
                                      TYPE
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                      HOST
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                      VALUE
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                      scope="row"
                                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                      {
                                        singleEvent?.senderOrganizerDomain[0]
                                          ?.dns.dkim1.type
                                      }
                                    </th>
                                    <td class="px-6 py-4">
                                      {
                                        singleEvent?.senderOrganizerDomain[0]
                                          ?.dns.dkim1.host
                                      }
                                      <i
                                        className="far fa-copy inline-block ml-2 cursor-pointer hover:text-primary"
                                        onClick={(e) => {
                                          copyRecords(e.target.parentNode);
                                        }}
                                      ></i>
                                    </td>
                                    <td class="px-6 py-4">
                                      <span className="ml-2 inline-block">
                                        {
                                          singleEvent?.senderOrganizerDomain[0]
                                            ?.dns.dkim1.data
                                        }
                                      </span>
                                      <i
                                        className="far fa-copy inline-block ml-2 cursor-pointer hover:text-primary"
                                        onClick={(e) => {
                                          copyRecords(e.target.parentNode);
                                        }}
                                      ></i>
                                    </td>
                                  </tr>
                                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                      scope="row"
                                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                      {
                                        singleEvent?.senderOrganizerDomain[0]
                                          ?.dns.dkim2.type
                                      }
                                    </th>
                                    <td class="px-6 py-4">
                                      {" "}
                                      {
                                        singleEvent?.senderOrganizerDomain[0]
                                          ?.dns.dkim2.host
                                      }
                                      <i
                                        className="far fa-copy inline-block ml-2 cursor-pointer hover:text-primary"
                                        onClick={(e) => {
                                          copyRecords(e.target.parentNode);
                                        }}
                                      ></i>
                                    </td>
                                    <td class="px-6 py-4">
                                      {" "}
                                      {
                                        singleEvent?.senderOrganizerDomain[0]
                                          ?.dns.dkim2.data
                                      }
                                      <i
                                        className="far fa-copy inline-block ml-2 cursor-pointer hover:text-primary"
                                        onClick={(e) => {
                                          copyRecords(e.target.parentNode);
                                        }}
                                      ></i>
                                    </td>
                                  </tr>
                                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th
                                      scope="row"
                                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                      {
                                        singleEvent?.senderOrganizerDomain[0]
                                          ?.dns.mail_cname.type
                                      }
                                    </th>
                                    <td class="px-6 py-4">
                                      {" "}
                                      {
                                        singleEvent?.senderOrganizerDomain[0]
                                          ?.dns.mail_cname.host
                                      }
                                      <i
                                        className="far fa-copy inline-block ml-2 cursor-pointer hover:text-primary"
                                        onClick={(e) => {
                                          copyRecords(e.target.parentNode);
                                        }}
                                      ></i>
                                    </td>
                                    <td class="px-6 py-4">
                                      {" "}
                                      {
                                        singleEvent?.senderOrganizerDomain[0]
                                          ?.dns.mail_cname.data
                                      }
                                      <i
                                        className="far fa-copy inline-block ml-2 cursor-pointer hover:text-primary"
                                        onClick={(e) => {
                                          copyRecords(e.target.parentNode);
                                        }}
                                      ></i>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          ) : null}

                          {/* <div class="flex items-center">
                            <input
                              checked
                              id="checked-checkbox"
                              type="checkbox"
                              value=""
                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              for="checked-checkbox"
                              class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                             Verify domain
                            </label>
                          </div> */}
                          {/*  <TextInput
                            register={register}
                            type="text"
                            id={"email"}
                            label="Sender Email"
                            placeholder="Sender Email"
                            mb="3"
                            required
                          />
                          <p className="text-gray-400 text-[12px] font-[500] relative -top-[39px] -right-[280px]">
                            @warpbay.co
                          </p>
                          <div className="mt-[10px]"></div>
                          <TextInput
                            register={register}
                            type="text"
                            id={"name"}
                            label="Sender Name"
                            placeholder="Sender Name"
                            mb="3"
                            required
                          /> 
                          <p className="text-gray-400 text-[12px] relative font-[500] -top-[9px] -right-[5px]">
                            eg. company name, support, help
                          </p>*/}
                          <div class="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 mt-3">
                            Only one domain can be added per account
                          </div>
                          <div className="fixed bottom-0 w-[87%] bg-white h-[90px] grid place-items-center grid-rows-1 ml-[-2px]">
                            <input
                              disabled={isSubmitting}
                              value={
                                isSubmitting ? "Loading..." : "Connect Domain"
                              }
                              type="submit"
                              className="primary_submit"
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

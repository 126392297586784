import React, { useEffect, useState } from "react";
import DefaultProfilePicture from "../../common/defaultProfilePicture/DefaultProfilePicture";

const LandingSponsors = ({ singleEvent }) => {
  return (
    <div className="mymd:flex justify-between flex-wrap w-full">
      {singleEvent?.exhibitorOrganizations?.length > 0 ? (
        singleEvent?.exhibitorOrganizations.map((sponsorAndExhibitor, key) => (
          <div className="mymd:w-[280px] mymd:h-[150px] bg-[#F5F5F5] rounded-xl mb-3 p-[20px] h-[175px]">
            {sponsorAndExhibitor?.logo ? (
              <img
                src={sponsorAndExhibitor.logo}
                className="rounded-full mymd:w-[40px] mymd:h-[40px] w-[40px] h-[40px] object-cover "
              />
            ) : (
              <div className="mr-2">
                <DefaultProfilePicture
                  firstName={sponsorAndExhibitor?.companyName}
                  lastName={" "}
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "300px",
                    fontSize: "14px",
                  }}
                />
              </div>
            )}

            <div className="mymd:text-[20px] text-base font-medium mymd:mt-[16px] mt-[8px] truncate">
              {sponsorAndExhibitor?.companyName}
            </div>
            <div className="mymd:text-[15px] text-xs font-normal text-[#727374] mymd:mt-[14px] mt-[6px] truncate">
              {sponsorAndExhibitor?.industry}
            </div>
          </div>
        ))
      ) : (
        <div className="grid w-full place-items-center h-[250px]">
          <div>
            <img
              src="/svgs/nullState.svg"
              alt=""
              className="w-[200px] h-[200px]"
            />
            <p className="text-[15px] font-[500] text-[#717171]  text-center">
              No Exhibitors or Sponsors available...
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingSponsors;

import React, { useEffect, useState } from "react";
import DefaultProfilePicture from "../../common/defaultProfilePicture/DefaultProfilePicture";
import { getAuthenticatedRequest } from "../../utils/API/api.ts";
import { useMatch } from "react-router-dom";

const ScannedLeads = ({ event }) => {
  const [attendeesData, setAttendeesData] = useState([]);
  const eventsId = useMatch("/attendee/:eventId/*");

  useEffect(() => {
    async function fetch() {
      const leadsData = await getAuthenticatedRequest(
        `/attendee/getLeadDetails?eventId=${eventsId.params.eventId}`
      );
      setAttendeesData(leadsData?.data?.user.scannedLeads);
    }
    fetch();
  }, [event]);

  const handleDownload = async () => {
    const fields = [
      "firstName",
      "lastName",
      "email",
      "countryCode",
      "mobile",
      "organization",
      "jobTitle",
    ];
    const headerRow = fields.join(",");
    const dataRows = attendeesData.map((row) =>
      fields.map((field) => row.attendee[field]).join(",")
    );
    const csvContent =
      "data:text/csv;charset=utf-8," + [headerRow, ...dataRows].join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `Leads.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="md:w-[100%] ">
        {attendeesData?.length > 0 ? (
          <>
            <div
              className="bg-primary text-white h-8 py-1 px-2 text-[14px] ml-4 my-1 font-semibold rounded-sm w-[145px] h-[35px] flex items-center text-center justify-center cursor-pointer hover:opacity-95"
              onClick={handleDownload}
            >
              Export Data
              <img
                src="/svgs/downloadIcon.svg"
                className="w-[18px] ml-2 font-[500]"
              />
            </div>
            <div className="md:flex flex-wrap">
              {attendeesData?.map((attendee, key) => (
                <div className="mx-[16px] " key={key}>
                  <div className="bg-white mb-4 rounded-[10px] p-[16px] md:border md:w-[350px] mt-[10px] border border-[#EDEDED]">
                    <div className="flex items-center">
                      {attendee.attendee.profilePicture ? (
                        <img
                          src={attendee.attendee.profilePicture}
                          className="rounded-full w-[45px] h-[45px] object-cover mr-3"
                          alt=""
                        />
                      ) : (
                        <div className="mr-2">
                          <DefaultProfilePicture
                            firstName={attendee.attendee.firstName}
                            lastName={attendee.attendee.lastName}
                            style={{
                              width: "45px",
                              height: "45px",
                              borderRadius: "300px",
                              fontSize: "16px",
                            }}
                          />
                        </div>
                      )}

                      <div className="">
                        <div className="sm:text-[14px] text-[13px] font-[500] pt-[5px] md:font-semibold">
                          {attendee.attendee.firstName}{" "}
                          {attendee.attendee.lastName}{" "}
                        </div>
                        <div className="text-[12px] font-medium py-[0px] text-gray-500">
                          {attendee.attendee.jobTitle},{" "}
                          {attendee.attendee.organization}
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="grid w-full place-items-center h-[250px] md:w-[65%]">
            <div>
              <img
                src="/svgs/nullState.svg"
                alt=""
                className="w-[200px] h-[200px]"
              />
              <p className="text-[15px] font-[500] text-[#717171]  text-center">
                Nothing here...
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ScannedLeads;

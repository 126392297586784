import React, { useState } from "react";
import DefaultProfilePicture from "../../../../common/defaultProfilePicture/DefaultProfilePicture";
import MoreDetails from "./MoreDetails";
import axios from "axios";
import { showToast } from "../../../../redux/actions/toastActions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const ApprovalPending = ({ eventsId }) => {
  const [open, setOpen] = useState(false);
  const [attendees, setAttendees] = useState([]);
  const [singleAttendee, setSingleAttendee] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [next, setNext] = useState();
  const [previous, setPrevious] = useState();
  const [totalUsers, setTotalUsers] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();

  const getAttendeesApprovalStatus = async (url) => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}${url}`)
      .then(function (response) {
        setCurrentPage(response.data.currentPage);
        setTotalUsers(response.data.totalCount);
        setTotalPages(response.data.totalPages);
        setNext(response.data.next);
        setPrevious(response.data.previous);
        setAttendees(response.data.attendees);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (eventsId.params.eventId) {
      getAttendeesApprovalStatus(
        `/attendee/${eventsId.params.eventId}/getAttendeesApprovalStatus?page=1`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsId]);

  const updateApprovalStatus = (userId, newStatus, lastStatus) => {
    axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/attendee/${eventsId.params.eventId}/${userId}/updateAttendeesApprovalStatus?newStatus=${newStatus}&lastStatus=${lastStatus}`
      )
      .then(function (response) {
        if (response.status === 200) {
          const updatedAttendees = attendees.map((attendee) =>
            attendee.user._id === userId
              ? { ...attendee, status: newStatus }
              : attendee
          );

          setAttendees(updatedAttendees);
          setOpen(false);
          dispatch(
            showToast({
              message: "Status updated successfully",
              category: "success",
            })
          );
        }
      })
      .catch(function (error) {
        setOpen(false);
        dispatch(
          showToast({
            message: "Sorry, something went wrong",
            category: "danger",
          })
        );
      });
  };

  function formatTime(regDate) {
    if (regDate) {
      const utcDate = new Date(regDate);
      const options = {
        day: "2-digit",
        month: "short",
      };

      const formatter = new Intl.DateTimeFormat("en-IN", options);
      const dateString = formatter.format(utcDate);

      return dateString;
    }
    return null;
  }

  return (
    <div className="md:w-[860px] pt-[5px] mb-[30px]">
      {attendees?.map((attendee) => {
        return (
          <div
            className=" w-full h-[55px] rounded-xl mt-[10px] hover:bg-white cursor-pointer flex items-center p-2 hover:border-transparent justify-between bg-[#f9fbff] hover:shadow-sm"
            key={attendee?._id}
            onClick={() => {
              setSingleAttendee(attendee);
              setOpen(true);
            }}
          >
            <div className="flex items-center">
              {attendee?.user?.profilePicture ? (
                <img
                  src={attendee?.user?.profilePicture}
                  className="w-[28px] h-[28px] rounded-[50%] object-cover"
                  alt="profile"
                />
              ) : (
                <div className=" ">
                  <DefaultProfilePicture
                    firstName={attendee.user?.firstName}
                    lastName={attendee.user?.lastName}
                    style={{
                      width: "28px",
                      height: "28px",
                      borderRadius: "300px",
                      fontSize: "13px",
                    }}
                  />
                </div>
              )}

              <div>
                <p className="text-[14px] font-[500] mx-2">
                  {attendee.user?.firstName.charAt(0).toUpperCase() +
                    attendee.user?.firstName.slice(
                      1,
                      attendee.user?.firstName.length
                    )}{" "}
                  {attendee.user?.lastName}
                </p>
              </div>

              <div>
                <p className="text-[14px] font-[500] text-gray-400">
                  {attendee.user?.email && attendee.user?.email}
                </p>
              </div>
            </div>
            <div className="flex items-center">
              {attendee.status === "approved" ? (
                <p className="p-[2px] font-[500] text-green-500 grid place-items-center w-[80px] bg-green-100 rounded-[12px] text-[12px] mr-2 h-[30px]">
                  Approved
                </p>
              ) : attendee.status === "rejected" ? (
                <p className="p-[2px] font-[500] text-red-500 grid place-items-center w-[80px] bg-red-100 rounded-[12px] text-[12px] mr-2 h-[30px]">
                  Rejected
                </p>
              ) : attendee.status === "pending" ? (
                <>
                  <p
                    className="p-[2px] font-[500] text-green-500 grid place-items-center w-[80px] bg-green-100 rounded-[12px] text-[12px] mr-2 h-[30px] hover:bg-green-200"
                    onClick={(e) => {
                      e.stopPropagation();
                      updateApprovalStatus(
                        attendee.user?._id,
                        "approved",
                        attendee.status
                      );
                    }}
                  >
                    Approve
                  </p>

                  <p
                    className="p-[2px] font-[500] text-red-500 grid place-items-center w-[80px] bg-red-100 rounded-[12px] text-[12px] mr-2 h-[30px] hover:bg-red-200"
                    onClick={(e) => {
                      e.stopPropagation();
                      updateApprovalStatus(
                        attendee.user?._id,
                        "rejected",
                        attendee.status
                      );
                    }}
                  >
                    Reject
                  </p>
                </>
              ) : (
                <></>
              )}

              <p className="text-[14px] font-[500] text-gray-400">
                {formatTime(attendee?.timeStamp) || "N/A"}
              </p>
            </div>
          </div>
        );
      })}

      <MoreDetails
        open={open}
        setOpen={setOpen}
        singleAttendee={singleAttendee}
        updateApprovalStatus={updateApprovalStatus}
        eventsId={eventsId}
      />

      <div className="flex items-center ml-0 w-full mx-auto py-[3px] fixed bottom-0 bg-white border-t-2 h-[60px]">
        <div className="text-[13px] mr-2">
          <span className="font-semibold text-[14px]">{attendees?.length}</span>{" "}
          of <span className="font-semibold text-[14px]">{totalUsers}</span>{" "}
          results
        </div>

        <button
          className={`text-center cursor-pointer text-${
            previous === null ? "gray-300" : "primary"
          } text-[23px]`}
          disabled={previous === null}
          onClick={async () => {
            await getAttendeesApprovalStatus(previous);
          }}
        >
          {"<"}
        </button>

        <div className="mx-3 cursor-pointer">
          <select
            id="title"
            name="title"
            required
            value={currentPage}
            onChange={(e) => {
              getAttendeesApprovalStatus(
                `/attendee/${eventsId.params.eventId}/getAttendeesApprovalStatus?page=${e.target.value}`
              );
            }}
            className={`
              w-[75px]
              peer bg-white border-2 border-primary rounded-[5px] text-gray-900 text-[12px] placeholder-transparent focus:ring-transparent py-0 bg-[right] 1.5rem cursor-pointer
               `}
          >
            {Array.from({ length: totalPages }).map((_, index) => (
              <option
                key={index + 1}
                value={index + 1}
                className="hover:bg-gray-300 border-primary rounded-[5px] overflow-scroll max-h-[50px]"
              >
                {index + 1}
              </option>
            ))}
          </select>
        </div>

        <button
          disabled={next === null}
          className={`text-center cursor-pointer text-${
            next === null ? "gray-300" : "primary"
          } text-[23px]`}
          onClick={async () => {
            await getAttendeesApprovalStatus(next);
          }}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};

export default ApprovalPending;

import React, { useEffect, useState } from "react";
import Primarybtn from "../../common/buttons/Primarybtn";
import { useSelector, useDispatch } from "react-redux";
import { getUserDetails, getUserEvents } from "../../redux/actions/userActions";
import { eventsTab } from "../../helper/constant";
import { EVENT_CREATE_DESTROY } from "../../redux/constants/eventConstants";
import { useNavigate } from "react-router";
import useDebounce from "../../helper/hooks/useDebounce";
import AttendeeProfile from "../attendeeEvent/AttendeeProfile";
import DefaultProfilePicture from "../../common/defaultProfilePicture/DefaultProfilePicture";
import EventCard from "./EventCard";

function AllEvents() {
  const navigate = useNavigate();
  const [triggerLabel, setTriggerLabel] = useState(false);
  let accessToken = localStorage.getItem("accessToken");

  const dispatch = useDispatch();
  const userEventsData = useSelector((state) => state.userEvents);
  const { error, loading, userEvents } = userEventsData;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const userDetails = useSelector((state) => state.userDetails);
  const { savedUserConfig } = userDetails;
  const [search, setSearch] = useState("");
  const [mobileSeacrh, setMobileSeacrh] = useState(false);
  const debouncedSearchValue = useDebounce(search, 700);
  const [eventTab, setEventTab] = useState("All Events");
  const [triggerProfile, setTriggerProfile] = useState(false);
  const [allEvents, setAllEvents] = useState([]);
  const now = new Date().getTime();

  useEffect(() => {
    let filteredEvents = userEvents;
    filteredEvents = filteredEvents.filter((event) =>
      event.title.toLowerCase().includes(debouncedSearchValue.toLowerCase())
    );
    setAllEvents(filteredEvents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  // useEffect(() => {
  //   if (savedUserConfig?.waitlist) {
  //     navigate("/waitlist");
  //   }
  // }, [savedUserConfig?._id]);

  useEffect(() => {
    setAllEvents((prev) => {
      let sortedEvents = userEvents?.sort((a, b) => {
        const dateA = new Date(a.startDate);
        const dateB = new Date(b.startDate);
        return dateB - dateA;
        // Sort in descending order
      });
      return sortedEvents;
    });
  }, [userEvents]);

  useEffect(() => {
    // generateText(prompt);
    let accessToken = localStorage.getItem("accessToken");
    dispatch(getUserDetails({ accessToken: accessToken }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedUserConfig?._id]);

  useEffect(() => {
    // setTimeout(() => {
    if (userInfo?.firstName) {
      dispatch(getUserEvents({ accessToken: userInfo.accessToken }));
    } else if (accessToken) {
      dispatch(getUserEvents({ accessToken }));
    } else {
      alert("Please Login"); //AccessToken not available.
      navigate("/login");
    }
    dispatch({
      type: EVENT_CREATE_DESTROY,
    });
    // }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); //ReRun
  // Run for error in JWT
  // useEffect(() => {
  //   alert("Please Login"); //JWT expired
  //   router.push("/login");
  // }, [error])

  return (
    <div className="w-full flex justify-center">
      {mobileSeacrh ? (
        <form
          className="flex items-center w-full md:hidden fixed top-0 z-30 justify-center h-[60px] bg-[#FAFAFA]"
          onSubmit={(event) => event.preventDefault()}
        >
          <div className="relative w-[90%] mt-3">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <img src="/svgs/Search.svg" className="h-[19px]" alt="search" />
            </div>
            <input
              type="text"
              id="simple-search"
              className="bg-[#FAFAFA] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full pl-10 pr-10 p-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary dark:focus:border-primary"
              placeholder="Search"
              autoComplete="off"
              required={true}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              autoFocus
            />
            <div
              className="flex absolute inset-y-0 right-0 items-center pr-3  cursor-pointer z-40"
              onClick={() => {
                setMobileSeacrh(false);
                setSearch("");
              }}
            >
              <img
                src="/svgs/Cross.svg"
                className="h-[19px] cursor-pointer"
                alt="close"
              />
            </div>
          </div>
        </form>
      ) : (
        <div className="w-full h-[60px] fixed top-0 bg-[#FAFAFA] flex items-center justify-between px-[16px] z-30 md:hidden">
          <div className="flex items-center text-[22px] font-[500]">
            <img
              src="/svgs/logo.svg"
              alt="logo"
              className="w-[30px] mr-[5px]"
            />{" "}
            Warpbay
          </div>
          <div className="flex items-center">
            <img
              src="/svgs/Search.svg"
              className="w-[20px] h-[20px] object-cover cursor-pointer"
              onClick={() => setMobileSeacrh(true)}
              alt="search"
            />
            <img
              src="/svgs/Notifications.svg"
              className="w-[19px] h-[19px] object-cover cursor-pointer mx-[20px]"
              alt="notification"
            />
            {savedUserConfig?.profilePicture ? (
              <img
                src={savedUserConfig?.profilePicture}
                alt=""
                className=" w-[24px] h-[24px] rounded-full cursor-pointer"
                onClick={() => setTriggerProfile(true)}
              />
            ) : (
              <div
                className="cursor-pointer"
                onClick={() => setTriggerProfile(true)}
              >
                <DefaultProfilePicture
                  firstName={savedUserConfig?.firstName}
                  lastName={savedUserConfig?.lastName}
                  style={{
                    width: "24px",
                    height: "24px",
                    borderRadius: "300px",
                    fontSize: "10px",
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {triggerProfile && (
        <AttendeeProfile
          setTriggerProfile={setTriggerProfile}
          triggerProfile={triggerProfile}
          savedUserConfig={savedUserConfig}
        />
      )}

      <div className="w-full md:w-[750px] md:pb-5 md:mt-7 px-[16px] flex flex-col md:bg-white bg-[#FAFAFA] pb-[80px] min-h-[90vh]">
        <div className="flex items-center justify-between">
          <span className="text-[#727374] text-[12px] block md:hidden my-[12px]">
            Hello{" "}
            <span className="text-black font-[500] ">
              {savedUserConfig?.firstName},
            </span>{" "}
            here's all your events ...
          </span>
          <div
            className="flex border relative md:hidden px-1.5 py-1 text-[13px] rounded-lg font-medium"
            onClick={(e) => {
              e.preventDefault();
              setTriggerLabel(!triggerLabel);
            }}
          >
            {eventTab === "Events Organized" ? "Organized" : eventTab}
            <img
              src="/svgs/arrow.svg"
              alt="arrow"
              className="w-[15px] font-[600] ml-[5px] cursor-pointer rotate-180"
            />
            {triggerLabel && (
              <div className="absolute w-[130px] top-[15px] pt-4 bg-transperent z-20 right-[2px]">
                <div
                  className="bg-white border h-[120px] rounded-lg overflow-y-auto"
                  style={{
                    boxShadow: "0px 0px 14px rgba(165, 94, 234, 0.2)",
                  }}
                >
                  {eventsTab?.map((event, index) =>
                    event === "Events Organized" ? (
                      <div
                        className="border-b hover:bg-gray-200 h-[38px] cursor-pointer flex justify-center items-center "
                        key={index}
                        onClick={() => setEventTab(event)}
                      >
                        Organized
                      </div>
                    ) : (
                      <div
                        className="border-b hover:bg-gray-200 h-[40px] cursor-pointer flex justify-center items-center "
                        key={index}
                        onClick={() => setEventTab(event)}
                      >
                        {event}
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="hidden md:grid grid-cols-1 justify-items-center md:grid-cols-2 gap-x-12 h-[60px]">
          <form
            className="flex items-center my-4 w-full md:w-[350px] "
            onSubmit={(event) => event.preventDefault()}
          >
            <label htmlFor="simple-search" className="sr-only">
              Search
            </label>
            <div className="relative w-full ">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                id="simple-search"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full pl-10 p-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary dark:focus:border-primary"
                placeholder="Search"
                autoComplete="off"
                required={true}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              ></input>
            </div>
          </form>
          <div className="hidden md:flex  md:items-center md:my-4 w-full md:w-[350px]">
            <Primarybtn
              onClick={() => {
                navigate("/createvent");
              }}
            >
              Create Event
            </Primarybtn>
          </div>
        </div>
        <div className="hidden md:flex md:justify-center md:mt-11 md:gap-x-5">
          {eventsTab.map((event, index) => (
            <React.Fragment key={index}>
              <div
                className={`${
                  eventTab === event
                    ? "pb-2.5 border-b-2 border-primary text-primary font-bold cursor-pointer text-[15px]"
                    : "cursor-pointer pb-2.5 border-b-2 text-[15px]"
                }`}
                onClick={() => setEventTab(event)}
              >
                {event}
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className="">
          {allEvents?.length === 0 ? (
            <div className="grid w-full place-items-center h-[350px] mt-[30px]">
              <div>
                <p className="text-[20px] font-[500] text-[#717171] text-center">
                  No Events Found
                </p>
                <img
                  src="/svgs/nullState.svg"
                  alt=""
                  className="w-[300px] h-[300px]"
                />
              </div>
            </div>
          ) : error ? (
            <h3 className="text-[25px] font-[600] text-center text-gray-500 mt-[30px]">
              Some error occurred...
            </h3>
          ) : loading ? (
            <h3 className="text-[25px] font-[500] md:font-[600] text-center text-gray-500 mt-[50%] md:mt-[30px]">
              Loading...
            </h3>
          ) : (
            <div>
              {eventTab === "All Events" ? (
                <>
                  {allEvents.filter(
                    (ele) =>
                      new Date(
                        new Date(ele?.endDate).toLocaleString("en-US", {
                          timeZone: "Asia/Kolkata",
                        })
                      ).getTime() >= now
                  ).length > 0 ? (
                    <div className="grid grid-cols-1 md:px-0 place-content-center justify-items-center md:grid-cols-2 gap-x-[65px] md:gap-y-[30px] gap-y-[14px] mt-1 md:mt-[46px]">
                      {allEvents
                        .filter(
                          (ele) =>
                            new Date(
                              new Date(ele?.endDate).toLocaleString("en-US", {
                                timeZone: "Asia/Kolkata",
                              })
                            ).getTime() >= now
                        )
                        .map((ele, index) => (
                          <React.Fragment key={index}>
                            <EventCard ele={ele} />
                          </React.Fragment>
                        ))}
                    </div>
                  ) : (
                    <div className="grid w-full place-items-center h-[350px] mt-[30px]">
                      <div>
                        <p className="text-[20px] font-[500] text-[#717171] text-center">
                          No Events Found
                        </p>
                        <img
                          src="/svgs/nullState.svg"
                          alt=""
                          className="w-[300px] h-[300px]"
                        />
                      </div>
                    </div>
                  )}
                </>
              ) : eventTab === "Events Organized" ? (
                <>
                  {allEvents.filter(
                    (ele) =>
                      new Date(
                        new Date(ele?.endDate).toLocaleString("en-US", {
                          timeZone: "Asia/Kolkata",
                        })
                      ).getTime() >= now &&
                      ele?.eventRole !== "attendee" &&
                      ele?.eventRole !== "speaker" &&
                      ele?.eventRole !== "exhibitorAndSponsor"
                  ).length > 0 ? (
                    <div className="grid grid-cols-1 md:px-0 place-content-center justify-items-center md:grid-cols-2 gap-x-[65px] md:gap-y-[30px] gap-y-[14px] mt-1 md:mt-[46px]">
                      {allEvents
                        .filter(
                          (ele) =>
                            new Date(
                              new Date(ele?.endDate).toLocaleString("en-US", {
                                timeZone: "Asia/Kolkata",
                              })
                            ).getTime() >= now &&
                            ele?.eventRole !== "attendee" &&
                            ele?.eventRole !== "speaker" &&
                            ele?.eventRole !== "exhibitorAndSponsor"
                        )
                        .map((ele, index) => (
                          <React.Fragment key={index}>
                            <EventCard ele={ele} />
                          </React.Fragment>
                        ))}
                    </div>
                  ) : (
                    <div className="grid w-full place-items-center h-[350px] mt-[30px]">
                      <div>
                        <p className="text-[20px] font-[500] text-[#717171] text-center">
                          No Organized Events Found
                        </p>
                        <img
                          src="/svgs/nullState.svg"
                          alt=""
                          className="w-[300px] h-[300px]"
                        />
                      </div>
                    </div>
                  )}
                </>
              ) : eventTab === "Past Events" ? (
                <>
                  {allEvents.filter(
                    (ele) =>
                      new Date(
                        new Date(ele?.endDate).toLocaleString("en-US", {
                          timeZone: "Asia/Kolkata",
                        })
                      ).getTime() <= now
                  ).length > 0 ? (
                    <div className="grid grid-cols-1 md:px-0 place-content-center justify-items-center md:grid-cols-2 gap-x-[65px] md:gap-y-[30px] gap-y-[14px] mt-1 md:mt-[46px]">
                      {allEvents
                        .filter(
                          (ele) =>
                            new Date(
                              new Date(ele?.endDate).toLocaleString("en-US", {
                                timeZone: "Asia/Kolkata",
                              })
                            ).getTime() <= now
                        )
                        .map((ele, index) => (
                          <React.Fragment key={index}>
                            <EventCard ele={ele} />
                          </React.Fragment>
                        ))}
                    </div>
                  ) : (
                    <div className="grid w-full place-items-center h-[350px] mt-[30px]">
                      <div>
                        <p className="text-[20px] font-[500] text-[#717171] text-center">
                          No Past Events Found
                        </p>
                        <img
                          src="/svgs/nullState.svg"
                          alt=""
                          className="w-[300px] h-[300px]"
                        />
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AllEvents;

import { showToast } from "../../../redux/actions/toastActions";

async function displayRazorpay({
  eventId,
  ticketId,
  amount,
  dispatch,
  transactionId,
  userData,
  couponId,
  discount,
  navigate,
  setLoading,
  billing,
}) {
  const orderId = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/payment/razorpay`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        eventId: eventId,
        ticketId: ticketId,
        receiptId: transactionId,
        amount: amount,
        couponId: couponId,
        currency: "INR",
      }),
    }
  );

  const dataR = await orderId.json();

  if (!dataR.success) {
    dispatch(
      showToast({
        message: "Internal Server Error! Please Retry",
        type: "danger",
      })
    );
    return;
  }
  localStorage.removeItem("tempUserPayDetails");

  var options = {
    key: "rzp_test_vlPwuXtjFz1cq1",
    amount: dataR.data.amount.toString(),
    currency: "INR",
    name: "Warpbay",
    description: "Test-room Transaction",
    image:
      "https://ik.imagekit.io/k3m4pqzpmlr/coupons/1880-moon-outline_TBPed9a84.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1640773110779",
    order_id: dataR.data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    handler: async function (response) {
      setLoading(true);
      let body = {
        ...response,
        amount: dataR.data.amount,
        ticketId: ticketId,
        eventId: eventId,
        couponId: couponId,
        discount: discount,
        merchantTransactionId: transactionId,
      };

      if (Array.isArray(userData)) {
        body.users = userData;
        body.billing = billing ? billing : userData[0];
      } else {
        body = { ...body, ...userData };
      }
      const data = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/payment/razorpay/success`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ...body,
          }),
        }
      );
      const data1 = await data.json();
      localStorage.setItem("receiptUrl", data1.url);
      if (data1.payment.razorpay.status === "SUCCESS")
        navigate("/payments/razorpay/success");
      else navigate("/payments/razorpay/failure");

      setLoading(false);
    },
    prefill: {
      name: Array.isArray(userData) ? userData[0].name : userData.name,
      email: Array.isArray(userData) ? userData[0].email : userData.email,
      contact: Array.isArray(userData) ? userData[0].mobile : userData.mobile,
    },
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();

  paymentObject.on("payment.failed", async function (response) {
    let body = {
      ...response,
      amount: dataR.data.amount,
      ticketId: ticketId,
      eventId: eventId,
      couponId: couponId,
      discount: discount,
      merchantTransactionId: transactionId,
    };

    if (Array.isArray(userData)) {
      body.users = userData;
      body = { ...body, ...userData[0] };
    } else {
      body = { ...body, ...userData };
    }
    const data = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/payment/razorpay/failure`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...body,
        }),
      }
    );
    const data1 = await data.json();
    console.log(data1);
    paymentObject.close();
    navigate("/payments/razorpay/failure");
  });
}

export default displayRazorpay;

import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { showToast } from "../../../../redux/actions/toastActions";
import TextInput from "../../../../common/inputElements/TextInput";
import { useNavigate } from "react-router-dom";
import { getSingleEvent } from "../../../../redux/actions/eventActions";

export default function AddTicket({
  open,
  setOpen,
  isEdit,
  setIsEdit,
  singleEvent,
  setTickets,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //   const [sessions,setSessions] = useState([])
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
  });

  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken) {
    alert("please login!! access token not found");
    navigate("/login");
  }

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      data.price = parseInt(data.price);
      data.discountValue =
        data.discountValue === "" ? 0 : parseInt(data.discountValue);
      data.totalTicketCount = parseInt(data.totalTicketCount);
      data.eventId = singleEvent._id;

      if (data.discountValue >= data.price) {
        throw new Error("Discount cant be greater than price of ticket");
      }

      const newTicket = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/event/${singleEvent._id}/ticketCategories`,
        data,
        {
          headers: {
            "x-access-token": `${accessToken}`,
          },
        }
      );

      dispatch(
        showToast({
          message: "Ticket Added Successfully",
          category: "success",
        })
      );
      setTickets((prev) => [...prev, newTicket]);

      setIsSubmitting(false);
      setOpen(false);
    } catch (error) {
      dispatch(
        showToast({
          message: error.message,
          category: "danger",
        })
      );
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="z-40 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="z-40 fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-[45px] md:left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md outline-none hidden md:block text-gray-300"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>X
                          {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
                        </button>
                        <button
                          className="md:hidden flex items-center"
                          onClick={() => setOpen(false)}
                        >
                          <i className="fa fa-angle-left text-[24px]"></i>
                          <span className="text-[17px] pt-0.5">Back</span>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6 fixed z-20 bg-white w-[100%] top-0">
                        <Dialog.Title className="text-[22px] pb-[0px] font-[600] text-gray-900 pt-5">
                          Add Ticket
                        </Dialog.Title>
                      </div>
                      <div className="relative mt-[35px] flex-1 px-4 sm:px-6 mb-[50px] overflow-x-hidden">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="mt-[30px]"></div>
                          <TextInput
                            register={register}
                            type="text"
                            id={"name"}
                            label="Name for ticket"
                            placeholder="Name for ticket"
                            mb="3"
                            required
                          />
                          <div className="mt-[30px]"></div>
                          <TextInput
                            register={register}
                            type="text"
                            id={"description"}
                            label="Description"
                            placeholder="Description"
                            mb="3"
                            required
                          />
                          <div className="mt-[30px]"></div>
                          <div className="mt-[20px]"></div>
                          <TextInput
                            register={register}
                            type="number"
                            id={"price"}
                            label="Price"
                            placeholder="Price"
                            mb="3"
                            required
                          />
                          <p className="text-gray-400 text-[12px] relative font-[500] -top-[9px] -right-[5px]">
                            eg. 599 in rupees
                          </p>
                          <div className="mt-[20px]"></div>
                          <TextInput
                            register={register}
                            type="text"
                            id={"discountValue"}
                            label="Discount"
                            placeholder="Discount"
                            mb="3"
                          />
                          <p className="text-gray-400 text-[12px] relative font-[500] -top-[9px] -right-[5px]">
                            eg. 100 or 200 in rupeees
                          </p>
                          <div className="mt-[20px]"></div>
                          <TextInput
                            register={register}
                            type="number"
                            id={"totalTicketCount"}
                            label="Ticket Count"
                            placeholder="Ticket Count"
                            mb="3"
                            required
                          />
                          {/* <div className="mt-[30px]"></div>
                          <div className="mt-[20px]"></div>
                          <TextInput
                            register={register}
                            type="number"
                            id={"totalTicketCount"}
                            label="Ticket Count"
                            placeholder="Ticket Count"
                            mb="3"
                            required
                          /> */}
                          <div className="fixed bottom-0 w-[87%] bg-white h-[80px] flex justify-center items-center ml-[-2px]">
                            <input
                              disabled={isSubmitting}
                              value={isSubmitting ? "Loading..." : "Add Ticket"}
                              type="submit"
                              className="primary_submit"
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

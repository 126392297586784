import { MouseEventHandler } from "react";
import styles from "./Button.module.css";

const SecondaryButton = ({
  btnText,
  onClick,
}) => {
  return (
    <button className={styles.secondary_btn} onClick={onClick}>
      {btnText}
    </button>
  );
};

export default SecondaryButton;

import React, { useRef, useEffect, useState } from "react";
import {
  Chart,
  LineController,
  LineElement,
  Filler,
  PointElement,
  LinearScale,
  TimeScale,
  Tooltip,
} from "chart.js";
import { chartColors } from "../../../../utils/Utils";

Chart.register(
  LineController,
  LineElement,
  Filler,
  PointElement,
  LinearScale,
  TimeScale,
  Tooltip
);

function LineChart03({ data, width, height }) {
  const [chart, setChart] = useState(null);
  const canvas = useRef(null);

  function formatTime(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const formattedTime = `${hours}h`;
    return formattedTime;
  }

  function formatTimeLabel(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    const formattedTime = `${hours}h ${minutes}m ${seconds}s`;
    return formattedTime;
  }

  const {
    textColor,
    gridColor,
    tooltipBodyColor,
    tooltipBgColor,
    tooltipBorderColor,
  } = chartColors;

  useEffect(() => {
    const ctx = canvas.current;
    const newChart = new Chart(ctx, {
      type: "line",
      data: data,
      options: {
        layout: {
          padding: 20,
        },
        scales: {
          y: {
            beginAtZero: true,
            border: {
              display: false,
            },
            ticks: {
              callback: (value) => formatTime(value), // Convert milliseconds to hours and minutes
              color: textColor.light,
            },
            grid: {
              color: gridColor.light,
            },
          },
          x: {
            type: "time",
            time: {
              parser: "MM-DD-YYYY",
              unit: "day",
              displayFormats: {
                day: "MMM DD",
              },
            },
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
            ticks: {
              autoSkipPadding: 48,
              maxRotation: 0,
              color: textColor.light,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: () => false,
              label: (context) => formatTimeLabel(context.parsed.y), // Convert tooltip label to hours and minutes
            },
            bodyColor: tooltipBodyColor.light,
            backgroundColor: tooltipBgColor.light,
            borderColor: tooltipBorderColor.light,
          },
        },
        interaction: {
          intersect: false,
          mode: "nearest",
        },
        maintainAspectRatio: false,
        resizeDelay: 200,
      },
    });
    setChart(newChart);
    return () => newChart.destroy();
  }, [data]);

  useEffect(() => {
    if (!chart) return;

    chart.options.scales.x.ticks.color = textColor.light;
    chart.options.scales.y.ticks.color = textColor.light;
    chart.options.scales.y.grid.color = gridColor.light;
    chart.options.plugins.tooltip.bodyColor = tooltipBodyColor.light;
    chart.options.plugins.tooltip.backgroundColor = tooltipBgColor.light;
    chart.options.plugins.tooltip.borderColor = tooltipBorderColor.light;

    chart.update("none");
  }, []);

  return <canvas ref={canvas} width={width} height={height}></canvas>;
}

export default LineChart03;

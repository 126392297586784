import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import TextInput from "../../../../common/inputElements/TextInput";
import DatePicker from "react-date-picker";
import TimeInput from "../../../../common/timeInput/TimeInput";
import Select from "../../../../common/inputElements/Select";
import { useForm } from "react-hook-form";
import throttle from "../../../../utils/throttle";
import moment from "moment";
import { useMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../../../redux/actions/toastActions";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { getUserDetails } from "../../../../redux/actions/userActions";

export default function AddEmailSchedule({
  open,
  setOpen,
  event,
  isEdit,
  setIsEdit,
  setEmailDetails,
  emailDetails,
  singleEmail,
  testEmail,
  setTestEmail,
}) {
  const [value1, setValue1] = useState([]);
  const [filtersValue, setFiltersValue] = useState([]);
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dateValue, setDateValue] = useState(new Date());
  const [scheduleTime, setscheduleTime] = useState("");
  const eventsId = useMatch("/events/:eventId/*");
  const [accessToken, setAccessToken] = useState("");
  const [minDate, setminDate] = useState(new Date());
  const [maxDate, setmaxDate] = useState(new Date(event.endDate));
  const [dropdown, setDropdown] = useState(false);
  const textareaRef = useRef(null);
  const [emailBody, setEmailBody] = useState("");
  const userDetails = useSelector((state) => state.userDetails);
  const { savedUserConfig } = userDetails;
  const [isDisabled, setIsDisabled] = useState(false);
  const { register, handleSubmit, setValue, reset } = useForm({
    mode: "onChange",
  });
  const now = new Date().getTime();

  useEffect(() => {
    if (singleEmail?.emailSubject && isEdit) {
      const isEmailSent =
        new Date(
          new Date(singleEmail?.campaignTime).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
          })
        ).getTime() -
          30 * 60 * 1000 <=
        now;
      setIsDisabled(isEmailSent);
    } else {
      setIsDisabled(false);
    }
  }, [singleEmail, open]);

  const filteredValues = [
    "firstName",
    "lastName",
    "eventTitle",
    "organizationName",
    "eventStartDate",
    "eventEndDate",
    "organizerEmail",
    "organizerName",
  ];

  const speakerOptions = [
    {
      label: "Send to all",
      value: "1",
    },
    {
      label: "Attended",
      value: "2",
    },
    {
      label: "Not attended",
      value: "3",
    },
  ];

  const labelOptions = [
    {
      label: "Sponsor",
      value: "11",
    },
    {
      label: "Speaker",
      value: "22",
    },
    {
      label: "Government",
      value: "33",
    },
    {
      label: "Delegate",
      value: "44",
    },
    {
      label: "Media",
      value: "55",
    },
    {
      label: "Organizer",
      value: "66",
    },
    {
      label: "Volunteer",
      value: "77",
    },
  ];

  useEffect(() => {
    let accessToken = localStorage.getItem("accessToken");
    dispatch(getUserDetails({ accessToken: accessToken }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedUserConfig?._id]);

  useEffect(() => {
    if (isEdit) {
      reset({
        emailSubject: singleEmail?.emailSubject,
        campaignTime: singleEmail?.campaignTime,
        sendBadge: singleEmail?.sendBadge,
      });
      setEmailBody(
        singleEmail?.emailBody.replace(/<p><br><\/p>/g, "&nbsp;<br><br>")
      );

      setOptions(speakerOptions);
      setValue1(singleEmail?.emailTo[0]);
      setValue("selectTo", singleEmail?.emailTo[0]);
      setFilterOptions(labelOptions);
      setFiltersValue(singleEmail?.filters[0]);
      setValue("filters", singleEmail?.filters[0]);

      const date = new Date(singleEmail?.campaignTime);

      const options = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        timeZone: "Asia/Kolkata",
      };
      const formattedTime = date.toLocaleString("en-US", options);
      const formattedTimeWithSeparator = formattedTime.replace(":", " : ");
      setscheduleTime(formattedTimeWithSeparator);
      setDateValue(new Date(singleEmail.campaignTime));
      setminDate(new Date());
      const eventEndDate = new Date(
        new Date(event?.endDate).getTime() + 25 * 24 * 60 * 60 * 1000
      );
      setmaxDate(eventEndDate);
    } else {
      const currentTime = moment();
      const roundedMinutes =
        (Math.ceil((currentTime.minute() + 1) / 15) * 15) % 60;
      const roundedTime = moment({
        hour: currentTime.hour() + (roundedMinutes === 0),
        minute: roundedMinutes,
      });
      const formattedVal = moment(
        roundedTime.format("hh : mm A"),
        "hh : mm A"
      ).format("HH:mm:ss");
      reset({
        emailSubject: "",
        emailBody: "",
        campaignTime: formattedVal,
        sendBadge: true,
      });

      setOptions(speakerOptions);
      setFilterOptions(labelOptions);
      setFiltersValue([]);
      setValue1([]);
      setValue();
      setscheduleTime(roundedTime.format("hh : mm A"));
      setDateValue(new Date());
      setminDate(new Date());
      const eventEndDate = new Date(
        new Date(event?.endDate).getTime() + 25 * 24 * 60 * 60 * 1000
      );
      setmaxDate(eventEndDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event?._id, open]);

  useEffect(() => {
    if (!open) {
      setEmailBody("");
    }
  }, [open]);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    setAccessToken(token);
  }, []);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    if (value1.length === 0 && !testEmail) {
      dispatch(
        showToast({
          message: "Please select atleast one option",
          category: "danger",
        })
      );
      setIsSubmitting(false);
    } else if (!data.campaignTime) {
      dispatch(
        showToast({
          message: "Please select time for scheduling",
          category: "danger",
        })
      );
      setIsSubmitting(false);
    } else {
      if (testEmail) {
        let emailData = {
          email: data.email,
          emailSubject: data.emailSubject,
          emailBody: emailBody,
        };
        try {
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/event/testEmailTemplate?eventId=${event?._id}&userId=${savedUserConfig?._id}`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                emailData,
              }),
            }
          );
          const allEmails = await response.json();
          if (allEmails.message === "success") {
            dispatch(
              showToast({
                message: "Test Email Send",
                category: "success",
              })
            );
          }
          setOpen(false);
          setIsSubmitting(false);
          setValue1([]);
          reset();
          setEmailBody("");
          setTestEmail(false);
        } catch (error) {
          console.log(error);
          setIsSubmitting(false);
        }
      } else {
        let emailData;
        let newEmailData;
        if (singleEmail.emailSubject !== "" && isEdit && !testEmail) {
          emailData = {
            ...data,
            emailBody: emailBody,
            _id: singleEmail._id,
            eventID: event._id,
            campaignTime:
              data.campaignTime === singleEmail.campaignTime
                ? data.campaignTime
                : new Date(
                    `${moment(dateValue).format("ll")}, ${data.campaignTime}`
                  ).toISOString(),
          };
          newEmailData = emailDetails.scheduledEmails.filter((item) => {
            return emailData._id !== item._id;
          });
        } else {
          emailData = {
            ...data,
            eventID: event._id,
            emailSubject: data.emailSubject,
            emailBody: emailBody,
            campaignTime: new Date(
              `${moment(dateValue).format("ll")}, ${data.campaignTime}`
            ).toISOString(),
          };
          newEmailData = emailDetails.scheduledEmails;
        }
        try {
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/event/${
              eventsId.params.eventId
            }/${"scheduleEmail"}`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                emailData,
                oldScheduledEmail: newEmailData,
              }),
            }
          );
          const allEmails = await response.json();
          if (response.status !== 200) {
            dispatch(
              showToast({
                message: "Please add again!! some error occurred",
                category: "danger",
              })
            );
          }
          setOpen(false);
          setEmailDetails(allEmails.savedEventConfig);
          setIsSubmitting(false);
          setEmailBody("");
          setValue1([]);
          reset();
        } catch (error) {
          console.log(error);
          setIsSubmitting(false);
        }
      }
    }
  };

  useEffect(() => {
    if (dropdown) {
      const editor = textareaRef.current.getEditor();
      editor.focus();
    }
  }, [dropdown]);

  const handleDropdown = (variable) => {
    const editor = textareaRef.current.getEditor();
    const selection = editor.getSelection(true);

    editor.clipboard.dangerouslyPasteHTML(selection.index, `{{${variable}}}`);

    const newCursorPosition = selection.index + variable.length + 4;
    editor.setSelection(newCursorPosition);
    editor.focus();
  };

  const handleEditorChange = (value) => {
    setEmailBody(value);
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="z-40 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="z-40 fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-[45px] md:left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md outline-none hidden md:block text-gray-300"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>X
                          {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
                        </button>
                        <button
                          className="md:hidden flex items-center"
                          onClick={() => setOpen(false)}
                        >
                          <i className="fa fa-angle-left text-[24px]"></i>
                          <span className="text-[17px] pt-0.5">Back</span>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6 fixed z-20 bg-white w-[100%] top-0">
                        <Dialog.Title className="text-[22px] pb-[25px] font-[600] text-gray-900 pt-5">
                          {isDisabled ? (
                            <div className="flex items-center gap-2">
                              <span>Preview</span>
                              <div className="flex text-[14px]">
                                {new Date(
                                  new Date(
                                    singleEmail?.campaignTime
                                  ).toLocaleString("en-US", {
                                    timeZone: "Asia/Kolkata",
                                  })
                                ).getTime() <= now ? (
                                  <div className="text-green-600 font-medium flex">
                                    (Sent:&nbsp;
                                    {new Date(
                                      singleEmail?.campaignTime
                                    ).toLocaleString("en-US", {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                    })}
                                    ,{" "}
                                    {new Date(
                                      singleEmail?.campaignTime
                                    ).toLocaleString("en-US", {
                                      hour: "numeric",
                                      minute: "numeric",
                                      hour12: true,
                                    })}
                                    )
                                  </div>
                                ) : (
                                  <div className="text-orange-500 font-medium	">
                                    (Scheduled:&nbsp;
                                    {new Date(
                                      singleEmail?.campaignTime
                                    ).toLocaleString("en-US", {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                    })}
                                    ,{" "}
                                    {new Date(
                                      singleEmail?.campaignTime
                                    ).toLocaleString("en-US", {
                                      hour: "numeric",
                                      minute: "numeric",
                                      hour12: true,
                                    })}
                                    )
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : !isEdit && !testEmail ? (
                            "Schedule Email"
                          ) : isEdit && !testEmail ? (
                            "Edit Email"
                          ) : (
                            "Test Email"
                          )}
                        </Dialog.Title>
                      </div>
                      <div className="relative mt-[75px] flex-1 px-4 sm:px-6 mb-[50px]">
                        <form
                          onSubmit={handleSubmit((data) =>
                            throttle(() => onSubmit(data), 5000)
                          )}
                        >
                          {testEmail ? (
                            <TextInput
                              register={register}
                              disabled={isDisabled}
                              type="email"
                              id={"email"}
                              required
                              label="Email Address"
                              placeholder="Email"
                            />
                          ) : (
                            <>
                              <div className="relative mb-[25px]">
                                <label
                                  htmlFor="startDate"
                                  className="text-[12px] text-[#9c9c9c] absolute -top-[18px] left-[12px]"
                                >
                                  To <span className="text-red-600">*</span>
                                </label>
                                <Select
                                  register={register}
                                  id={"selectTo"}
                                  options={isDisabled ? [] : options}
                                  value={value1}
                                  onChange={(event) => {
                                    if (!isDisabled) {
                                      setValue("selectTo", event);
                                      setValue1(event);
                                    }
                                  }}
                                />
                              </div>

                              <div className="relative mb-[40px] mt-[40px]">
                                <label
                                  htmlFor="startDate"
                                  className="text-[12px] text-[#9c9c9c] absolute -top-[18px] left-[12px]"
                                >
                                  Filters (Optional)
                                </label>
                                <Select
                                  register={register}
                                  id={"Filters"}
                                  options={isDisabled ? [] : filterOptions}
                                  value={filtersValue}
                                  onChange={(event) => {
                                    if (!isDisabled) {
                                      setValue("filters", event);
                                      setFiltersValue(event);
                                    }
                                  }}
                                />
                              </div>
                            </>
                          )}

                          <TextInput
                            register={register}
                            type="text"
                            id={"emailSubject"}
                            disabled={isDisabled}
                            required
                            label="Email Subject"
                            placeholder="Email Subject"
                          />

                          {/* <TextArea
                            register={register}
                            type="text"
                            id={"emailBody"}
                            required
                            label="Email Body"
                            placeholder="Email Body"
                          /> */}

                          <div className="relative mt-[50px]">
                            <label
                              htmlFor="dropdown"
                              className="text-[12px] text-[#9c9c9c] absolute -top-[18px] left-[5px]"
                            >
                              Email Body&nbsp;
                              <span className="text-red-600">*</span>
                            </label>
                            <ReactQuill
                              readOnly={isDisabled}
                              value={emailBody}
                              onChange={handleEditorChange}
                              theme="snow"
                              ref={textareaRef}
                              className="custom-editor"
                            />
                            <div className="border border-b-gray-300 border-x-gray-300 border-t-0 h-[50px] w-full bg-gray-50 rounded-b-lg flex items-center">
                              <div
                                className="flex text-gray-400 font-[500] text-[14px] items-center rounded-[3px] border border-gray-300 ml-2 p-1 bg-gray-200 cursor-pointer relative"
                                onClick={() => {
                                  if (!isDisabled) {
                                    setDropdown(!dropdown);
                                  }
                                }}
                              >
                                {"{} Variable"}{" "}
                                <img
                                  src="/svgs/arrow.svg"
                                  alt="arrow"
                                  className=" w-[17px] ml-1 "
                                />
                                {dropdown && (
                                  <div
                                    id="dropdown"
                                    className={`absolute top-[30px] overflow-y-scroll max-h-[110px] rounded-lg z-10 w-[160px] bg-white divide-y-0 divide-[#F4F6F9] shadow dark:bg-gray-700`}
                                  >
                                    {filteredValues.map((variable, index) => (
                                      <ul
                                        className="text-[13px] text-gray-700 dark:text-gray-200 list-none cursor-pointer"
                                        onClick={() => handleDropdown(variable)}
                                        key={index}
                                      >
                                        <li>
                                          <p className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                            {variable}
                                          </p>
                                        </li>
                                      </ul>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          {testEmail ? (
                            <></>
                          ) : (
                            <>
                              <div className="mt-3 text-[13px] font-[500] text-primary flex items-center  h-7">
                                <input
                                  type="checkbox"
                                  disabled={isDisabled}
                                  {...register("sendBadge")}
                                  id={"sendBadge"}
                                  className="mr-2 border-[#cacaca] border-2 bg-white checked:bg-primary checked:border-[#d5d8dd] cursor-pointer focus:ring-0"
                                />
                                <label
                                  htmlFor="sendBadge"
                                  className="cursor-pointer"
                                >
                                  Send event badge as attachment.
                                </label>
                              </div>

                              {!isDisabled && (
                                <div className="grid grid-cols-2 gap-[50px] mt-[40px] mb-[30px]">
                                  <div className="relative">
                                    <label
                                      htmlFor="startDate"
                                      className="text-[12px] text-[#9c9c9c] absolute -top-[18px] left-[5px]"
                                    >
                                      Scheduled Date
                                    </label>
                                    <DatePicker
                                      onChange={(value) => setDateValue(value)}
                                      value={dateValue}
                                      minDate={minDate}
                                      maxDate={maxDate}
                                      format="dMMMy"
                                    />
                                  </div>

                                  <TimeInput
                                    register={register}
                                    required={true}
                                    value={scheduleTime}
                                    setValue={setValue}
                                    id={"campaignTime"}
                                    label={"Scheduled Time"}
                                    isHalfWidth={false}
                                  />
                                </div>
                              )}
                            </>
                          )}

                          {!isDisabled && (
                            <div className="fixed bottom-0 w-[87%] bg-white  h-[80px] flex justify-center items-center ml-[-2px]">
                              {!testEmail ? (
                                <div className="flex justify-between w-full gap-4">
                                  {isEdit ? (
                                    <input
                                      disabled={isSubmitting}
                                      value={
                                        isSubmitting
                                          ? "Loading..."
                                          : "Save Changes"
                                      }
                                      type="submit"
                                      className="primary_submit"
                                    />
                                  ) : (
                                    <input
                                      disabled={isSubmitting}
                                      value={
                                        isSubmitting
                                          ? "Loading..."
                                          : "Schedule Email"
                                      }
                                      type="submit"
                                      className="primary_submit"
                                    />
                                  )}
                                  <input
                                    disabled={isSubmitting}
                                    value={"Test Email"}
                                    type="button"
                                    onClick={() => {
                                      setTestEmail(true);
                                    }}
                                    className="flex items-center cursor-pointer text-[#1C1C1E] text-[15px] font-[500] border h-[42px] justify-center rounded-[10px] my-[5px] mx-auto px-12"
                                  />
                                </div>
                              ) : (
                                <div className="flex justify-between w-full gap-4">
                                  <input
                                    disabled={isSubmitting}
                                    value={
                                      isSubmitting
                                        ? "Loading..."
                                        : "Send Test Email"
                                    }
                                    type="submit"
                                    className="primary_submit"
                                  />
                                  <input
                                    disabled={isSubmitting}
                                    value={"Cancel Test"}
                                    onClick={() => setTestEmail(false)}
                                    type="button"
                                    className="flex items-center cursor-pointer text-danger text-[15px] font-[500] border h-[42px] justify-center rounded-[10px] my-[5px] mx-auto px-11"
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

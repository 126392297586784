import React from "react";
import { useNavigate, useMatch } from "react-router-dom";

function Marketing() {
  const navigate = useNavigate();
  const eventsId = useMatch("events/:eventId/*");

  return (
    <>
      <p className="text-sm text-gray-500 dark:text-gray-400">
        You can use marketing channels to attract more attendees for your
        events.
      </p>
      <div className="my-4">
        <div
          className="flex items-center justify-between border-b-2 h-[65px] w-full cursor-pointer"
          onClick={() =>
            navigate(
              `/events/${eventsId.params.eventId}/communications/eventmarketing`
            )
          }
        >
          <div className="flex items-center cursor-pointer relative pr-[38px]">
            <img src="/svgs/gmail.svg" alt="Email" className="h-[25px] m-2" />

            <p>Email marketing</p>
            <span className=" top-[0px] right-0 bg-[#83f5b2] text-green-800  text-[9px] font-medium px-1.5 py-[1px] rounded ml-2">
              New
            </span>
          </div>
          <p className="p-3 text-gray-500 text-l">{">"}</p>
        </div>
        <div
          className="flex items-center justify-between border-b-2 h-[65px] w-full cursor-pointer"
          onClick={() => {
            navigate(
              `/events/${eventsId.params.eventId}/communications/linkedin-marketing`
            );
          }}
        >
          <div className="flex items-center pr-[38px]">
            <img
              src="/svgs/linkedinblue.svg"
              alt="Email"
              className="h-[25px] m-2"
            />
            <p>LinkedIn marketing </p>
            <span className=" top-[0px] right-0 bg-[#83f5b2] text-green-800  text-[9px] font-medium px-1.5 py-[1px] rounded ml-2">
              New
            </span>
          </div>
          <p className="p-3 text-gray-500 text-l">{">"}</p>
        </div>
        <div
          className="flex items-center justify-between border-b-2 h-[65px] w-full cursor-pointer"
          onClick={() => {
            navigate(
              `/events/${eventsId.params.eventId}/communications/whatsapp-marketing`
            );
          }}
        >
          <div className="flex items-center pr-[38px]">
            <img
              src="/svgs/whatsapp.svg"
              alt="Email"
              className="h-[34px] m-1 "
            />
            <p>Whatsapp marketing</p>
            <span className=" top-[0px] right-0 bg-[#83f5b2] text-green-800  text-[9px] font-medium px-1.5 py-[1px] rounded ml-2">
              New
            </span>
          </div>
          <p className="p-3 text-gray-500 text-l">{">"}</p>
        </div>
        <div className="flex items-center justify-between border-b-2 h-[65px] w-full cursor-pointer">
          <div className="flex items-center">
            <svg
              aria-hidden="true"
              className="flex-shrink-0 w-6 h-6 m-2 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
            </svg>
            <p>SMS marketing </p>
            <span className=" top-[0px] right-0 bg-blue-200 text-blue-800 text-[8px] font-medium px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-2">
              Coming Soon
            </span>
          </div>
          <p className="p-3 text-gray-500 text-l">{">"}</p>
        </div>
        <div
          className="flex items-center justify-between border-b-2 h-[65px] w-full cursor-pointer"
          onClick={() =>
            navigate(
              `/events/${eventsId.params.eventId}/communications/exhibitorDirectory`
            )
          }
        >
          <div className="flex items-center cursor-pointer relative pr-[38px]">
            <svg
              aria-hidden="true"
              className="flex-shrink-0 w-6 h-6 m-2 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
            </svg>

            <p>Exhibitor Directory</p>
            <span className=" top-[0px] right-0 bg-[#83f5b2] text-green-800  text-[9px] font-medium px-1.5 py-[1px] rounded ml-2">
              New
            </span>
          </div>
          <p className="p-3 text-gray-500 text-l">{">"}</p>
        </div>
      </div>
    </>
  );
}

export default Marketing;

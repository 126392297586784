import React from "react";

const PrintCheckin = ({ tabHover }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={tabHover === "print" ? "#A55EEA" : "#C5C5C7"}
      height="100%"
      viewBox="0 -960 960 960"
      width="100%"
    >
      <path d="M658-648v-132H302v132h-60v-192h476v192h-60Zm-518 60h680-680Zm599 95q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm-81 313v-192H302v192h356Zm60 60H242v-176H80v-246q0-45.05 30.5-75.525Q141-648 186-648h588q45.05 0 75.525 30.475Q880-587.05 880-542v246H718v176Zm102-236v-186.215Q820-562 806.775-575 793.55-588 774-588H186q-19.55 0-32.775 13.225Q140-561.55 140-542v186h102v-76h476v76h102Z" />
    </svg>
  );
};

export default PrintCheckin;

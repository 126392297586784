import React from "react";

const IDCard = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color ? color : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_2431_26060)">
        <path
          d="M4 22C3.45 22 2.97933 21.8043 2.588 21.413C2.196 21.021 2 20.55 2 20V9C2 8.45 2.196 7.979 2.588 7.587C2.97933 7.19567 3.45 7 4 7H9V4C9 3.45 9.196 2.979 9.588 2.587C9.97933 2.19567 10.45 2 11 2H13C13.55 2 14.021 2.19567 14.413 2.587C14.8043 2.979 15 3.45 15 4V7H20C20.55 7 21.021 7.19567 21.413 7.587C21.8043 7.979 22 8.45 22 9V20C22 20.55 21.8043 21.021 21.413 21.413C21.021 21.8043 20.55 22 20 22H4ZM6 18H12V17.55C12 17.2667 11.9207 17.004 11.762 16.762C11.604 16.5207 11.3833 16.3333 11.1 16.2C10.7667 16.05 10.4293 15.9377 10.088 15.863C9.746 15.7877 9.38333 15.75 9 15.75C8.61667 15.75 8.25433 15.7877 7.913 15.863C7.571 15.9377 7.23333 16.05 6.9 16.2C6.61667 16.3333 6.396 16.5207 6.238 16.762C6.07933 17.004 6 17.2667 6 17.55V18ZM14 16.5H18V15H14V16.5ZM9 15C9.41667 15 9.77067 14.854 10.062 14.562C10.354 14.2707 10.5 13.9167 10.5 13.5C10.5 13.0833 10.354 12.7293 10.062 12.438C9.77067 12.146 9.41667 12 9 12C8.58333 12 8.22933 12.146 7.938 12.438C7.646 12.7293 7.5 13.0833 7.5 13.5C7.5 13.9167 7.646 14.2707 7.938 14.562C8.22933 14.854 8.58333 15 9 15ZM14 13.5H18V12H14V13.5ZM11 9H13V4H11V9Z"
          fill={color ? color : "#C5C5C7"}
        />
      </g>
    </svg>
  );
};

export default IDCard;

import React from "react";

const Speakers = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color ? color : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1395_4176"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill={color ? color : "#D9D9D9"} />
      </mask>
      <g mask="url(#mask0_1395_4176)">
        <path
          d="M19.95 15.95L18.4 14.4C19.1333 13.7167 19.7083 12.9084 20.125 11.975C20.5417 11.0417 20.75 10.05 20.75 9.00005C20.75 7.95005 20.5417 6.96672 20.125 6.05005C19.7083 5.13338 19.1333 4.33338 18.4 3.65005L19.95 2.05005C20.8833 2.93338 21.625 3.97505 22.175 5.17505C22.725 6.37505 23 7.65005 23 9.00005C23 10.35 22.725 11.625 22.175 12.825C21.625 14.025 20.8833 15.0667 19.95 15.95ZM16.75 12.75L15.15 11.15C15.45 10.8667 15.6917 10.546 15.875 10.188C16.0583 9.82938 16.15 9.43338 16.15 9.00005C16.15 8.56672 16.0583 8.17072 15.875 7.81205C15.6917 7.45405 15.45 7.13338 15.15 6.85005L16.75 5.25005C17.2833 5.73338 17.7 6.29572 18 6.93705C18.3 7.57905 18.45 8.26672 18.45 9.00005C18.45 9.73338 18.3 10.4207 18 11.062C17.7 11.704 17.2833 12.2667 16.75 12.75ZM9 13C7.9 13 6.95833 12.6084 6.175 11.825C5.39167 11.0417 5 10.1 5 9.00005C5 7.90005 5.39167 6.95838 6.175 6.17505C6.95833 5.39172 7.9 5.00005 9 5.00005C10.1 5.00005 11.0417 5.39172 11.825 6.17505C12.6083 6.95838 13 7.90005 13 9.00005C13 10.1 12.6083 11.0417 11.825 11.825C11.0417 12.6084 10.1 13 9 13ZM1 21V18.2001C1 17.6501 1.14167 17.1334 1.425 16.65C1.70833 16.1667 2.1 15.8 2.6 15.55C3.45 15.1167 4.40833 14.75 5.475 14.45C6.54167 14.15 7.71667 14 9 14C10.2833 14 11.4583 14.15 12.525 14.45C13.5917 14.75 14.55 15.1167 15.4 15.55C15.9 15.8 16.2917 16.1667 16.575 16.65C16.8583 17.1334 17 17.6501 17 18.2001V21H1Z"
          fill={color ? color : "#C5C5C7"}
        />
      </g>
    </svg>
  );
};

export default Speakers;

import React, { useState, useCallback, useEffect } from "react";
import { useDropzone, ErrorCode } from "react-dropzone";
import { parse } from "papaparse";
import styles from "./Registrations.module.css";
import { getRequest, patchRequest } from "../../../utils/API/api.ts";
import { useMatch } from "react-router-dom";

function AttendeeCSVUpload({
  setIsBulkUpload,
  getAllEventAttendees,
  isBulkUpload,
}) {
  const [status, setStatus] = useState("empty");
  const [error, setError] = useState();
  const [parseError, setParseError] = useState();
  const [hasIgnoredColumns, setHasIgnoredColumns] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [sendRegMail, setSendRegMail] = useState(false);
  const eventsId = useMatch("/events/:eventId/*");
  const [acceptedHeaders, setAcceptedHeaders] = useState([
    "firstName",
    "lastName",
    "email",
    "countryCode",
    "mobile",
    "organization",
    "jobTitle",
    "country",
    "city",
    "state",
    "label",
  ]);
  useEffect(() => {
    if (parseError) {
      handleUploadError("parseError", parseError);
    }
  }, [parseError]);

  useEffect(() => {
    let defaultHeader = [
      "firstName",
      "lastName",
      "email",
      "countryCode",
      "mobile",
      "organization",
      "jobTitle",
      "country",
      "city",
      "state",
      "label",
    ];
    const getAdditionalForm = async () => {
      const response = await getRequest(
        `/event/${eventsId.params.eventId}/additionalForm`
      );
      if (response.data[0]?.form) {
        let updatedRegForm = response.data[0]?.form?.filter((form) => {
          return (
            form.inputType !== "city" &&
            form.inputType !== "country" &&
            form.inputType !== "state"
          );
        });

        const newHeaders = updatedRegForm.map((form) => form?.fieldName);
        setAcceptedHeaders([...defaultHeader, ...newHeaders]);
      }
    };

    if (isBulkUpload && eventsId.params.eventId) {
      getAdditionalForm();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsId, isBulkUpload]);

  const onStatusChange = (newStatus) => {
    setStatus(newStatus);
    if (newStatus !== "success") {
      setRecipients([]);
    }
  };
  const checkHeaderValidity = (headers) => {
    let isValid = true;
    const missingColumns = [];
    for (const acceptedHeader of acceptedHeaders) {
      if (!headers.includes(acceptedHeader)) {
        isValid = false;
        missingColumns.push(acceptedHeader);
      }
    }
    return {
      isValid,
      missingColumns,
    };
  };

  const getAdditionalForm = async () => {
    const response = await getRequest(
      `/event/${eventsId.params.eventId}/additionalForm`
    );
    if (response.data[0]?.form) {
      let updatedRegForm = response.data[0]?.form?.filter((form) => {
        return (
          form.inputType !== "city" &&
          form.inputType !== "country" &&
          form.inputType !== "state"
        );
      });
      return updatedRegForm;
    }
  };

  const validateRecipients = async (recipients) => {
    if (!recipients.length) {
      setParseError({
        type: "noRecipientData",
        message: "No Data is present in CSV File.",
      });
      return false;
    }
    let dynamicCsvFields = await getAdditionalForm();
    // Check max limit for recipients
    if (recipients.length > 5001) {
      setParseError({
        type: "tooManyRecipients",
        message: "5000 is the max limit to upload entries",
      });
      return false;
    }

    // Check recipient has "country_code" field, and is valid
    // const hasCountryCode = recipients.every((recipient) => {
    //   const country_code = recipient.countryCode;
    //   const pattern = /^\d/;
    //   const isValidCode = pattern.test(country_code);
    //   return isValidCode;
    // });

    // if (!hasCountryCode) {
    //   setParseError({
    //     type: "invalidCountryCode",
    //     message: "Country Code is Invalid",
    //   });
    //   return false;
    // }

    // Check recipient has "mobile_number" field, and this is a number
    let eventIdToSkip = [
      "659fc17696619de5add90095",
      "659fb71996619de5add8fc9e",
      "659e621536abc943ea777f21",
      "65d87a23b68f8753ec878982"
    ];

    let eventId = eventsId.params.eventId;
    const hasMobileNumber = recipients.every((recipient) => {
      const mobile_number = Number(recipient.mobile);

      return (
        Number.isInteger(mobile_number) &&
        mobile_number > 0 &&
        `${mobile_number}`.length === 10
      );
    });

    if (
      !hasMobileNumber &&
      !eventIdToSkip?.some((event) => event === eventId)
    ) {
      setParseError({
        type: "invalidMobileNumber",
        message: "Mobile Number is Invalid",
      });
      return false;
    }

    // Check that email and name are provided
    const areMandatoryFieldsProvided = recipients.every((recipient) => {
      const { firstName, lastName, email } = recipient;
      return firstName.length && lastName.length && email.length;
    });

    if (!areMandatoryFieldsProvided) {
      setParseError({
        type: "fieldsAreMandatory",
        message: "All fields are mandatory",
      });
      return false;
    }

    // Check if email is valid
    const invalidEmailIndexes = [];
    recipients.forEach((recipient, index) => {
      const { email } = recipient;
      const emailRegex =
        /^([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$/i;
      if (!emailRegex.test(email) && email.length !== 0)
        invalidEmailIndexes.push(email);
    });

    if (invalidEmailIndexes.length > 0) {
      setParseError({
        type: "invalidEmails",
        message: `${"invalidEmails"} ${invalidEmailIndexes.join(", ")}`,
      });
      return false;
    }

    return recipients.map((recipient, index) => {
      let code = "";
      // const randomFourDigit = Math.floor(1000 + Math.random() * 9000);
      // let attendeesLength = 0;
      // if (
      //   recipient?.gala_dinner === "Yes" &&
      //   recipient?.pre_conference_workshop !== "Yes" &&
      //   recipient?.attend_lll_workshop !== "Yes"
      // ) {
      //   code = `MG00 - ${randomFourDigit}` + (attendeesLength + 1 + index);
      // } else if (
      //   recipient?.gala_dinner !== "Yes" &&
      //   recipient?.pre_conference_workshop === "Yes" &&
      //   recipient?.attend_lll_workshop !== "Yes"
      // ) {
      //   code = `MP00 - ${randomFourDigit}` + (attendeesLength + 1 + index);
      // } else if (
      //   recipient?.gala_dinner === "Yes" &&
      //   recipient?.pre_conference_workshop === "Yes" &&
      //   recipient?.attend_lll_workshop !== "Yes"
      // ) {
      //   code = `MPG0 - ${randomFourDigit}` + (attendeesLength + 1 + index);
      // } else if (
      //   recipient?.gala_dinner === "Yes" &&
      //   recipient?.pre_conference_workshop !== "Yes" &&
      //   recipient?.attend_lll_workshop === "Yes"
      // ) {
      //   code = `MGL0 - ${randomFourDigit}` + (attendeesLength + 1 + index);
      // } else if (
      //   recipient?.gala_dinner !== "Yes" &&
      //   recipient?.pre_conference_workshop === "Yes" &&
      //   recipient?.attend_lll_workshop === "Yes"
      // ) {
      //   code = `MPL0 - ${randomFourDigit}` + (attendeesLength + 1 + index);
      // } else if (
      //   recipient?.gala_dinner === "Yes" &&
      //   recipient?.pre_conference_workshop === "Yes" &&
      //   recipient?.attend_lll_workshop === "Yes"
      // ) {
      //   code = `MPGL - ${randomFourDigit}` + (attendeesLength + 1 + index);
      // } else if (
      //   recipient?.gala_dinner !== "Yes" &&
      //   recipient?.pre_conference_workshop !== "Yes" &&
      //   recipient?.attend_lll_workshop === "Yes"
      // ) {
      //   code = `ML00 - ${randomFourDigit}` + (attendeesLength + 1 + index);
      // } else if (
      //   recipient?.gala_dinner !== "Yes" &&
      //   recipient?.pre_conference_workshop !== "Yes" &&
      //   recipient?.attend_lll_workshop !== "Yes"
      // ) {
      //   code = `M000 - ${randomFourDigit}` + (attendeesLength + 1 + index);
      // }


      let countryCode = recipient.countryCode ?? null;

      if (countryCode && !countryCode.startsWith("+")) {
        countryCode = "+" + countryCode;
      }
      let additionalData = {};
      if (Object.keys(recipient).length > 6) {
        for (const prop in recipient) {
          if (
            prop !== "firstName" &&
            prop !== "lastName" &&
            prop !== "mobile" &&
            prop !== "countryCode" &&
            prop !== "country" &&
            prop !== "email" &&
            prop !== "organization" &&
            prop !== "jobTitle" &&
            prop !== "country" &&
            prop !== "state" &&
            prop !== "city"
          ) {
            additionalData[prop] = recipient[prop].trim();
          }
        }
      }

      const updatedAdditionalData = dynamicCsvFields?.reduce((acc, form) => {
        if (form?.inputType === "multi-select") {
          const multiSelectData = getAdditionalInfo(
            additionalData,
            form?.fieldName
          );
          if (multiSelectData) {
            Object.assign(acc, multiSelectData);
          }
        }
        return acc;
      }, additionalData);
      return {
        userEmail: recipient.email.toLowerCase(),
        data: {
          userData: {
            firstName: recipient.firstName,
            lastName: recipient.lastName,
            jobTitle: recipient.jobTitle,
            organization: recipient.organization,
            email: recipient.email.toLowerCase().trim(),
            age: recipient.age ?? null,
            countryCode: countryCode,
            code: code,
            location: {
              country: {
                name: recipient.country ?? null,
              },
              state: {
                name: recipient.state ?? null,
              },
              city: {
                name: recipient.city ?? null,
              },
            },
            mobile: recipient.mobile ?? null,
          },
          eventSpecificData: {
            bio: recipient.bio,
            eventId: eventsId.params.eventId,
            eventSpecificRole: "attendee",
            label: recipient.label.toLowerCase() ?? null,
            additionalData: {
              ...updatedAdditionalData,
            },
          },
        },
      };
    });
  };

  const getAdditionalInfo = (additionalData, fieldName) => {
    let field = additionalData[fieldName] || null;

    if (field) {
      if (field.includes(",")) {
        const values = field
          .split(",")
          .map((value) => ({ label: value.trim() }));

        return { [fieldName]: values };
      } else {
        return { [fieldName]: [{ label: field }] };
      }
    }

    return null;
  };

  const downloadCSV = () => {
    if (acceptedHeaders.length === 0) {
      alert("Field names not available. Fetch them first.");
      return;
    }

    const csvContent = acceptedHeaders.join(",") + "\n";

    const blob = new Blob([csvContent], { type: "text/csv" });

    const csvURL = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = csvURL;
    a.download = "sample.csv";
    document.body.appendChild(a);
    a.click();

    URL.revokeObjectURL(csvURL);
  };

  const onFileUploaded = (fileContents) => {
    parse(fileContents, {
      header: true,
      skipEmptyLines: "greedy",
      complete: (results) => {
        if (!results.errors.length) {
          const parsedHeaders = results.meta.fields || [];
          const parsedData = results.data;
          const headerValidity = checkHeaderValidity(parsedHeaders);
          if (headerValidity.isValid) {
            // if (true) {
            // setHeaders(acceptedHeaders);

            // Check if any columns in uploaded csv were ignored

            parsedHeaders.length > 11 //To be updated when any other field is added
              ? setHasIgnoredColumns(true)
              : setHasIgnoredColumns(false);

            const validatedRecipients = validateRecipients(parsedData);

            if (validatedRecipients) {
              setRecipients(validatedRecipients);
              setParseError(null);
              onStatusChange("success");
            }
          } else {
            setParseError({
              type: "missingColumns",
              message: `${"Missing Columns"} ${headerValidity.missingColumns.join(
                ", "
              )}`,
            });
          }
        } else {
          const error = results.errors[0];
          if (error.row === 0 && error.code === "TooManyFields") {
            setParseError({
              type: "instructionRowError",
              message: "Delete the instruction row before upload.",
            });
          } else {
            setParseError({
              type: "generalError",
              message: "General upload error occurred.",
            });
          }
        }
      },
    });
  };

  const handleUploadError = (errorType, error) => {
    switch (errorType) {
      case ErrorCode.FileInvalidType:
        setError({
          type: "fileInvalidType",
          message: "The file type is invalid",
        });
        break;
      case ErrorCode.TooManyFiles:
        setError({
          type: "tooManyFiles",
          message: "Too many files are uploaded.",
        });
        break;
      case ErrorCode.FileTooLarge:
        setError({
          type: "fileTooLarge",
          message: "The file is too large.",
        });
        break;
      case ErrorCode.FileTooSmall:
        setError({
          type: "fileTooSmall",
          message: "The file is too small.",
        });
        break;
      case "parseError":
        if (error) setError({ ...error });
        else
          setError({
            type: "generalError",
            message: "General parse error has occurred. Please contact admin.",
          });
        break;
      default:
        setError({
          type: "generalError",
          message: "General parse error has occurred. Please contact admin.",
        });
        break;
    }
    onStatusChange("error");
  };

  const onDropAccepted = useCallback((acceptedFiles) => {
    onStatusChange("processing");
    try {
      const reader = new FileReader();
      reader.readAsText(acceptedFiles[0]);
      reader.onabort = () => {
        handleUploadError("file reading was aborted");
      };
      reader.onerror = () => {
        handleUploadError("file reading has failed");
      };
      reader.onload = (event) => {
        const csv = event.target?.result;
        onFileUploaded(csv);
      };
      setError(null);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const onDropRejected = useCallback((fileRejections) => {
    const error = fileRejections[0].errors[0].code;
    console.log(error);
    handleUploadError(error);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "text/csv": [".csv"] },
    multiple: false,
    minSize: 1,
    maxSize: 5242880,
    onDropRejected: onDropRejected,
    onDropAccepted: onDropAccepted,
  });

  // const renderWidgetIcon = (status) => {
  //   switch (status) {
  //     case "success":
  //       return <FileAttachedIcon />;
  //     case "processing":
  //       return <FileProcessingIcon />;
  //     case "empty":
  //     case "error":
  //     default:
  //       return (
  //         <i className="fas fa-file-upload text-[70px] cursor-pointer text-gray-400"></i>
  //       );
  //   }
  // };

  const renderStatusText = () => {
    let statusText;
    switch (status) {
      case "success":
        statusText = `Successfully added, press add attendees to proceed.`;
        if (hasIgnoredColumns) {
          statusText = statusText.concat(" - ", "ignoredColumns");
        }
        break;
      case "error":
        statusText = `CSV Upload Status: ${status} - ${error?.message}`;
        break;
      default:
        return null;
    }
    return <div>{statusText}</div>;
  };

  return (
    <>
      {status === "addedSuccessfully" ? (
        <>
          <div className={styles.csvContainer}>
            <div className={styles.csvInner}>
              <div className=" w-[300px] h-[200px] flex justify-center items-center flex-col">
                <svg
                  className="inline mr-2 w-11 h-11 text-gray-200 animate-spin dark:text-gray-600 fill-primary"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <p className="mt-3">Uploading file...</p>
              </div>
              <p className="text-[14px] text-center w-[300px] text-primary mt-4">
                if you dont see the data in the table, please refresh the page.
              </p>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.csvContainer}>
          <div className={styles.csvInner}>
            <p className="text-[19px] font-[600] text-center">Upload CSV</p>
            {/* <select>
          <option>Exhibitor</option>
          <option>Volunteer</option>
          <option>Service Worker</option>
        </select> */}

            <p className={`${styles.docLink}`}>
              {/* Please refer to the{" "}
          <a target="_blank" href="/" rel="noreferrer">
            documentation here
          </a>
          , and{" "} */}
              <span
                className="text-primary text-[13px] block hover:underline cursor-pointer"
                onClick={downloadCSV}
                // href="/SampleAttendeeData.csv"
              >
                Download the CSV format for adding new entries.
              </span>
            </p>

            <div
              {...getRootProps({
                // className: `uploadWidget`,
              })}
              className="min-h-[70px] h-[50%] m-auto bottom-2 md:flex justigy-center flex-col items-center border-2 border-dashed  border-gray-400 rounded-sm p-5 text-center cursor-pointer hover:bg-gray-100"
            >
              <input {...getInputProps()} />
              {/* {renderWidgetIcon(status)} */}

              {status === "success" ? (
                <img
                  src="/svgs/upload-success.svg"
                  alt="success"
                  className="w-[100px] mb-[15px] cursor-pointer"
                />
              ) : status === "error" ? (
                <img
                  src="/svgs/upload-failed.svg"
                  alt="success"
                  className="w-[100px] mb-[15px] cursor-pointer"
                />
              ) : (
                <img
                  src="/svgs/upload-initial.svg"
                  alt="success"
                  className="w-[100px] mb-[15px] cursor-pointer"
                />
              )}

              {status !== "success" ? (
                <p className="text-[15px] text-center font-[500] cursor-pointer mb-1">
                  Select CSV File
                </p>
              ) : (
                <></>
              )}

              <span
                className={`text-${
                  status === "error"
                    ? "danger"
                    : status === "success"
                    ? "green-500"
                    : ""
                } font-[500]`}
              >
                {renderStatusText(status, error, hasIgnoredColumns)}
              </span>
            </div>

            <p className="mt-[17px] text-[12px] font-[500] text-[#A55EEA] mb-[20px]  w-full flex justify-between items-center">
              <span className="relative top-[0]">
                Send registration mail to all the attendees
              </span>
              <label
                htmlFor="default-toggle"
                className="inline-flex top-[0px] right-0 relative items-center cursor-pointer"
              >
                <input
                  type="checkbox"
                  // checked
                  value={sendRegMail}
                  onChange={(e) => {
                    setSendRegMail(!sendRegMail);
                  }}
                  id="default-toggle"
                  className="sr-only peer"
                />
                <span className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></span>
              </label>
            </p>

            <div className="w-[170px] mx-auto">
              <input
                className="primary_submit"
                type="submit"
                value="Add Attendees"
                onClick={async () => {
                  let recipientData = await recipients;
                  if (recipientData.length > 0) {
                    try {
                      const response = await patchRequest(
                        `/event/${eventsId.params.eventId}/bulkRegister/attendee?sendRegMail=${sendRegMail}`,
                        recipientData
                      );
                      if (response) {
                        setStatus("addedSuccessfully");
                        setTimeout(() => {
                          setStatus("empty");
                          setIsBulkUpload(false);
                          getAllEventAttendees(
                            `/attendee/${eventsId.params.eventId}`
                          );
                        }, 6000);
                      }
                    } catch (err) {
                      console.log(err);
                    }
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
    // <div className={styles.csvContainer}>
  );
}

export default AttendeeCSVUpload;

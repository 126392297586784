import React from "react";

function TextInputP({
  label,
  id,
  placeholder,
  required,
  type,
  register,
  defaultValue,
  pattern,
  minLength,
  disabled = false,
  width = null,
  maxLength,
  ...props
}) {
  return (
    <div
      className={props.mb ? `relative mb-${props.mb}` : `relative mb-[28px]`}
    >
      <input
        type={type}
        id={id}
        className={`${
          width ? `w-[${width}]` : "w-full"
        } peer bg-[#F4F6F9] border-0 border-gray-200 text-gray-900 text-[13px] rounded-lg h-[40px] placeholder-transparent focus:ring-transparent focus:border-primary focus:border-[2px] block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white font-medium dark:focus:ring-blue-500 dark:focus:border-blue-500`}
        placeholder={placeholder}
        defaultValue={defaultValue}
        {...register(id, {
          required: required,
          pattern: pattern,
          minLength: +minLength,
          maxLength: new Number(maxLength),
        })}
        disabled={disabled}
      />
      <label
        htmlFor={id}
        className="absolute left-2 -top-5 mb-1 text-sm peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-900 peer-placeholder-shown:text-opacity-40 peer-placeholder-shown:top-[10px] peer-placeholder-shown:left-[13px] transition-all peer-focus:-top-5 peer-focus:text-gray-900 peer-focus:text-opacity-40 peer-focus:text-[12.5px] text-gray-900 text-opacity-40 dark:text-gray-300 font-[600]"
      >
        <>
          {required ? (
            <>
              {label}
              &nbsp;<span className="text-red-600">*</span>
            </>
          ) : (
            <>{label}</>
          )}
        </>
      </label>
      {props?.errors && props?.errors[id] && (
        <p className="absolute mt-[2px] text-danger text-[10px] font-[500]">
          {props?.errors[id]?.type === "pattern" && "Input is invalid"}
          {props?.errors[id]?.type === "required" && "Field is required"}
        </p>
      )}
      {/* {props.errors?.firstName ? (
        <p className="error_input_text_form">
          {props.errors.email?.type === "required" && "First Name is required"}
        </p>
      ) : null} */}
    </div>
  );
}

export default TextInputP;

import React, { useState } from "react";
import MainAnalytics from "./main/MainAnalytics.jsx";
import ScannerAnalytics from "./leadScanner/ScannerAnalytics.jsx";
import CheckinChekoutAnalytics from "./checkinCheckout/CheckinChekoutAnalytics.jsx";
import MeetingAnalytics from "./meetings/MeetingAnalytics.jsx";
import { useSelector } from "react-redux";

const Analytics = () => {
  const [tab, setTab] = useState("main");
  const singleEvent = useSelector((state) => state.eventData);

  return (
    <div className="w-full md:w-[85%] md:ml-[0px]">
      <div className="py-0">
        <div className="sticky top-0 bg-white z-20 pt-[10px] border-t">
          <div
            className="font-[600] w-[335px] md:w-full text-[24px] pt-0 text-black flex justify-between items-center
       bg-white sticky top-0"
          >
            <p className="text-[21px]">Analytics</p>
          </div>

          <div
            className="flex flex-row items-center w-[340px] md:w-[80%] mt-0  text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700 justify-between 
         bg-white md:pt-0 "
          >
            <ul className="flex flex-wrap -mb-px">
              <li
                className="mr-3 cursor-pointer"
                onClick={() => setTab("main")}
              >
                <span
                  className={`inline-block px-0 py-4 ${
                    tab === "main"
                      ? "text-primary border-primary underline underline-offset-8	 decoration-2"
                      : ""
                  } rounded-t-lg border-b-2 border-transparent dark:hover:text-gray-300 text-[14px] `}
                >
                  Main&nbsp;
                </span>
              </li>
              <li
                className="mr-3 cursor-pointer"
                onClick={() => setTab("leadScanner")}
              >
                <span
                  className={`inline-block py-4 ${
                    tab === "leadScanner"
                      ? "text-primary border-primary underline underline-offset-8	 decoration-2"
                      : ""
                  } rounded-t-lg border-b-2 border-transparent dark:hover:text-gray-300 text-[14px]`}
                >
                  Lead scanner
                </span>
              </li>
              <li
                className="mr-3 cursor-pointer"
                onClick={() => setTab("checkin")}
              >
                <span
                  className={`inline-block py-4 ${
                    tab === "checkin"
                      ? "text-primary border-primary underline underline-offset-8	 decoration-2"
                      : ""
                  } rounded-t-lg border-b-2 border-transparent dark:hover:text-gray-300 text-[14px]`}
                >
                  Checkin/out
                </span>
              </li>
              <li
                className="mr-3 cursor-pointer"
                onClick={() => setTab("meetings")}
              >
                <span
                  className={`inline-block py-4 ${
                    tab === "meetings"
                      ? "text-primary border-primary underline underline-offset-8	 decoration-2"
                      : ""
                  } rounded-t-lg border-b-2 border-transparent dark:hover:text-gray-300 text-[14px]`}
                >
                  Meetings
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="w-[335px] md:w-[100%]">
          {tab === "main" ? (
            <MainAnalytics singleEvent={singleEvent} />
          ) : tab === "checkin" ? (
            <CheckinChekoutAnalytics singleEvent={singleEvent} />
          ) : tab === "meetings" ? (
            <MeetingAnalytics eventsId={singleEvent?._id} />
          ) : tab === "leadScanner" ? (
            <ScannerAnalytics eventsId={singleEvent?._id} />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Analytics;

import React, { useEffect, useRef, useState } from "react";
import QrScanner from "qr-scanner";
import { useMatch, useNavigate } from "react-router-dom";
import { showToast } from "../../redux/actions/toastActions.js";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../redux/actions/userActions.js";
import axios from "axios";

const ScanPopup = ({ camera }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let videoElemRef = useRef();
  var qrScanner;
  const userDetails = useSelector((state) => state.userDetails);
  const { savedUserConfig } = userDetails;

  const getAllEventAttendees = async (uidParam, eventIdParam) => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/attendee/${savedUserConfig?._id}/addScannedLeads?scannedUserId=${uidParam}&eventId=${eventIdParam}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          dispatch(
            showToast({
              message: "Lead Created Successfully",
              category: "success",
            })
          );

          navigate(`/attendee/${eventIdParam}?tab=people&view=leads`);
        }
      })
      .catch(function (error) {
        console.log(error);
        dispatch(
          showToast({
            message: error.response.data.message,
            category: "danger",
          })
        );
      });
  };

  useEffect(() => {
    let accessToken = localStorage.getItem("accessToken");
    dispatch(getUserDetails({ accessToken: accessToken }));
  }, [savedUserConfig?._id]);

  useEffect(() => {
    qrScanner = new QrScanner(
      videoElemRef.current,
      (result) => {
        console.log("decoded qr code:", result);

        const uidStartIndex = result.data.indexOf("uid=") + 4;
        const uidEndIndex = result.data.indexOf("&eventId=");
        const uidParam = result.data.slice(uidStartIndex, uidEndIndex);

        const eventIdStartIndex = result.data.indexOf("eventId=") + 8;
        const eventIdParam = result.data.slice(eventIdStartIndex);

        if (uidParam && eventIdParam) {
          getAllEventAttendees(uidParam, eventIdParam);
          qrScanner.stop();
          navigate(-1);
        } else {
          console.log("nothing");
        }
      },
      {
        onDecodeError: (error) => {
          console.log(error);
        },
        highlightScanRegion: true,
        highlightCodeOutline: true,
        preferredCamera: camera,
        maxScansPerSecond: 10,
      }
    );

    qrScanner.start();

    return () => {
      qrScanner.stop();
    };
  }, []);

  useEffect(() => {
    function preventBackgroundScroll(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    document.body.style.overflow = "hidden";
    document.addEventListener("scroll", preventBackgroundScroll, {
      passive: false,
    });

    return () => {
      document.body.style.overflow = "visible";
      document.removeEventListener("scroll", preventBackgroundScroll);
    };
  }, []);

  const handleClose = () => {
    qrScanner?.destroy();
    navigate(-1);
  };

  const changeCamera = async (id) => {
    navigate(-1);

    setTimeout(() => {
      navigate(
        `/connections/scan?camera=${
          camera === "environment" ? "user" : "environment"
        }`
      );
    }, 500);
  };

  return (
    <>
      <div className="w-full flex justify-start items-center border-b-2 outline-offset-8 pb-3 relative mymd:mb-[20px]">
        <span className="text-[16px] font-[500]">Genrate Leads</span>
        <img
          src="/svgs/switch-camera.svg"
          alt="camera"
          className="w-[25px] cursor-pointer ml-3"
          onClick={changeCamera}
          title="Switch Camera"
        />

        <span
          className="absolute right-0 rounded-full bg-gray-200 cursor-pointer w-[28px] h-[28px] hover:bg-gray-300"
          onClick={handleClose}
        >
          <div className="flex justify-center items-center text-gray-800 w-[28px] h-[28px] text-[17px] font-[500] hover:font-[600]">
            x
          </div>
        </span>
      </div>

      <div className="pt-0 md:pt-0 md:mb-0 mx-auto grid place-items-center  w-full md:max-w-[1440px] md:w-full mb-14 h-[300px]">
        <video
          id="videoElem"
          ref={videoElemRef}
          className={`rounded-[15px] relative top-[10px] md:top-[10px] md:w-full md:h-full w-full border-[4px] border-[#d5d5d5] mx-auto`}
        ></video>
      </div>
    </>
  );
};

export default ScanPopup;

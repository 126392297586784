import React from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./common/navbar/Navbar";

export default class ErrorBoundary extends React.Component {
  state = { hasError: false };
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // errorService.log({ error, errorInfo });
    console.log({ error, errorInfo });
  }
  render() {
    if (this.state.hasError) {
      return (
        <>
          <div className="header h-[58px] w-full">
            <Navbar />
          </div>
          <div className="grid place-items-center h-[600px] overflow-hidden">
            <main>
              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-[600px] mx-auto">
                <div className="max-w-2xl m-auto mt-16">
                  <div className="text-center px-4">
                    <div className="inline-flex mb-8">
                      <img
                        src="/error.jpg"
                        width="326"
                        height="326"
                        alt="404 illustration"
                      />
                    </div>
                    <div className="mb-6 text-[22px] font-[600]">
                      Hmm... Something went wrong!
                    </div>
                    <ReturnToHome />
                  </div>
                </div>
              </div>
            </main>
          </div>
        </>
      );
    }
    return this.props.children;
  }
}

function ReturnToHome() {
  const navigate = useNavigate();
  return (
    <p
      onClick={() =>{ 
        navigate("/events")
        window.location.reload();
        }}
      className="btn bg-primary hover:bg-primary text-white rounded w-[250px] py-[7px] text-[18px] font-[500] mx-auto cursor-pointer"
    >
      Back To Dashboard
    </p>
  );
}

import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../../../../common/inputElements/TextInput";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import { getSingleEvent } from "../../../../redux/actions/eventActions";
import { showToast } from "../../../../redux/actions/toastActions";

export default function RegisterStaffForm() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState({
    isSuccess: null,
    message: "",
  });
  const dispatch = useDispatch();
  const eventsId = useMatch("/events/:eventId/register-staff");
  const [searchParams] = useSearchParams();
  const singleEvent = useSelector((state) => state.eventData);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      email: searchParams.get("email"),
    },
    mode: "onChange",
  });

  useEffect(() => {
    dispatch(getSingleEvent({ eventId: eventsId.params.eventId }));
    return () => {};
  }, []);

  async function onSubmit(data) {
    setIsSubmitting(true);
    if (Object.values(data).length <= 0) {
      dispatch(
        showToast({
          message: "Please fill all the details",
          category: "danger",
        })
      );
      return;
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/event/${eventsId.params.eventId}/add-staff`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              eventId: eventsId.params.eventId,
              data: { ...data },
            }),
          }
        );
        const IsLoggedIn = await response.json();
        if (response.status !== 200) {
          setSuccess({
            isSuccess: false,
            message: "Some Error Occured",
          });
          dispatch(
            showToast({
              message: "Some Error Occured",
              category: "danger",
            })
          );
        } else {
          setSuccess({
            isSuccess: true,
            message: "You have been successfully added to the team",
          });
          dispatch(
            showToast({
              message:
                "Thank you for registering, you have been successfully added",
              category: "success",
            })
          );
        }
        reset();
        setIsSubmitting(false);
      } catch (error) {
        console.log(error);
        setIsSubmitting(false);
      }
    }
  }

  return (
    <>
      {singleEvent?.startDate === undefined ? (
        <></>
      ) : success?.isSuccess === null ? (
        <>
          <div className="grid place-items-center my-[35px]">
            <div
              className="w-[600px] flex h-full flex-col overflow-y-scroll bg-white py-6  "
              style={{ boxShadow: "0px -4px 14px 4px rgba(0, 0, 0, 0.1)" }}
            >
              <div className="px-4 sm:px-6">
                <p className="text-[21px] pt-2 pb-[10px] font-[600] text-gray-900">
                  Join team{" "}
                  <span className="text-primary">
                    {searchParams.get("companyName")}
                  </span>{" "}
                  for <span className="text-primary">{singleEvent?.title}</span>
                  .
                </p>
              </div>
              <div className="relative mt-6 flex-1 px-4 sm:px-6">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TextInput
                    type="text"
                    placeholder="First Name"
                    id="firstName"
                    required
                    register={register}
                    label="First Name"
                  />
                  <TextInput
                    type="text"
                    id="lastName"
                    placeholder="Last Name"
                    required
                    register={register}
                    label="Last Name"
                  />

                  <TextInput
                    register={register}
                    // disabled="true"
                    type="email"
                    id={"email"}
                    placeholder="Email Address"
                    label="Email"
                    errors={errors}
                    required
                    pattern={
                      /^([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$/i
                    }
                  />

                  <TextInput
                    type="tel"
                    id="mobile"
                    placeholder="Mobile Number"
                    register={register}
                    label="Mobile Number"
                  />
                  <TextInput
                    type="text"
                    id="organization"
                    placeholder="Organization"
                    required
                    register={register}
                    label="Company Name"
                  />
                  <TextInput
                    type="text"
                    id="jobTitle"
                    placeholder="Job Title"
                    register={register}
                    label="Job Title"
                  />

                  <input
                    data-te-ripple-init
                    data-te-ripple-color="primary"
                    value={isSubmitting ? "Loading..." : "Join the team"}
                    type="submit"
                    className="primary_submit"
                  />
                </form>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="grid place-items-center my-[35px]">
          <div
            className="h-[300px] w-[600px] mt-[50px] flex flex-col items-center border justify-center"
            style={{ boxShadow: "0px -4px 14px 4px rgba(0, 0, 0, 0.1)" }}
          >
            {success?.isSuccess ? (
              <img
                src="/svgs/Checkcircle.svg"
                alt="success"
                className="w-[90px]"
              />
            ) : (
              <img
                src="/svgs/Cancelled.svg"
                alt="failed"
                className="w-[90px]"
              />
            )}

            <span className="text-[30px] mt-[10px] font-[500]">
              {success?.isSuccess
                ? "Thank You for Registering"
                : "Failed to Register"}
            </span>
            <p className="mt-[10px] font-[500]">{success?.message}</p>

            {success?.isSuccess ? (
              <p
                onClick={() => {
                  navigate("/login");
                }}
                className="btn bg-primary hover:bg-primary text-white rounded w-[150px] py-[7px] text-[18px] font-[500] mx-auto cursor-pointer text-center mt-[15px]"
              >
                Login
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </>
  );
}

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSingleEvent } from "../../../../redux/actions/eventActions";
import { useMatch, useNavigate } from "react-router-dom";
import { showToast } from "../../../../redux/actions/toastActions";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const OpenPDF = () => {
  const dispatch = useDispatch();
  const eventsId = useMatch("/event/:eventId/*");
  const singleEvent = useSelector((state) => state.eventData);
  const [pdfUrl, setPdfUrl] = useState(null);
  const navigate = useNavigate();
  const [numPages, setNumPages] = useState(null);
  const [windowWidth, setWindowWidth] = useState(0);
  const [overlayVisible, setOverlayVisible] = useState(false);

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  const handleVolumeDown = () => {
    // Your function logic here
    alert('Volume down button pressed!');
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if the key code corresponds to the volume down button (key code 174)
      if (event.keyCode === 174) {
        handleVolumeDown();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    dispatch(getSingleEvent({ eventId: eventsId.params.eventId }));
    return () => {};
  }, []);

  useEffect(() => {
    if (singleEvent?._id) {
      fetchData();
    }
  }, [singleEvent]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setOverlayVisible(true);
        } else {
          setOverlayVisible(false);
        }
      });
    });

    const overlayElement = document.getElementById(singleEvent?._id);
    if (overlayElement) {
      observer.observe(overlayElement);
    }

    return () => {
      if (overlayElement) {
        observer.unobserve(overlayElement);
      }
    };
  }, []);

  const fetchData = async () => {
    const temporaryUserData = JSON.parse(
      localStorage.getItem("temporaryUserData")
    );
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/event/exhibitor-directory/${singleEvent?._id}`
      );
      if (response.data.data.pdf && temporaryUserData?._id) {
        setPdfUrl(response.data.data.pdf);
      } else {
        dispatch(
          showToast({
            message: "Please Login",
            category: "danger",
          })
        );
        navigate(`/event/${singleEvent?._id}/exhibitor-directory/login`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      dispatch(
        showToast({
          message: "Access Expired",
          category: "danger",
        })
      );
      navigate(`/event/${singleEvent?._id}/exhibitor-directory/login`);
    }
  };

  const onDocumentLoad = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    const handleKeyUp = (event) => {
      if (event.key === "PrintScreen") {
        navigator.clipboard.writeText("");
      }
    };

    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <>
      <div
        onContextMenu={(e) => e.preventDefault()}
        className="min-h-screen bg-gray-100"
      >
        {pdfUrl ? (
          <div
            className="flex flex-col items-center w-full md:w-md mx-auto mt-[-56px] pb-[55px]"
            onContextMenu={(e) => e.preventDefault()}
          >
            <div
              className="mb-[20px] bg-white-200 border-b text-black w-full flex justify-center items-center h-[45px] text-[20px] font-[500]"
              onContextMenu={(e) => e.preventDefault()}
            >
              Exhibitor Directory
            </div>

            <Document
              onLoadSuccess={onDocumentLoad}
              file={pdfUrl}
              // onLoadError={(error) => console.error("Error loading PDF:", error)}
              onContextMenu={(e) => e.preventDefault()}
              className=""
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  renderTextLayer={false}
                  width={windowWidth > 767 ? "" : "350"}
                  className="mb-[25px]"
                />
              ))}
            </Document>
            <div
              id={singleEvent?._id}
              className={`fixed inset-0 bg-black bg-opacity-95 flex justify-center items-center transition-opacity duration-300 ${
                overlayVisible
                  ? "opacity-100 pointer-events-auto"
                  : "opacity-0 pointer-events-none"
              }`}
            >
              <p className="text-white text-2xl font-semibold">
                Screenshot attempts are not allowed.
              </p>
            </div>
          </div>
        ) : (
          <div className="grid place-items-center mt-[250px]">
            <div className=" w-full h-full flex justify-center items-center flex-col">
              <svg
                className="inline mr-2 w-11 h-11 text-gray-200 animate-spin dark:text-gray-600 fill-primary"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <p className="mt-[13px]">Almost there...</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OpenPDF;

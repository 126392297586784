import TransactionItem from "./TransactionsTableItem";

function TransactionsTable({
  registrations,
  showSearchResults,
  setRegistrations,
}) {
  return (
    <div className="bg-white">
      <div>
        <TransactionItem
          registrations={registrations}
          showSearchResults={showSearchResults}
          setRegistrations={setRegistrations}
        />
      </div>
    </div>
  );
}

export default TransactionsTable;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { topMenu } from "../../helper/constant";
import DefaultProfilePicture from "../../common/defaultProfilePicture/DefaultProfilePicture";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../redux/actions/userActions";

function TopMenu() {
  const [idx, setIdx] = useState("All Events");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const userDetails = useSelector((state) => state.userDetails);
  const { savedUserConfig } = userDetails;
  useEffect(() => {
    // generateText(prompt);
    let accessToken = localStorage.getItem("accessToken");
    dispatch(getUserDetails({ accessToken: accessToken }));
  }, [savedUserConfig?._id]);
  return (
    <ul className="hidden md:flex w-[320px] md:justify-between items-center text-[#C5C5C7] sticky top-[10px] pr-[30px] ">
      {topMenu.map((menu, index) => (
        <li
          className={`cursor-pointer grid place-items-center  ${
            location.pathname === `/${menu.path}` ? "text-primary" : ""
          }`}
          key={index}
          onClick={() => {
            setIdx(menu.name);
            navigate(`/${menu.path}`.toLowerCase());
          }}
        >
          {menu.name === "Profile" ? (
            <>
              <div className="">
                {savedUserConfig === undefined ? (
                  <img
                    src="/svgs/profile.svg"
                    className="rounded-full w-[30px] h-[30px] object-cover"
                    alt="profile"
                  />
                ) : savedUserConfig?.profilePicture ? (
                  <>
                    <img
                      src={savedUserConfig?.profilePicture}
                      className="rounded-full w-[30px] h-[30px] object-cover"
                      alt="profile"
                    />
                  </>
                ) : (
                  <DefaultProfilePicture
                    firstName={savedUserConfig?.firstName}
                    lastName={savedUserConfig?.lastName}
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "300px",
                      fontSize: "12px",
                    }}
                  />
                )}
              </div>
              {/* <span className="font-medium text-[10px]">{menu.name}</span> */}
            </>
          ) : (
            <>
              <i className={`${menu.icon} mb-1`}></i>
              <span className="font-medium text-[10px]">{menu.name}</span>
            </>
          )}
        </li>
      ))}
    </ul>
  );
}

export default TopMenu;

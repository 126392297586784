import React, { useEffect } from "react";
import PrimaryButton from "../../../../common/buttons/PrimaryButton";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UploadPdf from "./UploadPdf";
import { showToast } from "../../../../redux/actions/toastActions";
import { useNavigate } from "react-router-dom";
import UserDataTable from "./UserDataTable";
import { patchRequest } from "../../../../utils/API/api.ts";

const ExhibitorDirectory = () => {
  const [open, setOpen] = useState(false);
  const event = useSelector((state) => state.eventData);
  const [isEdit, setIsEdit] = useState(false);
  const [eventDetails, setEventDetails] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    if (event?._id) {
      setEventDetails(event);
    }
  }, [event]);

  const deleteFile = async () => {
    let obj = {
      users: [],
    };

    try {
      const updatedEvent = await patchRequest(`/event/${eventDetails?._id}`, {
        exhibitorDirectory: obj,
      });
      if (updatedEvent.status === 200) {
        setEventDetails(updatedEvent.data?.savedEventConfig);
        dispatch(
          showToast({
            message: "Successfully Deleted",
            category: "success",
          })
        );
        setPopup(false);
        navigate(`/events/${eventDetails?._id}?show=communications`);
      }
    } catch (err) {
      dispatch(
        showToast({
          message: "Some Error occured",
          category: "danger",
        })
      );
    }
  };
  async function downloadBlob() {
    try {
      const response = await fetch(eventDetails?.exhibitorDirectory?.image);
      const blob = await response.blob();

      const a = document.createElement("a");
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = "image.png";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  }

  return (
    <>
      {popup && (
        <div className="">
          <div
            className="h-full top-0 bg-[rgba(0,0,0,0.4)] z-40 fixed w-[100%] md:left-0"
            onClick={() => setPopup(false)}
          ></div>
          <div
            className={`mymd:w-[400px] h-[210px] w-full z-50 fixed bottom-0 bg-white rounded-lg p-5 md:left-0 md:right-0 mx-auto md:top-1/2 md:-translate-y-1/2 `}
          >
            <div className="h-[35px] font-semibold text-[20px] border-b-2 outline-offset-4 relative">
              Delete File
              <span
                className="absolute right-0 rounded-full bg-gray-200 cursor-pointer w-[28px] h-[28px]"
                onClick={() => setPopup(false)}
              >
                <div className="flex justify-center items-center text-gray-800 w-[28px] h-[28px] text-[17px]">
                  x
                </div>
              </span>
              <div className="h-[85px] flex justify-evenly items-center mt-2 font-[500]">
                Deleting this will delete all the user data associated with this
                file
              </div>
              {/* <div className="h-[40px]">
             
              </div> */}
              <div className="flex justify-between items-center h-[40px] w-[360px] mt-2">
                <button
                  className="border rounded-lg bg-white text-black text-[14px] w-[46%] h-[40px]"
                  onClick={() => setPopup(false)}
                >
                  Cancel
                </button>
                <button
                  className="rounded-lg bg-danger text-white text-[14px] w-[46%] h-[40px]"
                  onClick={deleteFile}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {!eventDetails?.exhibitorDirectory?.url ? (
        <>
          <div className="w-full md:ml-[0px] md:mt-[27px] pb-[100px]">
            <div className="font-[600] mx-auto md:w-full text-[24px] text-[#585858] flex items-center mt-2">
              <div className="cursor-pointer mr-4" onClick={() => navigate(-1)}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 12H5"
                    stroke="#585858"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 19L5 12L12 5"
                    stroke="#585858"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              Exhibitor Directory
            </div>
            <div className="w-[350px] h-[320px] flex justify-center flex-col  ">
              <img
                src="/svgs/undraw_upload.svg"
                alt="upload"
                className="w-[250px]"
              />
              <div className="w-[335px] md:w-[180px] mt-[20px]">
                <PrimaryButton
                  btnText={"Upload pdf"}
                  onClick={() => {
                    setOpen(true);
                  }}
                  style={{ height: "35px" }}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="w-full md:ml-[0px] md:mt-[27px] pb-[60px]">
          <div className="font-[600] mx-auto md:w-full text-[21px] text-[#585858] flex items-center mt-2">
            <p
              className="cursor-pointer mr-4 text-[22px]"
              onClick={() => navigate(-1)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 12H5"
                  stroke="#585858"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 19L5 12L12 5"
                  stroke="#585858"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </p>
            Exhibitor Directory
          </div>
          <div className="w-full rounded-[10px] mt-3 py-[10px] px-[10px] ">
            <div className="flex items-centers text-[14px] mb-[5px]">
              <span className="font-[600] text-[14px]">Uploaded PDF: </span>
              &nbsp;{eventDetails?.exhibitorDirectory?.fileName}
              <img
                src="/svgs/Edit.svg"
                alt="edit"
                className="w-[17px] h-[17px] cursor-pointer mx-[5px] mymd:mx-[10px]"
                onClick={() => {
                  setIsEdit(true);
                  setOpen(true);
                }}
              />
              <img
                src="/svgs/Delete.svg"
                alt="delete"
                className="w-[17px] h-[17px] cursor-pointer"
                onClick={() => setPopup(true)}
              />
            </div>

            <div className="mt-[10px]">
              <div className="flex">
                <img
                  src={eventDetails?.exhibitorDirectory?.image}
                  alt="qrcode"
                  className="w-[100px] rounded-lg border object-contain"
                />
                <img
                  src="/svgs/downloadArrow.svg"
                  className="w-[17px] ml-4 font-[500] cursor-pointer"
                  alt="download"
                  title="Download QR"
                  onClick={downloadBlob}
                />
              </div>

              <div className=" mt-4">
                <div className="flex items-center">
                  <input
                    type="text"
                    readOnly={true}
                    className="mymd:w-[280px] w-full rounded-[10px] border-gray-200  font-light text-[12px] py-[4px]"
                    value={eventDetails?.exhibitorDirectory?.url}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        eventDetails?.exhibitorDirectory?.url
                      );
                      dispatch(
                        showToast({
                          message: "Link Copied to Clipboard",
                          category: "success",
                        })
                      );
                    }}
                  />

                  <i
                    className="fa-regular fa-copy cursor-pointer text-[#C5C5C7] font-[500] text-[17px] ml-[10px]"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        eventDetails?.exhibitorDirectory?.url
                      );
                      dispatch(
                        showToast({
                          message: "Link Copied to Clipboard",
                          category: "success",
                        })
                      );
                    }}
                  ></i>
                </div>
              </div>

              {/* <a
                href="https://s3.ap-south-1.amazonaws.com/app.warpbay1/b0338ac2-a2a3-4b89-82a1-208855fab13c"
                onClick={() => {
                  const link = document.createElement("a");
                  link.href =
                    "https://s3.ap-south-1.amazonaws.com/app.warpbay1/b0338ac2-a2a3-4b89-82a1-208855fab13c";
                  link.download = "image.jpg";
                  link.click();
                }}
              >
               
              </a> */}
              {/* <button onClick={downloadBlob}>download</button> */}
              {/* <p
                onClick={() => {
                  const link = document.createElement("a");
                  link.href =
                    "https://s3.ap-south-1.amazonaws.com/app.warpbay1/b0338ac2-a2a3-4b89-82a1-208855fab13c";
                  link.download = "image.jpg";
                  link.click();
                }}
              >
                click me
              </p> */}
            </div>
          </div>
          <div className="border w-[75%] rounded-[10px] mt-[30px] py-[10px] px-[10px]">
            <UserDataTable event={eventDetails} />
          </div>
        </div>
      )}
      <UploadPdf
        open={open}
        setOpen={setOpen}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        event={eventDetails}
        setEventDetails={setEventDetails}
      />
    </>
  );
};

export default ExhibitorDirectory;

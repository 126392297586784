import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { CARRY_EVENT } from "../../../redux/constants/eventConstants";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../common/cards/Card";
import ProgressBar from "../../../common/progressBar/ProgressBar";
import DatePicker from "react-date-picker/dist/entry.nostyle";
// import DatePicker from "react-date-picker";
import TimeInput from "../../../common/timeInput/TimeInput";
import { useNavigate } from "react-router-dom";
import TextInput from "../../../common/inputElements/TextInput";
import moment from "moment";
import { showToast } from "../../../redux/actions/toastActions";

const CreateEvent = (props) => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dateValue, setDateValue] = useState({
    startDate: new Date(),
    endDate: "",
  });
  const [formattedValue, setFormattedValue] = useState({
    startDate: "",
    endDate: "",
  });

  const [event, setEvent] = useState({
    title: "Global India 2022",
    startDate: "",
    endDate: "",
    startTime: "",
    description: "",
    location: { addressLine1: "" },
    coverImage:
      "https://d2xqcdy5rl17k2.cloudfront.net/images/default-landing-banner.png",
    eventTag: [],
  });

  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    const currentTime = moment();
    const roundedMinutes =
      (Math.ceil((currentTime.minute() + 1) / 15) * 15) % 60;
    const roundedTime = moment({
      hour: currentTime.hour() + (roundedMinutes === 0),
      minute: roundedMinutes,
    });
    const endTime = moment(roundedTime).add(3, "hours");

    setFormattedValue({
      startTime: roundedTime.format("hh : mm A"),
      endTime: endTime.format("hh : mm A"),
    });
    const eventType = JSON.parse(localStorage.getItem("createvent"));
    if (eventType?.title) {
      reset({
        title: eventType?.title,
        startTime: eventType.startTime,
        endTime: eventType.endTime,
        isRegistrationApproval: eventType.isRegistrationApproval,
      });
      setDateValue({
        startDate: new Date(eventType.startDate),
        endDate: new Date(eventType.endDate),
      });
      setFormattedValue({
        startTime: moment(eventType.startTime, "HH:mm:ss").format("hh : mm A"),
        endTime: moment(eventType.endTime, "HH:mm:ss").format("hh : mm A"),
      });
    } else {
      reset({
        startTime: moment(roundedTime.format("hh : mm A"), "hh : mm A").format(
          "HH:mm:ss"
        ),
        endTime: moment(endTime.format("hh : mm A"), "hh : mm A").format(
          "HH:mm:ss"
        ),
      });
    }
  }, []);

  async function onSubmit(data) {
    setIsSubmitting(true);

    if (!dateValue.startDate) {
      dispatch(
        showToast({
          message: "Please select start date",
          category: "danger",
        })
      );
      setIsSubmitting(false);
      return;
    }
    if (!dateValue.endDate) {
      dispatch(
        showToast({
          message: "Please select end date manually",
          category: "danger",
        })
      );
      setIsSubmitting(false);
      return;
    }
    if (!data.startTime) {
      dispatch(
        showToast({
          message: "Please select start time",
          category: "danger",
        })
      );
      setIsSubmitting(false);
      return;
    }
    if (!data.endTime) {
      dispatch(
        showToast({
          message: "Please select end time",
          category: "danger",
        })
      );
      setIsSubmitting(false);
      return;
    } else {
      setEvent((prevState) => {
        return { ...prevState, ...data };
      });

      const eventDates = {
        startDate: new Date(
          `${moment(dateValue.startDate).format("ll")}, ${data.startTime}`
        ).toISOString(),
        endDate: new Date(
          `${moment(dateValue.endDate).format("ll")}, ${data.endTime}`
        ).toISOString(),
      };
      dispatch({
        type: CARRY_EVENT,
        payload: {
          newEvent: { ...data, ...eventDates },
        },
      });

      localStorage.setItem(
        "createvent",
        JSON.stringify({ ...data, ...dateValue })
      );
      props.setStep(2);
    }
  }

  return (
    <>
      <div className="h-[calc(100vh-58px)] grid justify-center content-center w-[1280px] mx-auto ">
        <Card>
          <form
            className="w-[370px] flex flex-col gap-[20px]"
            onSubmit={handleSubmit(onSubmit)}
          >
            <p className="text-[24px] font-[700] block text-left w-full">
              Create new event
            </p>

            <ProgressBar width="25" />
            <div className=""></div>

            <TextInput
              type="text"
              placeholder="Event Name"
              id="title"
              required
              register={register}
              mb="4"
              label={"Event Name"}
            />

            <div className="flex space-x-8 mb-[20px]">
              <div className="relative">
                <label
                  htmlFor="startDate"
                  className="text-[12px] text-[#9c9c9c] absolute -top-[18px] left-[5px]"
                >
                  Start Date&nbsp;<span className="text-red-600">*</span>
                </label>
                <DatePicker
                  onChange={(value) => {
                    setDateValue({ ...dateValue, startDate: value });
                  }}
                  value={dateValue.startDate}
                  minDate={new Date()}
                  format="dMMMy"
                />
              </div>

              <TimeInput
                register={register}
                required={true}
                setValue={setValue}
                id="startTime"
                value={formattedValue?.startTime}
                label={"Start Time"}
                isHalfWidth={true}
              />
            </div>
            <div className="flex space-x-8">
              <div className="relative">
                <label
                  htmlFor="endDate"
                  className="text-[12px] text-[#9c9c9c] absolute -top-[18px] left-[5px]"
                >
                  End Date&nbsp;<span className="text-red-600">*</span>
                </label>
                <DatePicker
                  onChange={(value) => {
                    setDateValue({ ...dateValue, endDate: value });
                  }}
                  value={dateValue.endDate}
                  minDate={dateValue.startDate}
                  dayPlaceholder={new Date().getDate().toString()}
                  monthPlaceholder={new Date()
                    .toLocaleString("default", {
                      month: "short",
                    })
                    .toString()}
                  yearPlaceholder={new Date().getFullYear().toString()}
                  format="dMMMy"
                />
              </div>
              <TimeInput
                register={register}
                required
                id="endTime"
                value={formattedValue?.endTime}
                setValue={setValue}
                isHalfWidth={true}
                label={"End Time"}
              />
            </div>

            <div className="pt-[-20px] text-[13px] font-[500] text-primary flex items-center h-7">
              <input
                type="checkbox"
                {...register("isRegistrationApproval")}
                id={"isRegistrationApproval"}
                className="mr-2 border-[#cacaca] border-2 bg-white checked:bg-primary checked:border-[#d5d8dd] cursor-pointer focus:ring-0"
              />
              <label
                htmlFor="isRegistrationApproval"
                className="cursor-pointer"
              >
                Require Registration Approval
                <p className="text-gray-400 font-[500] text-[10px]">
                  If selected, only approved guests will receive event badge.
                </p>
              </label>
            </div>
            <input
              type="submit"
              value={isSubmitting ? "Loading..." : "Proceed ahead"}
              className="primary_submit"
              disabled={isSubmitting}
            />
            <span
              className="go_back_btn"
              onClick={() => {
                localStorage.removeItem("showtype");
                localStorage.removeItem("location");
                localStorage.removeItem("shortDescription");
                localStorage.removeItem("createvent");
                navigate("/events");
              }}
            >
              <i className="fa-solid fa-arrow-left"></i> Go Back
            </span>
          </form>
        </Card>
      </div>
    </>
  );
};

export default CreateEvent;

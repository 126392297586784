import React from "react";

const LandingAbout = ({ singleEvent }) => {
  return (
    <div className="text-#1C1C1E sm:text-base font-normal text-base whitespace-pre-wrap">
      {singleEvent?.shortDescription.split("\n").map((line, index) => (
        <React.Fragment key={index}>
          {line.split(/(https?:\/\/[^\s]+|www\.[^\s]+)/g).map((word, index) =>
            /(^|[^<])(https?:\/\/[\S]+(\b|$)|www\.[^\s]+(\b|$))(\W|$)/gi.test(
              word
            ) ? (
              <a
                key={index}
                href={word.trim()}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary break-all"
                style={{ wordWrap: "break-word" }}
              >
                {word.trim()}
              </a>
            ) : (
              <span key={index}>{word}</span>
            )
          )}
          <br />
        </React.Fragment>
      ))}
    </div>
  );
};

export default LandingAbout;

import React from "react";
import { useNavigate } from "react-router-dom";
import DefaultProfilePicture from "../../common/defaultProfilePicture/DefaultProfilePicture";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import BookMeeting from "../people/BookMeeting";
import { useEffect } from "react";
import { getUserDetails } from "../../redux/actions/userActions";
import { cancelMeeting } from "../../redux/actions/meetingActions";
import { getAuthenticatedRequest } from "../../utils/API/api.ts";

const ExhibitorMeeting = ({
  exhibitorMeeting,
  setExhibitorMeeting,
  singleEvent,
}) => {
  const navigate = useNavigate();
  const [trigger, settrigger] = useState(false);
  const [isCancelled, setisCancelled] = useState(false);
  const [singleAttendee, setsingleAttendee] = useState([]);
  const [meetingDetails, setMeetingDetails] = useState([]);
  const [sentMeetings, setSentMeetings] = useState([]);
  const [receivedMeetings, setReceivedMeetings] = useState([]);
  const [scheduledMeetings, setScheduledMeetings] = useState([]);
  const dispatch = useDispatch();
  const cancelledMeeting = useSelector((state) => state.cancelledMeeting);
  const userDetails = useSelector((state) => state.userDetails);
  const { savedUserConfig } = userDetails;

  const now = new Date().getTime();
  let eventEnded =
    new Date(
      new Date(singleEvent?.endDate).toLocaleString("en-US", {
        timeZone: "Asia/Kolkata",
      })
    ).getTime() <= now;

  useEffect(() => {
    if (cancelledMeeting.error) {
      alert("Please Cancel again!! Some error occurred");
    }
  }, [cancelledMeeting]);

  const deleteMeeting = async (meetingData, singleAttendee) => {
    const cancelMeetings = sentMeetings.filter((meeting) => {
      return meeting.meetingID !== meetingData.meetingID;
    });
    setSentMeetings(cancelMeetings);
    dispatch(cancelMeeting({ meetingID: meetingData.meetingID }));
    setsingleAttendee(singleAttendee);
    setMeetingDetails(meetingData);
    setisCancelled(true);
    settrigger(true);
  };

  useEffect(() => {
    async function fetch() {
      const response = await getAuthenticatedRequest(
        "/user/userMeeting-details"
      );
      const { meetingRequestSent, meetingRequestReceived, scheduledMeetings } =
        response?.data.user;

      const filteredscheduledMeetings = scheduledMeetings.filter(
        (meeting) => meeting.eventId === singleEvent?._id
      );
      const filteredSentMeetings = meetingRequestSent.filter(
        (meeting) => meeting.eventId === singleEvent?._id
      );
      const filteredReceivedMeetings = meetingRequestReceived.filter(
        (meeting) => meeting.eventId === singleEvent?._id
      );

      setScheduledMeetings(filteredscheduledMeetings);
      setSentMeetings(filteredSentMeetings);
      setReceivedMeetings(filteredReceivedMeetings);
    }
    fetch();
  }, [singleEvent, trigger]);

  const checkIfEmailExists = (email) => {
    return (
      scheduledMeetings?.some((obj) => obj.meetingWith[0]?.email === email) ||
      receivedMeetings?.some((obj) => obj.meetingWith[0]?.email === email) ||
      sentMeetings?.some((obj) => obj.meetingWith[0]?.email === email)
    );
  };

  useEffect(() => {
    let accessToken = localStorage.getItem("accessToken");
    dispatch(getUserDetails({ accessToken: accessToken }));
  }, [savedUserConfig?._id]);

  return (
    <div className="md:ml-[17%] md:w-[500px] w-full">
      <div className="w-full h-[60px] fixed top-0 bg-white flex items-center px-[16px] border-b border-[#EDEDED] md:mt-[60px] md:relative z-10 md:hidden">
        <img
          src="/svgs/Arrowleft.svg"
          className="w-[24px] h-[24px] object-cover cursor-pointer"
          onClick={() => setExhibitorMeeting([])}
        />
        <span className="ml-2 text-[22px] font-[500]">
          {exhibitorMeeting?.companyName}
        </span>
      </div>
      {trigger && (
        <BookMeeting
          trigger={trigger}
          settrigger={settrigger}
          isCancelled={isCancelled}
          setisCancelled={setisCancelled}
          event={singleEvent}
          singleAttendee={singleAttendee}
          setMeetingDetails={setMeetingDetails}
          meetingDetails={meetingDetails}
          deleteMeeting={deleteMeeting}
          receivedMeetings={receivedMeetings}
          sentMeetings={sentMeetings}
          savedUserConfig={savedUserConfig}
        />
      )}
      <div className="mt-[80px] px-[16px]">
        <span className="text-[16px] text-[#1C1C1E] font-[400] md:text-[24px] md:font-[600]">
          About {exhibitorMeeting?.companyName}
        </span>
        <div className="mt-[13px] text-[#727374] text-sm font-[400] md:text-[16px] md:font-[400]">
          {exhibitorMeeting?.bio.split("").length > 170 ? (
            <>
              {exhibitorMeeting?.bio.slice(0, 170)}
              ...
            </>
          ) : (
            <> {exhibitorMeeting?.bio.slice(0, 170)}</>
          )}
        </div>
        <p className="text-[16px] text-[#1C1C1E] font-[400] my-[15px] md:text-[24px] md:font-[600]">
          Event representatives
        </p>
        <div className="md:w-[80%] md:flex flex-wrap ">
          {exhibitorMeeting?.exhibitorAndSponsors.length > 0 ? (
            <>
              {exhibitorMeeting?.exhibitorAndSponsors?.map((exhibitor, key) =>
                exhibitor?.email === savedUserConfig?.email ? (
                  <></>
                ) : (
                  <div className="">
                    <div
                      key={key}
                      className="bg-white mb-4 rounded-[10px] p-[16px] md:border md:w-[350px] mt-[10px] border border-[#EDEDED]"
                    >
                      <div className="flex items-center">
                        {exhibitor.profilePicture ? (
                          <img
                            src={exhibitor.profilePicture}
                            className="rounded-full w-[45px] h-[45px] object-cover mr-3"
                          />
                        ) : (
                          <div className="mr-2">
                            <DefaultProfilePicture
                              firstName={exhibitor.firstName}
                              lastName={exhibitor.lastName}
                              style={{
                                width: "45px",
                                height: "45px",
                                borderRadius: "300px",
                                fontSize: "16px",
                              }}
                            />
                          </div>
                        )}

                        <div className="">
                          <div className="sm:text-[14px] text-[13px] font-[500] pt-[5px] md:font-semibold">
                            {exhibitor.firstName} {exhibitor.lastName}
                          </div>
                          <div className="text-[12px] font-medium py-[0px] text-gray-500">
                            {exhibitor.jobTitle}, {exhibitor.organization}
                          </div>
                        </div>
                      </div>

                      {scheduledMeetings?.some(
                        (obj) => obj.meetingWith[0]?.email === exhibitor?.email
                      ) ? (
                        <div className="text-[#2ECC71] text-[12px] font-[600] my-2 md:my-1 flex items-center ">
                          <img
                            src="/svgs/Checkcircle.svg"
                            alt="check"
                            className="mr-1 rounded-full h-[17px]"
                          />
                          Your meeting is scheduled.
                        </div>
                      ) : sentMeetings?.some(
                          (obj) =>
                            obj.meetingWith[0]?.email === exhibitor?.email
                        ) ? (
                        <div className="text-primary text-[12px] font-[600] my-2 md:my-1 flex items-center">
                          <img
                            src="/svgs/send.svg"
                            alt="send"
                            className="cursor-pointer mr-1 h-[12px]"
                          />
                          You sent a meeting request
                        </div>
                      ) : receivedMeetings?.some(
                          (obj) =>
                            obj.meetingWith[0]?.email === exhibitor?.email
                        ) ? (
                        <div className="text-primary text-[12px] font-[600] my-2 md:my-1 flex items-center">
                          <img
                            src="/svgs/send.svg"
                            alt="send"
                            className="cursor-pointer mr-1 h-[12px]"
                          />
                          You received a meeting request.
                        </div>
                      ) : (
                        <div className="text-[12px] md:my-1 md:invisible hidden md:block">
                          No content
                        </div>
                      )}

                      <div className="mt-[15px]">
                        {checkIfEmailExists(exhibitor.email) ? (
                          <span
                            className="flex items-center cursor-pointer text-black text-[12px] font-[500] h-[32px] justify-center rounded-[4px] bg-white border"
                            onClick={() => {
                              navigate(
                                `/attendee/${singleEvent?._id}?tab=meetings`
                              );
                            }}
                          >
                            Go to meetings
                          </span>
                        ) : eventEnded ? (
                          <span className="flex items-center text-white text-[12px] font-[500] h-[32px] justify-center rounded-[4px] bg-primary  opacity-50">
                            Book a meeting
                          </span>
                        ) : (
                          <span
                            className="flex items-center cursor-pointer text-white text-[12px] font-[500] h-[32px]  justify-center rounded-[4px] bg-primary"
                            onClick={() => {
                              setsingleAttendee(exhibitor);
                              settrigger(true);
                            }}
                          >
                            Book a meeting
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )
              )}
            </>
          ) : (
            <div className="grid w-full place-items-center h-[250px] md:w-[65%]">
              <div>
                <img
                  src="/svgs/nullState.svg"
                  alt=""
                  className="w-[200px] h-[200px]"
                />
                <p className="text-[15px] font-[500] text-[#717171]  text-center">
                  Nothing here...
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExhibitorMeeting;

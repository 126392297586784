import React, { useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import moment from "moment";
import { getUserDetails } from "../../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import AttendeeBadgePopup from "./AttendeeBadgePopup";
import DefaultProfilePicture from "../../common/defaultProfilePicture/DefaultProfilePicture";
import ExhibitorMeeting from "./ExhibitorMeeting";

const AttendeeAbout = ({ singleEvent }) => {
  const navigate = useNavigate();
  const [tab, settab] = useState("");
  const [badegExpand, setBadegExpand] = useState(false);
  const [exhibitorMeeting, setExhibitorMeeting] = useState([]);
  const dispatch = useDispatch();
  const eventsId = useMatch("/attendee/:eventId/*");
  const userDetails = useSelector((state) => state.userDetails);
  const { savedUserConfig } = userDetails;
  const now = new Date().getTime();
  useEffect(() => {
    // generateText(prompt);
    let accessToken = localStorage.getItem("accessToken");
    dispatch(getUserDetails({ accessToken: accessToken }));
  }, [savedUserConfig?._id]);

  if (exhibitorMeeting?.length !== 0) {
    return (
      <ExhibitorMeeting
        exhibitorMeeting={exhibitorMeeting}
        setExhibitorMeeting={setExhibitorMeeting}
        singleEvent={singleEvent}
      />
    );
  }

  return (
    <div className="w-full min-h-[90vh] bg-[#FAFAFA] md:ml-[17%] md:w-[83%] md:bg-white md:min-h-full">
      <div className="w-full h-[60px] fixed top-0 bg-white flex items-center px-[16px] border-b border-[#EDEDED] md:mt-[60px] md:hidden">
        <img
          src="/svgs/Arrowleft.svg"
          className="w-[24px] h-[24px] object-cover cursor-pointer"
          onClick={() => navigate("/events")}
        />
        <p className="ml-2 text-[22px] font-[500] overflow-hidden overflow-ellipsis whitespace-nowrap w-[95%]">
          {singleEvent?.title}
        </p>
      </div>

      {badegExpand && (
        <AttendeeBadgePopup
          setBadegExpand={setBadegExpand}
          badegExpand={badegExpand}
          savedUserConfig={savedUserConfig}
          singleEvent={singleEvent}
        />
      )}
      <div className="mt-[60px] mx-[16px] pt-[16px] md:pt-1 md:mt-[88px] pb-[90px] md:ml-[30px]">
        <img
          src={singleEvent?.coverImage}
          alt="coverimage"
          className="h-[194px] w-full rounded-[10px] shadow md:w-[550px] md:h-[300px] "
        />
        <div className="w-full ">
          <div className="font-[500] text-[22px] mt-[8px] text-[#121212] md:mt-[12px]">
            <p className="overflow-hidden overflow-ellipsis whitespace-nowrap  w-[97%]">
              {singleEvent?.title}
            </p>
          </div>
          <div className="flex text-[12px] text-[#727374] font-normal mt-[4px] md:mt-[10px] md:text-[14px]">
            <img
              src="/svgs/calender.svg"
              alt="location"
              className="h-[16px] mr-[5px] md:h-[21px]"
            />
            <span>
              {(() => {
                try {
                  if (
                    new Date(singleEvent.startDate)
                      .toString()
                      .substring(0, 10)
                      .slice(-10) ===
                    new Date(singleEvent.endDate)
                      .toString()
                      .substring(0, 10)
                      .slice(-10)
                  ) {
                    return (
                      <>
                        {new Date(singleEvent.startDate).getDate()}{" "}
                        {new Intl.DateTimeFormat("en-US", {
                          month: "short",
                        }).format(new Date(singleEvent.startDate))}
                      </>
                    );
                  } else {
                    return (
                      <>
                        {new Date(singleEvent.startDate).getDate()}{" "}
                        {new Intl.DateTimeFormat("en-US", {
                          month: "short",
                        }).format(new Date(singleEvent.startDate))}{" "}
                        to {new Date(singleEvent.endDate).getDate()}{" "}
                        {new Intl.DateTimeFormat("en-US", {
                          month: "short",
                        }).format(new Date(singleEvent.endDate))}
                      </>
                    );
                  }
                } catch (error) {
                  console.error(error);
                  return null;
                }
              })()}
            </span>
            <span>
              &nbsp;| {moment(singleEvent?.startDate).format("LT")} |&nbsp;
            </span>
            {new Date(
              new Date(singleEvent?.endDate).toLocaleString("en-US", {
                timeZone: "Asia/Kolkata",
              })
            ).getTime() <= now ? (
              <span className="font-semibold text-[#E74C3C]">Ended</span>
            ) : new Date(
                new Date(singleEvent?.startDate).toLocaleString("en-US", {
                  timeZone: "Asia/Kolkata",
                })
              ).getTime() <= now ? (
              <span className="font-semibold text-[#2ECC71]">Ongoing</span>
            ) : (
              <span className="font-semibold text-primary">Upcoming</span>
            )}
          </div>

          <div className="flex text-[12px] text-[#727374] font-normal my-[9px] items-center w-[95%] md:text-[14px]">
            <img
              src="/svgs/Location.svg"
              alt="location"
              className="h-[18px] w-[18px] mr-[5px] ml-[-3px] md:h-[22px] md:w-[24px]"
            />
            <p className="overflow-hidden overflow-ellipsis whitespace-nowrap">
              {singleEvent.location?.addressLine1}, {singleEvent.location?.city}
              , {singleEvent.location?.state}
            </p>
          </div>
        </div>

        <div
          className="h-[130px] w-full rounded-[10px] mt-[24px] cursor-pointer md:w-[350px] md:h-[130px] flex flex-col justify-center pl-5 md:pl-3.5 md:mt-[17px] border-[2px] border-[#A55EEA]"
          onClick={() => setBadegExpand(true)}
          style={{
            boxShadow: "0px 0px 14px rgba(165, 94, 234, 0.2)",
          }}
        >
          <div className="mb-1">
            <p className="text-sm font-[400] text-[#727374] md:text-[14px]">
              Tap to expand your badge
            </p>
            <div className="flex mt-[10px] md:mt-[10px]">
              <img
                src="/svgs/scanner.svg"
                alt="Scanner"
                className="h-[64px] w-[64px] rounded-[4px] md:w-[80px] md:h-[80px] object-contain"
              />

              <div className="h-[64px] flex flex-col justify-between ml-3 md:h-[80px] md:ml-4">
                <span className="text-[#1C1C1E] font-[500] text-[16px] md:text-[20px]">
                  {savedUserConfig?.firstName} {savedUserConfig?.lastName}
                </span>
                <span className="text-[#727374] font-[400] text-[12px] md:text-[14px]">
                  Event badge
                </span>
                <span className="text-[#727374] font-[400] text-[12px] md:text-[14px]">
                  {" "}
                  {(() => {
                    try {
                      if (
                        new Date(singleEvent.startDate).getDate() ===
                        new Date(singleEvent.endDate).getDate()
                      ) {
                        return (
                          <>
                            {new Date(singleEvent.startDate).getDate()}{" "}
                            {new Intl.DateTimeFormat("en-US", {
                              month: "short",
                            }).format(new Date(singleEvent.startDate))}
                          </>
                        );
                      } else {
                        return (
                          <>
                            {new Date(singleEvent.startDate).getDate()}{" "}
                            {new Intl.DateTimeFormat("en-US", {
                              month: "short",
                            }).format(new Date(singleEvent.startDate))}{" "}
                            to {new Date(singleEvent.endDate).getDate()}{" "}
                            {new Intl.DateTimeFormat("en-US", {
                              month: "short",
                            }).format(new Date(singleEvent.endDate))}
                          </>
                        );
                      }
                    } catch (error) {
                      console.error(error);
                      return null;
                    }
                  })()}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className=" w-full flex my-[20px] md:mt-[20px]">
          <span
            className={
              tab === "sponsors" || tab === ""
                ? `font-[700] text-[#1C1C1E] text-[16px] underline underline-offset-8 cursor-pointer decoration-black decoration-2`
                : `font-[500] text-[#C5C5C7] text-[16px]  cursor-pointer`
            }
            onClick={() => settab("sponsors")}
          >
            Sponsors
          </span>
          <span
            className={
              tab === "exhibitors"
                ? `font-[700] text-[#1C1C1E] text-[16px] underline underline-offset-8 cursor-pointer decoration-black decoration-2 mx-[30px]`
                : `font-[500] text-[#C5C5C7] text-[16px]  cursor-pointer mx-[30px]`
            }
            onClick={() => settab("exhibitors")}
          >
            Exhibitors
          </span>
          <span
            className={
              tab === "about"
                ? `font-[700] text-[#1C1C1E] text-[16px] underline underline-offset-8 cursor-pointer decoration-black decoration-2`
                : `font-[500] text-[#C5C5C7] text-[16px]  cursor-pointer`
            }
            onClick={() => settab("about")}
          >
            About
          </span>
        </div>
        {tab === "about" ? (
          <div className="text-#1C1C1E sm:text-base font-normal text-base whitespace-pre-wrap">
            {singleEvent?.shortDescription.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line
                  .split(/(https?:\/\/[^\s]+|www\.[^\s]+)/g)
                  .map((word, index) =>
                    /(^|[^<])(https?:\/\/[\S]+(\b|$)|www\.[^\s]+(\b|$))(\W|$)/gi.test(
                      word
                    ) ? (
                      <a
                        key={index}
                        href={word.trim()}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary break-all"
                        style={{ wordWrap: "break-word" }}
                      >
                        {word.trim()}
                      </a>
                    ) : (
                      <span key={index}>{word}</span>
                    )
                  )}
                <br />
              </React.Fragment>
            ))}
          </div>
        ) : tab === "sponsors" || tab === "" ? (
          <div className="mymd:flex flex-wrap w-full md:w-[70%]">
            {singleEvent?.exhibitorOrganizations?.length > 0 ? (
              singleEvent?.exhibitorOrganizations.map(
                (sponsorAndExhibitor, key) => (
                  <div className="mymd:w-[280px] mymd:h-[195px] bg-white rounded-xl mymd:mb-3 mb-4 p-[20px]  md:border md:mr-[22px]">
                    {sponsorAndExhibitor?.logo ? (
                      <img
                        src={sponsorAndExhibitor?.logo}
                        className="rounded-full w-[40px] h-[40px] object-cover "
                      />
                    ) : (
                      <DefaultProfilePicture
                        firstName={sponsorAndExhibitor?.companyName}
                        lastName={" "}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "300px",
                          fontSize: "14px",
                        }}
                      />
                    )}

                    <div className="mymd:text-[20px] text-base font-medium mymd:mt-[16px] mt-[8px] truncate">
                      {sponsorAndExhibitor?.companyName}
                    </div>
                    <div className="mymd:text-[15px] text-xs font-normal text-[#727374] mymd:mt-[14px] mt-[6px] truncate">
                      {sponsorAndExhibitor?.industry}
                    </div>
                    <button
                      className="bg-primary w-full h-[32px] text-white text-[12px] fonr-[500] mymd:mt-[14px] mt-[12px] rounded-[4px] border-0"
                      onClick={() => setExhibitorMeeting(sponsorAndExhibitor)}
                    >
                      Request Meeting
                    </button>
                  </div>
                )
              )
            ) : (
              <div className="grid w-full place-items-center h-[250px] md:w-[60%]">
                <div>
                  <img
                    src="/svgs/nullState.svg"
                    alt=""
                    className="w-[200px] h-[200px]"
                  />
                  <p className="text-[15px] font-[500] text-[#717171]  text-center">
                    No Exhibitors or Sponsors available...
                  </p>
                </div>
              </div>
            )}
          </div>
        ) : tab === "exhibitors" ? (
          <div className="mymd:flex flex-wrap w-full md:w-[70%]">
            {singleEvent?.exhibitorOrganizations?.length > 0 ? (
              singleEvent?.exhibitorOrganizations.map(
                (sponsorAndExhibitor, key) => (
                  <div className="mymd:w-[280px] mymd:h-[195px] bg-white rounded-xl p-[20px] mymd:mb-3 mb-4 md:border md:mr-[22px]">
                    {sponsorAndExhibitor?.logo ? (
                      <img
                        src={sponsorAndExhibitor?.logo}
                        className="rounded-full w-[40px] h-[40px] object-cover "
                      />
                    ) : (
                      <DefaultProfilePicture
                        firstName={sponsorAndExhibitor?.companyName}
                        lastName={" "}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "300px",
                          fontSize: "14px",
                        }}
                      />
                    )}

                    <div className="mymd:text-[20px] text-base font-medium mymd:mt-[16px] mt-[8px] truncate">
                      {sponsorAndExhibitor?.companyName}
                    </div>
                    <div className="mymd:text-[15px] text-xs font-normal text-[#727374] mymd:mt-[14px] mt-[6px] truncate">
                      {sponsorAndExhibitor?.industry}
                    </div>
                    <button
                      className="bg-primary w-full h-[32px] text-white text-[12px] fonr-[500] mymd:mt-[14px] mt-[12px] rounded-[4px] border-0"
                      onClick={() => setExhibitorMeeting(sponsorAndExhibitor)}
                    >
                      Request Meeting
                    </button>
                  </div>
                )
              )
            ) : (
              <div className="grid w-full place-items-center h-[250px] md:w-[60%]">
                <div>
                  <img
                    src="/svgs/nullState.svg"
                    alt=""
                    className="w-[200px] h-[200px]"
                  />
                  <p className="text-[15px] font-[500] text-[#717171]  text-center">
                    No Exhibitors or Sponsors available...
                  </p>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="text-[#1C1C1E] font-[400] text-sm  whitespace-pre-wrap md:text-[16px] md:w-[60%]">
            {singleEvent?.shortDescription}
          </div>
        )}
      </div>
    </div>
  );
};

export default AttendeeAbout;

import React, { useEffect, useState } from "react";
import Layout from "../src/common/layout/Layout";
import { Provider } from "react-redux";
import "tailwindcss/tailwind.css";
import "./styles/globals.css";
import { useMatch } from "react-router-dom";
import store from "./redux/store";
import BottomBar from "./components/bottomBar/BottomBar";
import AllRoutes from "./AllRoutes";
import EventHomeLayout from "./common/layout/EventHomeLayout";
import Toast from "./common/toastPopup/Toast";
import { SpeedInsights } from "@vercel/speed-insights/react"

function App() {
  const eventsId = useMatch("events/:eventId");
  const eventId = useMatch("event/:eventId");
  const linkedin = useMatch("event/:eventId/linkedin");
  const checkout = useMatch("event/:eventId/checkout");
  const phonepeCallback = useMatch("/payments/phonepe/callback");
  const formBuilder = useMatch("/events/:eventId/settings/formbuilder");
  const staffManagement = useMatch(
    "/events/:eventId/settings/staff-management"
  );
  const ticketing = useMatch("/events/:eventId/settings/ticketing");
  const badgeTemplate = useMatch("/events/:eventId/settings/badge-templates");
  const emailMarketing = useMatch(
    "/events/:eventId/communications/eventmarketing"
  );
  const settings = useMatch("/events/:eventId/settings");
  const linkedinAutoPost = useMatch(
    "/events/:eventId/communications/linkedin-marketing"
  );
  const whatsAppMarketing = useMatch(
    "/events/:eventId/communications/whatsapp-marketing"
  );

  const mailChimp = useMatch("/events/:eventId/communications/mail-chimp");
  const emailDomainWhitelable = useMatch(
    "/events/:eventId/communications/email-domain-whitelable"
  );
  const exhibitorDirectory = useMatch(
    "/events/:eventId/communications/exhibitorDirectory"
  );

  const emailNotifications = useMatch(
    "/events/:eventId/communications/emailNotifications"
  );

  const waitlist = useMatch("/waitlist");

  const showTickets = useMatch("/events/:eventId");
  const confirmTicket = useMatch("/events/:eventId/tickets/:ticketId");

  const login = useMatch("/login");

  const [online, setOnline] = useState(true);

  useEffect(() => {
    if (!navigator.onLine) {
      setOnline(false);
    } else {
      setOnline(true);
    }
    window.addEventListener("offline", (e) => {
      setOnline(false);
    });

    window.addEventListener("online", (e) => {
      setOnline(true);
    });
  }, [navigator.onLine]);

  return (
    <>
      {!online && (
        <div className="z-[100] fixed right-[0px] top-[50px] w-[400px] my-[10px] mb-[30px] min-h-[45px] grid place-items-center bg-[tomato] text-[14px] font-[500] text-[white]">
          You are offline! Please check your network settings
        </div>
      )}
      {/* <Helmet>
        <title>Warpbay</title>
        <meta name="description" content="Warpbay Events application" />
      </Helmet> */}

      <Provider store={store}>
        <Toast />
        {eventsId ||
        formBuilder ||
        settings ||
        linkedinAutoPost ||
        staffManagement ||
        ticketing ||
        whatsAppMarketing ||
        mailChimp ||
        emailDomainWhitelable ||
        badgeTemplate ||
        exhibitorDirectory ||
        emailNotifications ||
        emailMarketing ? (
          <EventHomeLayout>
            <BottomBar />
          </EventHomeLayout>
        ) : (
          <Layout>
            {login ||
            eventId ||
            linkedin ||
            checkout ||
            waitlist ||
            phonepeCallback ? null : (
              <BottomBar />
            )}
          </Layout>
        )}

        {/* {All Page Router} */}
        <AllRoutes />
      </Provider>
      <SpeedInsights/>
    </>
  );
}

export default App;

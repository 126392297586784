// @ts-nocheck
import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import TextInput from "../../../../common/inputElements/TextInput";
import TextArea from "../../../../common/inputElements/TextArea";
import Select from "../../../../common/inputElements/Select";
// import styles from "../../common/inputElements/Input.module.css";
import styles from "../../../../common/inputElements/Input.module.css";

export default function AddForm({
  open,
  setOpen,
  register,
  handleSubmit,
  inputType,
  onSubmit,
  isSubmitting,
  setInputType,
  getValues,
  setValue,
  setIsEdit,
  isEdit,
}) {
  const options = [
    {
      value: "text",
      label: "Text",
    },
    {
      value: "select",
      label: "Select",
    },
    {
      value: "multi-select",
      label: "Multi Select",
    },
    {
      value: "number",
      label: "Number",
    },
    {
      value: "checkbox",
      label: "Checkbox",
    },
    {
      value: "phoneNumber",
      label: "Phone Number",
    },
    {
      value: "file",
      label: "File",
    },
    {
      value: "email",
      label: "Email",
    },
    {
      value: "url",
      label: "URL",
    },
  ];

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-[45px] md:left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md hidden md:block text-gray-300"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>X
                          {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
                        </button>
                        <button
                          className="md:hidden flex items-center"
                          onClick={() => setOpen(false)}
                        >
                          <i className="fa fa-angle-left text-[24px]"></i>
                          <span className="text-[17px] pt-0.5">Back</span>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-3 shadow-xl">
                      <div className="px-4 sm:px-6 fixed z-20 bg-white w-[100%] top-0">
                        <Dialog.Title className="text-[22px] pt-5 pb-4 font-[600] text-gray-900">
                          {isEdit?.id ? "Edit Field" : "Add custom fields"}
                        </Dialog.Title>
                      </div>
                      <div className="relative mt-[75px] flex-1 px-4 sm:px-6 mb-[50px]">
                        <form
                          onSubmit={handleSubmit(onSubmit)}
                          className="flex flex-col gap-y-[20px]"
                        >
                          {/* <input
                            id={"name"}
                            type="text"
                            {...register("name", { required: true })}
                            placeholder="Input Name"
                            className={`${styles.input} peer focus:ring-transparent	 focus:border-gray-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500`}
                          /> */}

                          <div className="relative">
                            <input
                              type="text"
                              id="name"
                              className="peer border border-gray-300 text-gray-900 text-[12px] rounded-lg h-[40px] placeholder-transparent focus:ring-primary focus:border-primary block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white font-medium dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              // placeholder={label}
                              {...register("name", { required: true })}
                            />
                            <label
                              htmlFor="name"
                              className="absolute left-2 -top-5 mb-1 text-sm peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-900 peer-placeholder-shown:text-opacity-40 peer-placeholder-shown:top-[10px] peer-placeholder-shown:left-[13px] transition-all peer-focus:-top-5 peer-focus:text-gray-900 peer-focus:text-opacity-40 peer-focus:text-[12.5px] font-medium text-gray-900 text-opacity-40 dark:text-gray-300"
                            >
                              <>
                                Field Name&nbsp;
                                <span className="text-red-500">*</span>
                              </>
                            </label>
                          </div>

                          <div className="mt-[20px] relative">
                            <label
                              htmlFor="type"
                              className="text-[12px] text-[#9c9c9c] absolute -top-[18px] left-[5px]"
                            >
                              Input Field Type&nbsp;
                              <span className="text-red-600">*</span>
                            </label>
                            <Select
                              register={register}
                              id={"type"}
                              options={options}
                              value={inputType}
                              onChange={(o) => {
                                const value = getValues("type");
                                setValue("type", o.value);
                                setInputType(o);
                              }}
                            />
                          </div>
                          {inputType?.value === "select" ||
                          inputType?.value === "multi-select" ? (
                            // <input
                            //   id={"options"}
                            //   type="text"
                            //   {...register("options", { required: true })}
                            //   placeholder="Enter options separated by ,"
                            //   className={`${styles.input} peer focus:ring-transparent	 focus:border-gray-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500`}
                            // />

                            <div className="relative mt-[20px]">
                              <input
                                type="text"
                                id={"options"}
                                className="peer border border-gray-300 text-gray-900 text-[13px] rounded-lg h-[40px] placeholder-transparent focus:ring-primary focus:border-primary block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white font-medium dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                // placeholder="Eg: IND, USA, UAE"
                                {...register("options", { required: true })}
                              />
                              <label
                                htmlFor="options"
                                className="absolute left-2 -top-5 mb-1 text-sm peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-900 peer-placeholder-shown:text-opacity-40 peer-placeholder-shown:top-[10px] peer-placeholder-shown:left-[13px] transition-all peer-focus:-top-5 peer-focus:text-gray-900 peer-focus:text-opacity-40 peer-focus:text-[12.5px] font-medium text-gray-900 text-opacity-40 dark:text-gray-300"
                              >
                                <>
                                  Options (Enter options separated by
                                  comma)&nbsp;
                                  <span className="text-red-500">*</span>
                                </>
                              </label>
                            </div>
                          ) : (
                            []
                          )}

                          <p className="text-[13px] flex justify-between items-center font-normal	text-[#A55EEA] mt-[10px]">
                            <span className="inline-block ">
                              Is this field required
                            </span>
                            <label
                              htmlFor="required"
                              className="inline-flex left-[0px] relative items-center cursor-pointer"
                            >
                              <input
                                type="checkbox"
                                value=""
                                id={"required"}
                                className="sr-only peer"
                                {...register("required")}
                              />
                              <span className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></span>
                            </label>
                          </p>
                          <div className="fixed bottom-0 w-[87%] bg-white  h-[80px] flex justify-center items-center ml-[-2px]">
                            {isEdit?.id ? (
                              <input
                                disabled={isSubmitting}
                                value={
                                  isSubmitting ? "Loading..." : "Save Changes"
                                }
                                type="submit"
                                className="primary_submit"
                              />
                            ) : (
                              <input
                                disabled={isSubmitting}
                                value={
                                  isSubmitting ? "Loading..." : "Add Field"
                                }
                                type="submit"
                                className="primary_submit"
                              />
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

import React, { useState } from "react";
import EventTickets from "./EventTickets";
import TicketAnalytics from "./TicketAnalytics";
import PaymentModes from "./PaymentModes";

const Ticketing = () => {
  const [tab, setTab] = React.useState("tickets");

  const [phonepe, setPhonepe] = useState(false);
  const [razorpay, setRazorpay] = useState(false);
  const [stripe, setStripe] = useState(false);
  return (
    <div>
      <div className="w-full md:w-[85%] md:ml-[0px] md:mt-[25px] ">
        <div className="py-0">
          <div
            className="font-[600] w-[335px] md:w-full text-[24px] pt-5 text-black flex justify-between items-center
        md:z-20 md:top-[59px] bg-white fixed "
          >
            <p className="text-[21px]">Event Ticketing</p>
          </div>

          <div
            className="flex flex-row items-center w-[340px] md:w-[80%] mt-0 text-sm font-medium text-center text-gray-500 justify-between fixed
        md:z-20 md:top-[109px] bg-white md:pt-0 "
          >
            <ul className="flex flex-wrap -mb-px">
              <li
                className="mr-5 cursor-pointer"
                onClick={() => setTab("tickets")}
              >
                <span
                  className={`inline-block px-0 py-4 ${
                    tab === "tickets"
                      ? "text-primary border-primary underline underline-offset-8	 decoration-2"
                      : ""
                  } rounded-t-lg border-b-2 border-transparent dark:hover:text-gray-300 text-[14px] `}
                >
                  Tickets&nbsp;
                </span>
              </li>
              <li
                className="mr-5 cursor-pointer"
                onClick={() => setTab("analytics")}
              >
                <span
                  className={`inline-block py-4 ${
                    tab === "analytics"
                      ? "text-primary border-primary underline underline-offset-8	 decoration-2"
                      : ""
                  } rounded-t-lg border-b-2 border-transparent dark:hover:text-gray-300 text-[14px]`}
                >
                  Analytics
                </span>
              </li>
              <li
                className="mr-4 cursor-pointer"
                onClick={() => setTab("mode")}
              >
                <span
                  className={`inline-block py-4 ${
                    tab === "mode"
                      ? "text-primary border-primary underline underline-offset-8	 decoration-2"
                      : ""
                  } rounded-t-lg border-b-2 border-transparent dark:hover:text-gray-300 text-[14px]`}
                >
                  Payment modes
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="w-[335px] md:w-[100%] mt-[125px]">
        {tab === "tickets" && (
          <EventTickets
            phonepe={phonepe}
            setPhonepe={setPhonepe}
            razorpay={razorpay}
            setRazorpay={setRazorpay}
            stripe={stripe}
            setStripe={setStripe}
          />
        )}
        {tab === "analytics" && <TicketAnalytics />}
        {tab === "mode" && (
          <PaymentModes
            phonepe={phonepe}
            setPhonepe={setPhonepe}
            razorpay={razorpay}
            setRazorpay={setRazorpay}
            stripe={stripe}
            setStripe={setStripe}
          />
        )}
      </div>
    </div>
  );
};

export default Ticketing;

import React, { useEffect, useState } from "react";
import { batch } from "react-redux";
import FintechCard03 from "../cards/FintechCard03";
import { getRequest } from "../../../../utils/API/api.ts";
import AnalyticsCard01 from "./AnalyticsCard01";
import AnalyticsCard12 from "./AnalyticsCard12";

const CheckinChekoutAnalytics = React.memo(({ singleEvent }) => {
  const [attendedRegistrations, setAttendedRegistrations] = useState([]);
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);
  const [overAllStats, setOverAllStats] = useState([]);
  const [averageTimeSpent, setAverageTimeSpent] = useState({
    hours: "",
    minutes: "",
    seconds: "",
  });
  const [averageTimeSpentDayWise, setAverageTimeSpentDayWise] = useState({
    day: "",
    date: "",
    hours: "",
    minutes: "",
    seconds: "",
  });

  const getAttendeeStats = async () => {
    const response = await getRequest(
      `attendee/${singleEvent?._id}/stats?location=eventCheckin`
    );

    const transformedData = [
      { title: "Total registered", value: response?.data?.registrations.total },
      { title: "Attended", value: response?.data?.registrations?.attended },
      {
        title: "Not attended",
        value: response?.data?.registrations?.notAttended,
      },
      { title: "Onsite", value: response?.data?.registrations?.checkedIn },
      {
        title: "Checked out",
        value: response?.data?.registrations?.checkedOut,
      },
    ];

    setOverAllStats(transformedData);
  };

  const getAttendedAttendees = async () => {
    const response = await getRequest(
      `attendee/${singleEvent?._id}/attended/?hasAttended=true&location=eventCheckin&limit=0`
    );

    batch(() => {
      setAttendedRegistrations(response?.data?.registrations);
    });
  };

  const calculateAverageTimeSpent = () => {
    const totalTimeSpent = attendedRegistrations.reduce((total, user) => {
      const timeSpentPerUser = user.attendee[0].eventSpecificData
        .filter((eventData) => eventData.eventId === singleEvent?._id)
        .flatMap((eventData) => eventData.checkInData)
        .filter((data) => data.location === "eventCheckin")
        .map((checkIn) => {
          const entryTime = new Date(checkIn.entry);
          const exitTime = new Date(checkIn.exit);
          return exitTime - entryTime;
        })
        .reduce((sum, timeSpent) => sum + timeSpent, 0);

      return total + timeSpentPerUser;
    }, 0);

    const totalUsersWithCheckInData = attendedRegistrations.reduce(
      (total, user) => {
        const hasCheckInData = user.attendee[0].eventSpecificData.some(
          (eventData) =>
            eventData.eventId === singleEvent?._id &&
            eventData.checkInData.some(
              (data) => data.location === "eventCheckin"
            )
        );

        return total + (hasCheckInData ? 1 : 0);
      },
      0
    );

    const averageTimeSpent =
      totalUsersWithCheckInData > 0
        ? totalTimeSpent / totalUsersWithCheckInData
        : 0;

    const milliseconds = averageTimeSpent;
    const seconds = Math.floor((milliseconds / 1000) % 60);
    const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
    const hours = Math.floor((milliseconds / 1000 / 60 / 60) % 24);

    setAverageTimeSpent({ hours: hours, minutes: minutes, seconds: seconds });
  };

  useEffect(() => {
    if (singleEvent?._id) {
      getAttendedAttendees();
      getAttendeeStats();
    }
  }, [singleEvent]);

  useEffect(() => {
    if (attendedRegistrations) {
      calculateAverageTimeSpent();
    }
  }, [attendedRegistrations]);

  useEffect(() => {
    if (attendedRegistrations) {
      const startDateTime = new Date(singleEvent?.startDate);
      const endDateTime = new Date(singleEvent?.endDate);

      const totalDays = Math.ceil(
        (endDateTime - startDateTime) / (1000 * 60 * 60 * 24)
      );

      const timeSpentPerDay = new Array(totalDays).fill(0);
      const usersWithCheckInDataPerDay = new Array(totalDays).fill(0);

      attendedRegistrations?.forEach((user) => {
        user.attendee[0].eventSpecificData.forEach((eventData) => {
          if (eventData.eventId === singleEvent?._id) {
            const checkInData = eventData.checkInData.filter(
              (data) => data.location === "eventCheckin"
            );

            if (checkInData.length > 0) {
              checkInData.forEach((checkIn) => {
                const checkInDateTime = new Date(checkIn.entry);
                const dayIndex = Math.floor(
                  (checkInDateTime - startDateTime) / (1000 * 60 * 60 * 24)
                );

                if (dayIndex >= 0 && dayIndex < totalDays) {
                  const exitTime = new Date(checkIn.exit);
                  const timeSpent = exitTime - checkInDateTime;

                  timeSpentPerDay[dayIndex] += timeSpent;
                  usersWithCheckInDataPerDay[dayIndex]++;
                }
              });
            }
          }
        });
      });

      const averageTimeSpentPerDay = timeSpentPerDay.map((timeSpent, index) => {
        const usersWithCheckInData = usersWithCheckInDataPerDay[index];
        return usersWithCheckInData > 0 ? timeSpent / usersWithCheckInData : 0;
      });

      let labels = [];
      let data = [];
      let totalData = [];

      averageTimeSpentPerDay.forEach((averageTimeSpent, index) => {
        const currentDate = new Date(startDateTime);
        currentDate.setDate(currentDate.getDate() + index);
        const day = index + 1;
        const formattedDay = day.toString().padStart(2, "0");
        const month = currentDate.getMonth() + 1;
        const formattedDate = `${month
          .toString()
          .padStart(2, "0")}-${currentDate
          .getDate()
          .toString()
          .padStart(2, "0")}-${currentDate.getFullYear()}`;

        const seconds = Math.floor((averageTimeSpent / 1000) % 60);
        const minutes = Math.floor((averageTimeSpent / 1000 / 60) % 60);
        const hours = Math.floor(averageTimeSpent / 1000 / 60 / 60);
        labels.push(formattedDate);
        data.push(averageTimeSpent);

        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const formattedDate2 = `${currentDate
          .getDate()
          .toString()
          .padStart(2, "0")} ${
          monthNames[currentDate.getMonth()]
        }, ${currentDate.getFullYear()}`;
        totalData.push({
          day: formattedDay,
          date: formattedDate2,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        });
      });

      batch(() => {
        setAverageTimeSpentDayWise(totalData);
        setLabels(labels);
        setData(data);
      });
    }
  }, [attendedRegistrations]);

  return (
    <div className="pb-[50px] md:mt-[25px] ">
      {labels?.length > 0 && data ? (
        <>
          <div className="flex w-full justify-between flex-wrap	">
            {overAllStats?.map((stats) => {
              return (
                <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 w-[160px] mb-[20px]">
                  <div className="p-4">
                    <h2 className="text-[17px] font-semibold text-slate-800 dark:text-slate-100 mb-2 text-wrap">
                      {stats?.title}
                    </h2>

                    <div className="flex items-start">
                      <div className="text-[21px] font-bold text-slate-800 dark:text-slate-100 mr-2">
                        {stats?.value}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div>
            <AnalyticsCard01
              labels={labels}
              data={data}
              averageTimeSpent={averageTimeSpent}
            />
          </div>

          <div className="">
            <FintechCard03
              attendees={attendedRegistrations}
              checkIns={true}
              checkOuts={false}
              eventId={singleEvent._id}
            />
          </div>

          <div className="">
            <AnalyticsCard12
              averageTimeSpentDayWise={averageTimeSpentDayWise}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
});

export default CheckinChekoutAnalytics;

import { useState, useEffect } from "react";
import { useMatch } from "react-router-dom";
import PrimaryButton from "../../../../common/buttons/PrimaryButton";
import { useSelector } from "react-redux";
import AddTicket from "./addTicket";

const TicketTemplate = () => {
  const [open, setOpen] = useState(false);
  const eventsId = useMatch("events/:eventId/*");
  const singleEvent = useSelector((state) => state.eventData);
  const [tickets, setTickets] = useState([]);

  useEffect(() => {
    if (singleEvent.isPaid) setTickets(singleEvent?.ticketCategories);
  }, [singleEvent._id, tickets]);

  return (
    <div className="w-full md:w-[400px] h-max ml-0 md:ml-[0px] mt-5 md:mt-[30px] ">
      <p className="font-[600] w-[335px] md:w-[400px] mx-auto md:mx-auto text-[19px] text-[#585858] md:ml-8">
        Your Tickets
      </p>

      <div className="flex w-[335px] md:w-[340px] flex-row place-content-around mx-auto md:mx-0">
        <AddTicket
          open={open}
          setOpen={setOpen}
          //   isSubmitting={isSubmitting}
          singleEvent={singleEvent}
          setTickets={setTickets}
        />
      </div>

      <div className="w-[335px] md:w-[300px] mx-auto md:mx-0 md:ml-8 mt-[30px]">
        {tickets?.length > 0 ? (
          tickets.map((ele, index) => {
            return (
              <div key={ele._id}>
                <div className="my-4 flex justify-between">
                  <div className="flex items-center">
                    <div className="pl-2.5 w-[197px]">
                      <div className="text-[14px] font-semibold py-1 flex flex-col gap-2">
                        <div>{ele.name}</div>
                        <div className="text-[12px] font-normal">
                          {ele.description}
                        </div>
                        <div>{ele.totalTicketCount} tickets remaining...</div>
                      </div>
                    </div>

                    <span className="relative -right-[60px] bg-blue-100 text-blue-800 text-[12px] font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
                      {ele.price}
                    </span>
                  </div>
                </div>
                <div className="w-[325px] md:w-[422px]">
                  <hr />
                </div>
              </div>
            );
          })
        ) : (
          // <div></div>
          <div>
            <img
              src="/svgs/nullState.svg"
              alt=""
              className="w-[200px] h-[200px]"
            />
            <p className="text-[15px] font-[500] text-[#717171]">
              No Team members added...
            </p>
          </div>
        )}

        <div className="mt-[50px]"></div>
        <PrimaryButton
          btnText={"Add Ticket"}
          onClick={() => {
            setOpen(true);
          }}
        />
      </div>
    </div>
  );
};

export default TicketTemplate;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import PrimaryButton from "../../../../common/buttons/PrimaryButton";
import DefaultProfilePicture from "../../../../common/defaultProfilePicture/DefaultProfilePicture";
import TextInput from "../../../../common/inputElements/TextArea";
import AddStaff from "./AddStaff";

const StaffManagement = () => {
  const eventsId = useMatch("events/:eventId/*");
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [team, setTeam] = useState([]);

  const singleEvent = useSelector((state) => state.eventData);

  useEffect(() => {
    let uniqueArr = singleEvent?.organizer?.reduce((acc, current) => {
      let isDuplicate = acc.some((item) => item._id === current._id);
      if (!isDuplicate) {
        acc.push(current);
      }
      return acc;
    }, []);

    setTeam(uniqueArr);
  }, [singleEvent._id]);

  return (
    <div className="w-full md:w-[400px] ml-0 md:ml-[0px] mt-5 md:mt-[30px] ">
      <div className="font-[600]  md:w-[400px] text-[20px]  text-black md:flex items-center justify-between bg-white">
        <p className="text-[19px]"> Your Team</p>
        <div className="w-[335px] md:w-[160px]">
          <PrimaryButton
            btnText={"Add Team"}
            onClick={() => {
              setOpen(true);
            }}
          />
        </div>
      </div>
      <div className="flex w-[335px] md:w-[340px] flex-row place-content-around mx-auto md:mx-0">
        <AddStaff
          open={open}
          setOpen={setOpen}
          isSubmitting={isSubmitting}
          singleEvent={singleEvent}
        />
      </div>

      <div className="w-[335px] md:w-[400px] md:mx-0 mt-[20px] relative">
        {team?.length > 0 ? (
          team.map((ele, index) => {
            return (
              <div key={ele._id}>
                <div className="my-4 flex justify-between ">
                  <div className="flex items-center">
                    {ele.profilePicture ? (
                      <img
                        src={ele.profilePicture}
                        alt="profile"
                        className="rounded-full w-[50px] h-[50px] object-cover"
                      />
                    ) : (
                      <div className=" ">
                        <DefaultProfilePicture
                          firstName={ele.firstName}
                          lastName={ele.lastName}
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "300px",
                            fontSize: "17px",
                          }}
                        />
                      </div>
                    )}

                    <div className="pl-2.5 w-[197px]">
                      <div className="text-[14px] font-semibold py-1">
                        {ele.firstName} {ele.lastName}
                      </div>
                    </div>

                    {index === 0 ? (
                      <span className="absolute right-0 bg-blue-100 text-blue-800 text-[12px] font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
                        Admin
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="w-[325px] md:w-[400px]">
                  <hr />
                </div>
              </div>
            );
          })
        ) : (
          <div>
            <img
              src="/svgs/nullState.svg"
              alt=""
              className="w-[200px] h-[200px]"
            />
            <p className="text-[15px] font-[500] text-[#717171]">
              No Team members added...
            </p>
          </div>
        )}

        <div className="mt-[50px]"></div>
      </div>
    </div>
  );
};

export default StaffManagement;

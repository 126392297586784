import React from "react";

function AnalyticsCard12({ averageTimeSpentDayWise }) {
  return (
    <>
      <div className="col-span-full bg-[#f9fbff] shadow-lg rounded-sm w-[50%]">
        <header className="px-5 py-4 border-b border-slate-100 dark:border-slate-700">
          <h2 className="font-semibold text-slate-800 dark:text-slate-100">
            Average time spent (Daywise - Tabular Format)
          </h2>
        </header>
        <div className="p-3">
          {/* Table */}
          <div className=" overflow-auto max-h-[300px] ">
            <table className="table-auto w-full dark:text-slate-300 ">
              {/* Table header */}
              <thead className="text-xs uppercase  bg-slate-50 dark:bg-slate-700 dark:bg-opacity-50 rounded-sm">
                <tr>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Day</div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Date</div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Time Spent</div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody className="text-sm divide-y divide-slate-100 dark:divide-slate-700 ">
                {/* Row */}
                {averageTimeSpentDayWise?.map((data) => {
                  return (
                    <tr>
                      <td className="p-2 whitespace-nowrap">
                        <div className="flex font-[500]">
                          <div>{data?.day}</div>
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap ">
                        <div className="font-[500]">{data?.date}</div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="font-[500]">
                          {data?.hours}hr {data?.minutes}m {data?.seconds}s
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default AnalyticsCard12;

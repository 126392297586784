import React, { useEffect, useState, useMemo } from "react";
import LineChart from "../../../analytics/charts/LineChart09";
// Import utilities
import { tailwindConfig, hexToRGB } from "../../../../../utils/Utils";

function getDayWiseRegistrations(arr, eventId = null, entity) {
  if (arr.length === 0) {
    return "arr has no elements";
  }
  if (!eventId) {
    return "can't find event id";
  }

  const resultsArray = [];

  for (let i = 0; i < arr.length; i++) {
    if (arr[i].payment.status !== "PAYMENT_SUCCESS") {
      const utcDate = new Date(arr[i].purchaseDate);
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        timeZone: "Asia/Kolkata",
      };
      const dateString = utcDate.toLocaleDateString("en-IN", options);

      let obj = {
        date: dateString,
        registrations: 1,
      };

      let index = null;
      for (let s = 0; s < resultsArray.length; s++) {
        if (resultsArray[s].date === dateString) {
          index = s;
        }
      }

      if (index === 0 || index) {
        resultsArray[index].registrations =
          resultsArray[index].registrations + 1;
      } else {
        resultsArray.push(obj);
      }
    }
  }
  return resultsArray;
}

function findDateRange(data) {
  let minDate = data[0].purchaseDate;
  let maxDate = data[0].purchaseDate;

  data.forEach((item) => {
    if (item.purchaseDate < minDate) {
      minDate = item.purchaseDate;
    }
    if (item.purchaseDate > maxDate) {
      maxDate = item.purchaseDate;
    }
  });

  return { minDate, maxDate };
}

function getDetailedLabels(data) {
  const { minDate, maxDate } = findDateRange(data);
  const startDate1 = new Date(minDate);
  const endDate1 = new Date(maxDate);

  const dateArray = [];
  let currentDate1 = startDate1;
  while (currentDate1 <= endDate1) {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      timeZone: "Asia/Kolkata",
    };
    const dateString = currentDate1.toLocaleDateString("en-IN", options);
    dateArray.push(dateString);
    currentDate1.setDate(currentDate1.getDate() + 1);
  }
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    timeZone: "Asia/Kolkata",
  };
  const dateString = currentDate1.toLocaleDateString("en-IN", options);
  dateArray.push(dateString);

  return dateArray;
}

function FintechCard17({ data }) {
  const [resultsArray, setResultsArray] = useState([]);

  const dateArray = useMemo(() => getDetailedLabels(data), [data]);
  const xAxis = useMemo(() => generateXAxis(dateArray), [dateArray]);
  const yAxis = useMemo(
    () => generateYAxis(resultsArray, dateArray),
    [resultsArray, dateArray]
  );

  const [chartData, setChartData] = useState(() => ({
    labels: xAxis,
    datasets: [
      // Line
      {
        data: yAxis,
        fill: true,
        backgroundColor: `rgba(${hexToRGB(
          tailwindConfig().theme.colors.orange[500]
        )}, 0.08)`,
        borderColor: tailwindConfig().theme.colors.orange[500],
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: tailwindConfig().theme.colors.orange[500],
        clip: 20,
      },
    ],
  }));

  useEffect(() => {
    if (data?.length > 0) {
      setResultsArray(getDayWiseRegistrations(data, "tickets"));
    }
  }, [data]);

  function generateXAxis(dateArray) {
    let arr = [];
    for (let i = 0; i < dateArray.length; i++) {
      arr.push(i);
    }
    return arr;
  }

  function generateYAxis(resultsArray, dateArray) {
    let dataArr = [];
    for (let i = 0; i < dateArray.length; i++) {
      let count = 0;
      for (let j = 0; j < resultsArray.length; j++) {
        if (dateArray[i] === resultsArray[j].date) {
          dataArr.push(resultsArray[j].registrations);
          count++;
        }
      }
      if (count === 0) {
        dataArr.push(0);
        count = 0;
      }
    }
    return dataArr;
  }

  useEffect(() => {
    setChartData((prevChartData) => ({
      ...prevChartData,
      datasets: [
        {
          ...prevChartData.datasets[0],
          data: generateYAxis(resultsArray, dateArray),
        },
      ],
    }));
  }, [resultsArray]);

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-3 bg-[#f9fbff] shadow-lg rounded-sm">
      <div className="px-5 pt-5">
        <header>
          <h3 className="text-[15px] font-[600] mb-1">
            <span className="text-[#000]">Failed</span>
          </h3>
          <div className="text-[22px] font-[600] text-[#000] mb-1">
            {yAxis.reduce((accumulator, currentValue) => {
              return accumulator + currentValue;
            }, 0)}
          </div>
        </header>
      </div>
      {/* Chart built with Chart.js 3 */}
      <div className="grow">
        {/* Change the height attribute to adjust the chart height */}
        <LineChart data={chartData} width={256} height={98} toolTip="Failed" />
      </div>
    </div>
  );
}

export default FintechCard17;

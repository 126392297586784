import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import throttle from "../../../../utils/throttle";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../redux/actions/toastActions";
import DatePicker from "react-date-picker";
import TimeInput from "../../../../common/timeInput/TimeInput";
import moment from "moment";
import axios from "axios";

export default function UploadPdf({
  open,
  setOpen,
  event,
  isEdit,
  setIsEdit,
  setEventDetails,
}) {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [expiryDateValue, setExpiryDateValue] = useState(new Date());
  const [minDate, setminDate] = useState(new Date());
  const [maxDate, setmaxDate] = useState();
  const [scheduleTime, setscheduleTime] = useState("");
  const [file, setFile] = useState(null);
  const [displayText, setDisplayText] = useState("Select PDF File");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({ mode: "onChange" });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setFile(file);
      setDisplayText(file.name);
    } else {
      setFile(null);
      setDisplayText("Upload PDF");
    }
  };

  useEffect(() => {
    if (isEdit) {
      const formattedTime = moment(event?.exhibitorDirectory?.validTill).format(
        "hh : mm A"
      );
      const timeStr = new Date(event?.exhibitorDirectory?.validTill)
        .toString()
        .slice(16, 24);

      reset({
        expiryTime: timeStr,
      });
      setscheduleTime(formattedTime);
      setExpiryDateValue(new Date(event?.exhibitorDirectory?.validTill));
      setDisplayText(event?.exhibitorDirectory?.fileName);
    } else {
      const currentTime = moment();
      const roundedMinutes =
        (Math.ceil((currentTime.minute() + 1) / 15) * 15) % 60;
      const roundedTime = moment({
        hour: currentTime.hour() + (roundedMinutes === 0),
        minute: roundedMinutes,
      });
      const formattedVal = moment(
        roundedTime.format("hh : mm A"),
        "hh : mm A"
      ).format("HH:mm:ss");
      reset({
        expiryTime: formattedVal,
      });
      setscheduleTime(roundedTime.format("hh : mm A"));
      setFile(null);
    }
  }, [event?._id, open]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    if (file === null && !isEdit) {
      dispatch(
        showToast({
          message: "Please select the PDF file",
          category: "danger",
        })
      );
      setIsSubmitting(false);
    } else if (!isEdit) {
      let validTill = new Date(
        `${moment(expiryDateValue).format("ll")}, ${data.expiryTime}`
      ).toISOString();
      const formData = new FormData();
      formData.append("file", file);
      formData.append("validTill", validTill);

      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/event/exhibitor-directory/${event?._id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(function (response) {
          let exhibitorDirectory = {
            ...event?.exhibitorDirectory,
            fileName: response.data.fileName,
            image: response.data.image,
            url: response.data.url,
            validTill: response.data.validTill,
          };

          const updatedEvent = {
            ...event,
            exhibitorDirectory: exhibitorDirectory,
          };
          setEventDetails(updatedEvent);
          dispatch(
            showToast({
              message: "PDF uploaded successfully",
              category: "success",
            })
          );
          setOpen(false);
          setIsSubmitting(false);
          setIsEdit(false);
        })
        .catch(function (error) {
          console.log(error);
          dispatch(
            showToast({
              message: "Some Error occured",
              category: "danger",
            })
          );
          setOpen(false);
          setIsEdit(false);
          setIsSubmitting(false);
        });
    } else if (isEdit) {
      // let formData;
      let validTill = new Date(
        `${moment(expiryDateValue).format("ll")}, ${data.expiryTime}`
      ).toISOString();
      if (file) {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("validTill", validTill);
        axios
          .patch(
            `${process.env.REACT_APP_SERVER_URL}/event/exhibitor-directory/${event?._id}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(function (response) {
            console.log(response);
            const updatedEvent = {
              ...event,
              exhibitorDirectory: response.data.data,
            };
            setEventDetails(updatedEvent);
            dispatch(
              showToast({
                message: "Changes saved",
                category: "success",
              })
            );
            setOpen(false);
            setIsSubmitting(false);
            setIsEdit(false);
          })
          .catch(function (error) {
            console.log(error);
            dispatch(
              showToast({
                message: "Some Error occured",
                category: "danger",
              })
            );
            setOpen(false);
            setIsEdit(false);
            setIsSubmitting(false);
          });
      } else {
        // formData = validTill;
        axios
          .patch(
            `${process.env.REACT_APP_SERVER_URL}/event/exhibitor-directory/${event?._id}`,
            { validTill }
          )
          .then(function (response) {
            const updatedEvent = {
              ...event,
              exhibitorDirectory: response.data.data,
            };
            setEventDetails(updatedEvent);
            dispatch(
              showToast({
                message: "Changes Saved",
                category: "success",
              })
            );
            setOpen(false);
            setIsSubmitting(false);
            setIsEdit(false);
          })
          .catch(function (error) {
            console.log(error);
            dispatch(
              showToast({
                message: "Some Error occured",
                category: "danger",
              })
            );
            setOpen(false);
            setIsEdit(false);
            setIsSubmitting(false);
          });
      }
    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="z-40 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="z-40 fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-[45px] md:left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md outline-none hidden md:block text-gray-300"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>X
                          {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
                        </button>
                        <button
                          className="md:hidden flex items-center"
                          onClick={() => setOpen(false)}
                        >
                          <i className="fa fa-angle-left text-[24px]"></i>
                          <span className="text-[17px] pt-0.5">Back</span>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6 fixed z-20 bg-white w-[100%] top-0">
                        <Dialog.Title className="text-[22px] pb-[25px] font-[600] text-gray-900 pt-5 ">
                          {isEdit ? "Edit Details" : "Add Details"}
                        </Dialog.Title>
                      </div>
                      <div className="relative mt-[60px] flex-1 px-4 sm:px-6 mb-[50px] ">
                        <form
                          enctype="multipart/form-data"
                          onSubmit={handleSubmit((data) =>
                            throttle(() => onSubmit(data), 5000)
                          )}
                        >
                          <p className="text-[12px] text-[#9c9c9c] mb-[5px]">
                            Upload PDF&nbsp;
                            <span className="text-red-600">*</span>
                          </p>

                          <div className="relative m-auto md:flex justify-center flex-col items-center border-2 border-dashed  border-gray-400 rounded-sm p-4 text-center cursor-pointer hover:bg-gray-100">
                            <input
                              type="file"
                              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                              accept=".pdf"
                              multiple={false}
                              onChange={handleFileChange}
                              title=" "
                            />
                            {file !== null ||
                            displayText !== "Select PDF File" ? (
                              <img
                                src="/svgs/upload-success.svg"
                                alt="success"
                                className="w-[90px] mb-[15px] cursor-pointer"
                              />
                            ) : (
                              <img
                                src="/svgs/upload-initial.svg"
                                alt="success"
                                className="w-[90px] mb-[15px] cursor-pointer"
                              />
                            )}

                            <p className="text-[15px] text-center font-[500] cursor-pointer mb-1">
                              {displayText}
                            </p>
                          </div>

                          <div className="grid grid-cols-2 gap-[50px] mt-[40px] mb-[30px]">
                            <div className="relative">
                              <label
                                htmlFor="startDate"
                                className="text-[12px] text-[#9c9c9c] absolute -top-[18px] left-[5px]"
                              >
                                Expiry Date&nbsp;
                                <span className="text-red-600">*</span>
                              </label>
                              <DatePicker
                                onChange={(value) => setExpiryDateValue(value)}
                                value={expiryDateValue}
                                minDate={minDate}
                                maxDate={maxDate}
                                format="dMMMy"
                              />
                            </div>

                            <TimeInput
                              register={register}
                              required={true}
                              value={scheduleTime}
                              setValue={setValue}
                              id={"expiryTime"}
                              label={"Expiry Time"}
                              isHalfWidth={false}
                            />
                          </div>

                          <div className="fixed bottom-0 w-[87%] bg-white  h-[80px] flex justify-center items-center ml-[-2px]">
                            {isEdit ? (
                              <input
                                disabled={isSubmitting}
                                value={
                                  isSubmitting ? "Loading..." : "Save Changes"
                                }
                                type="submit"
                                className="primary_submit"
                              />
                            ) : (
                              <input
                                disabled={isSubmitting}
                                value={
                                  isSubmitting ? "Uploading PDF..." : "Submit"
                                }
                                type="submit"
                                className="primary_submit"
                              />
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

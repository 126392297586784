import React, { useState, useEffect } from "react";
import { useNavigate, useMatch } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import styles from "./Landing.module.css";
import moment from "moment";
import displayRazorpay from "./razorpay/Razorpay";
import CheckOutPlatformPage from "./CheckOutPlatformPage";

const LandingCheckout = ({ singleEvent }) => {
  const navigate = useNavigate();
  const xmas95 = new Date(singleEvent.startDate);
  const optionmymdonth = { month: "short" };
  const [cartItems, setCartItems] = useState([]);
  const products = singleEvent?.ticketCategories;
  const [coupon, setCoupon] = useState("");
  const [couponId, setCouponId] = useState([]);
  const [checking, setChecking] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [processingFees, setProcessingFees] = useState(0);
  const [error, setError] = useState("");
  const [submit, setSubmit] = useState(false);
  const [paymentData, setPaymentData] = useState({});
  const eventId = useMatch("/event/:eventId/checkout").params.eventId;

  useEffect(() => {
    if (localStorage.getItem("tempUserPayDetails") === null) {
      window.location.href = `https://app.warpbay.com/event/${eventId}`;
    }
  }, [eventId]);

  const handleAddToCart = (product) => {
    const isProductInCart = cartItems.some((item) => item._id === product._id);

    if (!isProductInCart) {
      setCartItems([product]);
      setCoupon("");
      setDiscount(0);
      setTotalAmount(0);
      setProcessingFees(0);
      setCouponId([]);
    }
  };

  const handleRemoveItem = (id) => {
    setCartItems(cartItems.filter((item) => item._id !== id));
    setCoupon("");
    setCouponId([]);
    setDiscount(0);
    setTotalAmount(0);
    setProcessingFees(0);
  };
  useEffect(() => {
    const subTotal = cartItems.reduce(
      (total, item) => total + item.discountValue,
      0
    );
    if (processingFees == 0) {
      setProcessingFees(
        cartItems[0]?.commissionIncluded
          ? (subTotal * 0.038).toFixed(2)
          : (subTotal - subTotal / 1.038).toFixed(2)
      );
    }
  }, [cartItems]);

  useEffect(() => {
    const subTotal = cartItems.reduce(
      (total, item) => total + item.discountValue,
      0
    );

    if (Number(processingFees) > 0) {
      setTotalAmount(
        cartItems[0]?.commissionIncluded
          ? (Number(processingFees) + subTotal).toFixed(2)
          : subTotal
      );
    }
  }, [processingFees]);
  const handleCoupon = () => {
    const selectedCoupon = cartItems[0].coupons.filter(
      (item) => item.couponCode === coupon
    );

    if (selectedCoupon.length > 0) {
      if (selectedCoupon[0].redeemCount < selectedCoupon[0].maxRedeemCount) {
        setDiscount(
          selectedCoupon[0].discountType === "flat"
            ? selectedCoupon[0].discountValue
            : parseFloat(
                (selectedCoupon[0].discountValue * totalAmount) / 100
              ).toFixed(2)
        );
        setCouponId([selectedCoupon[0]]);
      }
    } else {
      setError("Invalid Coupon Code");
      setCouponId([]);
      setDiscount(0);
      setCoupon("");
    }
  };

  const handleRemoveCoupon = () => {
    setCouponId([]);
    setDiscount(0);
    setCoupon("");
  };

  async function processPaymentRequest(ticketId, totalAmount, eventId) {
    const id =
      Math.floor(Math.random() * (9999999999 - 1000000000 + 1)) + 1000000000;
    const transactionId =
      ticketId.substring(0, 6) + eventId.substring(0, 6) + id;

    localStorage.setItem("currentTransactionId", JSON.stringify(transactionId));

    const userData = JSON.parse(localStorage.getItem("tempUserPayDetails"));

    const paymentAmount = parseFloat((totalAmount - discount).toFixed(2));

    const selCoupon = couponId.length > 0 ? couponId[0]._id : "";

    setPaymentData({
      eventId: eventId,
      ticketId: ticketId,
      amount: paymentAmount,
      transactionId: transactionId,
      userData: userData,
      couponId: selCoupon,
      discount: discount,
    });
    setSubmit(true);

    // displayRazorpay(
    //   {
    //         eventId: eventId,
    //         ticketId: ticketId,
    //         amount: paymentAmount,
    //         transactionId: transactionId,
    //         userData: userData,
    //         couponId: selCoupon,
    //         discount: discount,
    //         navigate: navigate,
    //       }
    // )

    // axios({
    //   method: "POST",
    //   url: `${process.env.REACT_APP_SERVER_URL}/payment/phonepe`,
    //   data: {
    //     eventId: eventId,
    //     ticketId: ticketId,
    //     amount: paymentAmount,
    //     transactionId: transactionId,
    //     userData: userData,
    //     couponId: selCoupon,
    //     discount: discount,
    //   },
    // })
    //   .then((response) => {
    //     localStorage.removeItem("tempUserPayDetails");
    //     window.location.href =
    //       response.data.data.instrumentResponse.redirectInfo.url;
    //   })
    //   .catch((error) => {});
  }

  return (
    <>
      <Helmet>
        <title>Warpbay - {singleEvent?.title}</title>
        <link rel="icon" href={`${singleEvent?.coverImage}`} />
        <meta name="type" property="og:type" content="article" />
        <meta
          name="title"
          property="og:title"
          content={`${singleEvent?.title}`}
        />
        <meta
          name="description"
          property="og:description"
          content={`${singleEvent?.shortDescription}`}
        />
        <meta
          name="image"
          property="og:image"
          content={`${singleEvent?.coverImage}`}
        />
        <meta name="url" property="og:url" content="https://app.warpbay.com" />
      </Helmet>
      {!submit ? (
        <>
          <div className={styles.nav_parent}>
            <nav className={styles.navbar}>
              <div className={styles.logo}>
                <img src="/svgs/logo.svg" alt="logo" /> Warpbay
              </div>
              <ul className={styles.menu_ul}>
                <li className={styles.nav_home}>
                  <p
                    onClick={() =>
                      window.open(`${window.location.origin}/login`, "_blank")
                    }
                  >
                    Login
                  </p>
                </li>
              </ul>
            </nav>
          </div>

          <div className="mx-auto w-[824px] max-w-[1440px]">
            <div className="mb-4 mt-4">
              <h1 className="text-[24px] font-semibold">
                {" "}
                {singleEvent?.title}
              </h1>
            </div>

            <div className="w-full px-4 py-2 relative -left-[20px] -top-7">
              <span className="inline-flex items-center cursor-pointer my-3 font-normal mr-5">
                {" "}
                <img
                  src="/svgs/calender.svg"
                  alt="calendar"
                  className="mr-2"
                />{" "}
                {new Date(singleEvent?.startDate)
                  .toString()
                  .substring(0, 10)
                  .slice(-10) ===
                new Date(singleEvent?.endDate)
                  .toString()
                  .substring(0, 10)
                  .slice(-10) ? (
                  <>
                    {new Date(singleEvent?.startDate).getDate()}{" "}
                    {new Intl.DateTimeFormat("en-US", optionmymdonth).format(
                      xmas95
                    )}
                  </>
                ) : (
                  <>
                    {new Date(singleEvent?.startDate).getDate()}{" "}
                    {new Intl.DateTimeFormat("en-US", optionmymdonth).format(
                      new Date(singleEvent.startDate)
                    )}{" "}
                    to {new Date(singleEvent?.endDate).getDate()}{" "}
                    {new Intl.DateTimeFormat("en-US", optionmymdonth).format(
                      new Date(singleEvent?.endDate)
                    )}
                  </>
                )}
              </span>
              <span className="inline-flex items-center cursor-pointer font-normal">
                {" "}
                <img src="/svgs/Accesstime.svg" alt="time" className="mr-2" />
                <div className="line-clamp-1">
                  {moment(singleEvent?.startDate).format("LT")} to{" "}
                  {moment(singleEvent?.endDate).format("LT")}
                </div>
              </span>
            </div>

            <div className="grid grid-cols-3 mt-0 mx-auto w-full mb-[100px]">
              {/* Shopping Cart */}
              <div className="col-span-2 mr-[40px]">
                <h2 className="text-[20px] font-[600] mb-4">
                  Ticket categories
                </h2>
                <ul>
                  {products.length > 0
                    ? products.map((product, id) => (
                        <div className="h-[150px] border rounded-[5px] mb-[15px]">
                          <li
                            key={product._id}
                            className="grid grid-cols-[1fr_75px] items-center mb-[10px] border-b py-3 px-4"
                          >
                            <p className="text-[15px] font-[600]">
                              <i className="fa-solid fa-ticket text-gray-400"></i>{" "}
                              {product.name}{" "}
                            </p>
                            <button
                              className="px-2 py-1 bg-primary text-white rounded text-[13px] w-full"
                              onClick={() => handleAddToCart(product)}
                            >
                              select
                            </button>
                          </li>
                          <p className="text-[12px] font-[500] text-gray-600 capitalize pl-4">
                            {" "}
                            {product.description}
                          </p>
                          <div className="p-4">
                            <span className="text-[15px] font-[600] text-gray-600 line-through">
                              {" "}
                              <span className="!font-sans">₹ </span>
                              {product.price}
                            </span>
                            <span className="text-[15px] font-[600] inline-block ml-2">
                              {" "}
                              <span className="!font-sans">₹ </span>
                              {product.discountValue}
                            </span>
                            {/* <p className="text-[12px] font-[600] text-gray-400">
                              {product?.commissionIncluded
                                ? "excludes 3.8% processing fee"
                                : "includes 3.8% processing fee"}
                            </p> */}
                          </div>
                        </div>
                      ))
                    : null}
                </ul>
              </div>

              {/* Sidebar for Cart Preview */}
              <div className="col-span-1 border-l-1">
                <div className="w-full mt-[45px]">
                  <img
                    src={singleEvent?.coverImage}
                    alt=""
                    className="w-full h-full object-cover rounded-[2px]"
                  />
                </div>

                <h2 className="text-[18px] font-semibold my-4 px-0">
                  Order Preview
                </h2>

                <ul className="">
                  {cartItems.map((item) => (
                    <li
                      key={item._id}
                      className="mb-2 grid grid-cols-[1fr_auto] items-center"
                    >
                      <span className="text-[15px] font-[500] text-gray-600">
                        {item.name} - <span className="!font-sans">₹ </span>
                        {item.commissionIncluded
                          ? item.discountValue
                          : parseFloat(item.discountValue / 1.038).toFixed(2)}
                      </span>
                      <button
                        className="text-red-500 hover:text-red-600 justify-self-end"
                        onClick={() => handleRemoveItem(item._id)}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </li>
                  ))}
                </ul>
                {cartItems.length > 0 && (
                  <div className="mt-4 flex">
                    {couponId.length === 0 && (
                      <>
                        <input
                          id="coupon"
                          type="text"
                          placeholder="Enter Coupon:"
                          value={coupon}
                          className="whitespace-pre-line-wrap peer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded  focus:ring-primary focus:border-primary block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white font-medium dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          onChange={(e) => {
                            if (error.length > 0) setError("");
                            setCoupon(e.target.value);
                          }}
                        ></input>
                        <button
                          className="w-2/6 bg-gray-500 text-white py-2 px-2  rounded text-[13px]"
                          onClick={() => {
                            if (coupon.length > 0) handleCoupon();
                          }}
                        >
                          Apply
                        </button>
                      </>
                    )}
                    {couponId.length > 0 &&
                      couponId.map((ele) => {
                        return (
                          <div className="flex flex-col w-full">
                            <span className="text-[15px] font-[500] text-gray-600">
                              Coupons Applied:
                            </span>
                            <div className="flex gap-2 w-full justify-between">
                              <div className="flex gap-2">
                                <p className="text-[13px] font-[400] text-gray-400 py-2">
                                  {ele.couponCode}
                                </p>
                                <p className="text-[13px] font-[400] text-gray-400 py-2">
                                  - <span className="!font-sans">₹ </span>
                                  {discount}
                                </p>
                              </div>
                              <button
                                className="text-red-500 hover:text-red-600 justify-self-end"
                                onClick={() => handleRemoveCoupon(ele._id)}
                              >
                                <i className="fas fa-trash"></i>
                              </button>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}

                {/* Total Amount */}
                <div className="mt-4">
                  <p className="text-[13px] font-[400] text-gray-400 py-2">
                    Processing fees - <span className="!font-sans">₹ </span>
                    {processingFees}
                  </p>
                  <span className="text-[15px] font-semibold">
                    Total: <span className="!font-sans">₹ </span>
                    {(totalAmount - discount).toFixed(2)}
                  </span>
                </div>

                {/* Checkout Button */}
                <button
                  className="w-full mt-7 bg-primary text-white px-4 py-2 rounded text-[15px]"
                  onClick={() => {
                    if (cartItems.length > 0) {
                      processPaymentRequest(
                        cartItems[0]._id,
                        totalAmount,
                        eventId
                      );
                    }
                  }}
                >
                  Proceed to Checkout
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <CheckOutPlatformPage
          paymentData={paymentData}
          setSubmit={setSubmit}
          singleEvent={singleEvent}
          submit={submit}
        />
      )}
    </>
  );
};

export default LandingCheckout;

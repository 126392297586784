import React, { useState } from "react";
import { useMatch } from "react-router-dom";
import FintechCard14 from "./cards/FintechCard14";
import { useSelector } from "react-redux";
import FintechCard15 from "./cards/FintechCard15";
import FintechCard16 from "./cards/FintechCard16";
import FintechCard17 from "./cards/FintechCard17";
const getTickets = async (page = 1, eventId, sort = null) => {
  let url;
  if (!sort) {
    url = `${process.env.REACT_APP_SERVER_URL}/payment/event/${eventId}?page=${page}`;
  } else {
    url = `${process.env.REACT_APP_SERVER_URL}/payment/event/${eventId}?page=${page}&sort=${sort}`;
  }
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    });

    const response = await res.json();
    return response;
  } catch (err) {}
};

const TicketAnalytics = () => {
  const eventId = useMatch("events/:eventId/*");
  const [allCount, setAllCount] = useState([]);
  const singleEvent = useSelector((state) => state.eventData);

  return (
    <div className="flex flex-col gap-4 justify-center pb-32">

      <div className="mt-[10px] flex gap-8">
        {allCount.length > 0 && (
          <div className="h-[200px] w-[200px]">
            <FintechCard14 data={allCount} />{" "}
          </div>
        )}
        {allCount.length > 0 && (
          <div className="h-[200px] w-[200px]">
            <FintechCard15 data={allCount} />
          </div>
        )}
        {allCount.length > 0 && (
          <div className="h-[200px] w-[200px]">
            <FintechCard16 data={allCount} />
          </div>
        )}
        {allCount.length > 0 && (
          <div className="h-[200px] w-[200px]">
            <FintechCard17 data={allCount} />
          </div>
        )}
      </div>
      <AnalyticsTable
        getTickets={getTickets}
        eventId={eventId.params.eventId}
        setAllCount={setAllCount}
      />
    </div>
  );
};

const AnalyticsTable = ({ getTickets, eventId, setAllCount }) => {
  const [total, setTotal] = React.useState(null);
  const [current, setCurrent] = React.useState(1);
  const [tickets, setTickets] = React.useState([]);
  const [sort, setSort] = React.useState(null);
  const [triggerLabel, setTriggerLabel] = React.useState(false);

  const totalPagesMemo = React.useMemo(() => {
    let totalPages = total / 10;
    totalPages = Math.floor(totalPages);
    if (total % 10 > 0) totalPages++;
    return totalPages;
  }, [total]);

  const totalPages = totalPagesMemo;
  const pagination = React.useMemo(() => {
    const previous = current - 1 > 0 ? current - 1 : null;
    const next = current + 1 <= totalPages ? current + 1 : null;
    return {
      current,
      previous,
      next,
      totalPages,
    };
  }, [current, totalPages]);

  const { previous, next } = pagination;

  function formatTicketTime(ticketData) {
    if (ticketData) {
      const utcDate = new Date(ticketData.purchaseDate);
      const options = {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZone: "Asia/Kolkata",
      };

      const formatter = new Intl.DateTimeFormat("en-IN", options);
      const dateString = formatter.format(utcDate);
      return dateString;
    }
    return null;
  }

  React.useEffect(() => {
    getTickets(current, eventId, sort).then((response) => {
      setAllCount(response.data);
      setTickets(response.table);
      setTotal(response.count);
    });
  }, [current, sort]);

  const handlePrevious = () => {
    setCurrent((prev) => prev - 1);
  };

  const handleNext = () => {
    setCurrent((prev) => prev + 1);
  };

  const handlePageChange = (page) => {
    setCurrent(page);
  };

  return (
    <div className="flex-grow overflow-auto md:w-[100%] min-h-[200px] mt-4">
      {/* <div> */}
      {tickets?.length > 0 ? (
        <>
          <table className="table-auto mb-[30px]">
            <thead className="">
              <tr className="">
                <th className="text-[12px] font-[600] text-left leading-[45px] w-[200px] mr-[15px]">
                  Transaction Id
                </th>
                <th className="text-[12px] font-[600] text-left leading-[45px] w-[120px] mr-[15px]">
                  Ticket Name
                </th>
                <th className="text-[12px] font-[600] text-left leading-[45px] w-[100px] mr-[15px]">
                  Amount
                </th>
                <th className="text-[12px] font-[600] text-left leading-[45px] w-[150px] mr-[15px]">
                  Email
                </th>
                <th className="text-[12px] font-[600] text-left leading-[45px] w-[150px] mr-[15px]">
                  Status
                </th>
                <th className="text-[12px] font-[600] text-left leading-[45px] w-[150px]  mr-[5px]">
                  Coupon Code
                </th>
                <th className="text-[12px] font-[600] text-left relative leading-[45px] w-[200px] flex gap-8 items-center">
                  <span>Date</span>
                  <div
                    className="cursor-pointer"
                    onMouseEnter={() => setTriggerLabel(true)}
                    onMouseLeave={() => setTriggerLabel(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1"
                      stroke="currentColor"
                      className="w-5 h-5 stroke-gray-400"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
                      />
                    </svg>
                  </div>

                  {triggerLabel && (
                    <div
                      className="absolute w-[90px] top-6 left-4 pt-4 bg-transparent z-20"
                      onMouseEnter={() => setTriggerLabel(true)}
                      onMouseLeave={() => {
                        setTriggerLabel(false);
                      }}
                    >
                      <div
                        className="bg-white border h-[75px] flex flex-col gap-4 justify-center items-center p-2 rounded-lg overflow-y-scroll"
                        style={{
                          boxShadow: "0px 0px 14px rgba(165, 94, 234, 0.2)",
                        }}
                      >
                        <div
                          className="cursor-pointer flex items-center justify-between leading-tight font-medium"
                          onClick={() => {
                            if (sort != "asc") setSort("asc");
                            setTriggerLabel(false);
                          }}
                        >
                          Oldest
                          <img
                            src="/svgs/arrow.svg"
                            alt="arrow"
                            className="w-[15px] font-[600] ml-[5px] cursor-pointer rotate-180"
                          />
                        </div>
                        <div
                          className="cursor-pointer leading-tight flex  items-center justify-between font-medium"
                          onClick={() => {
                            if (sort != "desc") setSort("desc");
                            setTriggerLabel(false);
                          }}
                        >
                          Newest
                          <img
                            src="/svgs/arrow.svg"
                            alt="arrow"
                            className="w-[15px] font-[600] ml-[5px] cursor-pointer rotate-0"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                {/* <th className="text-[12px] font-[600] text-left leading-[45px] w-[100px]">
                  Receipt
                </th> */}
              </tr>
            </thead>

            <tbody className="md:w-[1020px]">
              {tickets.map((attendee, index) => {
                const couponArr = attendee.ticketCategoryId.coupons.filter(
                  (coupon) => coupon._id === attendee.couponId
                );
                return (
                  <React.Fragment key={index}>
                    <tr className="h-[55px] border-b">
                      <td className="text-[12px] font-[400] mr-[15px]">
                        {attendee.payment.merchantTransactionId}
                      </td>
                      <td className="text-[12px] font-[400] mr-[15px]">
                        {attendee.ticketCategoryId.name}
                      </td>
                      <td className="text-[12px] font-[400] mr-[15px]">
                        {attendee.purchasePrice}
                      </td>
                      <td className="text-[12px] font-[400] overflow-hidden mr-[15px]">
                        {attendee?.user[0]?.email}
                      </td>

                      <td className="text-[12px] font-[400] mr-[15px]">
                        {attendee.razorpay.status}
                      </td>
                      <td className="text-[12px] font-[400] mr-[15px]">
                        {couponArr.length > 0 ? couponArr[0].couponCode : "-"}
                      </td>
                      <td className="text-[12px] font-[400] mr-[15px]">
                        {formatTicketTime(attendee)}
                      </td>
                      {/* <td className="text-[12px] font-[400] w-[100px]">
                        <a href={attendee.payment.receiptUrl} target="_blank" className="rounded-[12px] p-[4px] text-white flex items-center justify-center bg-primary">View</a>
                      </td>  */}
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
          {/* {tab === "Registered" ? ( */}

          <div className="flex items-center ml-0  rounded-lg w-full mx-auto mt-[10px]">
            <div className="text-[13px] mr-2">
              <span className="font-semibold text-[14px]">
                {tickets?.length}
              </span>{" "}
              of <span className="font-semibold text-[14px]">{total}</span>{" "}
              results
            </div>

            <button
              className={`text-center cursor-pointer text-${
                previous === null ? "gray-300" : "primary"
              } text-[23px]`}
              disabled={previous === null ? true : false}
              onClick={() => handlePrevious()}
            >
              {"<"}
            </button>

            <div className="mx-3 cursor-pointer">
              <select
                id="title"
                name="title"
                // {...register("gender")}
                required
                value={current}
                onChange={(e) => {
                  handlePageChange(e.target.value);
                  // await getAllEventAttendees(next);
                }}
                className={`
            w-[75px]
            peer bg-white border-2 border-primary rounded-[5px] text-gray-900 text-[12px] placeholder-transparent focus:ring-transparent py-0 bg-[right] 1.5rem cursor-pointer
            bg-center `}
              >
                {Array.from({ length: totalPages }).map((_, index) => (
                  <option
                    key={index + 1}
                    value={index + 1}
                    className="hover:bg-gray-300 border-primary rounded-[5px] overflow-scroll max-h-[50px]"
                  >
                    {index + 1}
                  </option>
                ))}
              </select>
            </div>

            <button
              disabled={next === null ? true : false}
              className={`text-center cursor-pointer text-${
                next === null ? "gray-300" : "primary"
              } text-[23px]`}
              onClick={() => handleNext()}
            >
              {">"}
            </button>
          </div>
          {/* ) : (
          <></>
        )} */}
        </>
      ) : (
        <div className="h-[350px] mt-[0px]">
          <div>
            <div>
              <img
                src="/svgs/nullState.svg"
                alt="img"
                className="w-[200px] h-[200px]"
              />
            </div>
            <p className="text-[18px] font-[500] text-[#717171] mt-[0px] mr-[10px]">
              Nothing here...
            </p>
          </div>
        </div>
      )}
      <div className="text-gray-300 text-primary"></div>
    </div>
  );
};

export default TicketAnalytics;

import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { getSingleEvent } from "../../../../../redux/actions/eventActions";
import axios from "axios";
import { useMatch, useNavigate } from "react-router-dom";
import { showToast } from "../../../../../redux/actions/toastActions";
import TextInput from "../../../../../common/inputElements/TextInput";

export default function AddSenderEmail({
  open,
  setOpen,
  isEdit,
  setIsEdit,
  singleEvent,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [senderOrganizerDetails, setSenderOrganizerDetails] = useState({
    email: "",
    name: "",
  });
  const navigate = useNavigate();
  const eventsId = useMatch(
    "/events/:eventId/communications/email-domain-whitelable"
  );
  const event = useSelector((state) => state.eventData);
  const {
    register,
    handleSubmit,
    formState: { errors },
    // setValue,
    reset,
  } = useForm({
    defaultValues: {
      name: event?.senderOrganizerName,
      email: event?.senderOrganizerEmail,
    },
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if (event.title) {
      reset({
        name: event.senderOrganizerName,
        email: event.senderOrganizerEmail,
      });
    }
    if (!open) {
      reset({
        name: "",
        email: "",
      });
    }
  }, [open]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      dispatch(
        showToast({
          message: "please login!! access not found",
          category: "danger",
        })
      );
      navigate("/login");
    }

    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/event/${eventsId.params.eventId}/add-sender-email`,
        {
          senderEmail: data.email,
          senderName: data.name,
          reply_to_name: data.reply_to_name,
          reply_to_email: data.reply_to_email,
          nickName: data.nickName,
        },
        {
          headers: {
            Authorization: `bearer ${accessToken}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          dispatch(
            showToast({
              message: "sender Email connected",
              category: "success",
            })
          );
        }
        dispatch(getSingleEvent({ eventId: eventsId.params.eventId }));
        setOpen(false);
        reset({
          email: "",
          name: "",
        });
        setIsSubmitting(false);
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          dispatch(
            showToast({
              message: "Unauthorized!!",
              category: "danger",
            })
          );
        }
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="z-40 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="z-40 fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-[45px] md:left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md outline-none hidden md:block text-gray-300"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>X
                          {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
                        </button>
                        <button
                          className="md:hidden flex items-center"
                          onClick={() => setOpen(false)}
                        >
                          <i className="fa fa-angle-left text-[24px]"></i>
                          <span className="text-[17px] pt-0.5">Back</span>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6 fixed z-20 bg-white w-[100%] top-0">
                        <Dialog.Title className="text-[22px] pb-[0px] font-[600] text-gray-900 pt-5">
                          Add Sender Email
                        </Dialog.Title>
                      </div>
                      <div className="relative mt-[35px] flex-1 px-4 sm:px-6 mb-[50px] overflow-x-hidden">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="mt-[30px]"></div>
                          <TextInput
                            register={register}
                            type="text"
                            id={"email"}
                            label="Sender Email"
                            placeholder="Sender Email"
                            mb="3"
                            required
                          />
                          <div className="mt-[30px]"></div>
                          <TextInput
                            register={register}
                            type="text"
                            id={"name"}
                            label="Sender Name"
                            placeholder="Sender Name"
                            mb="3"
                            required
                          />
                          <div className="mt-[30px]"></div>
                          <div className="mt-[20px]"></div>
                          <TextInput
                            register={register}
                            type="text"
                            id={"reply_to_email"}
                            label="Reply To Email"
                            placeholder="Reply To"
                            mb="3"
                            required
                          />
                          <p className="text-gray-400 text-[12px] relative font-[500] -top-[9px] -right-[5px]">
                            eg. orders@example.com
                          </p>
                          <div className="mt-[20px]"></div>
                          <TextInput
                            register={register}
                            type="text"
                            id={"reply_to_name"}
                            label="Reply To Name"
                            placeholder="Reply To Name"
                            mb="3"
                            required
                          />
                          <p className="text-gray-400 text-[12px] relative font-[500] -top-[9px] -right-[5px]">
                            eg. Example Orders
                          </p>
                          <div className="mt-[20px]"></div>
                          <TextInput
                            register={register}
                            type="text"
                            id={"nickName"}
                            label="Nick Name"
                            placeholder="Nick Name"
                            mb="3"
                            required
                          />
                          <p className="text-red-500 text-[12px] relative font-[500] -top-[9px] -right-[5px]">
                            *Nick name should be unique (add any 2 numbers)
                          </p>
                          <div className="fixed bottom-0 w-[87%] bg-white h-[80px] flex justify-center items-center ml-[-2px]">
                            <input
                              disabled={isSubmitting}
                              value={
                                isSubmitting ? "Loading..." : "Save Changes"
                              }
                              type="submit"
                              className="primary_submit"
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import TextInput from "../../../../common/inputElements/TextInput";
import { useForm } from "react-hook-form";
import throttle from "../../../../utils/throttle";
import { useMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { patchRequest } from "../../../../utils/API/api.ts";
import { showToast } from "../../../../redux/actions/toastActions";
import ReactQuill from "react-quill";
import { getUserDetails } from "../../../../redux/actions/userActions";
import "react-quill/dist/quill.snow.css";

export default function EditDefaultEmail({
  open,
  setOpen,
  event,
  testEmail,
  defaultEmail,
  setTestEmail,
  emailBody,
  setEmailBody,
  setEmailDetails,
}) {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const eventsId = useMatch("/events/:eventId/*");
  const [accessToken, setAccessToken] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const textareaRef = useRef(null);
  const userDetails = useSelector((state) => state.userDetails);
  const { savedUserConfig } = userDetails;

  useEffect(() => {
    let accessToken = localStorage.getItem("accessToken");
    dispatch(getUserDetails({ accessToken: accessToken }));
  }, [savedUserConfig?._id]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({ mode: "onChange" });

  const filteredValues = [
    "firstName",
    "lastName",
    "eventTitle",
    "organizationName",
    "eventStartDate",
    "eventEndDate",
    "organizerEmail",
    "organizerName",
  ];
  useEffect(() => {
    if (
      event?.defaultEmails?.resgistrationConfirmationEmail.emailBody !==
        "none" &&
      defaultEmail === "registration"
    ) {
      reset({
        emailSubject:
          event?.defaultEmails?.resgistrationConfirmationEmail.emailSubject,
        isBadgeAttached:
          event?.defaultEmails?.resgistrationConfirmationEmail?.isBadgeAttached,
      });
      setEmailBody(
        event?.defaultEmails?.resgistrationConfirmationEmail.emailBody.replace(
          /<p><br><\/p>/g,
          "&nbsp;<br><br>"
        )
      );
    } else if (
      event?.defaultEmails?.speakerConfirmationEmail.emailBody !== "none" &&
      defaultEmail === "speaker"
    ) {
      reset({
        emailSubject:
          event?.defaultEmails?.speakerConfirmationEmail.emailSubject,
        isBadgeAttached:
          event?.defaultEmails?.speakerConfirmationEmail?.isBadgeAttached,
      });
      setEmailBody(
        event?.defaultEmails?.speakerConfirmationEmail.emailBody.replace(
          /<p><br><\/p>/g,
          "&nbsp;<br><br>"
        )
      );
    } else {
      if (defaultEmail === "registration") {
        reset({
          emailSubject: `Registration confirmed for ${event?.title}`,
          isBadgeAttached: true,
        });

        setEmailBody(
          "Hello {{firstName}} {{lastName}}&nbsp;<br><br>We successfully received your registration for <strong>{{eventTitle}}!</strong>&nbsp;<br><br>{{eventStartDate}} to {{eventEndDate}}&nbsp;<br><br>Open Event Page&nbsp;<br><br>Your QR badge is attached with this email.&nbsp;<br><br>Thanks and regards,&nbsp;<br><br>{{organizationName}}"
        );
      } else {
        reset({
          emailSubject: `You are invited as a speaker at ${event?.title}`,
          isBadgeAttached: true,
        });

        setEmailBody(
          "<div><p>Hey {{firstName}} {{lastName}}</p><br><br><p>You have been invited by {{organizerName}}, to be the speaker at {{eventTitle}} || Date: {{eventStartDate}} || City: {eventLocation}</p><br><br><p>Navigate to the link below to learn more about the event.</p><br><br><p>Here is the link </p><br><br><p>Please use the registered email address to log in.</p><br><br><p>Your QR badge is attached with this email.</p><br><br><p>Thanks & Regards,<br><br>{{organizationName}}</p><br><br><p>Powered By: Warpbay</p></div>"
        );
      }
    }
  }, [event?._id, open]);

  useEffect(() => {
    if (!open) {
      setEmailBody("");
    }
  }, [open]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    if (testEmail) {
      let emailData = {
        email: data.email,
        emailSubject: data.emailSubject,
        emailBody: emailBody,
      };
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/event/testEmailTemplate?eventId=${event?._id}&userId=${savedUserConfig?._id}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              emailData,
            }),
          }
        );
        const allEmails = await response.json();

        if (allEmails.message === "success") {
          dispatch(
            showToast({
              message: "Test Email Send",
              category: "success",
            })
          );
        }
        setOpen(false);
        setIsSubmitting(false);
        reset();
        setTestEmail(false);
        setEmailBody("");
      } catch (error) {
        console.log(error);
        setIsSubmitting(false);
      }
    } else {
      let obj;
      if (defaultEmail === "registration") {
        obj = {
          ...event?.defaultEmails,
          resgistrationConfirmationEmail: {
            emailSubject: data.emailSubject,
            emailBody: emailBody,
            isBadgeAttached: data?.isBadgeAttached,
          },
        };
      } else {
        obj = {
          ...event?.defaultEmails,
          speakerConfirmationEmail: {
            emailSubject: data.emailSubject,
            emailBody: emailBody,
            isBadgeAttached: data?.isBadgeAttached,
          },
        };
      }

      try {
        const updatedEvent = await patchRequest(
          `/event/${eventsId.params.eventId}`,
          {
            defaultEmails: obj,
          }
        );
        if (updatedEvent.status === 200) {
          setEmailDetails(updatedEvent.data?.savedEventConfig);
          dispatch(
            showToast({
              message: "Successfully Saved",
              category: "success",
            })
          );
        }
        setOpen(false);
        setIsSubmitting(false);
        reset();
        setTestEmail(false);
        setEmailBody("");
      } catch (err) {
        dispatch(
          showToast({
            message: "Some Error occured",
            category: "danger",
          })
        );
      }
    }
  };
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    setAccessToken(token);
  }, []);

  useEffect(() => {
    if (dropdown) {
      const editor = textareaRef.current.getEditor();
      editor.focus();
    }
  }, [dropdown]);

  const handleDropdown = (variable) => {
    const editor = textareaRef.current.getEditor();
    const selection = editor.getSelection(true);

    editor.clipboard.dangerouslyPasteHTML(selection.index, `{{${variable}}}`);

    const newCursorPosition = selection.index + variable.length + 4;
    editor.setSelection(newCursorPosition);
    editor.focus();
  };

  const handleEditorChange = (value) => {
    setEmailBody(value);
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="z-40 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="z-40 fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-[45px] md:left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md outline-none hidden md:block text-gray-300"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>X
                          {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
                        </button>
                        <button
                          className="md:hidden flex items-center"
                          onClick={() => setOpen(false)}
                        >
                          <i className="fa fa-angle-left text-[24px]"></i>
                          <span className="text-[17px] pt-0.5">Back</span>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6 fixed z-20 bg-white w-[100%] top-0">
                        <Dialog.Title className="text-[22px] pb-[25px] font-[600] text-gray-900 pt-5">
                          {!testEmail && defaultEmail === "registration"
                            ? "Registration Confirmation Email"
                            : defaultEmail === "speaker" && !testEmail
                            ? "Speaker Confirmation Email"
                            : "Test Email"}
                        </Dialog.Title>
                      </div>
                      <div className="relative mt-[75px] flex-1 px-4 sm:px-6 mb-[50px]">
                        <form
                          onSubmit={handleSubmit((data) =>
                            throttle(() => onSubmit(data), 5000)
                          )}
                        >
                          {testEmail ? (
                            <TextInput
                              register={register}
                              type="email"
                              id={"email"}
                              required
                              label="Email Address"
                              placeholder="Email"
                            />
                          ) : (
                            <div></div>
                          )}

                          <TextInput
                            register={register}
                            type="text"
                            id={"emailSubject"}
                            required
                            label="Email Subject"
                            placeholder="Email Subject"
                          />

                          {/* <TextArea
                            register={register}
                            type="text"
                            id={"emailBody"}
                            required
                            label="Email Body"
                            placeholder="Email Body"
                          /> */}

                          <div className="relative mt-[50px]">
                            <label
                              htmlFor="dropdown"
                              className="text-[12px] text-[#9c9c9c] absolute -top-[18px] left-[5px]"
                            >
                              Email Body&nbsp;
                              <span className="text-red-600">*</span>
                            </label>
                            <ReactQuill
                              value={emailBody}
                              onChange={handleEditorChange}
                              theme="snow"
                              ref={textareaRef}
                              className="custom-editor"
                            />
                            <div className="border border-b-gray-300 border-x-gray-300 border-t-0 h-[50px] w-full bg-gray-50 rounded-b-lg flex items-center">
                              <div
                                className="flex text-gray-400 font-[500] text-[14px] items-center rounded-[3px] border border-gray-300 ml-2 p-1 bg-gray-200 cursor-pointer relative"
                                onClick={() => setDropdown(!dropdown)}
                              >
                                {"{} Variable"}{" "}
                                <img
                                  src="/svgs/arrow.svg"
                                  alt="arrow"
                                  className=" w-[17px] ml-1 "
                                />
                                {dropdown && (
                                  <div
                                    id="dropdown"
                                    className={`absolute top-[30px] overflow-y-scroll max-h-[110px] rounded-lg z-10 w-[160px] bg-white divide-y-0 divide-[#F4F6F9] shadow dark:bg-gray-700`}
                                  >
                                    {filteredValues.map((variable, index) => (
                                      <ul
                                        className="text-[13px] text-gray-700 dark:text-gray-200 list-none cursor-pointer"
                                        onClick={() => handleDropdown(variable)}
                                        key={index}
                                      >
                                        <li>
                                          <a className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                            {variable}
                                          </a>
                                        </li>
                                      </ul>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          {!testEmail && (
                            <div className="mt-3 text-[13px] font-[500] text-primary flex items-center  h-7">
                              <input
                                type="checkbox"
                                {...register("isBadgeAttached")}
                                id={"isBadgeAttached"}
                                className="mr-2 border-[#cacaca] border-2 bg-white checked:bg-primary checked:border-[#d5d8dd] cursor-pointer focus:ring-0"
                              />
                              <label
                                htmlFor="isBadgeAttached"
                                className="cursor-pointer"
                              >
                                Send event badge as attachment.
                              </label>
                            </div>
                          )}

                          <div className="fixed bottom-0 w-[87%] bg-white  h-[80px] flex justify-center items-center ml-[-2px]">
                            {!testEmail ? (
                              <div className="flex justify-between w-full gap-4">
                                <input
                                  disabled={isSubmitting}
                                  value={isSubmitting ? "Loading..." : "Submit"}
                                  type="submit"
                                  className="primary_submit"
                                />

                                <input
                                  disabled={isSubmitting}
                                  value={"Test Email"}
                                  type="button"
                                  onClick={() => {
                                    setTestEmail(true);
                                  }}
                                  className="flex items-center cursor-pointer text-[#1C1C1E] text-[15px] font-[500] border h-[42px] justify-center rounded-[10px] my-[5px] mx-auto px-12"
                                />
                              </div>
                            ) : (
                              <div className="flex justify-between w-full gap-4">
                                <input
                                  disabled={isSubmitting}
                                  value={
                                    isSubmitting
                                      ? "Loading..."
                                      : "Send Test Email"
                                  }
                                  type="submit"
                                  className="primary_submit"
                                />
                                <input
                                  disabled={isSubmitting}
                                  value={"Cancel Test"}
                                  onClick={() => setTestEmail(false)}
                                  type="button"
                                  className="flex items-center cursor-pointer text-danger text-[15px] font-[500] border h-[42px] justify-center rounded-[10px] my-[5px] mx-auto px-11"
                                />
                              </div>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

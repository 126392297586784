import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import TimeInput from "../../../../common/timeInput/TimeInput";
import Select from "../../../../common/inputElements/Select";
import { useForm } from "react-hook-form";
import throttle from "../../../../utils/throttle";
import { useMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import countryCodes from "../../../../utils/CountryCodes.json";
import { showToast } from "../../../../redux/actions/toastActions";

const SelectWhatsAppTemplate = ({
  open,
  setOpen,
  event,
  testTemplate,
  savedUserConfig,
  setTestTemplate,
  setEventDetails,
  singleTemplate,
}) => {
  const [value1, setValue1] = useState([]);
  const [options, setOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dateValue, setDateValue] = useState(new Date());
  const [scheduleTime, setscheduleTime] = useState("");
  const eventsId = useMatch("/events/:eventId/*");
  const [minDate, setminDate] = useState(new Date());
  const [maxDate, setmaxDate] = useState(new Date());
  const [triggerCode, setTriggerCode] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");
  const [allCountryCode, setAllCountryCode] = useState(countryCodes);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({ mode: "onChange" });

  const optionsFull = {
    weekday: "long",
    year: "2-digit",
    month: "short",
    day: "numeric",
  };
  const startDate = new Intl.DateTimeFormat("en-US", optionsFull).format(
    new Date(event?.startDate)
  );
  const endDate = new Intl.DateTimeFormat("en-US", optionsFull).format(
    new Date(event?.endDate)
  );
  const eventUrl = `${window.location.origin}/event/${event?._id}`;
  const completeEventLocation =
    event?.location.addressLine1 +
    ", " +
    event?.location.city +
    ", " +
    event?.location.state;

  useEffect(() => {
    if (testTemplate) {
      // const timeStr = new Date(singleEmail?.campaignTime)
      //   .toISOString()
      //   .slice(11, 19);
      // reset({
      //   emailSubject: singleEmail?.emailSubject,
      //   emailBody: singleEmail?.emailBody,
      //   campaignTime: singleEmail?.campaignTime,
      // });
      const speakerOptions = [
        {
          label: "Registered",
          value: "1",
        },
      ];
      setOptions(speakerOptions);
      setValue1(speakerOptions);
      setValue("selectTo", speakerOptions);
      reset();
      // const date = new Date(singleEmail?.campaignTime);

      // const options = {
      //   hour: "numeric",
      //   minute: "numeric",
      //   hour12: true,
      //   timeZone: "Asia/Kolkata",
      // };
      // const formattedTime = date.toLocaleString("en-US", options);
      // const formattedTimeWithSeparator = formattedTime.replace(":", " : ");
      // setscheduleTime(formattedTimeWithSeparator);
      // setDateValue(new Date(singleEmail.campaignTime));
      // setminDate(new Date());
      // setmaxDate(new Date(event.endDate));
    } else {
      // reset({
      //   emailSubject: "",
      //   emailBody: "",
      // });
      const speakerOptions = [
        {
          label: "Registered",
          value: "1",
        },
      ];
      setOptions(speakerOptions);
      setValue1(speakerOptions);
      setValue("selectTo", speakerOptions);
      // setscheduleTime("");
      // setDateValue(new Date());
      // setminDate(new Date());
      // setmaxDate(new Date(event.endDate));
    }
  }, [event?._id, open]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    if (value1.length === 0) {
      dispatch(
        showToast({
          message: "Please select atleast one option",
          category: "danger",
        })
      );
      setIsSubmitting(false);
    } else {
      if (testTemplate) {
        const matchingCountry = countryCodes.find(
          (country) => country.dial_code === countryCode
        );
        const regex = /^[\d]{10}$/;
        if (!matchingCountry) {
          dispatch(
            showToast({
              message: "Invalid Country Code",
              category: "danger",
            })
          );
          setIsSubmitting(false);
          return;
        }

        if (!regex.test(data.mobile)) {
          dispatch(
            showToast({
              message: "Invalid Mobile Number",
              category: "danger",
            })
          );
          setIsSubmitting(false);
          return;
        }

        try {
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/event/${
              eventsId.params.eventId
            }/${"testWhatsAppTemplate"}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                firstName: savedUserConfig?.firstName,
                lastName: savedUserConfig?.lastName,
                mobile: data.mobile,
                countryCode: countryCode,
                templateName: singleTemplate?.name,
              }),
            }
          );

          let dataResponse = await response.json();
          if (response.status === 200) {
            setEventDetails(dataResponse.savedEventConfig);
            dispatch(
              showToast({
                message: "Message Sent",
                category: "success",
              })
            );
          } else if (response.status === 403) {
            dispatch(
              showToast({
                message: "You have exhausted your limit for test messages",
                category: "danger",
              })
            );
          } else {
            dispatch(
              showToast({
                message: "Please add again!! some error occurred",
                category: "danger",
              })
            );
          }
          setOpen(false);
          setIsSubmitting(false);
          setValue1([]);
          reset();
        } catch (error) {
          console.log(error);
          setIsSubmitting(false);
        }
      } else {
        if (event?.whatsAppMarketing?.approvalStatus !== "approved") {
          dispatch(
            showToast({
              message: "Please request access to enable WhatsApp Marketing",
              category: "danger",
            })
          );
          setIsSubmitting(false);
          return;
        }
        try {
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/event/${
              eventsId.params.eventId
            }/${"selectWhatsAppTemplate"}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                templateName: singleTemplate?.name,
                sendTo: data.selectTo,
              }),
            }
          );

          let dataResponse = await response.json();
          if (response.status === 200) {
            setEventDetails(dataResponse.savedEventConfig);
            dispatch(
              showToast({
                message: "Template Selected",
                category: "success",
              })
            );
          } else {
            dispatch(
              showToast({
                message: "Please add again!! some error occurred",
                category: "danger",
              })
            );
          }
          setOpen(false);
          setIsSubmitting(false);
          setValue1([]);
          reset();
        } catch (error) {
          console.log(error);
          setIsSubmitting(false);
        }
      }
    }
  };

  useEffect(() => {
    if (countryCode) {
      const matchingCountries = countryCodes.filter(
        (country) =>
          country.dial_code
            .toLowerCase()
            .startsWith(countryCode.toLowerCase()) ||
          country.name.toLowerCase().startsWith(countryCode.toLowerCase()) ||
          country.code.toLowerCase().startsWith(countryCode.toLowerCase())
      );

      const nonMatchingCountries = countryCodes.filter(
        (country) =>
          !(
            country.dial_code
              .toLowerCase()
              .startsWith(countryCode.toLowerCase()) ||
            country.name.toLowerCase().startsWith(countryCode.toLowerCase()) ||
            country.code.toLowerCase().startsWith(countryCode.toLowerCase())
          )
      );

      setAllCountryCode([...matchingCountries, ...nonMatchingCountries]);
    }
  }, [countryCode]);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="z-40 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="z-40 fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-[45px] md:left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md outline-none hidden md:block text-gray-300"
                          onClick={() => {
                            setOpen(false);
                            setTestTemplate(false);
                          }}
                        >
                          <span className="sr-only">Close panel</span>X
                          {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
                        </button>
                        <button
                          className="md:hidden flex items-center"
                          onClick={() => {
                            setOpen(false);
                            setTestTemplate(false);
                          }}
                        >
                          <i className="fa fa-angle-left text-[24px]"></i>
                          <span className="text-[17px] pt-0.5">Back</span>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6 fixed z-20 bg-white w-[100%] top-0">
                        <Dialog.Title className="text-[22px] pb-[25px] font-[600] text-gray-900 pt-5">
                          {!testTemplate ? "Select Template" : "Test Template"}
                        </Dialog.Title>
                      </div>
                      <div className="relative mt-[75px] flex-1 px-4 sm:px-6 mb-[50px]">
                        <form
                          onSubmit={handleSubmit((data) =>
                            throttle(() => onSubmit(data), 5000)
                          )}
                        >
                          {/* <TextInput
                           register={register}
                            type="text"
                            id={"campaignName"}
                            required
                            label="Campaign Name"
                            placeholder="Campaign Name"
                          />  */}

                          {!testTemplate ? (
                            <div className="relative mb-[25px]">
                              <label
                                htmlFor="startDate"
                                className="text-[12px] text-[#9c9c9c] absolute -top-[18px] left-[12px]"
                              >
                                To<span className="text-red-500">*</span>
                              </label>
                              <Select
                                multiple
                                register={register}
                                id={"selectTo"}
                                options={options}
                                value={value1}
                                onChange={(event) => {
                                  setValue("selectTo", event);
                                  setValue1(event);
                                }}
                              />
                            </div>
                          ) : (
                            <></>
                          )}

                          {singleTemplate.length !== 0 ? (
                            <div
                              className={`relative mt-${
                                testTemplate ? "[0px]" : "[40px]"
                              } mb-[30px]`}
                            >
                              <label
                                htmlFor="startDate"
                                className="text-[12px] text-[#9c9c9c] absolute -top-[18px] left-[5px]"
                              >
                                Preview
                              </label>
                              <div
                                className="rounded-[8px] p-5 flex justify-center "
                                style={{
                                  backgroundImage: `url(https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg)`,
                                }}
                              >
                                <div className="whitespace-pre-wrap text-[10px] w-[224px] bg-white p-2 rounded-[8px] text-[#121212]">
                                  {singleTemplate?.components[0]?.type ===
                                    "HEADER" &&
                                  singleTemplate?.components[0]?.format ===
                                    "IMAGE" ? (
                                    <div className="h-[110px] w-full rounded-[4px] mb-2 bg-orange-100 flex justify-center items-center flex-col">
                                      <img src="/svgs/IDCard.svg" alt="badge" />
                                      <span className="text-orange-500 font-[500] text-[13px] mt-2">
                                        IMAGE
                                      </span>
                                    </div>
                                  ) : "HEADER" &&
                                    singleTemplate?.components[0].format ===
                                      "TEXT" ? (
                                    <p className="mb-2">
                                      <b>
                                        {singleTemplate?.components[0].text}
                                      </b>
                                    </p>
                                  ) : (
                                    <></>
                                  )}

                                  <p>
                                    {singleTemplate?.components[0].type ===
                                    "HEADER" ? (
                                      <>{singleTemplate?.components[1].text}</>
                                    ) : (
                                      <>{singleTemplate?.components[0].text}</>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {testTemplate ? (
                            <>
                              <div
                                className="relative mb-[28px] w-[100%] 
                                 peer bg-white border border-gray-300 text-gray-900    text-[12px] rounded-lg h-[35px] placeholder-transparent focus:ring-transparent focus:border-primary focus:border-[1.2px] block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white font-medium dark:focus:ring-blue-500 dark:focus:border-blue-500 flex py-0 items-center"
                              >
                                <div className="relative">
                                  <div className="flex items-center">
                                    <input
                                      type="text"
                                      id="code"
                                      required
                                      value={countryCode}
                                      onFocus={() => setTriggerCode(true)}
                                      onBlur={() => setTriggerCode(false)}
                                      onChange={(event) =>
                                        setCountryCode(event.target.value)
                                      }
                                      // {...register("code")}
                                      className={`w-[52px]
                                 peer bg-white border-0 border-gray-300 text-gray-900 text-[12px] rounded-lg h-[30px] placeholder-transparent focus:ring-transparent block pl-1.5 `}
                                      autoComplete="off"
                                    />
                                    <img
                                      src="/svgs/arrow.svg"
                                      alt=""
                                      onClick={() =>
                                        setTriggerCode(!triggerCode)
                                      }
                                      className="transform rotate-180 h-[16px] cursor-pointer mr-2"
                                    />
                                  </div>

                                  {triggerCode && (
                                    <div className="border max-h-[110px] absolute w-[83px] z-20 bg-white overflow-y-scroll rounded-lg border-primary">
                                      {allCountryCode?.map((codes) => (
                                        <span
                                          // key={result}
                                          className="w-[100%] flex h-[40px] items-center pl-2 text-[12px] font-[500] cursor-pointer hover:bg-gray-200 "
                                          onMouseDown={(event) => {
                                            setCountryCode(codes.dial_code);
                                            event.stopPropagation();
                                          }}
                                        >
                                          {codes.code} {codes.dial_code}
                                        </span>
                                      ))}
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="w-[100%]"
                                  onClick={() => setTriggerCode(false)}
                                >
                                  <input
                                    type="tel"
                                    id="mobile"
                                    required
                                    maxLength="10"
                                    autoComplete="off"
                                    className={`w-[100%]
                                   bg-white border-0 text-gray-900 text-[12px] h-[33px] placeholder-transparent focus:ring-transparent p-0 appearance-none   bg-transparent focus:outline-none focus:ring-0 focus:border-blue-500 pl-2
                                 `}
                                    {...register("mobile")}
                                    style={{ backgroundColor: "transparent" }}
                                  />
                                  <label
                                    htmlFor="mobile"
                                    className="absolute left-2 -top-5 mb-1 text-sm peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-900 peer-placeholder-shown:text-opacity-40 peer-placeholder-shown:top-[10px] peer-placeholder-shown:left-[13px] transition-all peer-focus:-top-5 peer-focus:text-gray-900 peer-focus:text-opacity-40 peer-focus:text-[12.5px] font-medium text-gray-900 text-opacity-40 dark:text-gray-300"
                                  >
                                    <>
                                      Phone Number
                                      <span className="text-red-500">*</span>
                                      &nbsp;
                                      <span className="font-[600]">
                                        ({event?.whatsAppMarketing.testCount}
                                        /20)
                                      </span>
                                    </>
                                  </label>
                                </div>
                              </div>
                              <p className="text-[12px] font-[500] mb-4 text-[#E74C3C]">
                                Note: Our testing feature has a maximum limit of
                                20 test messages per user.
                              </p>
                            </>
                          ) : (
                            <>
                              {singleTemplate?.id === "236844172201926" ||
                              singleTemplate?.id === "556645986457627" ||
                              singleTemplate?.id === "745283603671502" ||
                              singleTemplate?.id === "234336692950182" ||
                              singleTemplate?.id === "2655996607881736" ? (
                                <>
                                  <p className="text-[12px] font-[500] mb-4 text-[#E74C3C]">
                                    The message is sent as soon as someone
                                    registers.
                                  </p>
                                </>
                              ) : (
                                <div className="grid grid-cols-2 gap-[50px] mt-[40px] mb-[30px]">
                                  <div className="relative">
                                    <label
                                      htmlFor="startDate"
                                      className="text-[12px] text-[#9c9c9c] absolute -top-[18px] left-[5px]"
                                    >
                                      Scheduled Date
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <DatePicker
                                      onChange={(value) => setDateValue(value)}
                                      value={dateValue}
                                      minDate={minDate}
                                      maxDate={maxDate}
                                      format="dMMMy"
                                    />
                                  </div>

                                  <TimeInput
                                    register={register}
                                    required={true}
                                    value={scheduleTime}
                                    setValue={setValue}
                                    id={"campaignTime"}
                                    label={"Scheduled Time"}
                                    isHalfWidth={false}
                                  />
                                </div>
                              )}
                            </>
                          )}
                          <div className="fixed bottom-0 w-[87%] bg-white  h-[80px] flex justify-center items-center ml-[-2px]">
                            {testTemplate ? (
                              <input
                                disabled={isSubmitting}
                                value={
                                  isSubmitting
                                    ? "Loading..."
                                    : "Send Test Message"
                                }
                                type="submit"
                                className="primary_submit"
                              />
                            ) : (
                              <input
                                disabled={isSubmitting}
                                value={
                                  isSubmitting
                                    ? "Loading..."
                                    : "Select Template"
                                }
                                type="submit"
                                className="primary_submit"
                              />
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default SelectWhatsAppTemplate;

import React from "react";
import BarChart from "../charts/BarChart05";

// Import utilities
import { tailwindConfig } from "../../../../utils/Utils";

function FintechCard03({ attendees, eventId }) {
  const chartData = getChartData(attendees, eventId);
  //get checkin map(attendees)-> map(i.attendee)->map(j.eventSpecificData)->map(k.checkInData)-> l.entry se date, date ++
  return (
    <div className="my-[40px] flex flex-col col-span-full sm:col-span-6 bg-[#f9fbff] shadow-lg rounded-sm">
      <header className="px-5 py-4 border-b border-slate-100">
        <h2 className="font-[600] text-[#000]">
          Participant checkIns - Day Wise
        </h2>
      </header>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
      <BarChart
        data={chartData.chartData}
        width={595}
        height={248}
        toolTip="Attendees"
        attendees={chartData.sum}
      />
    </div>
  );
}

function getChartData(attendees, eventId) {
  const indexes = new Map();
  let sum = 0;

  const chartData = {
    labels: [],
    datasets: [
      {
        label: "Check-ins",
        data: [],
        backgroundColor: tailwindConfig().theme.colors.indigo[500],
        hoverBackgroundColor: tailwindConfig().theme.colors.indigo[600],
        barPercentage: 0.66,
        categoryPercentage: 0.66,
      },
      {
        label: "Check-outs",
        data: [],
        backgroundColor: tailwindConfig().theme.colors.slate[300],
        hoverBackgroundColor: tailwindConfig().theme.colors.slate[400],
        barPercentage: 0.66,
        categoryPercentage: 0.66,
      },
    ],
  };
  let date2;
  attendees.forEach((attendee) => {
    attendee.attendee.map((event) => {
      const checkIns = event.eventSpecificData.filter(
        (obj) => obj.eventId === eventId
      );
      checkIns.map((checkIn) => {
        const entry = checkIn.checkInData[0];
        const date1 = new Date(entry.entry);
        const day = new Date(entry.entry).getDate();
        const month = new Date(entry.entry).getMonth();
        const year = new Date(entry.entry).getFullYear();
        const date = `${day}-${month + 1}-${year}`;
        var nextDay = new Date(date1.getTime() + 24 * 60 * 60 * 1000);

        date2 = `${nextDay.getDate()}-${
          nextDay.getMonth() + 1
        }-${nextDay.getFullYear()}`;

        sum++;
        if (indexes.has(date)) {
          const index = indexes.get(date);
          chartData.datasets[0].data[index] =
            chartData.datasets[0].data[index] + 1;
        } else {
          indexes.set(date, chartData.labels.length);
          chartData.labels.push(date);
          chartData.datasets[0].data.push(1);
          chartData.datasets[1].data.push(0);
        }

        if (entry.exit) {
          const day = new Date(entry.exit).getDate();
          const month = new Date(entry.exit).getMonth();
          const year = new Date(entry.exit).getFullYear();
          const date = `${day}-${month + 1}-${year}`;

          if (indexes.has(date)) {
            const index = indexes.get(date);
            chartData.datasets[1].data[index] =
              chartData.datasets[1].data[index] + 1;
          } else {
            indexes.set(date, chartData.labels.length);
            chartData.labels.push(date);
            chartData.datasets[1].data.push(1);
            chartData.datasets[0].data.push(0);
          }
        }
      });
    });
  });

  if (chartData.datasets[0].data.length === 1) {
    indexes.set(date2, chartData.labels.length);
    chartData.labels.push(date2);
    chartData.datasets[0].data.push(0);
    chartData.datasets[1].data.push(0);
  }

  return { sum, chartData };
}

export default FintechCard03;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DefaultProfilePicture from "../../common/defaultProfilePicture/DefaultProfilePicture";
import { useDispatch, useSelector } from "react-redux";
import { cancelMeeting } from "../../redux/actions/meetingActions";
import { getAuthenticatedRequest } from "../../utils/API/api.ts";
import BookMeeting from "../people/BookMeeting";
import { getUserDetails } from "../../redux/actions/userActions";

const AttendeeSpeakers = ({ singleEvent }) => {
  const navigate = useNavigate();
  const [trigger, settrigger] = useState(false);
  const [isCancelled, setisCancelled] = useState(false);
  const [singleAttendee, setsingleAttendee] = useState([]);
  const [meetingDetails, setMeetingDetails] = useState([]);
  const [sentMeetings, setSentMeetings] = useState([]);
  const [receivedMeetings, setReceivedMeetings] = useState([]);
  const [scheduledMeetings, setScheduledMeetings] = useState([]);
  const dispatch = useDispatch();
  const cancelledMeeting = useSelector((state) => state.cancelledMeeting);
  const userDetails = useSelector((state) => state.userDetails);
  const { savedUserConfig } = userDetails;

  const now = new Date().getTime();
  let eventEnded =
    new Date(
      new Date(singleEvent?.endDate).toLocaleString("en-US", {
        timeZone: "Asia/Kolkata",
      })
    ).getTime() <= now;

  useEffect(() => {
    if (cancelledMeeting.error) {
      alert("Please Cancel again!! Some error occurred");
    }
  }, [cancelledMeeting]);

  const deleteMeeting = async (meetingData, singleAttendee) => {
    const cancelMeetings = sentMeetings.filter((meeting) => {
      return meeting.meetingID !== meetingData.meetingID;
    });
    setSentMeetings(cancelMeetings);
    dispatch(cancelMeeting({ meetingID: meetingData.meetingID }));
    setsingleAttendee(singleAttendee);
    setMeetingDetails(meetingData);
    setisCancelled(true);
    settrigger(true);
  };

  useEffect(() => {
    async function fetch() {
      const response = await getAuthenticatedRequest(
        "/user/userMeeting-details"
      );
      const { meetingRequestSent, meetingRequestReceived, scheduledMeetings } =
        response?.data.user;

      const filteredscheduledMeetings = scheduledMeetings.filter(
        (meeting) => meeting.eventId === singleEvent?._id
      );
      const filteredSentMeetings = meetingRequestSent.filter(
        (meeting) => meeting.eventId === singleEvent?._id
      );
      const filteredReceivedMeetings = meetingRequestReceived.filter(
        (meeting) => meeting.eventId === singleEvent?._id
      );

      setScheduledMeetings(filteredscheduledMeetings);
      setSentMeetings(filteredSentMeetings);
      setReceivedMeetings(filteredReceivedMeetings);
    }
    fetch();
  }, [singleEvent, trigger]);

  const checkIfEmailExists = (email) => {
    return (
      scheduledMeetings?.some((obj) => obj.meetingWith[0]?.email === email) ||
      receivedMeetings?.some((obj) => obj.meetingWith[0]?.email === email) ||
      sentMeetings?.some((obj) => obj.meetingWith[0]?.email === email)
    );
  };

  useEffect(() => {
    let accessToken = localStorage.getItem("accessToken");
    dispatch(getUserDetails({ accessToken: accessToken }));
  }, [savedUserConfig?._id]);

  return (
    <div className="w-full min-h-[90vh] bg-[#FAFAFA] md:ml-[17%] md:w-[83%] md:bg-white md:min-h-full ">
      {/* <div className="w-full h-[60px] fixed top-0 bg-white flex items-center px-[16px] border-b border-[#EDEDED] md:mt-[59px] z-10 md:hidden">
        <img
          src="/svgs/Arrowleft.svg"
          className="w-[24px] h-[24px] object-cover cursor-pointer"
          onClick={() => navigate("/events")}
        />
        <span className="ml-2 text-[22px] font-[500]">Speakers</span>
      </div> */}

      {trigger && (
        <BookMeeting
          trigger={trigger}
          settrigger={settrigger}
          isCancelled={isCancelled}
          setisCancelled={setisCancelled}
          event={singleEvent}
          singleAttendee={singleAttendee}
          setMeetingDetails={setMeetingDetails}
          meetingDetails={meetingDetails}
          deleteMeeting={deleteMeeting}
          receivedMeetings={receivedMeetings}
          sentMeetings={sentMeetings}
          savedUserConfig={savedUserConfig}
        />
      )}
      <div className="mx-[16px] pt-[20px] pb-[80px] md:pt-0 md:mt-[80px] md:w-[730px] md:grid md:grid-cols-2 md:gap-2 md:items-start">
        {singleEvent.speakers && singleEvent.speakers.length > 0 ? (
          singleEvent.speakers.map((speakerData, key) =>
            speakerData?.email === savedUserConfig?.email ? (
              <></>
            ) : (
              <>
                <div
                  key={key}
                  className=" bg-white mb-4 rounded-[10px] p-[16px] md:border md:w-[350px] md:mr-[15px] border border-[#EDEDED]"
                >
                  <div className="flex items-center">
                    {speakerData.profilePicture ? (
                      <img
                        src={speakerData.profilePicture}
                        className="rounded-full w-[45px] h-[45px] object-cover mr-3"
                      />
                    ) : (
                      <div className="mr-2">
                        <DefaultProfilePicture
                          firstName={speakerData.firstName}
                          lastName={speakerData.lastName}
                          style={{
                            width: "45px",
                            height: "45px",
                            borderRadius: "300px",
                            fontSize: "14px",
                          }}
                        />
                      </div>
                    )}

                    <div className="">
                      <div className="sm:text-[14px] text-[13px] font-[500] pt-[5px] md:font-semibold">
                        {speakerData.firstName} {speakerData.lastName}{" "}
                      </div>
                      <div className="text-[12px] font-medium py-[0px] text-gray-500">
                        {speakerData.jobTitle}, {speakerData.organization}
                        {/* {speakerData.email} */}
                      </div>
                    </div>
                  </div>
                  <div className="text-[rgba(0,0,0,0.5)] text-sm font-medium mt-2 md:h-[47px] overflow-hidden">
                    {speakerData.speaker.eventSpecificData[0].bio.split("")
                      .length > 100 ? (
                      <>
                        {" "}
                        {speakerData.speaker.eventSpecificData[0].bio.slice(
                          0,
                          90
                        )}
                        ...
                      </>
                    ) : (
                      <>
                        {" "}
                        {speakerData.speaker.eventSpecificData[0].bio.slice(
                          0,
                          150
                        )}
                      </>
                    )}
                  </div>
                  {scheduledMeetings?.some(
                    (obj) => obj.meetingWith[0]?.email === speakerData.email
                  ) ? (
                    <div className="text-[#2ECC71] text-[12px] font-[600] my-2 md:my-1 flex items-center ">
                      <img
                        src="/svgs/Checkcircle.svg"
                        alt="check"
                        className="mr-1 rounded-full h-[17px]"
                      />
                      Your meeting is scheduled.
                    </div>
                  ) : sentMeetings?.some(
                      (obj) => obj.meetingWith[0]?.email === speakerData.email
                    ) ? (
                    <div className="text-primary text-[12px] font-[600] my-2 md:my-1 flex items-center">
                      <img
                        src="/svgs/send.svg"
                        alt="send"
                        className="cursor-pointer mr-1 h-[12px]"
                      />
                      You sent a meeting request
                    </div>
                  ) : receivedMeetings?.some(
                      (obj) => obj.meetingWith[0]?.email === speakerData.email
                    ) ? (
                    <div className="text-primary text-[12px] font-[600] my-2 md:my-1 flex items-center">
                      <img
                        src="/svgs/send.svg"
                        alt="send"
                        className="cursor-pointer mr-1 h-[12px]"
                      />
                      You received a meeting request.
                    </div>
                  ) : (
                    <div className="text-[12px] md:my-1 md:invisible hidden md:block">
                      No content
                    </div>
                  )}

                  <div className="mymd:mt-3 mt-0 flex justify-between mt-3">
                    <span className="flex items-center cursor-pointer text-[#0E76A8] text-[12px] font-[500] text-[12px] border h-[32px] w-[47%] justify-center rounded-[4px] md:w-[47%]">
                      <a
                        href={speakerData.linkedinUrl}
                        className="flex items-center "
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/svgs/linkedinblue.svg"
                          alt="share"
                          className="mr-2 h-[15px] w-[15px]"
                        />
                        Visit Linkedin
                      </a>
                    </span>
                    {checkIfEmailExists(speakerData.email) ? (
                      <span
                        className="flex items-center cursor-pointer text-black text-[12px] font-[500] h-[32px] w-[47%] justify-center rounded-[4px] bg-white md:w-[47%] border"
                        onClick={() => {
                          navigate(
                            `/attendee/${singleEvent?._id}?tab=meetings`
                          );
                        }}
                      >
                        Go to meetings
                      </span>
                    ) : eventEnded ? (
                      <span className="flex items-center text-white text-[12px] font-[500] h-[32px] w-[47%] justify-center rounded-[4px] bg-primary md:w-[47%] opacity-50">
                        Book a meeting
                      </span>
                    ) : (
                      <span
                        className="flex items-center cursor-pointer text-white text-[12px] font-[500] h-[32px] w-[47%] justify-center rounded-[4px] bg-primary md:w-[47%]"
                        onClick={() => {
                          setsingleAttendee(speakerData);
                          settrigger(true);
                        }}
                      >
                        Book a meeting
                      </span>
                    )}
                  </div>
                </div>
              </>
            )
          )
        ) : (
          <div className="grid w-full place-items-center h-[250px] mt-[100px] md:mt-[60px]">
            <div>
              <img
                src="/svgs/nullState.svg"
                alt=""
                className="w-full h-[250px]"
              />
              <p className="text-[15px] font-[500] text-[#717171] text-center mt-5">
                Nothing here...
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AttendeeSpeakers;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { createEvent } from "../../../redux/actions/eventActions";
import Card from "../../../common/cards/Card";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../../../common/progressBar/ProgressBar";

function combineDateAndTime(dateString, time) {
  // Extract date components
  const date = new Date(dateString);
  // Extract time components
  const [hours, minutes, seconds] = time.split(":").map(Number);
  const milliseconds = 0;
  date.setHours(hours, minutes, seconds, milliseconds);

  let isoString = date.toISOString();
  console.log(isoString);
  return isoString; //in UTC
}

const EventDescription = (props) => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const createEventB = useSelector((state) => state.createdEvent);
  const { createdEvent, newEvent, location } = createEventB;
  const [characterCount, setCharacterCount] = useState("");

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      shortDescription: `Tell us more about the event, what is the event about? How long have you been doing this Event?`,
    },
  });
  async function onSubmit(data) {
    setIsSubmitting(true);
    dispatch(
      createEvent({
        ...newEvent,
        location: location,
        shortDescription: data.shortDescription,
      })
    );
    localStorage.removeItem("showtype");
    localStorage.removeItem("location");
    localStorage.removeItem("shortDescription");
    localStorage.removeItem("createvent");

    return;
  }

  useEffect(() => {
    const description = JSON.parse(localStorage.getItem("shortDescription"));
    if (description) {
      reset({
        shortDescription: description,
      });
      setCharacterCount(description);
    } else {
      setCharacterCount(
        "Tell us more about the event, what is the event about? How long have you been doing this Event?"
      );
    }
  }, []);

  useEffect(() => {
    //to redirect on same event dashboard
    if (createdEvent?.newEventConfig?._id) {
      setIsSubmitting(true);
      navigate(`/events/${createdEvent.newEventConfig._id}?show=eventInfo`);
    }
  }, [createdEvent?.newEventConfig?._id]);

  const handleText = (e) => {
    setCharacterCount(e.target.value);
    localStorage.setItem("shortDescription", JSON.stringify(e.target.value));
  };

  return (
    <>
      <div className="grid  justify-center content-center w-full sm:max-w-[1280px] mx-auto min-h-[calc(100vh-58px)]">
        <Card>
          <form
            className="w-[340px] flex flex-col gap-[20px]"
            onSubmit={handleSubmit(onSubmit)}
          >
            <p className="text-[23px] font-[700] block text-left w-full">
              Tell us more about your event
            </p>

            <ProgressBar width="100" step={4} />

            <div className="w-[340px]">
              <textarea
                rows={4}
                cols={50}
                // className="bg-gray-100 border-0 rounded-[10px] focus:outline-slate-50"
                className="border-none text-[14px] font-medium rounded-[10px] bg-[#F4F6F9] outline-none focus:border focus:border-bg-[rgb(189, 189, 189)] focus:outline-none w-[340px]"
                placeholder="Event description..."
                maxLength={2500}
                {...register("shortDescription", {
                  required: true,
                })}
                onChange={handleText}
                // value={event.description}
                // onChange={(e) => {
                //   setEvent({ ...event, description: e.target.value });
                //   console.log(event);
                // }}
              ></textarea>
              <div className="flex justify-end text-[12px] font-[500] text-gray-500">
                {characterCount.split("").length}/2500 characters
              </div>
            </div>

            <input
              type="submit"
              value={isSubmitting ? "Launching your event..." : "Create Event"}
              className="primary_submit"
              disabled={isSubmitting}
            />
            <div className="-mt-[20px] spacer"></div>
            <span className="go_back_btn" onClick={() => props.setStep(3)}>
              <i className="fa-solid fa-arrow-left"></i> Go Back
            </span>
          </form>
        </Card>
      </div>
    </>
  );
};

export default EventDescription;

import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import throttle from "../../../../utils/throttle";
import { useMatch } from "react-router-dom";
import TextInput from "../../../../common/inputElements/TextInput";
import { useDispatch } from "react-redux";
import { patchRequest } from "../../../../utils/API/api.ts";
import { showToast } from "../../../../redux/actions/toastActions";

const AddWhatsappApi = ({ open, setOpen, event, setEventDetails }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const eventsId = useMatch("/events/:eventId/*");
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    if (
      event?.whatsAppMarketing?.approvalStatus === "approved" &&
      event?.whatsAppMarketing?.phoneNumberId &&
      event?.whatsAppMarketing?.whatsAppBusinessAccountID &&
      event?.whatsAppMarketing?.authorizationToken
    ) {
      reset({
        phoneNumberId: event?.whatsAppMarketing?.phoneNumberId,
        whatsAppBusinessAccountID:
          event?.whatsAppMarketing?.whatsAppBusinessAccountID,
        authorizationToken: event?.whatsAppMarketing?.authorizationToken,
      });
    }
  }, []);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    if (event?.whatsAppMarketing?.approvalStatus !== "approved") {
      dispatch(
        showToast({
          message: "Please request access to enable WhatsApp Marketing",
          category: "danger",
        })
      );
      setIsSubmitting(false);
      return;
    }
    let validToken = await getApprovedWhatsAppTemplates(data);

    if (!validToken?.data) {
      dispatch(
        showToast({
          message: "Please check you api details",
          category: "danger",
        })
      );
      setIsSubmitting(false);
      return;
    }

    try {
      const updatedEvent = await patchRequest(
        `/event/${eventsId.params.eventId}`,
        {
          whatsAppMarketing: {
            ...event?.whatsAppMarketing,
            ...data,
          },
        }
      );
      if (updatedEvent.status === 200) {
        setEventDetails(updatedEvent.data?.savedEventConfig);
        setOpen(false);
        setIsSubmitting(false);
        dispatch(
          showToast({
            message: "Successfully Added",
            category: "success",
          })
        );
      }
    } catch (err) {
      setOpen(false);
      setIsSubmitting(false);
      dispatch(
        showToast({
          message: "Some Error occured",
          category: "danger",
        })
      );
    }
  };

  const getApprovedWhatsAppTemplates = async (data) => {
    try {
      const response = await fetch(
        `https://graph.facebook.com/v16.0/${data?.whatsAppBusinessAccountID}/message_templates?limit=10&status=approved&access_token=${data?.authorizationToken}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const dataResponse = await response.json();
      return dataResponse;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="z-40 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="z-40 fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-[45px] md:left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md outline-none hidden md:block text-gray-300"
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          <span className="sr-only">Close panel</span>X
                          {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
                        </button>
                        <button
                          className="md:hidden flex items-center"
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          <i className="fa fa-angle-left text-[24px]"></i>
                          <span className="text-[17px] pt-0.5">Back</span>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6 fixed z-20 bg-white w-[100%] top-0">
                        <Dialog.Title className="text-[22px] pb-[25px] font-[600] text-gray-900 pt-5">
                          Add Api
                        </Dialog.Title>
                      </div>
                      <div className="relative mt-[70px] flex-1 px-4 sm:px-6 mb-[50px]">
                        <form
                          onSubmit={handleSubmit((data) =>
                            throttle(() => onSubmit(data), 5000)
                          )}
                        >
                          <TextInput
                            register={register}
                            type="text"
                            id={"phoneNumberId"}
                            required
                            label="Phone Number ID"
                            placeholder="Phone Number ID"
                          />

                          <TextInput
                            register={register}
                            type="text"
                            id={"whatsAppBusinessAccountID"}
                            required
                            label="WhatsApp Business Account ID"
                            placeholder="WhatsApp Business Account ID"
                          />

                          <TextInput
                            register={register}
                            type="text"
                            id={"authorizationToken"}
                            required
                            label="Authorization Token (Permanent)"
                            placeholder="Authorization Token (Permanent)"
                          />

                          <div className="fixed bottom-0 w-[87%] bg-white  h-[80px] flex justify-center items-center ml-[-2px]">
                            <input
                              disabled={isSubmitting}
                              value={
                                isSubmitting
                                  ? "Loading..."
                                  : "Verify Api Details"
                              }
                              type="submit"
                              className="primary_submit"
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default AddWhatsappApi;

import React, { useEffect, useState } from "react";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import { getRequest } from "../../../utils/API/api.ts";

function CheckinAnalytics() {
  const eventsId = useMatch("events/:eventId/*");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = searchParams.get("location");
  const [options, setOptions] = useState({
    total: { value: 0, title: "Registrations" },
    attended: {
      value: 0,
      title: location === "eventCheckin" ? "Attended" : `${location} Attendees`,
    },
    notAttended: {
      value: 0,
      title: "Yet to Attend",
    },
    checkedIn: {
      value: 0,
      title: location === "eventCheckin" ? "Checked In" : "Present",
    },
    checkedOut: {
      value: 0,
      title: location === "eventCheckin" ? "Checked Out" : "Left",
    },
  });

  useEffect(() => {
    if (eventsId.params.eventId && options) {
      getAttendeeStats();
    }
  }, [eventsId.params.eventId]);

  const getAttendeeStats = async () => {
    const response = await getRequest(
      `attendee/${eventsId.params.eventId}/stats?location=${location}`
    );

    const newOptions = { ...options };

    Object.keys(newOptions).forEach((stat) => {
      newOptions[stat].value = response?.data?.registrations[stat];
    });

    setOptions(newOptions);
  };

  return (
    <div>
      <div
        className="flex items-center cursor-pointer mymd:mt-[0px] mymd:w-[110px]"
        onClick={() => navigate(-1)}
      >
        <img
          src="/svgs/Arrowleft.svg"
          alt=""
          className="w-[20px] mr-2 ml-[-16px]"
        />
        <span className="font-[500] text-[15px]"> Go back</span>
      </div>
      <div className="flex flex-wrap justify-center rounded-lg h-[300px] items-center ">
        {options &&
          Object.keys(options).length > 0 &&
          Object.keys(options).map((key, index) => {
            return (
              <>
                {options[key].title === "Yet to Attend" &&
                location !== "eventCheckin" ? (
                  <></>
                ) : (
                  <SingleAnalytics
                    key={key}
                    index={index}
                    title={options[key].title}
                    value={options[key].value}
                  />
                )}
              </>
            );

            // options[key].title !== "Yet to Attend" ? (
            //   <SingleAnalytics
            //     key={key}
            //     index={index}
            //     title={options[key].title}
            //     value={options[key].value}
            //     location={location}
            //   />
            // ) : (
            //   <></>
            // );
          })}
      </div>
      {/* <div className="flex flex-col items-center m-10 ">
        <div
          className="px-5 grid place-items-center cursor-pointer"
          onClick={() => navigate(`${location.pathname}/scan`)}
        >
          <img src="/svgs/qr.svg" alt="QR" className="" />
          <div>Scan QR</div>
        </div>
      </div> */}
    </div>
  );
}

const SingleAnalytics = ({ index, title, value }) => {
  return (
    <div
      id={`${index}`}
      key={index}
      className="w-[135px] h-[135px] mx-6 mb-4 rounded-lg bg-[#f9fbff] shadow-lg flex items-center justify-around flex-col"
    >
      <div className="font-[600] text-[#2b2b2b] text-center text-[16px]">
        {title}
      </div>
      <div className="font-[700] text-[#2b2b2b] text-[23px] pb-5">{value}</div>
    </div>
  );
};

export default CheckinAnalytics;
